import _ from "lodash";
import {
  CPDActivityType,
  CompetenceActivityClass,
  EvidenceAttachment,
  EvidenceDefinition,
  EvidencePart,
  EvidenceStandardTag,
  IEvidence,
  IEvidenceDraft,
  IMandatoryStandard,
  LearningObjective,
  LinkedPartType,
  ProgramData,
  ProgramSkill,
  ProgressCheck,
} from "../Interfaces";
import { FileContent } from "use-file-picker";
import { format } from "date-fns";
import { IAttachmentProgress } from "../types/Components";
import * as ProgramUtils from "../utils/programUtils";

/**
 * Checks whether a user's selected attachment already exists before adding it to the upload array
 * @param plainFiles - An array of the selected file(s) metadata
 * @param filesContent - An array of the selected file(s) contents
 * @param attachments - The current attachments for the evidence
 * @param attachmentsToAdd - The attachments the user wants to add to the evidence
 * @returns The processed arrays of attachments
 */
export function addAttachmentToUploadArray(
  plainFiles: File[],
  filesContent: FileContent[],
  attachments: EvidenceAttachment[],
  attachmentsToAdd: EvidenceAttachment[]
): { attachments: EvidenceAttachment[]; toAdd: EvidenceAttachment[] } {
  const _attachments = _.cloneDeep(attachments);
  const toAdd = _.cloneDeep(attachmentsToAdd);

  for (let i = 0; i < plainFiles.length; i++) {
    const file = {
      name: plainFiles[i].name,
      type: plainFiles[i].type,
      size: plainFiles[i].size,
      content: filesContent[i].content,
      lastModified: plainFiles[i].lastModified,
    };

    const fileExists = _.findIndex(_attachments, (item) => item.name === file.name) > -1;
    const adding = _.findIndex(toAdd, (item) => item.name === file.name) > -1;

    if (!fileExists && !adding) {
      _attachments.push(file);
      toAdd.push(file);
    }
  }

  return {
    attachments: _attachments,
    toAdd,
  };
}

/**
 * Inserts a title into the evidenceJSON of a piece of evidence using the competence name
 * @param evidenceJSON - The evidence to insert
 * @param competence - The current progress check competence
 * @returns The evidenceJSON object with the added Title field
 */
export function addTitleToProgressCheck(evidenceJSON: any, competence: CompetenceActivityClass): any {
  const object = _.cloneDeep(evidenceJSON);
  const title = competence.Name;

  const newObject = _.extend(object, { Title: title });

  return newObject;
}

/**
 * Adds the the required skill to a piece of evidence for the chosen competence
 * @param evidenceJSON - The evidence to insert skill into
 * @param skill - The required skill to add
 * @param competence - The chosen competence
 * @returns The evidenceJSON item with the required skill inserted
 */
export function addRequiredSkill(evidenceJSON: any, skill: ProgramSkill, competence: CompetenceActivityClass): any {
  const evidenceDefinitions = competence["Evidence Definitions"][0];
  const evidenceParts = evidenceDefinitions?.["Evidence parts"] || [];
  const evidencePartWithSkills = evidenceParts.filter((item: EvidencePart) => item.Skills && item.Skills.length > 0)[0];

  let object = _.cloneDeep(evidenceJSON);
  let key = evidencePartWithSkills.Name;
  let newObject = _.extend(object, { [key]: [skill.Name] });

  return newObject;
}

export function addSelectedStandard(evidenceJSON: any, selectedStandard: IMandatoryStandard): any {
  const object = _.cloneDeep(evidenceJSON);

  let newObject = _.extend(object, { Comps: [{ id: selectedStandard.id, Name: selectedStandard.name }], LOs: [] });

  return newObject;
}

export function calculateTotalProgress(progressData: IAttachmentProgress[]): number {
  const percentages = progressData.map((item) => item.percentage);

  const progress = percentages.reduce((a, b) => a + b, 0) / (percentages.length * 100);

  return progress;
}

/**
 * Checks if any fields have been filled in by the user to be able to save as a draft
 * @param currentEvidence - The evidence to process
 * @returns
 */
export function checkAnyFieldsFilled(currentEvidence: IEvidence): boolean {
  return Object.keys(currentEvidence).length > 0;
}

/**
 * Checks whether this program evidence has a title field
 * @param evidenceJSON - The evidenceJSON field of the evidence
 * @returns
 */
export function checkEvidenceJSONHasTitle(evidenceJSON: any): boolean {
  return evidenceJSON.Title !== undefined;
}

export function checkIfSomeEvidenceAddedForComp(evidence: IEvidence[], comp: CompetenceActivityClass) {
  if (!evidence.length) return false;

  let res = false;

  // check if evidence is added
  for (const e of evidence) {
    const parsedEvidence = JSON.parse(e.evidenceJSON);
    if (parsedEvidence.programInfo.progressCheckCompetenceID === comp.ID) {
      res = true;
    }
  }

  // check if los are added
  if (comp["Learning Objective"]) {
    for (const lo of comp["Learning Objective"]) {
      const loFound = evidence.some((e) => {
        let predicateResult = false;
        const parsedEvidence = JSON.parse(e.evidenceJSON);
        if (parsedEvidence["Comp Selector"]) {
          Array.from(parsedEvidence["Comp Selector"]).forEach((it: any) => {
            it.selectedOutcomes.forEach((outcome: any) => {
              if (outcome.id === lo.ID) {
                predicateResult = true;
              }
            });
          });
        }
        if (parsedEvidence["LearningOutcomes"]) {
          Array.from(parsedEvidence["LearningOutcomes"]).forEach((outcome: any) => {
            if (outcome.ID === lo.ID) {
              predicateResult = true;
            }
          });
        }

        return predicateResult;
      });

      if (loFound) {
        res = true;
      }
    }
  }

  return res;
}

// TODO this is also used in CustomProgressCheckButton - consider putting in ProgramUtils.ts instead. comps count is also done in CustomProgressCheckEvidenceButton.tsx
export function calculateCompsDone(programEvidence: IEvidence[], progressCheck: ProgressCheck) {
  if (!programEvidence) {
    console.log("programEvidence is undefined");
    return 0;
  }
  const filteredEvidence = programEvidence.filter((e) => {
    const parsed = JSON.parse(e.evidenceJSON);
    const onHoldReset = parsed.OnHoldReset ?? 0;
    if (!e.draft && onHoldReset === 0 && progressCheck.ID === parsed.programInfo.progressCheckID) {
      return true;
    }
  });

  let count = 0;
  progressCheck["Competence/Activity"].forEach((comp) => {
    if (comp["Number Evidences required"] === 0) return;

    const requiredNumber = comp["Number Evidences required"] ?? 0;
    const evidenceForComp = filteredEvidence.filter((e) => {
      const parsed = JSON.parse(e.evidenceJSON);
      return parsed.programInfo.progressCheckCompetenceID == comp.ID;
    });
    if (evidenceForComp.length) {
      if (comp.MultipleAllowed) {
        if (evidenceForComp.length > 0) count += 1;
      } else {
        if (evidenceForComp.length >= requiredNumber) count += 1;
      }
    }
  });

  return count;
}

export function hasReachedEvidenceCount(
  evidence: IEvidence[],
  comp: CompetenceActivityClass
): "NONE" | "IN_PROGRESS" | "COMPLETE" {
  const filteredEvidence: IEvidence[] = evidence.filter((evidence: IEvidence) => {
    const evidenceJSON = evidence.evidenceJSON && JSON.parse(evidence.evidenceJSON);
    return !evidenceJSON.OnHoldReset && !evidence.draft;
  });

  let evidenceCount = 0;
  for (let e of filteredEvidence) {
    const parsedEvidence = JSON.parse(e.evidenceJSON);
    if (parsedEvidence.programInfo.progressCheckCompetenceID === comp.ID) {
      evidenceCount += 1;
    }
  }

  if (evidenceCount === 0) {
    return "NONE";
  } else if (evidenceCount >= (comp["Number Evidences required"] ?? 1)) {
    return "COMPLETE";
  } else {
    return "IN_PROGRESS";
  }
}

export function hasLearningOutcomes(
  evidence: IEvidence[],
  comp: CompetenceActivityClass
): "NONE" | "IN_PROGRESS" | "COMPLETE_REQUIRED" | "COMPLETE_ALL" {
  const filteredEvidence: IEvidence[] = evidence.filter((evidence: IEvidence) => {
    const evidenceJSON = evidence.evidenceJSON && JSON.parse(evidence.evidenceJSON);
    return !evidenceJSON.OnHoldReset && !evidence.draft;
  });

  const totalNumberOfLearningOutcomes = comp["Learning Objective"]?.length ?? 0;

  let losRequired: string[] = []; // map of ids
  comp["Learning Objective"]?.forEach((currentLearningOutcome) => {
    const loFound = losRequired.find((lo) => lo.ID === currentLearningOutcome.ID);
    if (currentLearningOutcome.NumberRequired && !loFound) {
      losRequired.push(currentLearningOutcome.ID);
    }
  });

  let losDone = 0;
  let requiredLosDone: string[] = [];
  comp["Learning Objective"]?.forEach((lo) => {
    const numRequired = !lo.NumberRequired || lo.NumberRequired === 0 ? 1 : lo.NumberRequired;
    let countOfLearningOutcomesDone = 0;
    filteredEvidence.forEach((e) => {
      const parsedEvidence = JSON.parse(e.evidenceJSON);
      if (parsedEvidence["Comp Selector"]) {
        Array.from(parsedEvidence["Comp Selector"]).forEach((it: any) => {
          it.selectedOutcomes.forEach((outcome: any) => {
            if (outcome.id === lo.ID) {
              countOfLearningOutcomesDone += 1;
            }
          });
        });
      }
      if (parsedEvidence["LearningOutcomes"]) {
        Array.from(parsedEvidence["LearningOutcomes"]).forEach((outcome: any) => {
          if (outcome.ID === lo.ID) {
            countOfLearningOutcomesDone += 1;
          }
        });
      }
    });

    if (countOfLearningOutcomesDone >= numRequired) {
      losDone += 1;

      if (numRequired > 0) {
        requiredLosDone.push(lo.ID);
      }
    }
  });

  if (losDone === 0) {
    return "NONE";
  } else if (losDone === totalNumberOfLearningOutcomes) {
    return "COMPLETE_ALL";
  } else if (losRequired.every((it) => requiredLosDone.includes(it))) {
    return "COMPLETE_REQUIRED";
  } else {
    return "IN_PROGRESS";
  }
}

export function checkIfEvidencePartIsRequired(evidencePart: EvidencePart, evidenceJSON: any): boolean {
  let required = true;

  if (evidencePart.Skills && evidencePart.Skills.length > 0) {
    required = checkLinkedPartIsRequiredSkill(evidencePart, evidenceJSON);
  } else if (evidencePart["Linked Part trigger"]) {
    required = checkLinkedPartIsRequired(evidencePart, evidenceJSON);
  }

  return required;
}

// returns true if evidence parts contains a trigger (and it's been triggered), returns false otherwise // TODO replace
export const evidencePartTriggerUsed = (evidenceParts: EvidencePart[], evidenceJSON: any) => {
  let triggerObj: null | { trigger: string; evidenceKey: string } = null;
  for (const part of evidenceParts) {
    if (part["Progress Check Submission Trigger"]) {
      triggerObj = { trigger: part["Progress Check Submission Trigger"], evidenceKey: part.Name };
      break;
    }
  }

  if (triggerObj && evidenceJSON[triggerObj.evidenceKey] === triggerObj.trigger) {
    return true;
  }

  return false;
};

// returns true if evidence parts contains a trigger, returns false otherwise // TODO replace
export const evidencePartTriggerFound = (evidenceParts: EvidencePart[]) => {
  let result = false;
  for (const part of evidenceParts) {
    if (part["Progress Check Submission Trigger"]) {
      result = true;
    }
  }

  return result;
};

export function checkLinkedPartIsRequiredSkill(item: EvidencePart, evidenceJSON: any): boolean {
  let required = true;

  if (item["Linked Part"] && item["Linked Part"].length > 0) {
    const linkedPart = item["Linked Part"][0];
    const evidencePart = evidenceJSON[linkedPart.Name];
    const skill = item.Skills?.map((_item) => _item.Name)[0] ?? "";

    required = evidencePart?.includes(skill) || false;
  }

  return required;
}

export function checkLinkedPartIsRequired(item: EvidencePart, evidenceJSON: any): boolean {
  let required = true;

  if (item["Linked Part"] && item["Linked Part"].length > 0) {
    const linkedPart = item["Linked Part"][0];
    const evidencePart = evidenceJSON[linkedPart.Name];

    required = evidencePart === item["Linked Part trigger"];
  }

  return required;
}

export function checkNOSSelectIsFilled(evidenceJSON: any): boolean {
  if (evidenceJSON.Comps) {
    return evidenceJSON.Comps.length > 0;
  }

  return false;
}

export function checkLearningOutcomesIsFilled(evidenceJSON: any): boolean {
  if (evidenceJSON.LearningOutcomes) {
    return evidenceJSON.LearningOutcomes.length > 0;
  }

  return false;
}

/**
 * Removes any non-required evidence parts from a piece of evidence that are empty
 * @param json - The evidence to process
 * @param evidenceParts - The evidence parts for the competence/ skill
 * @returns The evidence with the non-required parts removed
 */
export function deleteNonRequiredEvidencePart(json: object, evidenceParts: EvidencePart[]) {
  const object: object = _.cloneDeep(json);

  for (let i = 0; i < evidenceParts.length; i++) {
    let required = true;
    const part: EvidencePart = evidenceParts[i];

    if (part.Skills && part.Skills.length > 0) {
      required = checkLinkedPartIsRequiredSkill(part, object);
    } else if (part["Linked Part trigger"]) {
      required = checkLinkedPartIsRequired(part, object);
    }

    if (!required && typeof object === "object") {
      delete object[part.Name as keyof object];
    }
  }

  return object;
}

export function filterDrafts(
  sortedDrafts: IEvidenceDraft[],
  selectedStandards: string[],
  selectedCustomTags: string[],
  selectedPrograms: string[],
  filterHCPC: boolean
): IEvidenceDraft[] {
  if (selectedStandards.length === 0 && selectedCustomTags.length === 0 && selectedPrograms.length === 0) {
    let copy = _.cloneDeep(sortedDrafts);

    if (filterHCPC) {
      copy = copy.filter((draft: IEvidenceDraft) => draft.evidence.addToHCPCAudit);
    }

    return copy;
  } else {
    let copy = _.cloneDeep(sortedDrafts) || [];

    if (filterHCPC) {
      copy = copy.filter((draft: IEvidenceDraft) => draft.evidence.addToHCPCAudit);
    }

    copy = copy?.filter((item) => {
      let standardTags = item.evidence.standardTags?.map((tag) => tag.tag) || [];
      let customTags = item.evidence.customTags?.map((tag) => tag.tag) || [];
      const evidenceJSON = item.evidence.evidenceJSON;
      const programName = evidenceJSON?.programInfo?.program || "";

      let standardIncluded = selectedStandards.every((tag) => standardTags.includes(tag));
      let customIncluded = selectedCustomTags.every((tag) => customTags.includes(tag));
      let programIncluded = selectedPrograms.includes(programName);

      if (selectedPrograms.length > 0 && selectedCustomTags.length > 0 && selectedStandards.length > 0) {
        return programIncluded && standardIncluded && customIncluded;
      } else if (selectedPrograms.length > 0 && selectedCustomTags.length > 0) {
        return programIncluded && customIncluded;
      } else if (selectedPrograms.length > 0 && selectedStandards.length > 0) {
        return programIncluded && standardIncluded;
      } else if (selectedStandards.length > 0 && selectedCustomTags.length > 0) {
        return standardIncluded && customIncluded;
      } else if (selectedPrograms.length > 0) {
        return programIncluded;
      } else if (selectedStandards.length > 0) {
        return standardIncluded;
      } else if (selectedCustomTags.length > 0) {
        return customIncluded;
      } else {
        return false;
      }
    });

    return copy;
  }
}

export function filterEvidence(
  sortedEvidence: IEvidence[],
  selectedStandards: string[],
  selectedCustomTags: string[],
  selectedPrograms: string[],
  filterHCPC: boolean,
  filterDrafts: boolean
): IEvidence[] {
  if (selectedStandards.length === 0 && selectedCustomTags.length === 0 && selectedPrograms.length === 0) {
    let copy = _.cloneDeep(sortedEvidence);

    if (filterDrafts) {
      copy = copy.filter((evidence: IEvidence) => evidence.draft === true);
    }

    if (filterHCPC) {
      copy = copy.filter((evidence: IEvidence) => evidence.addToHCPCAudit);
    }

    return copy;
  } else {
    let copy = _.cloneDeep(sortedEvidence) || [];

    if (filterDrafts) {
      copy = copy.filter((evidence: IEvidence) => evidence.draft === true);
    }

    if (filterHCPC) {
      copy = copy.filter((evidence: IEvidence) => evidence.addToHCPCAudit);
    }

    copy = copy?.filter((item) => {
      let standardTags = item.standardTags?.map((tag) => tag.tag) || [];
      let customTags = item.customTags?.map((tag) => tag.tag) || [];
      const evidenceJSON = item.evidenceJSON && JSON.parse(item.evidenceJSON);
      const programName = evidenceJSON?.programInfo?.program || "";

      let standardIncluded = selectedStandards.every((tag) => standardTags.includes(tag));
      let customIncluded = selectedCustomTags.every((tag) => customTags.includes(tag));
      let programIncluded = selectedPrograms.includes(programName);

      if (selectedPrograms.length > 0 && selectedCustomTags.length > 0 && selectedStandards.length > 0) {
        return programIncluded && standardIncluded && customIncluded;
      } else if (selectedPrograms.length > 0 && selectedCustomTags.length > 0) {
        return programIncluded && customIncluded;
      } else if (selectedPrograms.length > 0 && selectedStandards.length > 0) {
        return programIncluded && standardIncluded;
      } else if (selectedStandards.length > 0 && selectedCustomTags.length > 0) {
        return standardIncluded && customIncluded;
      } else if (selectedPrograms.length > 0) {
        return programIncluded;
      } else if (selectedStandards.length > 0) {
        return standardIncluded;
      } else if (selectedCustomTags.length > 0) {
        return customIncluded;
      } else {
        return false;
      }
    });

    return copy;
  }
}

export function filterStandardTags(
  search: string,
  standardTags: EvidenceStandardTag[],
  mandatoryStandards: any[] | undefined,
  optionalStandards: any[] | undefined
): { mandatory: any[] | undefined; optional: any[] | undefined } {
  const addedTags = standardTags.map((item) => item.tag);

  const mandatory = mandatoryStandards?.filter((item) => {
    return (
      !addedTags.includes(item.Code) &&
      (item.Code.toLowerCase().includes(search.toLowerCase()) ||
        (item.Description && item.Description.toLowerCase().includes(search.toLowerCase())))
    );
  });

  const optional = optionalStandards?.filter((item) => {
    return (
      !addedTags.includes(item.Code) &&
      (item.Code.toLowerCase().includes(search.toLowerCase()) ||
        (item.Description && item.Description.toLowerCase().includes(search.toLowerCase())))
    );
  });

  return { mandatory, optional };
}

export function getLinkedChildParts(evidencePart: EvidencePart): EvidencePart[] | undefined {
  if (evidencePart["Linked Part"]) {
    const array = evidencePart["Linked Part"].filter((item) => item["Linked Part type"] === LinkedPartType.Child);

    if (array.length > 0) {
      return array;
    }
  }

  return undefined;
}

export function getLinkedSiblingParts(evidencePart: EvidencePart) {
  if (evidencePart["Linked Part"]) {
    const array = evidencePart["Linked Part"].filter((item) => item["Linked Part type"] === LinkedPartType.Sibling);

    if (array.length > 0) {
      return array;
    }
  }

  return [];
}

/**
 *
 * @returns An array of the default activity types for general evidence
 */
export function getDefaultActivityTypes(): CPDActivityType[] {
  return [
    { type: "Command post exercise" },
    { type: "Formal education" },
    { type: "Live exercise" },
    { type: "Professional activity" },
    { type: "Self-directed learning" },
    { type: "Table top exercise" },
    { type: "Work-based learning" },
    { type: "Other" },
  ];
}

export function getDraftsCount(evidence: IEvidence[] | undefined): number {
  if (evidence) {
    const draftEvidence = evidence.filter((item) => item.draft === true);

    return draftEvidence.length;
  }

  return 0;
}

export function getEvidenceParts(evidenceDefinition: EvidenceDefinition): EvidencePart[] {
  return evidenceDefinition["Evidence parts"] || evidenceDefinition["Evidence parts Mandatory"] || [];
}

export function getSearchResultText(searchResults: IEvidence[]): string {
  if (searchResults) {
    return `${searchResults && searchResults.length} result${searchResults && searchResults.length !== 1 ? "s" : ""}`;
  }

  return "";
}

export function getStandardTagsForRole(
  roles: any,
  userRole: string,
  standards: any,
  initialEvidence: any
): {
  optionalRoles: any;
  mandatoryRoles: any;
  filteredOptional: any;
  filteredMandatory: any;
} {
  const roleObject = roles.find((item: any) => item.Name === userRole);

  const optional = roleObject?.OptionalRoles;
  const mandatory = roleObject?.MandatoryRoles;

  const mandatoryRoles = mandatory?.map((id: string) => standards.find((item: any) => item.id === id));
  const optionalRoles = optional?.map((id: string) => standards.find((item: any) => item.id === id));

  const initial: IEvidence | null = _.cloneDeep(initialEvidence);
  const codes = initial?.standardTags?.map((tag) => tag.tag) || [];

  const filteredOptional = _.filter(optionalRoles, (item) => !codes?.includes(item?.Code));
  const filteredMandatory = _.filter(mandatoryRoles, (item) => !codes?.includes(item?.Code));

  return {
    optionalRoles,
    mandatoryRoles,
    filteredOptional,
    filteredMandatory,
  };
}

export function handleNumberOfHours(input: string): string {
  let result = "";

  if (input.includes(":")) {
    let split = input.split(":");

    if (split[1].length === 0) {
      result = `${split[0]}:00`;
    } else if (split[1].length === 1) {
      result = `${split[0]}:0${split[1]}`;
    } else if (split[1].length === 2) {
      let hours = parseInt(split[0], 10);
      let minutes = parseInt(split[1], 10);

      if (minutes > 59) {
        hours += 1;
        minutes = minutes - 60;
      }

      if (hours === 100) {
        hours = 99;
        minutes = 59;
      }

      if (hours < 10) {
        if (minutes < 10) {
          result = `0${hours}:0${minutes}`;
        } else {
          result = `0${hours}:${minutes}`;
        }
      } else {
        if (minutes < 10) {
          result = `${hours}:0${minutes}`;
        } else {
          result = `${hours}:${minutes}`;
        }
      }
    }
  } else {
    if (input.length === 1) {
      result = `0${input}:00`;
    } else if (input.length === 2) {
      result = `${input}:00`;
    }
  }

  return result;
}

export function hasData(evidence: IEvidence[] | undefined): boolean {
  if (evidence) {
    return evidence.length > 0;
  }

  return false;
}

export function isBanner(evidencePart: EvidencePart): boolean {
  if (evidencePart["Field type"]) {
    return ["Banner"].includes(evidencePart["Field type"]);
  }

  return false;
}

export function isEvidenceTitleBlank(title: string | undefined): boolean {
  return typeof title === "undefined" || title.trim().length === 0;
}

export function isEvidencePartRequired(part: EvidencePart, evidenceJSON: any): boolean {
  let required = true;

  if (part["Linked Part"] && part["Linked Part"].length > 0) {
    if (part.Skills && part.Skills.length > 0) {
      const linkedPart = part["Linked Part"][0];
      const evidencePart = evidenceJSON[linkedPart.Name];
      const skill = part.Skills?.map((item) => item.Name)[0] || "";

      required = evidencePart?.includes(skill) || false;
    }
  }

  return required;
}

export function isCompMultiSelect(evidencePart: EvidencePart): boolean {
  if (evidencePart["Field type"]) {
    return ["CompMultiSelectForLOs"].includes(evidencePart["Field type"]);
  }

  return false;
}

export function isNOSSelect(evidencePart: EvidencePart): boolean {
  if (evidencePart["Field type"]) {
    return ["NOS Select"].includes(evidencePart["Field type"]);
  }

  return false;
}

export function isLearningOutcomesList(evidencePart: EvidencePart): boolean {
  if (evidencePart["Field type"]) {
    return ["LearningOutcomesList"].includes(evidencePart["Field type"]);
  }

  return false;
}

export function titleHasNoData(evidenceParts: EvidencePart[]): boolean {
  const titlePart = evidenceParts.find((item) => item.Name === "Title");

  return titlePart == null;
}

export function searchAndFilterEvidence(
  searchText: string,
  sortedEvidence: IEvidence[],
  selectedStandards: string[],
  selectedCustomTags: string[],
  selectedPrograms: string[],
  filterHCPC: boolean,
  filterDrafts: boolean
): IEvidence[] {
  let search = searchText.toLowerCase();
  let copy = _.cloneDeep(sortedEvidence);

  if (filterDrafts) {
    copy = copy.filter((evidence: IEvidence) => evidence.draft === true);
  }

  if (selectedStandards.length > 0 || selectedCustomTags.length > 0 || selectedPrograms.length > 0) {
    copy = copy?.filter((item) => {
      let standardTags = item.standardTags?.map((tag) => tag.tag) || [];
      let customTags = item.customTags?.map((tag) => tag.tag) || [];
      const evidenceJSON = item.evidenceJSON && JSON.parse(item.evidenceJSON);
      const programName = evidenceJSON?.programInfo?.program || "";

      let standardIncluded = selectedStandards.every((tag) => standardTags.includes(tag));
      let customIncluded = selectedCustomTags.every((tag) => customTags.includes(tag));
      let programIncluded = selectedPrograms.includes(programName);

      if (selectedPrograms.length > 0 && selectedCustomTags.length > 0 && selectedStandards.length > 0) {
        return programIncluded && standardIncluded && customIncluded;
      } else if (selectedPrograms.length > 0 && selectedCustomTags.length > 0) {
        return programIncluded && customIncluded;
      } else if (selectedPrograms.length > 0 && selectedStandards.length > 0) {
        return programIncluded && standardIncluded;
      } else if (selectedStandards.length > 0 && selectedCustomTags.length > 0) {
        return standardIncluded && customIncluded;
      } else if (selectedPrograms.length > 0) {
        return programIncluded;
      } else if (selectedStandards.length > 0) {
        return standardIncluded;
      } else if (selectedCustomTags.length > 0) {
        return customIncluded;
      } else {
        return false;
      }
    });
  }

  if (filterHCPC) {
    copy = copy.filter((evidence: IEvidence) => evidence.addToHCPCAudit);
  }

  copy = _.filter(copy, (evidence: IEvidence) => {
    const evidenceJSON = evidence.evidenceJSON && JSON.parse(evidence.evidenceJSON);

    const title: string | undefined = evidence.title || evidenceJSON.Title;
    const programName: string | undefined = evidenceJSON?.programInfo?.program;
    const competenceName: string | undefined = evidenceJSON?.programInfo?.competence;
    const progressCheckName: string | undefined = evidenceJSON?.programInfo?.progressCheck;
    const taskingType: string | undefined = evidenceJSON?.["Tasking type or training"];

    return (
      (title ? title.toLowerCase().includes(search) : false) ||
      (programName ? programName.toLowerCase().includes(search) : false) ||
      (competenceName ? competenceName.toLowerCase().includes(search) : false) ||
      (progressCheckName ? progressCheckName.toLowerCase().includes(search) : false) ||
      (taskingType ? taskingType.toLowerCase().includes(search) : false) ||
      format(new Date(evidence.date), "d MMM yyyy").toLowerCase().includes(search) ||
      (evidence.activity !== null &&
        (evidence.activity ? evidence.activity.type.toLowerCase().includes(search) : false))
    );
  });

  return copy;
}
