import { useEffect, useState } from "react";
import { EvidencePart } from "../../../Interfaces";
import { IonItem, IonList, IonSelect, IonSelectOption } from "@ionic/react";
import { Text, View } from "@react-pdf/renderer";

type Props = {
  children?: React.ReactNode;
  style?: React.CSSProperties;
  definition: EvidencePart;
  onValueChanged?: (value: any) => void;
  data?: any;
  isDisplay?: boolean;
  isPDF?: boolean;
  pdfStyles: any;
  initialValue?: any;
};

const DropDownList: React.FC<Props> = ({
  children,
  style,
  definition,
  onValueChanged,
  data,
  isDisplay,
  isPDF,
  pdfStyles,
  initialValue,
}) => {
  const [value, setValue] = useState<string>(initialValue || "");
  const [listValues, setListValues] = useState<string[]>([]);

  useEffect(() => {
    const getListValues = (): void => {
      const values = definition?.["List of values"];

      if (values && values.length > 0) {
        let strings = values.split("\n");
        strings = strings.filter((item) => item.length > 0);

        setListValues(strings);
      }
    };

    definition && getListValues();
  }, [definition]);

  const valueChanged = (item: any) => {
    const _value = item.detail.value;

    setValue(_value);
    onValueChanged && onValueChanged(_value);
  };

  if (isPDF) {
    return (
      <View>
        <Text style={pdfStyles.reflectionHeader}>{(definition["Display Title"] || definition.Name) + ":"}</Text>
        <Text style={pdfStyles.evidenceDate}>{data}</Text>
      </View>
    );
  }

  if (isDisplay) {
    return (
      <div className="readEvidenceBlock">
        <div className="readEvidenceHeader">{definition["Display Title"] || definition.Name}</div>
        <div className="readEvidenceText">{data}</div>
        <div className="h-[1px] w-full bg-grey-30 mt-4" />
      </div>
    );
  }

  return (
    <div className="reflectionActivityTypeContainer">
      <IonList mode="md" className="reflectionActivityList w-full" color="white">
        <IonItem mode="md" className="reflectionActivityItem" color="white">
          <IonSelect
            color="white"
            mode="md"
            className="reflectionActivitySelectItem w-full"
            interface="popover"
            justify="space-between"
            interfaceOptions={{
              size: "auto",
              alignment: "start",
            }}
            placeholder={definition["Placeholder Text"] || "Select value"}
            value={value || undefined}
            onIonChange={valueChanged}
          >
            {listValues.map((item: string, index) => {
              return (
                <IonSelectOption color="white" key={item} value={item}>
                  {item}
                </IonSelectOption>
              );
            })}
          </IonSelect>
        </IonItem>
      </IonList>
    </div>
  );
};

export default DropDownList;
