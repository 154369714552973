import React from "react";
import "../../theme/tailwind.css";
import { IProgramCardButton } from "../../types/Components";
import { IonButton, IonImg } from "@ionic/react";

const ProgramCardButton: React.FC<IProgramCardButton> = (props) => {
  return (
    <IonButton
      className={`self-center w-full h-[50px] max-w-[358px] font-bold leading-[1.5] tracking-default text-center text-cta-blue shrink-0 mt-[16px] program-card-button ${props.callToAction && "!program-card-button-cta"} ${props.buttonType ? `${props.buttonType}` : ""} ${props.disabled && "opacity-40"}`}
      disabled={props.disabled}
      mode="ios"
      onClick={props.buttonPressed}
    >
      <div className="flex flex-row items-center">
        {props.icon && <IonImg className="w-[14px] h-[14px] !mr-[9px]" src={props.icon} />}
        {props.children ? (
          <div>{props.children}</div>
        ) : (
          <div
            className={`text-[16px] font-semibold leading-default tracking-default text-center text-cta-blue ${props.callToAction && "text-white"} ${props.buttonTextColor ? `${props.buttonTextColor}` : ""} `}
          >
            {props.buttonText}
          </div>
        )}
      </div>
    </IonButton>
  );
};

export default ProgramCardButton;
