import "./ProfileComponents.css";

type Props = {
  title: string;
  children?: React.ReactNode;
};

const AccessibilitySection = ({ title, children }: Props) => {
  return (
    <div className="accessibilitySection">
      <div className="accessibilitySectionInner">
        <div className="accessibilitySectionTitleContainer">
          <div className="accessibilitySectionTitle">{title.toUpperCase()}</div>
        </div>
        <div className="accessibilitySectionContent">{children}</div>
      </div>
    </div>
  );
};

export default AccessibilitySection;
