import { EvidencePart } from "../../../Interfaces";
import { IonItem } from "@ionic/react";
import {
  Button_Checkbox_Active,
  Button_Checkbox_Inactive,
  Button_Collapse,
  Button_Expand,
} from "../../../assets/images";
import "./EvidenceParts.css";
import { Text, View } from "@react-pdf/renderer";
import { useEffect, useState } from "react";

type Props = {
  children?: React.ReactNode;
  childParts?: EvidencePart[] | undefined;
  style?: React.CSSProperties;
  definition: EvidencePart;
  onValueChanged?: (value: any) => void;
  setCollapsibleOpen: (value: boolean) => void;
  toggleCollapsible: () => void;
  collapsibleOpen: boolean;
  data?: any;
  isDisplay?: boolean;
  isPDF?: boolean;
  pdfStyles: any;
  initialValue?: any;
};

const SingleTickExpandable: React.FC<Props> = (props) => {
  const [selectedValue, setSelectedValue] = useState<string>(props.initialValue ?? "");
  const [listValues, setListValues] = useState<string[]>([]);

  useEffect(() => {
    const getListValues = (): void => {
      const values = props.definition?.["List of values"];

      if (values && values.length > 0) {
        let strings = values.split("\n");
        strings = strings.filter((item) => item.length > 0);

        setListValues(strings);
      }
    };

    props.definition && getListValues();
  }, [props.definition]);

  const toggleValue = (value: string): void => {
    if (selectedValue === value) {
      setSelectedValue("");
      props.setCollapsibleOpen && props.setCollapsibleOpen(false);
      props.onValueChanged && props.onValueChanged("");
    } else {
      setSelectedValue(value);
      props.setCollapsibleOpen && props.setCollapsibleOpen(true);
      props.onValueChanged && props.onValueChanged(value);
    }
  };

  if (props.isPDF) {
    return (
      <View>
        <Text style={props.pdfStyles.reflectionHeader}>
          {(props.definition["Display Title"] || props.definition.Name) + ":"}
        </Text>
        <Text style={props.pdfStyles.evidenceDate}>
          {Array.isArray(props.data) ? props.data.sort().join(", ") : props.data}
        </Text>
      </View>
    );
  }

  if (props.isDisplay) {
    return (
      <div className="mt-[20px]">
        <div className="text-[13px] font-[700] leading-[1.54] tracking-[-0.1px] !text-grey-90 mb-[4px]">
          {(props.definition["Display Title"] || props.definition.Name) + ":"}
        </div>
        <div className="text-[17px] font-acc-normal leading-[1.53] tracking-[-0.3px] !text-grey-90">
          {Array.isArray(props.data) ? props.data.sort().join(", ") : props.data}
        </div>
        <div className="h-[1px] w-full bg-grey-30 mt-4" />
      </div>
    );
  }

  return (
    <div>
      {listValues.map((item) => {
        const selected = selectedValue === item;

        return (
          <div key={item} className="relative">
            <IonItem button detail={false} className="evidencePartsCheckboxContainer" onClick={() => toggleValue(item)}>
              <div className="w-full flex flex-row items-center !justify-between">
                <div
                  className={`text-[17px] ${selected ? "font-semibold" : "font-normal"} leading-default tracking-[-0.25px] text-grey-90 w-[75%] whitespace-nowrap overflow-hidden text-ellipsis acc-underline decoration-current`}
                >
                  {item}
                </div>
                <img
                  alt=""
                  className="h-icon w-icon img-no-select"
                  src={selected ? Button_Checkbox_Active : Button_Checkbox_Inactive}
                />
              </div>
            </IonItem>
            {selected && (
              <button className="z-50 absolute right-11 top-0" onClick={() => props.toggleCollapsible()}>
                <img
                  alt=""
                  className="h-icon w-icon img-no-select"
                  src={props.collapsibleOpen ? Button_Collapse : Button_Expand}
                />
              </button>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default SingleTickExpandable;
