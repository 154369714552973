import { Directory } from "@capacitor/filesystem";
import { AWSAccessParams, EvidenceAttachment, IUser } from "../Interfaces";
import AWSService from "../controllers/AWSService";
import { FileOpener } from "@capacitor-community/file-opener";
import WriteBlob from "capacitor-blob-writer";
import DataController from "../controllers/DataController";
import mime from "mime";

export namespace FileUtils {
  export async function viewAttachment(user: IUser, id: string, attachment: EvidenceAttachment): Promise<void> {
    try {
      const accessParams: AWSAccessParams = await AWSService.generateSTSToken(user);
      const file = await AWSService.openAttachmentFromServer(user, accessParams, id, attachment);

      if (await DataController.isWebAsync()) {
        const url = window.URL.createObjectURL(
          new Blob([new Uint8Array(file.Body.data)], {
            type: file.ContentType,
          })
        );
        window.open(url, "_blank");
      } else {
        const blob = new Blob([new Uint8Array(file.Body.data)], {
          type: file.ContentType,
        });

        const filePath = await WriteBlob({
          path: `ParaFolio/Attachments/${attachment.name}.${mime.getExtension(file.ContentType)}`,
          blob,
          directory: Directory.Library,
          recursive: true,
        });

        FileOpener.open({
          filePath,
        });
      }
    } catch (error) {
      throw error;
    }
  }
}
