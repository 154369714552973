import {
  IonButton,
  IonButtons,
  IonContent,
  IonFooter,
  IonHeader,
  IonImg,
  IonModal,
  IonSpinner,
  IonToast,
  IonToolbar,
} from "@ionic/react";
import { useEffect, useRef, useState } from "react";
import { useRecoilStateLoadable } from "recoil";
import { Button_Export, Button_Info_White } from "../../assets/images";
import { IEvidenceModal, IUser } from "../../Interfaces";
import { userAtom } from "../../state/State";
import ContentContainer from "../common/ContentContainer";
import { Editor } from "@tinymce/tinymce-react";
import { Editor as TinyMCEEditor } from "tinymce";
import { DatabaseService } from "../../controllers/DatabaseService";
import AuthenticationController from "../../controllers/AuthenticationController";
import _ from "lodash";
import DataController from "../../controllers/DataController";
import { format } from "date-fns";
import { Clipboard } from "@capacitor/clipboard";
import "./ProfileComponents.css";
import { Keyboard, KeyboardInfo } from "@capacitor/keyboard";
import { Capacitor } from "@capacitor/core";
import HeaderTitle from "../common/HeaderTitle";

const SummaryOfWorkModal = ({ visible, closeModal }: IEvidenceModal) => {
  const inputRef = useRef<TinyMCEEditor | null>(null);
  const maxWord: number = 500;

  const [user, setUser] = useRecoilStateLoadable<IUser | null>(userAtom);

  const [initialValue, setInitialValue] = useState<string>("");
  const [modalVisible, setModalVisible] = useState<boolean>(visible);
  const [inputFocused, setInputFocused] = useState<boolean>(false);
  const [summary, setSummary] = useState<string>("");
  const [saving, setSaving] = useState<boolean>(false);
  const [tipModalVisible, setTipModalVisible] = useState<boolean>(false);
  const [showToast, setShowToast] = useState<boolean>(false);

  const [keyboardActive, setKeyboardActive] = useState<boolean>(false);
  const [keyboardHeight, setKeyboardHeight] = useState<number>(0);

  useEffect(() => {
    if (Capacitor.getPlatform() === "ios") {
      Keyboard.addListener("keyboardWillShow", (info: KeyboardInfo) => {
        setKeyboardActive(true);
        setKeyboardHeight(info.keyboardHeight);
      });

      Keyboard.addListener("keyboardWillHide", () => {
        setKeyboardActive(false);
      });
    }
  }, []);

  useEffect(() => {
    const getInitialValue = (): void => {
      if (user.state === "hasValue" && user.contents && user.contents.summaryOfWork) {
        setInitialValue(user.contents.summaryOfWork);
        setSummary(user.contents.summaryOfWork);
      }
    };

    if (visible) {
      getInitialValue();
    }

    setModalVisible(visible);
  }, [visible, user]);

  const exportClicked = async (): Promise<void> => {
    let plaintext = DataController.formatHTMLToPlaintext(summary);

    if (await DataController.isWebAsync()) {
      const blobHTML = new Blob([summary], { type: "text/html" });
      const blobPlainText = new Blob([plaintext], { type: "text/plain" });
      const clipboardItem = new ClipboardItem({
        "text/html": blobHTML,
        "text/plain": blobPlainText,
      });

      navigator.clipboard.write([clipboardItem]);

      setShowToast(true);
    } else {
      Clipboard.write({
        string: plaintext,
      });
    }
  };

  const infoClicked = (): void => {
    setTipModalVisible(true);
  };

  const resetModal = (): void => {
    setSummary("");
    setInitialValue("");
    setInputFocused(false);
    setSaving(false);
    closeModal(false);
  };

  const cancelClicked = (): void => {
    if (initialValue) {
      const noChanges = _.isEqual(initialValue, summary);

      if (!noChanges) {
        const action = window.confirm("You have unsaved changes, are you sure you want to cancel?");

        if (action) {
          resetModal();
        }
      } else {
        resetModal();
      }
    } else {
      if (summary.length > 0) {
        const action = window.confirm("You have unsaved changes, are you sure you want to cancel?");

        if (action) {
          resetModal();
        }
      } else {
        resetModal();
      }
    }
  };

  const saveSummaryOfWork = async (): Promise<void> => {
    try {
      setSaving(true);

      if (user.state === "hasValue" && user.contents) {
        let userToSave: IUser = { ...user.contents };

        if (userToSave) {
          userToSave.summaryOfWork = summary;
          userToSave.summaryOfWorkTimestamp = new Date().toISOString();

          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const updated = await DatabaseService.updateUserDetails(userToSave, {
            summaryOfWork: userToSave.summaryOfWork.replace(/'/gim, "''"),
            summaryOfWorkTimestamp: userToSave.summaryOfWorkTimestamp,
          });

          setSaving(false);
          setUser(userToSave);
          resetModal();
        }
      }
    } catch (error) {
      console.log(error);
      setSaving(false);
      window.alert("An error occurred when saving your summary of work");
    }
  };

  const editorFocused = (): void => {
    setInputFocused(true);
    const editor = document.getElementById("");
    editor && editor.scrollIntoView();

    try {
      Capacitor.getPlatform() !== "web" && Keyboard.show();
    } catch (error) {
      console.log(error);
    }
  };

  const disableFocusTrap = (): boolean => {
    return inputFocused;
  };

  const hasSummaryOfWorkTimestamp = (): boolean => {
    let value = false;

    if (user.state === "hasValue" && user.contents) {
      value = typeof user.contents.summaryOfWorkTimestamp !== "undefined";
    }

    return value;
  };

  const getLastEditedSummaryOfWork = (): string => {
    let value = "";

    if (user.state === "hasValue" && user.contents && user.contents.summaryOfWorkTimestamp) {
      let date = new Date(user.contents.summaryOfWorkTimestamp);
      // date = new Date(date.getTime() + (date.getTimezoneOffset() * 60000));
      const dateText = format(date, "d MMM yyyy");
      const timeText = format(date, "h:mmaaa");

      value = `Last edited ${dateText} at ${timeText} · `;
    }

    return value;
  };

  const onTextChanged = (a: string): void => {
    if (DataController.getWordCount(a) <= maxWord) {
      setSummary(a);
    } else {
      setSummary(summary.trim());
    }
  };

  const canDismiss = (): Promise<boolean> => {
    return new Promise<boolean>((resolve, reject) => {
      if (initialValue) {
        const noChanges = _.isEqual(initialValue, summary);

        if (!noChanges) {
          const action = window.confirm("You have unsaved changes, are you sure you want to cancel?");

          if (action) {
            resolve(true);
          } else {
            resolve(false);
          }
        } else {
          resolve(true);
        }
      } else {
        if (summary.length > 0) {
          const action = window.confirm("You have unsaved changes, are you sure you want to cancel?");

          if (action) {
            resolve(true);
          } else {
            resolve(false);
          }
        } else {
          resolve(true);
        }
      }
    });
  };

  return (
    <IonModal
      id="summaryOfWorkModal"
      isOpen={modalVisible}
      onIonModalDidDismiss={() => resetModal()}
      backdropDismiss={true}
      canDismiss={canDismiss}
      className={`fullscreen ${disableFocusTrap() ? "ion-disable-focus-trap" : ""}`}
    >
      <IonHeader>
        <IonToast
          isOpen={showToast}
          mode="ios"
          position="bottom"
          cssClass="exportToast"
          onDidDismiss={() => setShowToast(false)}
          message="Copied to clipboard"
          duration={1500}
        />
        <IonToolbar
          mode="ios"
          className="navBar"
          style={{
            maxWidth: DataController.isWeb() ? 980 : undefined,
            height: Capacitor.getPlatform() === "android" ? "54px" : "unset",
          }}
        >
          <HeaderTitle>{"Summary of work"}</HeaderTitle>
          <IonButtons slot="end">
            <IonButton className="header-button" mode="ios" onClick={() => exportClicked()}>
              <IonImg src={Button_Export} className="headerIcon" />
            </IonButton>
            <IonButton className="header-button" mode="ios" onClick={() => infoClicked()}>
              <IonImg src={Button_Info_White} className="headerIcon" />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent className="page-background whiteBackground">
        <IonModal
          id="summaryOfWorkTipModal"
          isOpen={tipModalVisible}
          backdropDismiss={true}
          canDismiss={true}
          className="fullscreen"
          onDidDismiss={() => setTipModalVisible(false)}
        >
          <IonContent className="summaryOfWorkTipModal">
            <div className="tipModalContainer">
              <div>
                <div className="tipModalTitle">{"Tips for writing"}</div>
                <div className="tipModalText">
                  {"It's that recommended you write up to 500 words for your summary of work. Your summary should describe " +
                    "your role and the type of work you do. The summary should include your main responsibilities, identify " +
                    "the specialist areas you work in and identify the people you communicate and work with most."}
                </div>
              </div>
              <div className="tipBottomButtonContainer">
                <button className="tipBottomButton" onClick={() => setTipModalVisible(false)}>
                  {"Got it"}
                </button>
              </div>
            </div>
          </IonContent>
        </IonModal>
        <ContentContainer
          style={{
            paddingBottom: keyboardActive ? keyboardHeight : "24px",
          }}
        >
          <div className="summaryContainer">
            <div className="wordCount">
              {hasSummaryOfWorkTimestamp() && <span>{getLastEditedSummaryOfWork()}</span>}
              <span>
                {`${DataController.getWordCount(summary)} word${DataController.getWordCount(summary) === 1 ? "" : "s"} (max ${maxWord})`}
              </span>
            </div>
            <div style={{ marginTop: inputFocused ? 54 : 0 }}>
              <Editor
                tinymceScriptSrc={process.env.PUBLIC_URL + "/tinymce/tinymce.min.js"}
                id="summaryOfWorkEditorRef"
                onInit={(event, editor) => {
                  inputRef.current = editor;

                  // Auto focus at end of input
                  editor.selection.select(editor.getBody(), true);
                  editor.selection.collapse(false);
                  editor.focus();
                }}
                value={summary}
                onEditorChange={(a, editor) => {
                  a.length > 0 && editor.getBody().setAttribute("data-mce-placeholder", "");
                  onTextChanged(a);
                }}
                onFocus={() => editorFocused()}
                onBlur={() => setInputFocused(false)}
                init={{
                  height: 500,
                  placeholder: "Add your summary of work here...",
                  browser_spellcheck: true,
                  branding: false,
                  menubar: false,
                  paste_data_images: false,
                  content_style: "body {font-size: 14pt;}",
                  invalid_styles: {
                    "*": "color line-height mso-outline-level background font-family",
                  },
                  inline: true,
                  toolbar_sticky: true,
                  toolbar_mode: "scrolling",
                  plugins: ["autolink", "wordcount", "lists"],
                  toolbar: ["undo redo | bold italic underline | bullist numlist"],
                }}
              />
            </div>
          </div>
        </ContentContainer>
      </IonContent>
      <IonFooter className={`reflectionSaveFooter ${keyboardActive ? "pb-0" : "pb-safe-area-footer"}`}>
        <div className="reflectionSaveContainer">
          <IonButton
            mode="ios"
            disabled={DataController.getWordCount(summary) === 0}
            className={`reflectionSaveButton`}
            onClick={() => saveSummaryOfWork()}
          >
            {saving ? <IonSpinner className="reflectionSaveSpinner" /> : <div>{"Save"}</div>}
          </IonButton>
          <IonButton
            mode="ios"
            className="reflectionSaveButton reflectionSaveButtonOutline"
            onClick={() => cancelClicked()}
          >
            {"Cancel"}
          </IonButton>
        </div>
      </IonFooter>
    </IonModal>
  );
};

export default SummaryOfWorkModal;
