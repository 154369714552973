import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonContent,
  IonDatetime,
  IonHeader,
  IonItem,
  IonLabel,
  IonPage,
  IonPopover,
  IonRadio,
  IonRadioGroup,
  IonSpinner,
  IonToolbar,
  useIonRouter,
  useIonViewDidEnter,
  useIonViewWillLeave,
} from "@ionic/react";
import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Button_Checkbox_Active,
  Button_Checkbox_Inactive,
  Button_Error,
  Button_Reflections_Tick,
  Icon_Attachment_Onboarding,
} from "../../assets/images";
import {
  AWSAccessParams,
  IAppAccess,
  IAppFeatures,
  ICertificateInfo,
  IClassSubscription,
  ISubscriptionModalType,
  IUser,
  OnboardingCertificateIssueDate,
} from "../../Interfaces";
import validator from "validator";

import "../../components/onboarding/LoginModal.css";
import { useRecoilState, useRecoilValue } from "recoil";
import { appFeaturesAtom, appInfoAtom, deviceInfoAtom, userAtom } from "../../state/State";
import { useHistory, withRouter } from "react-router";
import AuthenticationController from "../../controllers/AuthenticationController";
import roles from "../../assets/json/roles.json";
import roleCertificates from "../../assets/json/role-certificates.json";
import { addDays, format, isAfter, isSameDay, startOfDay } from "date-fns";
import { useFilePicker } from "use-file-picker";
import AWSService from "../../controllers/AWSService";
import { DatabaseService } from "../../controllers/DatabaseService";
import _ from "lodash";
import DataController from "../../controllers/DataController";
import { DeviceInfo } from "@capacitor/device";
import { AppInfo } from "@capacitor/app";
import { FirebaseService } from "../../controllers/FirebaseService";
import RemoteDataController from "../../controllers/RemoteDataController";
import LearnMoreModal from "../../components/onboarding/LearnMoreModal";
import { EventRegister } from "react-native-event-listeners";
import { NavigationBar } from "@hugotomazi/capacitor-navigation-bar";
import { OAuth2Client } from "@byteowls/capacitor-oauth2";
import { Capacitor } from "@capacitor/core";
import HeaderTitle from "../../components/common/HeaderTitle";
import {
  CERTIFICATE_ACCEPTED_FILE_TYPES,
  CERTIFICATE_MAX_FILE_SIZE,
  CLASS_TERMS_URL,
  PASSWORD_RESET_URL,
} from "../../Constants";
import { DEV_CONTACT_ID, DEV_TOKEN } from "../../Constants";
import { NWAS_SSO_CONFIG } from "../../environment/environment";
import { useNWASSSO } from "../../hooks/auth/useNWASSSO";

const Login: React.FC = () => {
  const emailRef = useRef<HTMLInputElement>(null);
  const passwordRef = useRef<HTMLInputElement>(null);

  const [openFileSelector, { filesContent, clear, plainFiles }] = useFilePicker({
    accept: CERTIFICATE_ACCEPTED_FILE_TYPES,
    maxFileSize: CERTIFICATE_MAX_FILE_SIZE,
    readAs: "DataURL",
    multiple: false,
    limitFilesConfig: { max: 1 },
  });

  const history = useHistory();
  const router = useIonRouter();
  const nwasSSOAvailable = useNWASSSO();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_recoilUser, setUser] = useRecoilState(userAtom);
  const deviceInfo = useRecoilValue<DeviceInfo | null>(deviceInfoAtom);
  const appInfo = useRecoilValue<AppInfo | null>(appInfoAtom);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [appFeatures, setAppFeatures] = useRecoilState<IAppFeatures[] | string[]>(appFeaturesAtom);

  // Login page
  const [isLoggingIn, setIsLoggingIn] = useState<boolean>(false);
  const [hasErrors, setHasErrors] = useState<boolean>(false);
  const [errors, setErrors] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [passwordHidden, setPasswordHidden] = useState<boolean>(true);
  const [termsAccepted, setTermsAccepted] = useState<boolean>(false);
  const [learnMoreModalVisible, setLearnMoreModalVisible] = useState<boolean>(false);

  // Setup page
  const [isSavingRole, setIsSavingRole] = useState<boolean>(false);
  const [userToSave, setUserToSave] = useState<IUser | null>(null);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [name, setName] = useState<string>("");
  const [role, setRole] = useState<string>("");
  const [certificatesForRole, setCertificatesForRole] = useState<any[]>([]);
  const [certificateType, setCertificateType] = useState<string>("");
  const [certificate, setCertificate] = useState<ICertificateInfo[]>([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [hasCertificate, setHasCertificate] = useState<boolean>(false);
  const [dates, setDates] = useState<OnboardingCertificateIssueDate[]>([]);
  const [datePickersVisible, setDatePickersVisible] = useState<boolean[]>([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [optIn, setOptIn] = useState<boolean>(false);
  const [showSetup, setShowSetup] = useState<boolean>(false);
  const [showSetupPageOne, setShowSetupPageOne] = useState<boolean>(true);
  const [showSetupPageTwo, setShowSetupPageTwo] = useState<boolean>(false);
  const [showSetupPageThree, setShowSetupPageThree] = useState<boolean>(false);

  // Post login page
  const [fullFreeAccess, setFullFreeAccess] = useState<boolean>(false);
  const [trialAccess, setTrialAccess] = useState<boolean>(false);

  // No subscription page
  const [noSubscription, setNoSubscription] = useState<boolean>(false);
  const [expiredSubscription, setExpiredSubscription] = useState<boolean>(false);
  const [noSubscriptionUser, setNoSubscriptionUser] = useState<IUser | null>(null);

  useEffect(() => {
    const setNavbarColors = async (): Promise<void> => {
      if (Capacitor.getPlatform() === "android") {
        try {
          NavigationBar.setColor({ color: "#F7FAF8" });
        } catch (error) {}
      }
    };

    setNavbarColors();
  }, []);

  useEffect(() => {
    const scrollToTop = (): void => {
      try {
        const element = document.getElementById(`loginContentContainer`);

        // @ts-ignore
        element?.scrollIntoView({ behavior: "instant" });
      } catch (error) {
        console.log(error);
      }
    };

    if (fullFreeAccess) {
      scrollToTop();
    }

    if (trialAccess) {
      scrollToTop();
    }
  }, [fullFreeAccess, trialAccess]);

  useEffect(() => {
    let _role = roles.find((item) => item.Name === role);

    if (_role) {
      let _certificates = roleCertificates.filter((item) => item.Roles.includes(_role?.id || ""));

      setCertificatesForRole(_certificates);
      setDates(
        Array.from({ length: _certificates.length }, (item, index) => ({
          date: new Date(),
          type: _certificates[index].Name,
        }))
      );
    }
  }, [role]);

  useEffect(() => {
    if (plainFiles.length > 0) {
      let _certificates = _.cloneDeep(certificate);

      const _certificate: ICertificateInfo = {
        name: plainFiles[0].name,
        type: plainFiles[0].type,
        size: plainFiles[0].size,
        content: filesContent[0].content,
        lastModified: plainFiles[0].lastModified,
        certificateType,
      };

      const index = certificate.findIndex((item) => item.certificateType === certificateType);

      if (index > -1) {
        _certificates.splice(index, 1, _certificate);
      } else {
        _certificates.push(_certificate);
      }

      setCertificate(_certificates);
      setHasCertificate(true);
      clear();
    }
  }, [plainFiles, filesContent, certificateType, certificate, clear]);

  const handleClick = async (event: Event): Promise<void> => {
    const element = event.target as HTMLAnchorElement;

    if (element.href && element.href?.includes("loginContactSupport")) {
      event.preventDefault();
      await FirebaseService.logEvent("support_link_pressed", {
        link: "contact_support",
        type: "app_support",
      });

      window.open("mailto:apps@class.co.uk?subject=ParaFolio - App support", "_blank");
    } else if (element.href && element.href?.includes(CLASS_TERMS_URL)) {
      await FirebaseService.logEvent("support_link_pressed", {
        link: "terms_and_conditions",
      });
    } else if (
      element.href &&
      element.href.includes("mailto:C2-training@swast.nhs.uk?subject=ParaFolio - Data opt out")
    ) {
      await FirebaseService.logEvent("support_link_pressed", {
        link: "data_opt_out",
      });
    } else if (element.href && element.href?.includes("loginLearnMore")) {
      event.preventDefault();
      setLearnMoreModalVisible(true);
    } else if (element.href && element.href?.includes("loginGoToRegister")) {
      event.preventDefault();
      router.push("/onboarding/register", "forward", "replace");
    } else if (element.href && (element.href?.includes("loginSupport") || element.href?.includes("classWebsite"))) {
      event.preventDefault();
    }
  };

  useIonViewDidEnter(() => {
    document.addEventListener("click", handleClick);
  });

  useIonViewWillLeave(() => {
    document.removeEventListener("click", handleClick);
  });

  const resetModal = (): void => {
    setTimeout(() => {
      resetErrors();
      setEmail("");
      setPassword("");
      setPasswordHidden(true);
      setTermsAccepted(false);
      setUserToSave(null);
      setName("");
      setRole("");
      setShowSetup(false);
      setShowSetupPageOne(true);
      setShowSetupPageTwo(false);
      setShowSetupPageThree(false);
      setCertificate([]);
      setHasCertificate(false);
      setDates([]);
      setOptIn(false);
      setIsLoggingIn(false);
      setFullFreeAccess(false);
      setTrialAccess(false);
      setNoSubscription(false);
      setExpiredSubscription(false);
      setNoSubscriptionUser(null);
      setIsSavingRole(false);
      clear();
    }, 500);
  };

  const logout = async (): Promise<void> => {
    resetModal();
    await AuthenticationController.logout();
    setUser(null);
    history.goBack();
  };

  const contactSupport = async (): Promise<void> => {
    await FirebaseService.logEvent("support_link_pressed", {
      link: "contact_support",
      type: "subscription_support",
    });

    const url = "mailto:apps@class.co.uk";
    const subject = "ParaFolio - Subscription support";

    const userToSend = noSubscriptionUser?.userData;

    if (userToSend) {
      delete userToSend.token;
      delete userToSend.dbRecord;
    }

    const body = DataController.supportEmailBody(userToSend, deviceInfo, appInfo);

    FirebaseService.logEvent("support_link", { page: "login" });

    window.open(`${url}?subject=${subject}&body=${body}`, "_blank");
  };

  const loginClicked = (): void => {
    login();
  };

  const pageOneContinueClicked = (): void => {
    setShowSetupPageOne(false);
    setShowSetupPageTwo(true);
  };

  const pageTwoContinueClicked = (): void => {
    setShowSetupPageTwo(false);
    setShowSetupPageThree(true);
  };

  const pageThreeContinueClicked = (): void => {
    saveAllDetails();
  };

  const addCertificate = (type: string): void => {
    setCertificateType(type);
    openFileSelector();
  };

  const removeCertificate = (type: string): void => {
    let _certificates = _.cloneDeep(certificate);
    _certificates = _certificates.filter((item) => item.certificateType !== type);

    setCertificate(_certificates);
  };

  const openDatePicker = (index: number): void => {
    setDatePickersVisible(Array.from({ length: certificatesForRole.length }, (item, i) => i === index));
  };

  const closeDatePicker = useCallback(
    (index: number): void => {
      setDatePickersVisible(Array.from({ length: certificatesForRole.length }, (item, i) => false));
    },
    [certificatesForRole.length]
  );

  const onDateChanged = useCallback(
    (event: any, type: string): void => {
      const _dates = _.cloneDeep(dates);
      const index = _dates.findIndex((item) => item.type === type);
      const datesEqual = isSameDay(_dates[index].date, new Date(event.detail.value));

      if (index > -1 && datePickersVisible[index] && !datesEqual) {
        _dates.splice(index, 1, { date: new Date(event.detail.value), type });
        setDates(_dates);
      } else {
      }
      closeDatePicker(index);
    },
    [closeDatePicker, datePickersVisible, dates]
  );

  const skipSetup = async (): Promise<void> => {
    try {
      await FirebaseService.logEvent("onboarding_skipped", {});
    } catch (error) {
      console.log(error);
    }

    try {
      await saveUserRole();
    } catch (error) {
      console.log(error);
    }
  };

  const saveUserRole = async (): Promise<void> => {
    try {
      setIsSavingRole(true);

      if (userToSave) {
        const user = userToSave;
        user.role = role;

        const response = await DatabaseService.addUserDetails(user, { role });

        resetModal();

        document.removeEventListener("click", handleClick);

        setTimeout(() => {
          setUser(user);
        }, 500);

        setTimeout(() => {
          // router.push('/', 'none', 'replace');
          history.replace("/");
          // DataController.removePageFromDOM('onboardingRouterOutlet');
        }, 500);
      } else {
        setIsSavingRole(false);
      }
    } catch (error) {
      console.log(error);

      setIsSavingRole(false);
    }
  };

  const uploadCertificates = async (user: IUser, certificates: ICertificateInfo[]): Promise<ICertificateInfo[]> => {
    const toUpload = _.cloneDeep(certificates);
    const accessParams: AWSAccessParams = await AWSService.generateSTSToken(user);

    const uploaded = [];

    for (let i = 0; i < toUpload.length; i++) {
      let file = await AWSService.uploadCertificate(user, accessParams, toUpload[i]);

      if (file && file.networkIssue) {
        file = await AWSService.uploadCertificateFromServer(user, accessParams, toUpload[i]);
      }

      const updatedFile: ICertificateInfo = {
        ...toUpload[i],
        content: undefined,
        url: file.Location,
      };

      delete updatedFile.content;

      uploaded.push(updatedFile);
    }

    return uploaded;
  };

  const saveAllDetails = async (): Promise<void> => {
    try {
      setIsSavingRole(true);

      if (userToSave) {
        const user = userToSave;
        user.role = role;
        user.usageData = true;

        if (certificate && certificate.length > 0) {
          const uploaded = await uploadCertificates(user, certificate);

          await FirebaseService.logEvent("onboarding_certificates_uploaded", {
            role: role,
            certificates_uploaded: certificate.length,
            certificates_for_role: certificatesForRole.length,
          });

          setIsSavingRole(false);

          // TODO: Setup go to general version

          if (uploaded) {
            user.certificates = certificate
              ? uploaded.map((item) => {
                  const date = dates.find((_date) => _date.type === item.certificateType);

                  return {
                    certificate: item,
                    issueDate: date?.date.toISOString() || new Date().toISOString(),
                  };
                })
              : undefined;

            const response2 = await DatabaseService.addUserDetails(user, {
              certificates: user.certificates,
              role: user.role,
              dataSharing: user.usageData,
            });

            resetModal();

            document.removeEventListener("click", handleClick);

            setTimeout(() => {
              setUser(user);
            }, 500);

            setTimeout(() => {
              // router.push('/', 'none', 'replace');
              history.replace("/");
              // DataController.removePageFromDOM('onboardingRouterOutlet');
            }, 500);
          } else {
            setIsSavingRole(false);
          }
        } else {
          resetModal();

          document.removeEventListener("click", handleClick);

          setTimeout(() => {
            setUser(user);
          }, 500);

          setTimeout(() => {
            // router.push('/', 'none', 'replace');
            history.replace("/");
            // DataController.removePageFromDOM('onboardingRouterOutlet');
          }, 500);
        }
      } else {
        setIsSavingRole(false);
      }
    } catch (error: any) {
      console.log(error);
      RemoteDataController.logError(error);
      setIsSavingRole(false);
    }
  };

  const getLatestPortfolioSubscription = (subscriptions: IClassSubscription[]): IClassSubscription | null => {
    const subscriptionIDs = DataController.getPortfolioSubscriptionIDs();

    let portfolioSubscriptions = subscriptions.filter((subscription) =>
      subscriptionIDs.includes(subscription.SubscriptionID)
    );
    let latestSubscription = null;

    if (portfolioSubscriptions.length > 0) {
      portfolioSubscriptions = _.orderBy(portfolioSubscriptions, ["ExpiryDate"], ["desc"]);

      // Get non-expiring subscription
      latestSubscription = portfolioSubscriptions.find((item) => {
        const dateSeconds = parseInt(item.ExpiryDate.replace("/Date(", "").replace(")/", ""), 10);

        return dateSeconds === 0;
      });

      // No non-expiring subscription, get latest
      if (!latestSubscription) {
        latestSubscription = portfolioSubscriptions[0];
      }
    }

    return latestSubscription;
  };

  const getAllParaFolioSubscriptions = (subscriptions: IClassSubscription[]): IClassSubscription[] => {
    const subscriptionIDs = DataController.getPortfolioSubscriptionIDs();
    const portfolioSubscriptions = subscriptions.filter((subscription) =>
      subscriptionIDs.includes(subscription.SubscriptionID)
    );

    return portfolioSubscriptions;
  };

  const getAppFeaturesFromSubscriptions = (subscriptions: IClassSubscription[]): IAppFeatures[] | string[] => {
    const subscriptionIDs = _.uniq(subscriptions.map((subscription) => subscription.SubscriptionID));

    const appFeaturesArray: IAppFeatures[] | string[] =
      _.uniq(subscriptionIDs.map((id) => DataController.getAppFeaturesFromSubscriptionID(id))) || [];

    return appFeaturesArray;
  };

  const checkAccessType = (subscription: IClassSubscription): void => {};

  const setFirstScreenShown = async (user: IUser): Promise<void> => {
    try {
      const subscriptions = user.userData.subscriptions;
      const subscription = getLatestPortfolioSubscription(subscriptions);

      if (subscription) {
        if (subscription.App_Data) {
          let json: any = {};

          try {
            json = JSON.parse(subscription.App_Data);
          } catch (error) {
            console.log(error);
          }

          // console.log(json);

          if (!json.FirstScreenShown) {
            const data = {
              ...json,
              FirstScreenShown: true,
            };

            const success = await AuthenticationController.appendSubAppData(
              user.userData.contactID,
              user.token!,
              subscription.SubscriberID,
              data
            );
          }
        } else {
          const data = {
            FirstScreenShown: true,
          };

          const success = await AuthenticationController.appendSubAppData(
            user.userData.contactID,
            user.token!,
            subscription.SubscriberID,
            data
          );
        }
        // console.log('setFirstScreenShow', success);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const setupUser = async (): Promise<void> => {
    const user = await AuthenticationController.getUser();

    if (user) {
      await setFirstScreenShown(user);
    }

    setTimeout(() => {
      setUser(user);
    }, 500);
  };

  const goToApp = async (): Promise<void> => {
    try {
      document.removeEventListener("click", handleClick);

      await setupUser();

      setTimeout(() => {
        history.replace("/");
      }, 500);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    EventRegister.addEventListener("subscription/continue", () => {
      goToApp();
    });

    return () => {
      EventRegister.removeEventListener("subscription/continue");
    };
  }, []);

  const openSubscriptionOptions = async (sendUser?: boolean): Promise<void> => {
    EventRegister.emit("subscriptions/show-modal", ISubscriptionModalType.ONBOARDING);
  };

  const getAppType = (subscription: IClassSubscription): string => {
    return DataController.getAppTypeFromSubscriptionID(subscription.SubscriptionID);
  };

  const getAppAccessType = (subscription: IClassSubscription): string => {
    return DataController.getAccessTypeFromSubscriptionID(subscription.SubscriptionID);
  };

  const showAccessType = (accessType: string): void => {
    if (accessType === IAppAccess.GROUP) {
      setFullFreeAccess(true);
    } else if (accessType === IAppAccess.INDIVIDUAL) {
      setTrialAccess(true);
    } else if (accessType === IAppAccess.SWAST) {
      // TODO: SWAST access
    }
  };

  const closeLogin = (): void => {
    goToApp();
  };

  const loginSSO = async (): Promise<void> => {
    try {
      resetErrors();
      setIsLoggingIn(true);

      let features: string[] = [IAppFeatures.GENERAL];
      let latestSubscription: IClassSubscription | null = null;

      let response = await OAuth2Client.authenticate(NWAS_SSO_CONFIG);

      let accessToken = response.access_token;

      if (accessToken == null && response.access_token_response != null) {
        accessToken = response.access_token_response.access_token;
      }

      const user = await AuthenticationController.loginSSO(accessToken);

      if (user) {
        const subscriptions = user.userData.subscriptions;
        let valid = false;
        let showFirstScreen = true;

        if (subscriptions && subscriptions.length > 0) {
          latestSubscription = getLatestPortfolioSubscription(subscriptions);

          if (!latestSubscription) {
            setIsLoggingIn(false);
            setNoSubscription(true);
            setNoSubscriptionUser(user);

            return;
          } else {
            const dateSeconds = parseInt(latestSubscription.ExpiryDate.replace("/Date(", "").replace(")/", ""), 10);
            const withGrace = startOfDay(addDays(new Date(dateSeconds), 2));

            if (dateSeconds === 0) {
              valid = true;
            } else if (isAfter(withGrace, new Date())) {
              valid = true;
            } else if (isSameDay(new Date(), withGrace)) {
              valid = true;
            }

            // Set valid to false to test inactive subscription
            // valid = false;

            if (!valid) {
              setExpiredSubscription(true);
            }

            // Check whether to show welcome screen based on if
            // user has logged in before
            if (latestSubscription.App_Data) {
              let json: any = {};

              try {
                json = JSON.parse(latestSubscription.App_Data);
              } catch (error) {
                console.log(error);
              }

              // console.log(json);

              if (json.FirstScreenShown) {
                showFirstScreen = false;
              }
            }

            if (showFirstScreen) {
              // Check whether to show welcome screen based on
              // user subscriptions. Subscriptions with program
              // should not show first screen

              const ids = DataController.getSubscriptionIDsWithPrograms();
              let programSubscriptions = subscriptions.filter((item: IClassSubscription | any) =>
                ids.includes(item.SubscriptionID)
              );

              showFirstScreen = programSubscriptions.length === 0;
            }

            if (!latestSubscription.FreeTrial) {
              showFirstScreen = false;
            }

            const allParaFolioSubscriptions = getAllParaFolioSubscriptions(subscriptions);
            features = getAppFeaturesFromSubscriptions(allParaFolioSubscriptions);

            // Get correct app features from subscriptions
            setAppFeatures(features);
            await AuthenticationController.saveAppFeatures(features);
          }
        } else {
          setIsLoggingIn(false);
          setNoSubscription(true);
          setNoSubscriptionUser(null);

          return;
        }

        let userDetails = await DatabaseService.getUserDetails(user);

        if (features.includes(IAppFeatures.SWAST_MIC)) {
          if (userDetails && userDetails.contactID === `${user.userData.contactID}`) {
            if (userDetails.role) {
              user.role = userDetails.role;
            }

            if (userDetails.dataSharing) {
              user.usageData = userDetails.dataSharing === 1;
            }

            if (userDetails.certificates) {
              user.certificates = userDetails.certificates && JSON.parse(userDetails.certificates);
            }

            if (userDetails.personalStatement) {
              user.personalStatement = userDetails.personalStatement;
            }

            if (userDetails.summaryOfWork) {
              user.summaryOfWork = userDetails.summaryOfWork;
            }

            if (userDetails.personalStatementTimestamp) {
              user.personalStatementTimestamp = userDetails.personalStatementTimestamp;
            }

            if (userDetails.summaryOfWorkTimestamp) {
              user.summaryOfWorkTimestamp = userDetails.summaryOfWorkTimestamp;
            }

            if (userDetails.HCPCNumber) {
              user.HCPCNumber = userDetails.HCPCNumber;
            }

            if (userDetails.programCertificates) {
              user.programCertificates = userDetails.programCertificates && JSON.parse(userDetails.programCertificates);
            }

            if (userDetails.programRoles) {
              user.programRoles = userDetails.programRoles && JSON.parse(userDetails.programRoles);
            }

            // user.token =  DEV_TOKEN;
            // user.userData.token = DEV_TOKEN;
            // user.userData.contactID = DEV_CONTACT_ID;

            resetModal();

            document.removeEventListener("click", handleClick);

            setTimeout(() => {
              setUser(user);
            }, 500);

            setTimeout(() => {
              history.replace("/");
            }, 500);
          } else {
            setUserToSave(user);
            setShowSetup(true);
          }
        } else if (features.includes(IAppFeatures.GENERAL)) {
          if (!userDetails) {
            await DatabaseService.addUserDetails(user, {});
            userDetails = await DatabaseService.getUserDetails(user);
          }

          if (
            (userDetails && userDetails.contactID === `${user.userData.contactID}`) ||
            features.includes(IAppFeatures.GENERAL)
          ) {
            if (userDetails.role) {
              user.role = userDetails.role;
            }

            if (userDetails.dataSharing) {
              user.usageData = userDetails.dataSharing === 1;
            }

            if (userDetails.certificates) {
              user.certificates = userDetails.certificates && JSON.parse(userDetails.certificates);
            }

            if (userDetails.personalStatement) {
              user.personalStatement = userDetails.personalStatement;
            }

            if (userDetails.summaryOfWork) {
              user.summaryOfWork = userDetails.summaryOfWork;
            }

            if (userDetails.personalStatementTimestamp) {
              user.personalStatementTimestamp = userDetails.personalStatementTimestamp;
            }

            if (userDetails.summaryOfWorkTimestamp) {
              user.summaryOfWorkTimestamp = userDetails.summaryOfWorkTimestamp;
            }

            if (userDetails.HCPCNumber) {
              user.HCPCNumber = userDetails.HCPCNumber;
            }

            if (userDetails.programCertificates) {
              user.programCertificates = userDetails.programCertificates && JSON.parse(userDetails.programCertificates);
            }

            if (userDetails.programRoles) {
              user.programRoles = userDetails.programRoles && JSON.parse(userDetails.programRoles);
            }

            // user.token =  DEV_TOKEN;
            // user.userData.token = DEV_TOKEN;
            // user.userData.contactID = DEV_CONTACT_ID;

            await AuthenticationController.saveUser(user);

            const accessType = getAppAccessType(latestSubscription);

            // console.log(accessType);
            // showAccessType(accessType);
            // showFirstScreen = true;

            if (showFirstScreen) {
              showAccessType(accessType);
            } else {
              goToApp();
            }
          } else {
            setUserToSave(user);
            setShowSetup(true);
          }
        }
      }
    } catch (error: any) {
      console.log(error);
      setIsLoggingIn(false);
    }
  };

  const login = async (): Promise<void> => {
    resetErrors();
    setIsLoggingIn(true);

    let features: string[] = [IAppFeatures.GENERAL];
    let latestSubscription: IClassSubscription | null = null;

    try {
      const user = await AuthenticationController.login(email, password);

      if (user) {
        const subscriptions = user.userData.subscriptions;
        let valid = false;
        let showFirstScreen = true;

        if (subscriptions && subscriptions.length > 0) {
          latestSubscription = getLatestPortfolioSubscription(subscriptions);

          if (!latestSubscription) {
            setIsLoggingIn(false);
            setNoSubscription(true);
            setNoSubscriptionUser(user);

            return;
          } else {
            const dateSeconds = parseInt(latestSubscription.ExpiryDate.replace("/Date(", "").replace(")/", ""), 10);
            const withGrace = startOfDay(addDays(new Date(dateSeconds), 2));

            if (dateSeconds === 0) {
              valid = true;
            } else if (isAfter(withGrace, new Date())) {
              valid = true;
            } else if (isSameDay(new Date(), withGrace)) {
              valid = true;
            }

            // Set valid to false to test inactive subscription
            // valid = false;

            if (!valid) {
              setExpiredSubscription(true);
            }

            // Check whether to show welcome screen based on if
            // user has logged in before
            if (latestSubscription.App_Data) {
              let json: any = {};

              try {
                json = JSON.parse(latestSubscription.App_Data);
              } catch (error) {
                console.log(error);
              }

              // console.log(json);

              if (json.FirstScreenShown) {
                showFirstScreen = false;
              }
            }

            if (showFirstScreen) {
              // Check whether to show welcome screen based on
              // user subscriptions. Subscriptions with program
              // should not show first screen

              const ids = DataController.getSubscriptionIDsWithPrograms();
              let programSubscriptions = subscriptions.filter((item: IClassSubscription | any) =>
                ids.includes(item.SubscriptionID)
              );

              showFirstScreen = programSubscriptions.length === 0;
            }

            if (!latestSubscription.FreeTrial) {
              showFirstScreen = false;
            }

            const allParaFolioSubscriptions = getAllParaFolioSubscriptions(subscriptions);
            features = getAppFeaturesFromSubscriptions(allParaFolioSubscriptions);

            // Get correct app features from subscriptions
            setAppFeatures(features);
            await AuthenticationController.saveAppFeatures(features);
          }
        } else {
          setIsLoggingIn(false);
          setNoSubscription(true);
          setNoSubscriptionUser(null);

          return;
        }

        let userDetails = await DatabaseService.getUserDetails(user);

        if (features.includes(IAppFeatures.SWAST_MIC)) {
          if (userDetails && userDetails.contactID === `${user.userData.contactID}`) {
            if (userDetails.role) {
              user.role = userDetails.role;
            }

            if (userDetails.dataSharing) {
              user.usageData = userDetails.dataSharing === 1;
            }

            if (userDetails.certificates) {
              user.certificates = userDetails.certificates && JSON.parse(userDetails.certificates);
            }

            if (userDetails.personalStatement) {
              user.personalStatement = userDetails.personalStatement;
            }

            if (userDetails.summaryOfWork) {
              user.summaryOfWork = userDetails.summaryOfWork;
            }

            if (userDetails.personalStatementTimestamp) {
              user.personalStatementTimestamp = userDetails.personalStatementTimestamp;
            }

            if (userDetails.summaryOfWorkTimestamp) {
              user.summaryOfWorkTimestamp = userDetails.summaryOfWorkTimestamp;
            }

            if (userDetails.HCPCNumber) {
              user.HCPCNumber = userDetails.HCPCNumber;
            }

            if (userDetails.programCertificates) {
              user.programCertificates = userDetails.programCertificates && JSON.parse(userDetails.programCertificates);
            }

            if (userDetails.programRoles) {
              user.programRoles = userDetails.programRoles && JSON.parse(userDetails.programRoles);
            }

            // user.token =  DEV_TOKEN;
            // user.userData.token = DEV_TOKEN;
            // user.userData.contactID = DEV_CONTACT_ID;

            resetModal();

            document.removeEventListener("click", handleClick);

            setTimeout(() => {
              setUser(user);
            }, 500);

            setTimeout(() => {
              history.replace("/");
            }, 500);
          } else {
            setUserToSave(user);
            setShowSetup(true);
          }
        } else if (features.includes(IAppFeatures.GENERAL)) {
          if (!userDetails) {
            await DatabaseService.addUserDetails(user, {});
            userDetails = await DatabaseService.getUserDetails(user);
          }

          if (
            (userDetails && userDetails.contactID === `${user.userData.contactID}`) ||
            features.includes(IAppFeatures.GENERAL)
          ) {
            if (userDetails.role) {
              user.role = userDetails.role;
            }

            if (userDetails.dataSharing) {
              user.usageData = userDetails.dataSharing === 1;
            }

            if (userDetails.certificates) {
              user.certificates = userDetails.certificates && JSON.parse(userDetails.certificates);
            }

            if (userDetails.personalStatement) {
              user.personalStatement = userDetails.personalStatement;
            }

            if (userDetails.summaryOfWork) {
              user.summaryOfWork = userDetails.summaryOfWork;
            }

            if (userDetails.personalStatementTimestamp) {
              user.personalStatementTimestamp = userDetails.personalStatementTimestamp;
            }

            if (userDetails.summaryOfWorkTimestamp) {
              user.summaryOfWorkTimestamp = userDetails.summaryOfWorkTimestamp;
            }

            if (userDetails.HCPCNumber) {
              user.HCPCNumber = userDetails.HCPCNumber;
            }

            if (userDetails.programCertificates) {
              user.programCertificates = userDetails.programCertificates && JSON.parse(userDetails.programCertificates);
            }

            if (userDetails.programRoles) {
              user.programRoles = userDetails.programRoles && JSON.parse(userDetails.programRoles);
            }

            // user.token =  DEV_TOKEN;
            // user.userData.token = DEV_TOKEN;
            // user.userData.contactID = DEV_CONTACT_ID;

            await AuthenticationController.saveUser(user);

            const accessType = getAppAccessType(latestSubscription);

            // console.log(accessType);
            // showAccessType(accessType);
            // showFirstScreen = true;

            if (showFirstScreen) {
              showAccessType(accessType);
            } else {
              goToApp();
            }
          } else {
            setUserToSave(user);
            setShowSetup(true);
          }
        }
      }
    } catch (error: string | any) {
      setIsLoggingIn(false);
      setHasErrors(true);
      setErrors(error + "");
    }
  };

  const resetErrors = (): void => {
    setHasErrors(false);
    setErrors("");
  };

  const emailChanged = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setEmail(event.target.value);
  };

  const passwordChanged = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setPassword(event.target.value);
  };

  const forgotPasswordClicked = async (): Promise<void> => {
    await FirebaseService.logEvent("support_link_pressed", {
      link: "forgot_password",
    });

    window.open(PASSWORD_RESET_URL, "_blank");
  };

  const validateLoginDetails = (): boolean => {
    return validateEmail(email) && password.length > 0 && termsAccepted;
  };

  const validateEmail = (email: string): boolean => {
    return validator.isEmail(email);
  };

  const emailKeyPressed = (event: React.KeyboardEvent): void => {
    if (event.key === "Enter") {
      if (!isLoggingIn && validateLoginDetails()) {
        login();
      } else {
        passwordRef.current?.focus();
      }
    } else if (event.key === " ") {
      event.preventDefault();
    }
  };

  const passwordKeyPressed = (event: React.KeyboardEvent): void => {
    if (event.key === "Enter") {
      !isLoggingIn && validateLoginDetails() && login();
    }
  };

  const setupPageOneContinueDisabled = (): boolean => {
    return role !== "" && !isSavingRole;
  };

  const loginEnabled = (): boolean => {
    return validateEmail(email) && password.length > 0 && termsAccepted;
  };

  const toggleModalVisible = (value: boolean): void => {
    setLearnMoreModalVisible(value);
  };

  return (
    <IonPage>
      <LearnMoreModal
        page="Login"
        visible={learnMoreModalVisible}
        closeModal={(value: boolean) => toggleModalVisible(value)}
      />
      <IonHeader>
        <IonToolbar
          mode="ios"
          className="navBar"
          style={{
            maxWidth: DataController.isWeb() ? 980 : undefined,
            height: Capacitor.getPlatform() === "android" ? "54px" : "unset",
          }}
        >
          <HeaderTitle>{"ParaFolio"}</HeaderTitle>
          <IonButtons slot="start">
            {fullFreeAccess || trialAccess ? (
              <IonButton mode="ios" className="cancelButton" onClick={() => closeLogin()}>
                {"Close"}
              </IonButton>
            ) : (
              <IonBackButton
                className="header-back-buttons"
                defaultHref="/onboarding"
                text={DataController.getBackIconText()}
                icon={DataController.getBackIconType()}
                style={{
                  marginLeft: Capacitor.getPlatform() === "android" ? 8 : 0,
                  "--icon-font-size": Capacitor.getPlatform() === "android" ? "24px" : "30px",
                }}
              />
            )}
          </IonButtons>
          {fullFreeAccess ||
            trialAccess ||
            (showSetup && (
              <IonButtons slot="end">
                <IonButton mode="ios" className="cancelButton" onClick={() => logout()}>
                  {"Log out"}
                </IonButton>
              </IonButtons>
            ))}
        </IonToolbar>
      </IonHeader>
      <IonContent className="onboardingLoginModalPage fullHeight">
        {showSetup && (
          <>
            <div className="onboardingLoginContent">
              {showSetupPageOne && (
                <>
                  <div className="onboardingLoginHeader">
                    {`Welcome${userToSave?.userData.name ? `, ${userToSave.userData.name}` : ""}!`}
                  </div>
                  <div className="onboardingLoginSubheader">
                    {
                      "Please select what role you have to get the correct content type for you. You can amend your selection later in your profile."
                    }
                  </div>
                  <div className="onboardingRadioGroup">
                    <IonRadioGroup color="light" onIonChange={(event) => setRole(event.detail.value)}>
                      {roles.map((item, index) => {
                        return (
                          <IonItem className="onboardingRadioContainer" key={item.id}>
                            <IonLabel className="onboardingRadioLabel">{item.Name}</IonLabel>
                            <IonRadio aria-label={item.Name} value={item.Name} slot="end" />
                          </IonItem>
                        );
                      })}
                    </IonRadioGroup>
                  </div>
                  <div className="loginButtonContainer">
                    <IonButton
                      mode="ios"
                      onClick={() => pageOneContinueClicked()}
                      disabled={!setupPageOneContinueDisabled()}
                      className={`loginButton ${!setupPageOneContinueDisabled() ? "loginButtonDisabled" : ""}`}
                    >
                      {isSavingRole ? <IonSpinner /> : <div>{"Continue"}</div>}
                    </IonButton>
                  </div>
                </>
              )}
              {showSetupPageTwo && (
                <>
                  <div className="onboardingLoginHeader">{"Please add your certificates"}</div>
                  <div className="onboardingLoginSubheader">
                    {
                      "Add your certificate from your initial command course and record the issue date of the certificate. You can skip this for now and add it later through your portfolio."
                    }
                  </div>
                  {certificatesForRole.map((item, index) => {
                    const addedCertificate = certificate.find((cert) => cert.certificateType === item.Name);
                    const date = dates.find((_date) => _date.type === item.Name);

                    return (
                      <div key={item.Name}>
                        <div className="onboardingDateHeader">{item.Name}</div>
                        {addedCertificate && (
                          <>
                            <div className="onboardingAttachmentContainer" key={index}>
                              <img src={Icon_Attachment_Onboarding} className="onboardingAttachmentIcon" alt="" />
                              <div className="onboardingAttachmentFilename">{addedCertificate.name}</div>
                              <div
                                className="onboardingAttachmentCancel"
                                onClick={() => removeCertificate(addedCertificate.certificateType)}
                              >
                                {"Remove"}
                              </div>
                            </div>
                            <div>
                              <div className="onboardingDateHeader">{"Issue date"}</div>
                              <div
                                id={`onboardingDatePicker-${item.Name}`}
                                className="onboardingDateContainer"
                                onClick={() => openDatePicker(index)}
                              >
                                <div className="onboardingDateText">
                                  {format(date?.date || new Date(), "dd / MM / yyyy")}
                                </div>
                                <IonPopover
                                  trigger={`onboardingDatePicker-${item.Name}`}
                                  isOpen={datePickersVisible[index]}
                                  onDidDismiss={() => closeDatePicker(index)}
                                >
                                  <IonDatetime
                                    mode="md"
                                    size="cover"
                                    value={date?.date.toISOString()}
                                    onChange={(event) => console.log(event)}
                                    onIonChange={(event) => onDateChanged(event, item.Name)}
                                    max={new Date().toISOString()}
                                    presentation="date"
                                    firstDayOfWeek={1}
                                  />
                                </IonPopover>
                              </div>
                            </div>
                          </>
                        )}
                        <div className="loginButtonContainer" style={{ marginTop: 24 }}>
                          <IonButton
                            mode="ios"
                            fill="outline"
                            onClick={() => addCertificate(item.Name)}
                            className="addAttachmentButton"
                          >
                            <div>{addedCertificate ? "Update certificate" : "Add certificate"}</div>
                          </IonButton>
                        </div>
                      </div>
                    );
                  })}
                  <div className="loginButtonContainer">
                    <IonButton mode="ios" onClick={() => pageTwoContinueClicked()} className="loginButton">
                      {isSavingRole ? <IonSpinner /> : <div>{"Continue"}</div>}
                    </IonButton>
                  </div>
                </>
              )}
              {showSetupPageThree && (
                <>
                  <div className="onboardingLoginHeader">{"Some of your data will be shared with your trust"}</div>
                  <div className="onboardingLoginOptIn">
                    {"Your portfolio data can help your trust know who is on track with their CPD. To help keep " +
                      "on top of auditing, some of your top-level data will therefore be shared with your trust. If you would like to discuss opting out of this, please contact "}
                    <a
                      href="mailto:C2-training@swast.nhs.uk?subject=ParaFolio - Data opt out"
                      target="_blank"
                      rel="noreferrer"
                    >
                      {"C2-training@swast.nhs.uk"}
                    </a>
                  </div>
                  <div className="loginButtonContainer">
                    <IonButton mode="ios" onClick={() => pageThreeContinueClicked()} className="loginButton">
                      {isSavingRole ? <IonSpinner /> : <div>{"Continue"}</div>}
                    </IonButton>
                  </div>
                </>
              )}
            </div>
          </>
        )}
        {noSubscription && expiredSubscription && (
          <>
            <div className="onboardingLoginContent">
              <div className="onboardingLoginHeader">{"Your subscription has expired"}</div>
              <div className="onboardingLoginSubheader" style={{ marginTop: 8 }}>
                {
                  "If you do have access but are seeing this screen, please contact our support team through the button below."
                }
              </div>
              <div className="loginButtonContainer" style={{ marginTop: 16, marginBottom: 12 }}>
                <IonButton mode="ios" onClick={() => openSubscriptionOptions()} className="loginButton">
                  <div>{"See subscription options"}</div>
                </IonButton>
              </div>
              <div className="loginButtonContainer" style={{ marginTop: 4, marginBottom: 12 }}>
                <IonButton mode="ios" onClick={() => logout()} className="loginButton">
                  <div>{"Log out"}</div>
                </IonButton>
              </div>
              <div className="loginButtonContainer" style={{ marginTop: 4, marginBottom: 0 }}>
                <IonButton
                  mode="ios"
                  fill="outline"
                  onClick={() => contactSupport()}
                  className="loginButton loginButtonClear"
                >
                  <div>{"Contact support"}</div>
                </IonButton>
              </div>
              <div className="loginFooter">
                <div className="loginFooterText">
                  {"Need assistance? "}
                  <a href="loginContactSupport">{"Contact support"}</a>
                </div>
                <div className="loginFooterText">
                  {"Please read our "}
                  <a href="loginPrivacyPolicy">{"Privacy Policy"}</a>
                  {" & "}
                  <a href="loginTermsAndConditions">{"Terms & Conditions"}</a>
                </div>
              </div>
            </div>
          </>
        )}
        {noSubscription && !expiredSubscription && (
          <>
            <div className="onboardingLoginContent">
              <div className="onboardingLoginHeader">{"Unfortunately you don't have an active subscription"}</div>
              <div className="onboardingLoginSubheader">
                {"ParaFolio is currently only available to select Trusts who have set up for their paramedics"}
              </div>
              <div className="onboardingLoginSubheader" style={{ marginTop: 16 }}>
                {
                  "If you do have access but are seeing this screen, please contact our support team through the button below."
                }
              </div>
              <div className="loginButtonContainer" style={{ marginBottom: 0 }}>
                <IonButton
                  mode="ios"
                  fill="outline"
                  onClick={() => contactSupport()}
                  className="loginButton loginButtonClear"
                >
                  <div>{"Contact support"}</div>
                </IonButton>
              </div>
              <div className="loginButtonContainer" style={{ marginTop: 16 }}>
                <IonButton mode="ios" onClick={() => resetModal()} className="loginButton">
                  <div>{"Log out"}</div>
                </IonButton>
              </div>
              <div className="loginFooter">
                <div className="loginFooterText">
                  {"Need assistance? "}
                  <a href="loginContactSupport">{"Contact support"}</a>
                </div>
                <div className="loginFooterText">
                  {"Please read our "}
                  <a href="loginPrivacyPolicy">{"Privacy Policy"}</a>
                  {" & "}
                  <a href="loginTermsAndConditions">{"Terms & Conditions"}</a>
                </div>
              </div>
            </div>
          </>
        )}
        {fullFreeAccess && (
          <>
            <div className="onboardingLoginContent" id="loginContentContainer">
              <div className="onboardingLoginHeader">{"Great news, you’ve got full free access"}</div>
              <div className="onboardingLoginSubheader">
                {"The email you entered ("}
                <b>{email}</b>
                {") has "}
                <b>{"full free access"}</b>
                {" to ParaFolio. Get started with your portfolio now."}
              </div>
              <div className="onboardingFeaturesHeader">{"What ParaFolio offers:"}</div>
              <div className="onboardingFeaturesTickContainer">
                <div className="onboardingFeaturesTick">
                  <img className="onboardingFeaturesTickImage" src={Button_Reflections_Tick} alt="Tick icon" />
                  <div className="onboardingFeaturesTickText">
                    {"Track added evidence and hours spent doing CPD with "}
                    <b>{"Dashboard"}</b>
                    {"."}
                  </div>
                </div>
                <div className="onboardingFeaturesTick">
                  <img className="onboardingFeaturesTickImage" src={Button_Reflections_Tick} alt="Tick icon" />
                  <div className="onboardingFeaturesTickText">
                    {"See your activity from ParaPass and JRCALC Plus with "}
                    <b>{"Timeline"}</b>
                    {"."}
                  </div>
                </div>
                <div className="onboardingFeaturesTick">
                  <img className="onboardingFeaturesTickImage" src={Button_Reflections_Tick} alt="Tick icon" />
                  <div className="onboardingFeaturesTickText">
                    {"Manage your CPD with audit tagging and custom tags in  "}
                    <b>{"Evidence"}</b>
                    {"."}
                  </div>
                </div>
                <div className="onboardingFeaturesTick">
                  <img className="onboardingFeaturesTickImage" src={Button_Reflections_Tick} alt="Tick icon" />
                  <div className="onboardingFeaturesTickText">
                    {"Keep on top of your portfolio and be ready for audit with your "}
                    <b>{"Profile"}</b>
                    {"."}
                  </div>
                </div>
              </div>
              <div className="loginButtonContainer" style={{ marginTop: -3.5 }}>
                <IonButton mode="ios" onClick={() => goToApp()} className="loginButton">
                  <div>{"Get started"}</div>
                </IonButton>
              </div>
              <div className="loginFooter">
                <div className="loginFooterText">
                  {"Need assistance? "}
                  <a href="loginContactSupport">{"Contact support"}</a>
                </div>
                <div className="loginFooterText">
                  {"Please read our "}
                  <a href="loginPrivacyPolicy">{"Privacy Policy"}</a>
                  {" & "}
                  <a href="loginTermsAndConditions">{"Terms & Conditions"}</a>
                </div>
              </div>
            </div>
          </>
        )}
        {trialAccess && (
          <>
            <div className="onboardingLoginContent" id="loginContentContainer">
              <div className="onboardingLoginHeader">{"Explore ParaFolio with a 30 day trial"}</div>
              <div className="onboardingLoginSubheader">
                {"The email you entered ("}
                <b>{email}</b>
                {") gets a "}
                <b>{"30 day trial"}</b>
                {" to ParaFolio. Get started with your portfolio now."}
              </div>
              <div className="onboardingFeaturesHeader">{"What ParaFolio offers:"}</div>
              <div className="onboardingFeaturesTickContainer">
                <div className="onboardingFeaturesTick">
                  <img className="onboardingFeaturesTickImage" src={Button_Reflections_Tick} alt="Tick icon" />
                  <div className="onboardingFeaturesTickText">
                    {"Track added evidence and hours spent doing CPD with "}
                    <b>{"Dashboard"}</b>
                    {"."}
                  </div>
                </div>
                <div className="onboardingFeaturesTick">
                  <img className="onboardingFeaturesTickImage" src={Button_Reflections_Tick} alt="Tick icon" />
                  <div className="onboardingFeaturesTickText">
                    {"See your activity from ParaPass and JRCALC Plus with "}
                    <b>{"Timeline"}</b>
                    {"."}
                  </div>
                </div>
                <div className="onboardingFeaturesTick">
                  <img className="onboardingFeaturesTickImage" src={Button_Reflections_Tick} alt="Tick icon" />
                  <div className="onboardingFeaturesTickText">
                    {"Manage your CPD with audit tagging and custom tags in  "}
                    <b>{"Evidence"}</b>
                    {"."}
                  </div>
                </div>
                <div className="onboardingFeaturesTick">
                  <img className="onboardingFeaturesTickImage" src={Button_Reflections_Tick} alt="Tick icon" />
                  <div className="onboardingFeaturesTickText">
                    {"Keep on top of your portfolio and be ready for audit with your "}
                    <b>{"Profile"}</b>
                    {"."}
                  </div>
                </div>
              </div>
              <div className="loginButtonContainer" style={{ marginTop: -3.5 }}>
                <IonButton mode="ios" onClick={() => goToApp()} className="loginButton">
                  <div>{"Get started"}</div>
                </IonButton>
                <IonButton
                  mode="ios"
                  style={{ marginTop: 12 }}
                  fill="outline"
                  onClick={() => openSubscriptionOptions(true)}
                  className="loginButtonClear"
                >
                  <div>{"See subscription options"}</div>
                </IonButton>
              </div>
              <div className="loginFooter">
                <div className="loginFooterText">
                  {"Need assistance? "}
                  <a href="loginContactSupport">{"Contact support"}</a>
                </div>
                <div className="loginFooterText">
                  {"Please read our "}
                  <a href="loginPrivacyPolicy">{"Privacy Policy"}</a>
                  {" & "}
                  <a href="loginTermsAndConditions">{"Terms & Conditions"}</a>
                </div>
              </div>
            </div>
          </>
        )}
        {!showSetup && !noSubscription && !trialAccess && !fullFreeAccess && (
          <>
            <div className="onboardingLoginContent">
              <div className="onboardingLoginHeader">{"Log in"}</div>
              <div className="onboardingLoginSubheader">
                {"Use your Trust email address and the password that you use for ParaPass and JRCALC Plus. "}
                <a className="onboardingLoginLink" href="loginLearnMore">
                  {"Learn more"}
                </a>
              </div>
              <br />
              <div className="onboardingLoginSubheader">
                {"Need an account? "}
                <a className="onboardingLoginLink" href="loginGoToRegister">
                  {"Register here"}
                </a>
              </div>
              <form>
                <div className="loginInputHeader">Email</div>
                <div className="loginInputContainer">
                  <input
                    ref={emailRef}
                    className={`loginInput loginEmailInput ${hasErrors && "loginInputError"}`}
                    placeholder="Email address"
                    id="loginEmailInput"
                    type="email"
                    value={email}
                    autoComplete="email"
                    onChange={emailChanged}
                    onFocus={resetErrors}
                    onKeyDown={emailKeyPressed}
                  />
                  {hasErrors && <img src={Button_Error} className="loginInputErrorIcon" alt="Alert icon" />}
                </div>
                <div className="loginInputHeader">Password</div>
                <div className="loginInputContainer">
                  <input
                    ref={passwordRef}
                    className={`loginInput loginPasswordInput ${hasErrors && "loginPasswordInputError"}`}
                    placeholder="Password"
                    type={passwordHidden ? "password" : "text"}
                    value={password}
                    autoComplete="current-password"
                    onChange={passwordChanged}
                    onFocus={resetErrors}
                    onKeyDown={passwordKeyPressed}
                  />
                  <button
                    type="button"
                    className={`loginPasswordHidden ${hasErrors && "loginPasswordHiddenErrors"}`}
                    onClick={() => {
                      setPasswordHidden(!passwordHidden);
                      passwordRef.current?.focus();
                    }}
                  >
                    {passwordHidden ? "Show" : "Hide"}
                  </button>
                  {hasErrors && <img src={Button_Error} className="loginInputErrorIcon" alt="Alert icon" />}
                </div>
                <button
                  type="button"
                  className="flex flex-row mt-[8px] mb-[-20px] items-center cursor-pointer"
                  onClick={() => setTermsAccepted(!termsAccepted)}
                >
                  <img
                    src={termsAccepted ? Button_Checkbox_Active : Button_Checkbox_Inactive}
                    className="h-[44px] w-[44px] img-no-select ml-[-13px]"
                    alt=""
                  />
                  <div className="text-start">
                    {"I confirm I have read the "}
                    <a
                      className="text-cta-blue font-acc-500"
                      href={CLASS_TERMS_URL}
                      target="_blank"
                      rel="noreferrer"
                      onClick={(event: any) => event?.stopPropagation()}
                    >
                      {"Terms & Conditions"}
                    </a>
                  </div>
                </button>
                <div className="loginButtonContainer">
                  <IonButton
                    mode="ios"
                    onClick={() => loginClicked()}
                    disabled={!loginEnabled() || isLoggingIn}
                    className={`loginButton ${!loginEnabled() ? "loginButtonDisabled" : ""}`}
                  >
                    {isLoggingIn ? <IonSpinner /> : <div>{"Log in"}</div>}
                  </IonButton>
                  <IonButton
                    mode="ios"
                    fill="clear"
                    onClick={() => forgotPasswordClicked()}
                    className="loginForgotPasswordButton"
                  >
                    {"Forgot password?"}
                  </IonButton>
                </div>
                {nwasSSOAvailable && (
                  <div>
                    {"If you are an NWAS member, please "}
                    <button type="button" onClick={() => loginSSO()}>
                      <div className="text-cta-blue font-acc-500">{"login here"}</div>
                    </button>
                  </div>
                )}
                {hasErrors && (
                  <div className="loginErrorContainer">
                    <img src={Button_Error} className="loginErrorIcon" alt="Alert icon" />
                    <div className="loginErrorMessageText">{errors}</div>
                  </div>
                )}
              </form>
              <div className="loginFooter">
                <div className="loginFooterText">
                  {"Need assistance? "}
                  <a href="loginContactSupport">{"Contact support"}</a>
                </div>
                <div className="loginFooterText">
                  {"Please read our "}
                  <a href={CLASS_TERMS_URL} target="_blank" rel="noreferrer">
                    {"Privacy Policy"}
                  </a>
                  {" & "}
                  <a href={CLASS_TERMS_URL} target="_blank" rel="noreferrer">
                    {"Terms & Conditions"}
                  </a>
                </div>
              </div>
            </div>
          </>
        )}
      </IonContent>
    </IonPage>
  );
};

export default withRouter(Login);
