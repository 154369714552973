/* eslint-disable no-extend-native */
import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonImg,
  IonPage,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import ContentContainer from "../../components/common/ContentContainer";
import { useEffect } from "react";
import { EventRegister } from "react-native-event-listeners";
import { useParams, withRouter } from "react-router";
import data from "../../assets/json/info.json";
import DOMPurify from "dompurify";
import "./Info.css";
import DataController from "../../controllers/DataController";
import { Button_Audio_Play_White, Button_Audio_Stop } from "../../assets/images";
import { accessibilityAtom } from "../../state/State";
import { IAccessibilitySettings } from "../../Interfaces";
import { useRecoilValueLoadable } from "recoil";
import { Capacitor } from "@capacitor/core";
import { useTextToSpeech } from "../../hooks/accessibility/useTextToSpeech";

String.prototype.replaceAll = function (search: any, replacement: any) {
  var target = this;
  return target.split(search).join(replacement);
};

const Info: React.FC = (props) => {
  const params = useParams<{ pageId: string }>();

  const accessibilitySettings = useRecoilValueLoadable<IAccessibilitySettings | null>(accessibilityAtom);
  const { handleTTS, playing, stopTTS } = useTextToSpeech("infoContainer");

  const search = params.pageId?.replace(/-/gim, " ");
  const info: any = data.find((item) => item.Title.toLowerCase() === search) ?? null;

  useEffect(() => {
    return () => {
      stopTTS();
    };
  }, []);

  useEffect(() => {
    EventRegister.emit("tab-bar/visibility-changed", false);

    return () => EventRegister.emit("tab-bar/visibility-changed", true);
  });

  useEffect(() => {
    const handleClick = async (event: Event): Promise<void> => {
      const element = event.target as HTMLAnchorElement;

      if (element.href && element.href) {
        event.preventDefault();
        window.open(element.href, "_blank");
      }
    };

    document.addEventListener("click", handleClick);

    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, []);

  const sanitizeData = (data: string) => ({ __html: DOMPurify.sanitize(data) });

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar
          mode="ios"
          className="navBar"
          style={{
            maxWidth: DataController.isWeb() ? 980 : undefined,
            height: Capacitor.getPlatform() === "android" ? "54px" : "unset",
          }}
        >
          <IonButtons slot="start">
            <IonBackButton
              className="header-back-buttons"
              defaultHref="/dashboard"
              text={DataController.getBackIconText()}
              icon={DataController.getBackIconType()}
              style={{
                marginLeft: Capacitor.getPlatform() === "android" ? 8 : 0,
                "--icon-font-size": Capacitor.getPlatform() === "android" ? "24px" : "30px",
              }}
            />
          </IonButtons>
          <IonTitle>{""}</IonTitle>
          {accessibilitySettings.contents?.textToSpeech && (
            <IonButtons slot="end">
              <IonButton className="header-button" mode="ios" onClick={() => handleTTS()}>
                <IonImg src={playing ? Button_Audio_Stop : Button_Audio_Play_White} className="headerIcon" />
              </IonButton>
            </IonButtons>
          )}
        </IonToolbar>
      </IonHeader>
      <IonContent className="page-background whiteBackground infoPage">
        <ContentContainer tabBarDisabled id="infoContainer">
          {info && (
            <>
              <div className="infoPageTitle">{info.Title}</div>
              <div dangerouslySetInnerHTML={sanitizeData(info.HTML ?? "")} />
            </>
          )}
        </ContentContainer>
      </IonContent>
    </IonPage>
  );
};

export default withRouter(Info);
