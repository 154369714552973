import { AWSAccessParams, ICertificateInfo, IUser, EvidenceAttachment, IUserProgramCertificate } from "./../Interfaces";
import AWS from "aws-sdk";
import { Buffer } from "buffer";
import RemoteDataController from "./RemoteDataController";
import { PF_SERVER_URL } from "../Constants";
import { EventRegister } from "react-native-event-listeners";

export class AWSService {
  /**
   * Requests AWS STS (Security Token Service) to be created for the current user
   * @param user
   * @returns The generated credentials { accessKeyId: string; secretAccessKey: string; sessionToken: string; region: string; bucket: string; }
   */
  async generateSTSToken(user: IUser): Promise<AWSAccessParams> {
    return new Promise(async (resolve, reject) => {
      try {
        const url = PF_SERVER_URL + "/portfolio/AWS/generateSTSToken";

        const response: Response = await fetch(url, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            Token: user.token,
            ContactID: user.userData.contactID,
          }),
        });

        if (response) {
          const json = await response.json();

          if (json.success) {
            resolve(json.body);
          } else {
            reject(json.message || json.error);
          }
        } else {
          reject("The server didn't respond");
        }
      } catch (error: any) {
        console.log(error);
        RemoteDataController.logError(error);
        reject("Failed to generate token");
      }
    });
  }

  /**
   * Uploads a user certificate to their S3 bucket - portfolio-plus/{contactID}/certificate
   * @param user - Current user object
   * @param accessParams - AWS STS credentials generated with generateSTSToken
   * @param certificate - Certificate to upload
   * @returns The file information returned for the file stored on S3 if uploaded successfully
   */
  async uploadCertificate(
    user: IUser,
    accessParams: AWSAccessParams,
    certificate: ICertificateInfo
  ): Promise<boolean | any> {
    return new Promise(async (resolve, reject) => {
      try {
        const data = certificate.content?.split("base64,")[1];
        const base64Data: any = data && Buffer.from(data, "base64");

        const s3 = new AWS.S3({
          region: accessParams.region,
          credentials: {
            accessKeyId: accessParams.accessKeyId,
            secretAccessKey: accessParams.secretAccessKey,
            sessionToken: accessParams.sessionToken,
          },
        });

        const file = await s3
          .upload({
            Bucket: accessParams.bucket,
            Key: `${user.userData.contactID}/certificate/${certificate.name}`,
            Body: base64Data,
            ContentEncoding: "base64",
            ContentType: certificate.type,
          })
          .promise();

        resolve(file);
      } catch (error: any) {
        if (error instanceof Error && error.stack?.includes("Network Failure")) {
          resolve({ networkIssue: true });
        } else {
          console.log(error);
          RemoteDataController.logError(error);
          reject("An error has occurred");
        }
      }
    });
  }

  /**
   * Deletes a user certificate from their S3 bucket - portfolio-plus/{contactID}/certificate
   * @param user - Current user object
   * @param accessParams - AWS STS credentials generated with generateSTSToken
   * @param certificate - Certificate to delete
   * @returns true if certificate deleted
   */
  async deleteCertificate(
    user: IUser,
    accessParams: AWSAccessParams,
    certificate: ICertificateInfo | undefined
  ): Promise<boolean | any> {
    return new Promise(async (resolve, reject) => {
      try {
        const s3 = new AWS.S3({
          region: accessParams.region,
          credentials: {
            accessKeyId: accessParams.accessKeyId,
            secretAccessKey: accessParams.secretAccessKey,
            sessionToken: accessParams.sessionToken,
          },
        });

        await s3
          .deleteObject({
            Bucket: accessParams.bucket,
            Key: `${user.userData.contactID}/certificate/${certificate?.name}`,
          })
          .promise();

        resolve(true);
      } catch (error: any) {
        console.log(error);
        RemoteDataController.logError(error);
        reject("An error has occurred");
      }
    });
  }

  /**
   * Creates a signed URL for the requested certificate
   * @param user - Current user object
   * @param accessParams - AWS STS credentials generated with generateSTSToken
   * @param certificate - Certificate to generate URL from
   * @returns The generated signed URL if successful
   */
  async openCertificate(
    user: IUser,
    accessParams: AWSAccessParams,
    certificate: ICertificateInfo | undefined
  ): Promise<string> {
    return new Promise(async (resolve, reject) => {
      try {
        const s3 = new AWS.S3({
          region: accessParams.region,
          credentials: {
            accessKeyId: accessParams.accessKeyId,
            secretAccessKey: accessParams.secretAccessKey,
            sessionToken: accessParams.sessionToken,
          },
        });

        const signedURL = s3.getSignedUrl("getObject", {
          Bucket: accessParams.bucket,
          Key: `${user.userData.contactID}/certificate/${certificate?.name}`,
          Expires: 604800, // Time in seconds
        });

        resolve(signedURL);
      } catch (error: any) {
        console.log(error);
        RemoteDataController.logError(error);
        reject("An error has occurred");
      }
    });
  }

  /**
   * Downloads the requested certificate directly from S3 through Class server
   * @param user - Current user object
   * @param accessParams - AWS STS credentials generated with generateSTSToken
   * @param certificate - Certificate to download
   * @returns The raw file data
   */
  async openCertificateFromServer(
    user: IUser,
    accessParams: AWSAccessParams,
    certificate: ICertificateInfo | undefined
  ): Promise<any> {
    return new Promise(async (resolve, reject) => {
      try {
        const url = PF_SERVER_URL + "/portfolio/AWS/getFileFromS3";

        const response: Response = await fetch(url, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            Token: user.token,
            ContactID: user.userData.contactID,
            Bucket: accessParams.bucket,
            Key: `${user.userData.contactID}/certificate/${certificate?.name}`,
          }),
        });

        if (response) {
          const json = await response.json();

          if (json.success) {
            resolve(json.body);
          } else {
            reject(json.message || json.error);
          }
        } else {
          reject("The server didn't respond");
        }
      } catch (error: any) {
        console.log(error);
        RemoteDataController.logError(error);
        reject("An error has occurred");
      }
    });
  }

  /**
   * Downloads the requested attachment directly from S3 through Class server
   * @param user - Current user object
   * @param accessParams - AWS STS credentials generated with generateSTSToken
   * @param id - The evidence id
   * @param attachment - Attachment to download
   * @returns The raw file data
   */
  async openAttachmentFromServer(
    user: IUser,
    accessParams: AWSAccessParams,
    id: string,
    attachment: EvidenceAttachment
  ): Promise<any> {
    return new Promise(async (resolve, reject) => {
      try {
        const url = PF_SERVER_URL + "/portfolio/AWS/getFileFromS3";

        const response: Response = await fetch(url, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            Token: user.token,
            ContactID: user.userData.contactID,
            Bucket: accessParams.bucket,
            Key: `${user.userData.contactID}/attachments/${id}/${attachment.name}`,
          }),
        });

        if (response) {
          const json = await response.json();

          if (json.success) {
            resolve(json.body);
          } else {
            reject(json.message || json.error);
          }
        } else {
          reject("The server didn't respond");
        }
      } catch (error: any) {
        console.log(error);
        RemoteDataController.logError(error);
        reject("An error has occurred");
      }
    });
  }

  /**
   * Generates a signed URL for the requested attachment when the evidence ID is unavailable
   * @param user - Current user object
   * @param accessParams - AWS STS credentials generated with generateSTSToken
   * @param url - The unsigned URL of the attachment file
   * @returns The generated signed URL if successful
   */
  getAttachmentURLWithoutID(user: IUser, accessParams: AWSAccessParams | null, url: string): string {
    try {
      const s3 = new AWS.S3({
        region: accessParams?.region,
        credentials: {
          accessKeyId: accessParams?.accessKeyId || "",
          secretAccessKey: accessParams?.secretAccessKey || "",
          sessionToken: accessParams?.sessionToken,
        },
      });

      const signedURL = s3.getSignedUrl("getObject", {
        Bucket: accessParams?.bucket,
        Key: `${user.userData.contactID}/${url}`,
        Expires: 604800, // Time in seconds
      });

      return signedURL;
    } catch (error: any) {
      console.log(error);
      RemoteDataController.logError(error);
      return "";
    }
  }

  /**
   * Creates a signed URL for the requested attachment
   * @param user - Current user object
   * @param accessParams - AWS STS credentials generated with generateSTSToken
   * @param id - The evidence id
   * @param attachment - Attachment to download
   * @returns The generated signed URL if successful
   */
  getAttachmentURL(
    user: IUser,
    accessParams: AWSAccessParams | null,
    id: string,
    attachment: EvidenceAttachment
  ): string {
    try {
      const s3 = new AWS.S3({
        region: accessParams?.region,
        credentials: {
          accessKeyId: accessParams?.accessKeyId || "",
          secretAccessKey: accessParams?.secretAccessKey || "",
          sessionToken: accessParams?.sessionToken,
        },
      });

      const signedURL = s3.getSignedUrl("getObject", {
        Bucket: accessParams?.bucket,
        Key: `${user.userData.contactID}/attachments/${id}/${attachment.name}`,
        Expires: 604800, // Time in seconds
      });

      // console.log(signedURL);

      return signedURL;
    } catch (error: any) {
      console.log(error);
      RemoteDataController.logError(error);
      return "";
    }
  }

  /**
   * Creates a signed URL for the requested certificate
   * @param user - Current user object
   * @param accessParams - AWS STS credentials generated with generateSTSToken
   * @param certificate - Certificate to download
   * @returns The generated signed URL if successful
   */
  getCertificateURL(user: IUser, accessParams: AWSAccessParams | null, certificate: IUserProgramCertificate): string {
    try {
      const s3 = new AWS.S3({
        region: accessParams?.region,
        credentials: {
          accessKeyId: accessParams?.accessKeyId || "",
          secretAccessKey: accessParams?.secretAccessKey || "",
          sessionToken: accessParams?.sessionToken,
        },
      });

      const signedURL = s3.getSignedUrl("getObject", {
        Bucket: accessParams?.bucket,
        Key: `${user.userData.contactID}/certificate/${certificate.certificate?.name}`,
        Expires: 604800, // Time in seconds
      });

      return signedURL;
    } catch (error: any) {
      console.log(error);
      RemoteDataController.logError(error);
      return "";
    }
  }

  /**
   * Uploads an evidence attachment to the user's S3 bucket - portfolio-plus/{contactID}/attachments/{evidenceID}
   * @param user - Current user object
   * @param accessParams - AWS STS credentials generated with generateSTSToken
   * @param id - The evidence id
   * @param attachment - Attachment to upload
   * @returns The file information returned for the file stored on S3 if uploaded successfully
   */
  async uploadAttachment(
    user: IUser,
    accessParams: AWSAccessParams,
    id: string,
    attachment: EvidenceAttachment
  ): Promise<AWS.S3.ManagedUpload.SendData | any> {
    return new Promise(async (resolve, reject) => {
      try {
        const data = attachment.content?.split("base64,")[1];
        const base64Data: any = data && Buffer.from(data, "base64");

        const s3 = new AWS.S3({
          region: accessParams.region,
          credentials: {
            accessKeyId: accessParams.accessKeyId,
            secretAccessKey: accessParams.secretAccessKey,
            sessionToken: accessParams.sessionToken,
          },
        });

        const key = `${user.userData.contactID}/attachments/${id}/${attachment.name}`;

        const uploadedFile = await s3
          .upload({
            Bucket: accessParams.bucket,
            Key: key,
            Body: base64Data,
            ContentEncoding: "base64",
            ContentType: attachment.type,
          })
          // .on("httpUploadProgress", (progress) => {
          //   const progressObject = {
          //     percentage: Math.round((progress.loaded / progress.total) * 100),
          //     key,
          //   };

          //   EventRegister.emit("evidence/update-progress", progressObject);
          // })
          .promise();

        resolve(uploadedFile);
      } catch (error: any) {
        // Checks if the reason for the error was a network issue so file upload can be retried through server
        if (error instanceof Error && error.stack?.includes("Network Failure")) {
          resolve({ networkIssue: true });
        } else {
          console.log(error);
          RemoteDataController.logError(error);
          reject("An error has occurred");
        }
      }
    });
  }

  /**
   *
   * Deletes an evidence attachment to the user's S3 bucket - portfolio-plus/{contactID}/attachments/{evidenceID}
   * @param user - Current user object
   * @param accessParams - AWS STS credentials generated with generateSTSToken
   * @param id - The evidence id
   * @param attachment - Attachment to delete
   * @returns true if attachment deleted
   */
  async deleteAttachment(
    user: IUser,
    accessParams: AWSAccessParams,
    id: string,
    attachment: EvidenceAttachment
  ): Promise<AWS.S3.ManagedUpload.SendData | any> {
    return new Promise(async (resolve, reject) => {
      try {
        const s3 = new AWS.S3({
          region: accessParams.region,
          credentials: {
            accessKeyId: accessParams.accessKeyId,
            secretAccessKey: accessParams.secretAccessKey,
            sessionToken: accessParams.sessionToken,
          },
        });

        await s3
          .deleteObject({
            Bucket: accessParams.bucket,
            Key: `${user.userData.contactID}/attachments/${id}/${attachment.name}`,
          })
          .promise();

        resolve(true);
      } catch (error: any) {
        console.log(error);
        RemoteDataController.logError(error);
        reject("An error has occurred");
      }
    });
  }

  /**
   * Uploads an evidence attachment to the user's S3 bucket through Class server - portfolio-plus/{contactID}/attachments/{evidenceID}
   * @param user - Current user object
   * @param accessParams - AWS STS credentials generated with generateSTSToken
   * @param id - The evidence id
   * @param attachment - Attachment to upload
   * @returns The file information returned for the file stored on S3 if uploaded successfully
   */
  async uploadAttachmentFromServer(
    user: IUser,
    accessParams: AWSAccessParams,
    id: string,
    attachment: EvidenceAttachment
  ): Promise<any> {
    return new Promise(async (resolve, reject) => {
      try {
        const url = PF_SERVER_URL + "/portfolio/AWS/uploadFileToS3";

        const data = attachment.content?.split("base64,")[1];
        const base64Data: any = data && Buffer.from(data, "base64");

        const response: Response = await fetch(url, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            Token: user.token,
            ContactID: user.userData.contactID,
            Data: base64Data,
            Bucket: accessParams.bucket,
            Key: `${user.userData.contactID}/attachments/${id}/${attachment.name}`,
            ContentType: attachment.type,
          }),
        });

        if (response) {
          const json = await response.json();

          if (json.success) {
            resolve(json.body);
          } else {
            reject(json.message || json.error);
          }
        } else {
          reject("The server didn't respond");
        }
      } catch (error: any) {
        console.log(error);
        RemoteDataController.logError(error);
        reject("An error has occurred");
      }
    });
  }

  /**
   * Uploads a user certificate to their S3 bucket through Class server - portfolio-plus/{contactID}/attachments/{evidenceID}
   * @param user - Current user object
   * @param accessParams - AWS STS credentials generated with generateSTSToken
   * @param certificate - Certificate to upload
   * @returns The file information returned for the file stored on S3 if uploaded successfully
   */
  async uploadCertificateFromServer(
    user: IUser,
    accessParams: AWSAccessParams,
    certificate: ICertificateInfo
  ): Promise<any> {
    return new Promise(async (resolve, reject) => {
      try {
        const url = PF_SERVER_URL + "/portfolio/AWS/uploadFileToS3";

        const data = certificate.content?.split("base64,")[1];
        const base64Data: any = data && Buffer.from(data, "base64");

        const response: Response = await fetch(url, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            Token: user.token,
            ContactID: user.userData.contactID,
            Data: base64Data,
            Bucket: accessParams.bucket,
            Key: `${user.userData.contactID}/certificate/${certificate.name}`,
            ContentType: certificate.type,
          }),
        });

        if (response) {
          const json = await response.json();

          if (json.success) {
            resolve(json.body);
          } else {
            reject(json.message || json.error);
          }
        } else {
          reject("The server didn't respond");
        }
      } catch (error: any) {
        console.log(error);
        RemoteDataController.logError(error);
        reject("An error has occurred");
      }
    });
  }
}

export default new AWSService();
