import { useRecoilStateLoadable, useRecoilValueLoadable } from "recoil";
import { IEvidence, IMandatoryStandard, IOptionalStandard, IUser, ProgramData } from "../../Interfaces";
import { evidenceAtom, userAtom } from "../../state/State";
import * as ProgramUtils from "../../utils/programUtils";
import { useMemo } from "react";
import { subYears } from "date-fns";
import SegmentedProgressBar from "../common/SegmentedProgressBar";
import { useProgramData } from "../../hooks/data/useProgramData";

const EprrOverview: React.FC<{}> = ({}) => {
  const [user, _] = useRecoilStateLoadable<IUser | null>(userAtom);
  const evidence = useRecoilValueLoadable<IEvidence[] | null>(evidenceAtom);

  const { userPrograms } = useProgramData();

  const program = userPrograms.find((item) => item.ID === "LR0HYZYWY6JQK");

  let programEvidence: IEvidence[] = [];
  let draftEvidenceCount = 0;

  let mandatoryStandards: IMandatoryStandard[] = [];
  let mandatoryStandardsCompletionCount = 0;

  let optionalStandards: IOptionalStandard[] = [];
  let optionalStandardsCompletionCount = 0;

  if (user.state === "hasValue" && evidence.state === "hasValue" && evidence.contents?.length && program) {
    programEvidence = evidence.contents.filter((evidence: IEvidence) => {
      const evidenceJSON = evidence.evidenceJSON && JSON.parse(evidence.evidenceJSON);
      const programName = evidenceJSON?.programInfo?.program || "";
      const onHoldReset = evidenceJSON?.OnHoldReset ?? 0;
      const include =
        programName === program.Name && onHoldReset !== 1 && new Date(evidence.date) >= subYears(new Date(), 2);
      if (include) {
        if (evidence.draft) {
          draftEvidenceCount += 1;
          return false;
        } else {
          return true;
        }
      } else {
        return false;
      }
    });

    const roleID = ProgramUtils.getSelectedRoleIdForNWASICProgram(user.contents);
    mandatoryStandards = ProgramUtils.getMandatoryStandardsFromProgram(program, roleID);
    mandatoryStandardsCompletionCount = ProgramUtils.getCompliantCountForStandards(
      mandatoryStandards,
      userPrograms,
      user.contents,
      programEvidence
    );

    optionalStandards = ProgramUtils.getOptionalStandardsFromUser(user.contents, program);
    optionalStandardsCompletionCount = ProgramUtils.getCompliantCountForStandards(
      optionalStandards,
      userPrograms,
      user.contents,
      programEvidence
    );
  }

  return (
    <div>
      <div className="mt-4">
        <SegmentedProgressBar
          title={"Mandatory Standards"}
          numerator={mandatoryStandardsCompletionCount}
          denominator={mandatoryStandards.length}
        />
      </div>
      <div className="mt-4">
        <SegmentedProgressBar
          title={"Optional Standards"}
          numerator={optionalStandardsCompletionCount}
          denominator={optionalStandards.length}
        />
      </div>
      <div className="flex mt-4">
        <div className="flex-1 flex flex-col justify-between">
          <div className="text-grey-90 text-acc-13px font-normal mb-2">Evidence added</div>
          <div className="text-program-card-eprr-text text-acc-28px font-bold">
            {programEvidence ? programEvidence.length : 0}
          </div>
        </div>
        <div className="flex-1 flex flex-col justify-between items-start">
          <div className="mb-2 rounded-xl bg-draft-badge text-draft-badge-text px-2 py-1 text-acc-12px font-semibold">
            Draft
          </div>
          <div className="text-program-card-eprr-text text-acc-28px font-bold">{draftEvidenceCount}</div>
        </div>
      </div>
    </div>
  );
};

export default EprrOverview;
