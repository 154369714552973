import { IonRange } from "@ionic/react";
import { useEffect, useState } from "react";
import { IonRangeCustomEvent } from "@ionic/core/dist/types/components";
import { RangeChangeEventDetail } from "@ionic/core/dist/types/components/range/range-interface";
import { IWellbeingRangeSelect } from "../../../types/Components";

const WellbeingRangeSelect: React.FC<IWellbeingRangeSelect> = (props) => {
  const [selectedValue, setSelectedValue] = useState<number>(0);
  const [currentValue, setCurrentValue] = useState<number>(0);
  const maxValue = props.wellbeingPart["Max value"] ?? 10;

  const onChange = (event: IonRangeCustomEvent<RangeChangeEventDetail>): void => {
    if (typeof event.detail.value === "number") {
      setSelectedValue(event.detail.value);
      props.onValueChanged({ value: event.detail.value, maxValue });
    }
  };

  const onInput = (event: IonRangeCustomEvent<RangeChangeEventDetail>): void => {
    typeof event.detail.value === "number" && setCurrentValue(event.detail.value);
  };

  const getBarColourClassName = (): string => {
    if (currentValue <= 3) {
      return "wellbeing-range-bar-red";
    } else if (currentValue <= 6) {
      return "wellbeing-range-bar-orange";
    } else {
      return "wellbeing-range-bar-green";
    }
  };

  const isSelected = (value: number): boolean => {
    return value === currentValue;
  };

  const getStepPercentage = (value: number): string => {
    const result = `left-[${(value / maxValue) * 100}%]`;

    return result;
  };

  return (
    <div className="rounded-[5px] pt-5 bg-white m-4 pb-8">
      <div className="text-14px font-semibold">{props.wellbeingPart["Display Title"]}</div>
      <div className="px-7 mt-5">
        <IonRange
          className={`wellbeing-ion-range ${getBarColourClassName()}`}
          snaps
          ticks
          min={0}
          max={maxValue}
          onIonChange={(event) => onChange(event)}
          onIonInput={(event) => onInput(event)}
        />
        <div className="flex justify-between relative ml-[-5px] mr-[5px]">
          {Array.from(Array(maxValue + 1).keys()).map((value) => (
            <span
              key={value}
              className={`absolute text-[14px] font-semibold ${getStepPercentage(value)} ${isSelected(value) ? "text-grey-90 animate-fade-down animate-once animate-duration-[150ms] animate-ease-linear animate-normal animate-fill-both" : "text-grey-60 animate-fade-up animate-once animate-duration-[150ms] animate-ease-linear animate-normal animate-fill-both"}`}
            >
              {value}
            </span>
          ))}
        </div>
      </div>
    </div>
    /*
    left-[0%]
    left-[12.5%]
    left-[25%]
    left-[37.5%]
    left-[50%]
    left-[62.5%]
    left-[75%]
    left-[87.5%]
    left-[100%]
    left-[10%]
    left-[20%]
    left-[30%]
    left-[40%]
    left-[50%]
    left-[60%]
    left-[70%]
    left-[80%]
    left-[90%]
    left-[100%]
    */
  );
};

export default WellbeingRangeSelect;
