import { IonButton } from "@ionic/react";
import { IWellbeingSupportCard } from "../../../types/Components";

const WellbeingSupportCard: React.FC<IWellbeingSupportCard> = (props) => {
  return (
    <div className="rounded-[5px] p-4 bg-white mx-4 my-1.5">
      {props.wellbeingPart["Support Image"] && (
        <img alt="" className="!max-w-24 !max-h-14 !block !w-auto !h-auto" src={props.wellbeingPart["Support Image"]} />
      )}
      <div className="text-acc-14px text-start mt-3">{props.wellbeingPart["List of values"]}</div>
      {props.wellbeingPart["Contact Link"] && (
        <IonButton mode="ios" className="grey-button w-full h-[56px] max-w-[374px] mt-4">
          <div className="text-[16px] font-bold tracking-default text-center text-cta-blue">{"Contact"}</div>
        </IonButton>
      )}
    </div>
  );
};

export default WellbeingSupportCard;
