import { PARAPASS_SERVER_URL, PF_SERVER_URL } from "../Constants";
import { IEvidence, IUser } from "../Interfaces";
import RemoteDataController from "./RemoteDataController";

export namespace DatabaseService {
  /**
   * Retrieves all user's evidence from database
   * @param {IUser} user - The current user
   * @returns {IEvidence[] | any} All user evidence from database
   */
  export async function getEvidence(user: IUser): Promise<IEvidence[] | any> {
    return new Promise(async (resolve, reject) => {
      try {
        const url = PF_SERVER_URL + "/portfolio/DB/getEvidence";

        const response: Response = await fetch(url, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            Token: user.token,
            ContactID: user.userData.contactID,
          }),
        });

        if (response) {
          const json = await response.json();

          if (json.success) {
            let array: any[] = json.body;

            array = array.map((evidence) => {
              return {
                id: evidence.id,
                title: evidence.title,
                date: evidence.date,
                ...(evidence.activityDate && {
                  activityDate: evidence.activityDate,
                }),
                activity: JSON.parse(evidence.activity),
                ...(evidence.numberOfHours && {
                  numberOfHours: evidence.numberOfHours,
                }),
                ...(evidence.standardTags && {
                  standardTags: JSON.parse(evidence.standardTags),
                }),
                ...(evidence.customTags && {
                  customTags: JSON.parse(evidence.customTags),
                }),
                ...(evidence.activityDescription && {
                  activityDescription: evidence.activityDescription,
                }),
                benefits: evidence.benefits,
                reflections: evidence.reflections,
                objectives: evidence.objectives || "",
                ...(evidence.attachments && {
                  attachments: JSON.parse(evidence.attachments),
                }),
                ...(evidence.addToHCPCAudit !== undefined && {
                  addToHCPCAudit: evidence.addToHCPCAudit === 1,
                }),
                ...(evidence.timelineReference && {
                  timelineReference: evidence.timelineReference,
                }),
                ...(evidence.evidenceJSON && {
                  evidenceJSON: evidence.evidenceJSON,
                }),
                draft: evidence.draft !== undefined ? evidence.draft === 1 : false,
              };
            });

            resolve(array);
          } else {
            reject(json.message || json.error);
          }
        } else {
          reject("The server didn't respond");
        }
      } catch (error: any) {
        console.log(error);
        RemoteDataController.logError(error);
        reject("An error has occurred");
      }
    });
  }

  /**
   * Adds new program evidence to the database
   * @param {IUser} user - The current user
   * @param {IEvidence} evidence  - The program evidence being added
   * @returns {boolean} Whether the request was successful or not
   */
  export async function addProgramEvidence(user: IUser, evidence: IEvidence): Promise<boolean | any> {
    return new Promise(async (resolve, reject) => {
      try {
        const url = PF_SERVER_URL + "/portfolio/DB/jsonAddEvidence";

        const response: Response = await fetch(url, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            Token: user.token,
            ContactID: user.userData.contactID,
            id: evidence.id,
            date: evidence.date,
            ...(evidence.addToHCPCAudit !== undefined && {
              addToHCPCAudit: evidence.addToHCPCAudit,
            }),
            ...(evidence.numberOfHours !== "" && {
              numberOfHours: evidence.numberOfHours,
            }),
            ...(evidence.evidenceJSON && {
              evidenceJSON: JSON.stringify(evidence.evidenceJSON),
              programName: evidence.evidenceJSON?.programInfo?.program,
              competenceName: evidence.evidenceJSON?.programInfo?.competence,
            }),
            draft: evidence.draft,
          }),
        });

        if (response) {
          const json = await response.json();
          let body = json.body.replace("affectedRows ", "");
          body = JSON.parse(body);

          if (json.success) {
            if (body.code === "ER_DUP_ENTRY") {
              resolve(false);
            } else {
              resolve(true);
            }
          } else {
            reject(json.message || json.error);
          }
        } else {
          reject("The server didn't respond");
        }
      } catch (error: any) {
        console.log(error);
        RemoteDataController.logError(error);
        reject(false);
      }
    });
  }

  /**
   * Adds general evidence to the database
   * @param {IUser} user - The current user
   * @param {IEvidence} evidence - The general evidence being added
   * @returns {boolean} Whether the request was successful or not
   */
  export async function addEvidence(user: IUser, evidence: IEvidence): Promise<boolean | any> {
    return new Promise(async (resolve, reject) => {
      try {
        const url = PF_SERVER_URL + "/portfolio/DB/addEvidence";

        const response: Response = await fetch(url, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            Token: user.token,
            ContactID: user.userData.contactID,
            id: evidence.id,
            title: evidence.title,
            date: evidence.date,
            ...(evidence.activityDate && {
              activityDate: evidence.activityDate,
            }),
            activity: JSON.stringify(evidence.activity),
            ...(evidence.numberOfHours && {
              numberOfHours: evidence.numberOfHours,
            }),
            ...(evidence.standardTags && {
              standardTags: JSON.stringify(evidence.standardTags),
            }),
            ...(evidence.customTags && {
              customTags: JSON.stringify(evidence.customTags),
            }),
            ...(evidence.activityDescription && {
              activityDescription: evidence.activityDescription,
            }),
            benefits: evidence.benefits,
            reflections: evidence.reflections,
            ...(evidence.objectives && { objectives: evidence.objectives }),
            ...(evidence.attachments && {
              attachments: JSON.stringify(evidence.attachments),
            }),
            ...(evidence.addToHCPCAudit !== undefined && {
              addToHCPCAudit: evidence.addToHCPCAudit,
            }),
            ...(evidence.timelineReference && {
              timelineReference: evidence.timelineReference,
            }),
            draft: evidence.draft,
          }),
        });

        if (response) {
          const json = await response.json();
          let body = json.body.replace("affectedRows ", "");
          body = JSON.parse(body);

          if (json.success) {
            if (body.code === "ER_DUP_ENTRY") {
              resolve(false);
            } else {
              resolve(true);
            }
          } else {
            reject(json.message || json.error);
          }
        } else {
          reject("The server didn't respond");
        }
      } catch (error: any) {
        console.log(error);
        RemoteDataController.logError(error);
      }
    });
  }

  /**
   * Updates a piece of program evidence in the database
   * @param {IUser} user - The current user
   * @param evidence - The program evidence being updated
   * @returns {boolean} Whether the request was successful or not
   */
  export async function updateProgramEvidence(user: IUser, evidence: IEvidence): Promise<boolean | any> {
    return new Promise(async (resolve, reject) => {
      try {
        const url = PF_SERVER_URL + "/portfolio/DB/jsonUpdateEvidence";

        const response: Response = await fetch(url, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            Token: user.token,
            ContactID: user.userData.contactID,
            id: evidence.id,
            ...(evidence.addToHCPCAudit !== undefined && {
              addToHCPCAudit: evidence.addToHCPCAudit,
            }),
            ...(evidence.numberOfHours !== "" && {
              numberOfHours: evidence.numberOfHours,
            }),
            date: evidence.date,
            ...(evidence.evidenceJSON && {
              evidenceJSON: JSON.stringify(evidence.evidenceJSON),
              programName: evidence.evidenceJSON?.programInfo?.program,
              competenceName: evidence.evidenceJSON?.programInfo?.competence,
            }),
            draft: evidence.draft,
          }),
        });

        if (response) {
          const json = await response.json();
          let body = json.body.replace("affectedRows ", "");
          body = JSON.parse(body);

          // console.log('/jsonAddEvidence', body);

          if (json.success) {
            if (body.code === "ER_DUP_ENTRY") {
              resolve(false);
            } else {
              resolve(true);
            }
          } else {
            reject(json.message || json.error);
          }
        } else {
          reject("The server didn't respond");
        }
      } catch (error: any) {
        console.log(error);
        RemoteDataController.logError(error);
      }
    });
  }

  /**
   * Updates a piece of general evidence in the database
   * @param {IUser} user - The current user
   * @param {IEvidence} evidence - The general evidence being updated
   * @returns {boolean} Whether the request was successful or not
   */
  export async function updateEvidence(user: IUser, evidence: IEvidence): Promise<boolean | any> {
    return new Promise(async (resolve, reject) => {
      try {
        const url = PF_SERVER_URL + "/portfolio/DB/updateEvidence";

        // console.log(evidence);

        const response: Response = await fetch(url, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            Token: user.token,
            ContactID: user.userData.contactID,
            id: evidence.id,
            title: evidence.title,
            date: evidence.date,
            ...(evidence.activityDate && {
              activityDate: evidence.activityDate,
            }),
            activity: JSON.stringify(evidence.activity),
            ...(evidence.numberOfHours && {
              numberOfHours: evidence.numberOfHours,
            }),
            ...(evidence.standardTags && {
              standardTags: JSON.stringify(evidence.standardTags),
            }),
            ...(evidence.customTags && {
              customTags: JSON.stringify(evidence.customTags),
            }),
            ...(evidence.activityDescription && {
              activityDescription: evidence.activityDescription,
            }),
            benefits: evidence.benefits,
            reflections: evidence.reflections,
            ...(evidence.objectives && { objectives: evidence.objectives }),
            ...(evidence.attachments && {
              attachments: JSON.stringify(evidence.attachments),
            }),
            ...(evidence.addToHCPCAudit !== undefined && {
              addToHCPCAudit: evidence.addToHCPCAudit,
            }),
            ...(evidence.timelineReference && {
              timelineReference: evidence.timelineReference,
            }),
            draft: evidence.draft,
          }),
        });

        if (response) {
          const json = await response.json();
          let body = json.body.replace("affectedRows ", "");
          body = JSON.parse(body);

          // console.log('/updateEvidence', body);

          if (json.success) {
            if (body.code === "ER_DUP_ENTRY") {
              resolve(false);
            } else {
              resolve(true);
            }
          } else {
            reject(json.message || json.error);
          }
        } else {
          reject("The server didn't respond");
        }
      } catch (error: any) {
        console.log(error);
        RemoteDataController.logError(error);
      }
    });
  }

  /**
   * Deletes a piece of evidence in the database
   * @param {IUser} user - The current user
   * @param {string} id - The id of the evidence in the database
   * @returns {boolean} Whether the request was successful or not
   */
  export async function deleteEvidence(user: IUser, id: string): Promise<boolean | any> {
    return new Promise(async (resolve, reject) => {
      try {
        const url = PF_SERVER_URL + "/portfolio/DB/deleteEvidence";

        const response: Response = await fetch(url, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            Token: user.token,
            ContactID: user.userData.contactID,
            id: id,
          }),
        });

        if (response) {
          const json = await response.json();
          let body = json.body.replace("affectedRows ", "");
          body = JSON.parse(body);

          // console.log('/deleteEvidence', body);

          if (json.success) {
            if (body.affectedRows === 0) {
              resolve(false);
            } else {
              resolve(true);
            }
          } else {
            reject(json.message || json.error);
          }
        } else {
          reject("The server didn't respond");
        }
      } catch (error: any) {
        console.log(error);
        RemoteDataController.logError(error);
      }
    });
  }

  /**
   * Gets
   * @param {IUser} user - The current user
   * @returns All fields for the current user's ContactID from the PortfolioUserData table
   */
  export async function getUserDetails(user: IUser): Promise<any> {
    return new Promise(async (resolve, reject) => {
      try {
        const url = PF_SERVER_URL + "/portfolio/DB/getUserDetails";

        const response: Response = await fetch(url, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            Token: user.token,
            ContactID: user.userData.contactID,
          }),
        });

        if (response) {
          const json = await response.json();
          const body = json.body;

          if (json.success) {
            if (body.length > 0) {
              resolve(body[0]);
            } else {
              resolve(null);
            }
          } else {
            reject(json.message || json.error);
          }
        } else {
          reject("The server didn't respond");
        }
      } catch (error: any) {
        console.log(error);
        RemoteDataController.logError(error);
      }
    });
  }

  /**
   * Creates row in PortfolioUserData table for a new user
   * @param {IUser} user - The current user
   * @param data - The data to be added
   * @returns {boolean} Whether the request was successful or not
   */
  export async function addUserDetails(user: IUser, data: any): Promise<boolean | any> {
    return new Promise(async (resolve, reject) => {
      try {
        const url = PF_SERVER_URL + "/portfolio/DB/addUserDetails";

        const response: Response = await fetch(url, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            Token: user.token,
            ContactID: user.userData.contactID,
            ...(data.certificates && {
              Certificates: JSON.stringify(data.certificates),
            }),
            ...(data.role && { Role: data.role }),
            ...(data.dataSharing && { DataSharing: data.dataSharing }),
            ...(data.HCPCNumber && { HCPCNumber: data.HCPCNumber }),
          }),
        });

        if (response) {
          const json = await response.json();
          let body = json.body.replace("affectedRows ", "");
          body = JSON.parse(body);

          if (json.success) {
            if (body.code === "ER_DUP_ENTRY") {
              resolve(false);
            } else {
              resolve(true);
            }
          } else {
            reject(json.message || json.error);
          }
        } else {
          reject("The server didn't respond");
        }
      } catch (error: any) {
        console.log(error);
        RemoteDataController.logError(error);
      }
    });
  }

  /**
   * Updates the current user's details in PortfolioUserData table
   * @param {IUser} user - The current user
   * @param data - The data to be updated
   * @returns {boolean} Whether the request was successful or not
   */
  export async function updateUserDetails(user: IUser, data: any): Promise<boolean | any> {
    return new Promise(async (resolve, reject) => {
      try {
        const url = PF_SERVER_URL + "/portfolio/DB/updateUserDetails";

        const response: Response = await fetch(url, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            Token: user.token,
            ContactID: user.userData.contactID,
            ...(data.certificates && {
              Certificates: JSON.stringify(data.certificates),
            }),
            ...(data.role && { Role: data.role }),
            ...(data.dataSharing !== undefined && {
              DataSharing: data.dataSharing,
            }),
            ...(data.summaryOfWork && { SummaryOfWork: data.summaryOfWork }),
            ...(data.summaryOfWorkTimestamp && {
              SummaryOfWorkTimestamp: data.summaryOfWorkTimestamp,
            }),
            ...(data.personalStatement && {
              PersonalStatement: data.personalStatement,
            }),
            ...(data.personalStatementTimestamp && {
              PersonalStatementTimestamp: data.personalStatementTimestamp,
            }),
            ...(data.HCPCNumber && { HCPCNumber: data.HCPCNumber }),
            ...(data.programCertificates && {
              ProgramCertificates: JSON.stringify(data.programCertificates),
            }),
            ...(data.programRoles && {
              ProgramRoles: JSON.stringify(data.programRoles),
            }),
          }),
        });

        if (response) {
          const json = await response.json();

          if (json.success) {
            resolve(true);
          } else {
            reject(json.message || json.error);
          }
        } else {
          reject("The server didn't respond");
        }
      } catch (error: any) {
        console.log(error);
        RemoteDataController.logError(error);
      }
    });
  }

  /**
   * Retrieves the user's JRCALC Plus usage data for the last 30 days
   * @param {IUser} user - The current user
   * @returns The user's JRCALC Plus usage data (30-days)
   */
  export async function getJRCALCPLusTimelineData(user: IUser): Promise<any> {
    return new Promise(async (resolve, reject) => {
      try {
        const url = PF_SERVER_URL + "/portfolio/TL/getPlusTimeLineData";

        const response: Response = await fetch(url, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            Token: user.token,
            ContactID: user.userData.contactID,
          }),
        });

        if (response) {
          const json = await response.json();
          const body = json.body;

          if (json.success) {
            resolve(body);
          } else {
            reject(json.message || json.error);
          }
        } else {
          reject("The server didn't respond");
        }
      } catch (error) {
        console.log(error);
      }
    });
  }

  /**
   * Retrieves all of the user's ParaPass usage data
   * @param {IUser} user - The current user
   * @returns The user's ParaPass usage data (All-time)
   */
  export async function getParapassUsageData(user: IUser): Promise<any> {
    return new Promise(async (resolve, reject) => {
      try {
        const url = PARAPASS_SERVER_URL + "/classapi/pppapi/getQuizInfoForUser";

        const response: Response = await fetch(url, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            token: user.token,
            contactID: `${user.userData.contactID}`,
          }),
        });

        if (response) {
          const json = await response.json();
          const body = json.body;

          // console.log('getParapassUsageData', body.items);

          if (json.auth) {
            resolve(body.items);
          } else {
            reject(json.message || json.error);
          }
        } else {
          reject("The server didn't respond");
        }
      } catch (error) {
        console.log(error);
      }
    });
  }
}
