import { useRecoilStateLoadable, useRecoilValueLoadable } from "recoil";
import { IEvidence, IUser, ProgramData } from "../../Interfaces";
import { evidenceAtom, userAtom } from "../../state/State";
import { useProgramData } from "../../hooks/data/useProgramData";
import SegmentedProgressBar from "../common/SegmentedProgressBar";
import { subYears } from "date-fns";
import * as EvidenceUtils from "../../utils/evidenceUtils";

function ChubOverview() {
  const [user, _] = useRecoilStateLoadable<IUser | null>(userAtom);

  const evidence = useRecoilValueLoadable<IEvidence[] | null>(evidenceAtom);

  const { userPrograms } = useProgramData();

  const program: ProgramData = userPrograms.find((item) => item.ID === "LNA4FTCKB004M")!;

  let programEvidence: IEvidence[] = [];
  let draftEvidenceCount = 0;

  let progressCheckSnapshots: { pcId: string; pcName: string; doneCount: number; maxCount: number }[] = [];

  if (user.state === "hasValue" && evidence.state === "hasValue" && evidence.contents?.length && program) {
    programEvidence = evidence.contents.filter((evidence: IEvidence) => {
      const evidenceJSON = evidence.evidenceJSON && JSON.parse(evidence.evidenceJSON);
      const programName = evidenceJSON?.programInfo?.program || "";
      const onHoldReset = evidenceJSON?.OnHoldReset ?? 0;
      const include =
        programName === program.Name && onHoldReset !== 1 && new Date(evidence.date) >= subYears(new Date(), 2);
      if (include) {
        if (evidence.draft) {
          draftEvidenceCount += 1;
          return false;
        } else {
          return true;
        }
      } else {
        return false;
      }
    });

    program.ProgressChecks?.forEach((pc) => {
      let compsDone = 0;
      pc["Competence/Activity"].forEach((comp) => {
        const evidenceStatus = EvidenceUtils.hasReachedEvidenceCount(programEvidence, comp);
        const learningOutcomesStatus = EvidenceUtils.hasLearningOutcomes(programEvidence, comp);
        if (
          comp["Number Evidences required"] &&
          evidenceStatus === "COMPLETE" &&
          (learningOutcomesStatus === "COMPLETE_ALL" ||
            learningOutcomesStatus === "COMPLETE_REQUIRED" ||
            !comp["Learning Objective"]?.length)
        ) {
          compsDone += 1;
        }
      });
      const groupedEvidenceForOngoingDevelopment = programEvidence
        .filter((e) => {
          const parsedEvidence = JSON.parse(e.evidenceJSON);
          const peerReviewLoFound = parsedEvidence?.LearningOutcomes?.find((lo: any) => lo.ID === "LX4VATNYDQGT7"); // TODO Peer Review LO id (set in airtable id instead??)
          return (
            !parsedEvidence.OnHoldReset &&
            !e.draft &&
            (parsedEvidence.programInfo?.progressCheckCompetenceID == "LWAGQ8UP7YU3L" || peerReviewLoFound)
          );
        })
        .reduce((acc: { [key: string]: IEvidence[] }, current) => {
          const month = new Date(current.date).toLocaleString("default", { month: "long" });
          if (!acc[month]) {
            acc[month] = [];
          }
          acc[month].push(current);
          return acc;
        }, {});
      const currentMonth = new Date().toLocaleString("default", { month: "long" });
      const ongoingDevelopmentForThisMonth = groupedEvidenceForOngoingDevelopment[currentMonth];

      progressCheckSnapshots.push({
        pcId: pc.ID,
        pcName: pc.Name,
        doneCount: pc.ID === "LUK25EYB6DL4A" ? ongoingDevelopmentForThisMonth?.length ?? 0 : compsDone,
        maxCount: pc["Competence/Activity"].filter((comp) => comp["Number Evidences required"]).length,
      });
    });
  }

  return (
    <>
      {progressCheckSnapshots.map((item, i) => {
        const title =
          item.pcId === "LUK25EYB6DL4A"
            ? "Ongoing evidence this month"
            : `Mandatory ${item.pcName} competencies completed`;
        const den = item.pcId === "LUK25EYB6DL4A" ? 2 : item.maxCount;
        return (
          <div className="mt-4" key={i}>
            <SegmentedProgressBar title={title} numerator={item.doneCount} denominator={den} />
          </div>
        );
      })}
      <div className="flex mt-4">
        <div className="flex-1 flex flex-col justify-between">
          <div className="text-grey-90 text-acc-13px font-normal mb-2">Evidence added</div>
          <div className="text-program-card-eprr-text text-acc-28px font-bold">
            {programEvidence ? programEvidence.length : 0}
          </div>
        </div>
        <div className="flex-1 flex flex-col justify-between items-start">
          <div className="mb-2 rounded-xl bg-draft-badge text-draft-badge-text px-2 py-1 text-acc-12px font-semibold">
            Draft
          </div>
          <div className="text-program-card-eprr-text text-acc-28px font-bold">{draftEvidenceCount}</div>
        </div>
      </div>
    </>
  );
}

export default ChubOverview;
