import { IWellbeingTextDisplay } from "../../../types/Components";
import sanitizeHTML from "./helpers/sanitizeHTML";

const WellbeingTextDisplay: React.FC<IWellbeingTextDisplay> = (props) => {
  const value = props.wellbeingPart["Linked Key"]
    ? props.evidenceJSON[props.wellbeingPart["Linked Key"]]
    : "Not entered.";

  return (
    <div className="rounded-[5px] p-4 bg-white mx-4 my-1.5">
      <div className="text-acc-14px font-acc-600 text-grey-80 text-left">{props.wellbeingPart["Display Title"]}</div>
      <div
        className="[&>p]:!text-acc-13px [&>p]:!font-acc-normal [&>p]:!text-grey-80 [&>p]:!text-left mt-5"
        dangerouslySetInnerHTML={sanitizeHTML(value ?? "<p>Not entered.</p>")}
      />
    </div>
  );
};

export default WellbeingTextDisplay;
