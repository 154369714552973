import { OAuth2AuthenticateOptions } from "@byteowls/capacitor-oauth2";

export const environment = {
  production: false,
  firebaseConfig: {
    apiKey: "AIzaSyD2lKFZr-wxvYaqHuOSIPkYZkEd8QWYHZU",
    authDomain: "parafolio-2c523.firebaseapp.com",
    projectId: "parafolio-2c523",
    storageBucket: "parafolio-2c523.appspot.com",
    messagingSenderId: "1076933796429",
    appId: "1:1076933796429:web:6e545e4bec0909281bb204",
    measurementId: "G-P2DMC3M4T2",
  },
};

export const NWAS_SSO_CONFIG: OAuth2AuthenticateOptions = {
  appId: "41a72370-8cfa-462e-b0af-5ba256923634",
  authorizationBaseUrl: `https://login.microsoftonline.com/f65c6dd6-6719-46ed-b3dd-3f6d27f951dd/oauth2/v2.0/authorize`,
  scope: "api://41a72370-8cfa-462e-b0af-5ba256923634/Read.JRCALC.User",
  accessTokenEndpoint: `https://login.microsoftonline.com/f65c6dd6-6719-46ed-b3dd-3f6d27f951dd/oauth2/v2.0/token`,
  responseType: "code",
  pkceEnabled: true,
  logsEnabled: true,
  additionalParameters: {
    prompt: "select_account",
  },
  web: {
    // Remember to switch redirectUrl when deploying to preview/ live site
    // redirectUrl: "http://localhost:3000", // Development
    redirectUrl: "https://paraportfolio.co.uk/onboarding/login", // Live site
    // redirectUrl: "https://nwas-eprr-preview.netlify.app/onboarding/login", // Preview site
  },
  android: {
    redirectUrl: "msauth://uk.co.classprofessional.parafolio/ee6NCLxOPAEQ%2BF4gp%2FlUjnvxQdA%3D", // See Azure Portal -> Authentication -> Android Configuration "Redirect URI"
  },
  ios: {
    pkceEnabled: true,
    redirectUrl: "msauth.uk.co.classprofessional.parafolio://auth",
  },
};
