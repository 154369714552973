import { IWellbeingRadarGraphData } from "../types/Components";
import _ from "lodash";

/**
 * Maps the Wellbeing Activity graph data into the correct format for the graph to match the designs, pads the array to match the number of labels in the graph
 * @param data - The activity data array
 * @param direction - Whether to pad the array at the start or end
 * @returns An array with the data padded with [0, null, null, null, 0] at the start or the end
 */
export function padRadarGraphData(
  data: IWellbeingRadarGraphData[] | undefined,
  allDataSets: IWellbeingRadarGraphData[][],
  direction: "start" | "end"
): (number | null)[] | undefined {
  if (data && data.length > 0) {
    let result: (number | null)[] = [];

    const flattenedData = _.flatten(allDataSets);
    const startIndex = flattenedData.findIndex((item) => item.label === data[0].label);

    const nullNumber = startIndex > 2 ? startIndex - 2 : flattenedData.length - data.length - 1;

    const nullFill = nullNumber > 0 ? Array.apply(null, Array(nullNumber)).map(() => null) : [];

    let testPaddingArray = [0, ...nullFill, 0];

    const values = data.map((_item) => _item.value);

    if (direction === "start") {
      return result.concat(values, testPaddingArray);
    } else if (direction === "end") {
      return result.concat(testPaddingArray, values);
    }
  }

  return undefined;
}
