import React, { useEffect, useRef, useState } from "react";
import { IonFooter, IonImg, IonSpinner, IonTextarea } from "@ionic/react";
import { ICommentInput } from "../../types/Components";
import { Button_Send } from "../../assets/images";
import { StringUtils } from "parafolio-components";
import { Capacitor } from "@capacitor/core";
import { Keyboard, KeyboardInfo } from "@capacitor/keyboard";

const CommentInput: React.FC<ICommentInput> = (props) => {
  const inputRef = useRef<HTMLIonTextareaElement>(null);

  const [comment, setComment] = useState<string>("");
  const [sendingComment, setSendingComment] = useState<boolean>(false);

  const [keyboardActive, setKeyboardActive] = useState<boolean>(false);
  const [keyboardHeight, setKeyboardHeight] = useState<number>(0);

  useEffect(() => {
    if (Capacitor.getPlatform() === "ios") {
      Keyboard.addListener("keyboardWillShow", (info: KeyboardInfo) => {
        setKeyboardActive(true);
        setKeyboardHeight(info.keyboardHeight);
      });

      Keyboard.addListener("keyboardWillHide", () => {
        setKeyboardActive(false);
      });
    }
  }, []);

  const inputChanged = (event: any): void => {
    const value = event.target.value;

    setComment(`${value}`);
  };

  const sendComment = async (): Promise<void> => {
    setSendingComment(true);

    try {
      await props.sendComment(comment);

      setSendingComment(false);
      setComment("");
      inputRef.current?.focus();
    } catch (error) {
      console.log(error);
      setSendingComment(false);
    }
  };

  const handleKeyboardEvent = async (event: React.KeyboardEvent<HTMLIonTextareaElement>): Promise<void> => {
    if (event.key === "Enter" && (event.metaKey || event.ctrlKey)) {
      if (!sendDisabled()) {
        await sendComment();
      }
    }
  };

  const sendDisabled = (): boolean => {
    return sendingComment || StringUtils.checkStringIsEmptyAfterTrimming(comment);
  };

  return (
    <IonFooter
      className={`flex justify-center flex-col bg-grey-00 shadow-comment-input ${keyboardActive ? "pb-0" : "pb-safe-area-footer"}`}
    >
      {props.commentsDisabled && (
        <div className="flex justify-center">
          <div className="mt-[12px] mb-[4px] rounded-[6px] bg-orange-20 p-[8px] max-w-app-column ml-safe-area-l mr-safe-area-r">
            <div className="text-acc-12px font-medium leading-[1.33] tracking-[-0.1px] text-grey-90">
              {"You can no longer send new comments because your progress check is approved."}
            </div>
          </div>
        </div>
      )}
      <div className="flex flex-row justify-center">
        <div className="flex flex-row items-center min-h-[50px] py-[7px] w-full max-w-app-column pl-comment-input-l pr-comment-input-r">
          <div className="bg-white w-full rounded-[18px] border-solid border-[0.5px] border-grey-60 mr-[12px] overflow-hidden">
            <IonTextarea
              ref={inputRef}
              disabled={props.commentsDisabled}
              autoGrow
              className="!min-h-[36px] !py-0 !max-h-[144px] px-[12px] overflow-y-auto text-grey-90"
              placeholder="Add a comment..."
              id="preceptor-comment-input"
              spellcheck
              mode="ios"
              value={comment}
              onIonInput={inputChanged}
              onKeyDown={handleKeyboardEvent}
              rows={1}
            />
          </div>
          <button
            className={`flex shrink-0 w-[36px] h-[36px] bg-cta-blue rounded-[50px] justify-center items-center overflow-hidden ${
              sendDisabled() ? "opacity-40" : "opacity-100"
            }`}
            onClick={sendComment}
            disabled={sendDisabled()}
          >
            {sendingComment ? (
              <IonSpinner class="w-[20px] h-[20px] text-white" />
            ) : (
              <IonImg className="!w-[44px] !h-[44px] max-h-full max-w-full" src={Button_Send} />
            )}
          </button>
        </div>
      </div>
    </IonFooter>
  );
};

export default CommentInput;
