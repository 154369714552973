import "../../App.css";
import { IEvidenceContainer } from "../../types/Components";
import "./EvidenceComponents.css";

const EvidenceContainer: React.FC<IEvidenceContainer> = (props) => {
  return (
    <div id={props.id} className="reflectionsContainer" style={props.style}>
      {props.children}
    </div>
  );
};

export default EvidenceContainer;
