import React from "react";
import "../../theme/tailwind.css";
import { IEvidenceDisclaimer } from "../../types/Components";

const EvidenceDisclaimer: React.FC<IEvidenceDisclaimer> = (props) => {
  return (
    <div className="mb-[4px] rounded-[6px] bg-orange-20 p-[8px] max-w-app-column">
      <div className="text-acc-12px font-medium leading-[1.33] tracking-[-0.1px] text-grey-90">{props.message} </div>
    </div>
  );
};

export default EvidenceDisclaimer;
