import React, { useEffect, useState } from "react";
import { IonProgressBar } from "@ionic/react";
import { IAttachmentProgressIndicator } from "../../types/Components";

const AttachmentProgressIndicator: React.FC<IAttachmentProgressIndicator> = (props) => {
  const [visible, setVisible] = useState<boolean>(false);

  useEffect(() => {
    setVisible(props.visible);
  }, [props.visible]);

  if (!visible) {
    return null;
  }

  return (
    <div className="flex flex-col w-full max-w-[358px] mb-[18px] self-center">
      <div className="text-acc-17px font-acc-normal leading-default tracking-default text-grey-90 mb-[8px] text-center">
        {"Uploading attachments"}
      </div>
      <IonProgressBar type="indeterminate" className="rounded-[10px]" />
    </div>
  );
};

export default AttachmentProgressIndicator;
