import { App } from "@capacitor/app";
import { useEffect, useState } from "react";

export const useAppFocus = (): boolean => {
  const [appState, setAppState] = useState<boolean>(true);

  useEffect(() => {
    App.addListener("appStateChange", (state) => {
      setAppState(state.isActive);
    });
  }, []);

  return appState;
};
