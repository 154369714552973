import { IonContent, useIonRouter } from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import { useRef } from "react";
import { useLocation } from "react-router";

const ErrorBoundaryFallback: React.FC<{}> = (props) => {
  const router = useIonRouter();
  //   const location = useLocation();
  //   const routerRef = useRef<null | IonReactRouter>(null);

  return (
    // <IonReactRouter ref={routerRef}>
    <IonContent className="ion-text-center ion-padding">
      <>
        <h1>{"Oops, an error has occurred!"}</h1>
        {/* <button onClick={() => routerRef.current?.history.goBack()}>Go back</button> */}
        {/* <button onClick={() => router.push("/")}>Go home</button> */}
        {/* <button onClick={() => window.location.reload()}>Reload page</button> */}
      </>
    </IonContent>
    // </IonReactRouter>
  );
};

export default ErrorBoundaryFallback;
