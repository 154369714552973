import { useEffect, useState } from "react";
import _ from "lodash";
import { EvidencePart } from "../../../Interfaces";
import { IonItem } from "@ionic/react";
import { Button_Checkbox_Active, Button_Checkbox_Inactive } from "../../../assets/images";
import "./EvidenceParts.css";
import { Text, View } from "@react-pdf/renderer";

type Props = {
  children?: React.ReactNode;
  style?: React.CSSProperties;
  definition: EvidencePart;
  onValueChanged?: (value: any) => void;
  data?: any;
  isDisplay?: boolean;
  isPDF?: boolean;
  pdfStyles: any;
  initialValue?: any;
};

const TickBoxList: React.FC<Props> = ({
  children,
  style,
  definition,
  onValueChanged,
  data,
  isDisplay,
  isPDF,
  pdfStyles,
  initialValue,
}) => {
  const [selectedValues, setSelectedValues] = useState<string[]>(initialValue || []);
  const [listValues, setListValues] = useState<string[]>([]);

  useEffect(() => {
    const getListValues = (): void => {
      const values = definition?.["List of values"];

      if (values && values.length > 0) {
        let strings = values.split("\n");
        strings = strings.filter((item) => item.length > 0);

        setListValues(strings);
      }
    };

    definition && getListValues();
  }, [definition]);

  const toggleValue = (value: string): void => {
    let array = _.cloneDeep(selectedValues);

    if (!Array.isArray(array)) {
      array = [array];
    }

    if (array.includes(value)) {
      array = array.filter((item) => item !== value);
    } else {
      array.push(value);
    }

    setSelectedValues(array);
    onValueChanged && onValueChanged(array);
  };

  if (isPDF) {
    return (
      <View>
        <Text style={pdfStyles.reflectionHeader}>{(definition["Display Title"] || definition.Name) + ":"}</Text>
        <Text style={pdfStyles.evidenceDate}>{Array.isArray(data) ? data?.sort().join(", ") : data}</Text>
      </View>
    );
  }

  if (isDisplay) {
    return (
      <div className="readEvidenceBlock">
        <div className="readEvidenceHeader">{definition["Display Title"] || definition.Name}</div>
        <div className="readEvidenceText">{Array.isArray(data) ? data?.sort().join(", ") : data}</div>
        <div className="h-[1px] w-full bg-grey-30 mt-4" />
      </div>
    );
  }

  return (
    <div>
      {listValues.map((item) => {
        const selected = selectedValues.includes(item);

        return (
          <IonItem
            button
            detail={false}
            key={item}
            className="evidencePartsCheckboxContainer"
            onClick={() => toggleValue(item)}
          >
            <div className="evidencePartsCheckboxContainerInner">
              <div className="evidencePartsCheckboxLabel">{item}</div>
              <img
                alt=""
                className="evidencePartsRadioCheckbox"
                src={selected ? Button_Checkbox_Active : Button_Checkbox_Inactive}
              />
            </div>
          </IonItem>
        );
      })}
    </div>
  );
};

export default TickBoxList;
