import { AppInfo, App as CapacitorApp } from "@capacitor/app";
import { Capacitor } from "@capacitor/core";
import { DeviceInfo } from "@capacitor/device";
import {
  IonButtons,
  IonContent,
  IonHeader,
  IonImg,
  IonMenuButton,
  IonPage,
  IonSpinner,
  IonToolbar,
  RefresherEventDetail,
  useIonRouter,
  useIonViewDidLeave,
  useIonViewWillEnter,
} from "@ionic/react";
import { Suspense, useCallback, useEffect } from "react";
import { withRouter } from "react-router";
import { useRecoilValue, useRecoilValueLoadable } from "recoil";
import { Button_Menu } from "../../assets/images";
import ContentContainer from "../../components/common/ContentContainer";
import CPDOverview from "../../components/home/CPDOverview";
import DashboardCard from "../../components/home/DashboardCard";
import EvidenceOverview from "../../components/home/EvidenceOverview";
import InformationCard from "../../components/home/InformationCard";
import NOSOverviewCard from "../../components/home/NOSOverviewCard";
import DataController from "../../controllers/DataController";
import { FirebaseService } from "../../controllers/FirebaseService";
import { IAppFeatures, IUser, ProgramData } from "../../Interfaces";
import { appFeaturesAtom, appInfoAtom, deviceInfoAtom, userAtom } from "../../state/State";

import { HeaderTitle } from "parafolio-components";
import ProgramCard from "../../components/common/ProgramCard";
import { PF_FAQ_URL } from "../../Constants";
import { useDebugUser } from "../../hooks/auth/useDebugUser";
import { useProgramData } from "../../hooks/data/useProgramData";
import "./Dashboard.css";
import "./News.css";

const Dashboard: React.FC = () => {
  const { canGoBack } = useIonRouter();

  const { isDebugUser } = useDebugUser();
  const { userPrograms } = useProgramData();

  const user = useRecoilValueLoadable<IUser | null>(userAtom);
  const appFeatures = useRecoilValue<IAppFeatures[] | string[]>(appFeaturesAtom);
  const deviceInfo = useRecoilValue<DeviceInfo | null>(deviceInfoAtom);
  const appInfo = useRecoilValue<AppInfo | null>(appInfoAtom);

  const sendSupportEmail = useCallback((): void => {
    if (user.state === "hasValue" && user.contents) {
      const url = "mailto:apps@class.co.uk";
      const subject = "ParaFolio - General support";

      const body = DataController.supportEmailBody(user.contents.userData, deviceInfo, appInfo);

      window.open(`${url}?subject=${subject}&body=${body}`, "_blank");
    }
  }, [appInfo, deviceInfo, user]);

  useEffect(() => {
    const setPageName = async (): Promise<void> => {
      await FirebaseService.setScreenName("dashboard");
    };

    setPageName();
  }, []);

  const handleAppExit = (event: any): void => {
    if (Capacitor.getPlatform() === "android") {
      event.detail!.register(-1, () => {
        if (!canGoBack()) {
          CapacitorApp.exitApp();
        }
      });
    }
  };

  const handleClick = async (event: Event): Promise<void> => {
    const element = event.target as HTMLAnchorElement;

    if (element.href?.includes("mailto:apps@class.co.uk")) {
      event.preventDefault();
      await FirebaseService.logEvent("support_link_pressed", {
        link: "contact_support",
        type: "app_info_feedback",
      });

      sendSupportEmail();
    }
  };

  const handleAuxClick = async (event: MouseEvent): Promise<void> => {
    const element = event.target as HTMLAnchorElement;

    if (event.button === 1) {
      if (element.href?.includes("mailto:apps@class.co.uk")) {
        event.preventDefault();
        await FirebaseService.logEvent("support_link_pressed", {
          link: "contact_support",
          type: "app_info_feedback",
        });

        sendSupportEmail();
      }
    }
  };

  useIonViewWillEnter(() => {
    document.addEventListener("ionBackButton", handleAppExit);
    document.addEventListener("click", handleClick);
    document.addEventListener("auxclick", handleAuxClick);
  });

  useIonViewDidLeave(() => {
    document.removeEventListener("ionBackButton", handleAppExit);
    document.removeEventListener("click", handleClick);
    document.removeEventListener("auxclick", handleAuxClick);
  });

  const handleRefresh = (event: CustomEvent<RefresherEventDetail>): void => {
    setTimeout(() => {
      event.detail.complete();
    }, 2500);
  };

  const openArticle = async (): Promise<void> => {
    await FirebaseService.logEvent("external_article_pressed", {
      link: PF_FAQ_URL,
    });

    window.open(PF_FAQ_URL, "_blank");
  };

  return (
    <IonPage>
      <Suspense
        fallback={
          <div className="loadingScreen">
            <IonSpinner className="loadingScreenSpinner" />
            <div className="loadingScreenText">{"Loading"}</div>
          </div>
        }
      >
        <IonHeader>
          <IonToolbar
            mode="ios"
            className="navBar"
            style={{
              maxWidth: DataController.isWeb() ? 980 : undefined,
              height: DataController.getHeaderHeight(),
            }}
          >
            <IonButtons slot="start">
              <IonMenuButton>
                <IonImg src={Button_Menu} className="menuIcon" />
              </IonMenuButton>
            </IonButtons>
            <HeaderTitle>{`ParaFolio${isDebugUser ? " (DEBUG)" : ""}`}</HeaderTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent className="page-background">
          {/* <IonRefresher slot="fixed" onIonRefresh={handleRefresh}>
            <IonRefresherContent></IonRefresherContent>
          </IonRefresher> */}
          {/* <pre>{JSON.stringify(userPrograms.length, null, 2)}</pre> */}
          <ContentContainer id="dashboardContentContainer">
            {userPrograms.map((program: ProgramData, index) => {
              return (
                <div key={program.id}>
                  {user.state === "hasValue" && <ProgramCard userObject={user.contents} programID={program.ID} />}
                </div>
              );
            })}
            {appFeatures.includes(IAppFeatures.SWAST_MIC) && (
              <>{user.state === "hasValue" && <NOSOverviewCard userObject={user.contents} />}</>
            )}
            <EvidenceOverview />
            <CPDOverview />
            <InformationCard />
            <DashboardCard title="About">
              <p>{"ParaFolio is a CPD management tool for organising your portfolio for audit."} </p>
              <div className="homeCardButtonContainer">
                <button className="homeCardButton" onClick={() => openArticle()}>
                  {"Find about more about ParaFolio"}
                </button>
              </div>
            </DashboardCard>
            <DashboardCard title="Support">
              <p>
                If you need help{" "}
                <a href="mailto:apps@class.co.uk?subject=ParaFolio - General support" target="_blank" rel="noreferrer">
                  {"please contact support"}
                </a>
                .
              </p>
            </DashboardCard>
          </ContentContainer>
        </IonContent>
      </Suspense>
    </IonPage>
  );
};

export default withRouter(Dashboard);
