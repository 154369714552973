import React from "react";
import { IEvidenceFilterHeader } from "../../types/Components";

const EvidenceFilterHeader: React.FC<IEvidenceFilterHeader> = (props) => {
  return (
    <div className="flex bg-grey-20">
      <div className="text-acc-13px font-bold leading-default tracking-default text-grey-70 my-[4px] ml-[16px]">
        {props.title.toUpperCase()}
      </div>
    </div>
  );
};

export default EvidenceFilterHeader;
