import React, { useEffect, useState } from "react";
import { IonContent, IonModal } from "@ionic/react";
import { IExistingTagModal } from "../../types/Components";

const ExistingTagModal: React.FC<IExistingTagModal> = (props) => {
  const [visible, setVisible] = useState<boolean>(false);

  useEffect(() => {
    setVisible(props.visible);
  }, [props.visible]);

  const dismissModal = (): void => {
    props.dismissPressed();
  };

  return (
    <IonModal
      id="empty-tag-modal"
      isOpen={visible}
      backdropDismiss={true}
      canDismiss={true}
      onDidDismiss={dismissModal}
      className="fullscreen"
    >
      <IonContent>
        <div className="flex flex-col justify-between h-full">
          <div className="mt-[16px] px-[16px] flex flex-col justify-center">
            <div className="text-[20px] font-bold leading-default tracking-[-0.1px] text-center text-grey-90">
              {"Error"}
            </div>
            <div className="text-[15px] leading-[1.33] tracking-[-0.3px] text-center text-grey-90 mt-[8px]">
              {"You've already added this custom tag"}
            </div>
          </div>
          <div className="flex flex-col">
            <div className="border-t border-t-grey-30">
              <button className="h-[48px] w-full" onClick={props.dismissPressed}>
                <div className="text-[16px] font-semibold leading-default tracking-default text-center text-cta-blue ">
                  {"Dismiss"}
                </div>
              </button>
            </div>
          </div>
        </div>
      </IonContent>
    </IonModal>
  );
};

export default ExistingTagModal;
