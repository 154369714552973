import { Subscriptions } from "capacitor-subscriptions";
import RemoteDataController from "./RemoteDataController";
import { Capacitor } from "@capacitor/core";
import { IAppEntitlementsResponse, ISubscriptionPurchase, IUser } from "../Interfaces";
import DataController from "./DataController";
import { AUTH_SERVER_URL } from "../Constants";

export class IAPService {
  async getSubscription(productIdentifier: string): Promise<any> {
    return new Promise(async (resolve, reject) => {
      try {
        let response = await Subscriptions.getProductDetails({
          productIdentifier,
        });

        // console.log(`IAP Product (${productIdentifier})`, response.data);

        resolve(response.data);
      } catch (error: any) {
        console.log(error);
        RemoteDataController.logError(error);
        reject(error);
      }
    });
  }

  async restoreSubscription(): Promise<IAppEntitlementsResponse> {
    return new Promise(async (resolve, reject) => {
      try {
        let response: IAppEntitlementsResponse = { success: true, subs: [] };

        if (Capacitor.getPlatform() === "android") {
          response = await this.getEntitlementsFromGoogle();
        } else if (Capacitor.getPlatform() === "ios") {
          response = await this.getEntitlementsFromApple();
        }

        resolve(response);
      } catch (error: any) {
        console.log(error);
        RemoteDataController.logError(error);
        reject(error);
      }
    });
  }

  async checkSubscriptionRenewal(user: IUser): Promise<IUser> {
    return new Promise(async (resolve, reject) => {
      try {
      } catch (error: any) {
        console.log(error);
        RemoteDataController.logError(error);
        reject(error);
      }
    });
  }

  async getEntitlementsFromApple(): Promise<IAppEntitlementsResponse> {
    return new Promise(async (resolve, reject) => {
      try {
        let subs: ISubscriptionPurchase[] = [];
        let success = true;

        // Calling getCurrentEntitlements() will return an array of subscription transactions which are still ACTIVE.
        const response = await Subscriptions.getCurrentEntitlements();
        // console.log('entitlementsResponse (Apple): ', response.data);

        /**
         * response codes and matching messages:
         * -1: Incompatible with web
         * 0: Successfully found all entitlements across all product types
         * 1: No entitlements were found
         * 2: Unknown problem trying to retrieve entitlements
         */
        if (response.responseCode === 2) {
          success = false;
        } else if (response.responseCode === 0) {
          const data = response.data as any;

          if (data) {
            const keys = Object.keys(data);

            keys.forEach((key) => {
              subs.push({
                endDate: data[key].expiryDate,
                productId: data[key].productIdentifier,
                transactionId: data[key].transactionId,
                startDate: data[key].originalStartDate,
              });
            });
          }
        }

        resolve({ success, subs });
      } catch (error: any) {
        console.log(error);
        RemoteDataController.logError(error);
        reject({ success: false, subs: [] });
      }
    });
  }

  async getEntitlementsFromGoogle(): Promise<IAppEntitlementsResponse> {
    return new Promise(async (resolve, reject) => {
      try {
        let subs: ISubscriptionPurchase[] = [];
        let success = true;

        // Calling getCurrentEntitlements() will return an array of subscription transactions which are still ACTIVE.
        const response = await Subscriptions.getCurrentEntitlements();
        // console.log('entitlementsResponse (Google): ', response);
        // console.log('entitlementsResponseData (Google): ', response.data);

        /**
         * response codes and matching messages:
         * -1: Incompatible with web
         * 0: Successfully found all entitlements across all product types
         * 1: No entitlements were found
         * 2: Unknown problem trying to retrieve entitlements
         */
        if (response.responseCode === 2) {
          success = false;
        } else if (response.responseCode === 0) {
          const data = response.data;

          if (data) {
            const array: [] = JSON.parse(data.toString());

            array.forEach((s) => {
              subs.push(DataController.mapGoogleSub(s));
            });
          }
        }

        resolve({ success, subs });
      } catch (error: any) {
        console.log(error);
        RemoteDataController.logError(error);
        reject({ success: false, subs: [] });
      }
    });
  }

  async sendPurchaseInfo(
    contactID: string,
    transactionDate: string,
    transactionId: string,
    productIdentifier: string,
    deviceType: string,
    token: string,
    endDate: string
  ): Promise<boolean> {
    return new Promise(async (resolve, reject) => {
      try {
        const url: string = AUTH_SERVER_URL + "/pppapi/sendPurchaseInfo";

        let response: Response = await fetch(url, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            subscriptionID: 1517,
            contactID: contactID,
            transactionDate: transactionDate,
            transactionIdentifier: transactionId,
            productIdentifier: productIdentifier,
            deviceType: deviceType,
            token: token,
            expiryDate: endDate,
          }),
        });

        if (response) {
          const json = await response.json();

          // console.log('sendPurchaseInfo', json);

          if (json.success) {
            resolve(true);
          } else {
            reject(json.errorMsg);
          }
        } else {
          reject("The server didn't respond");
        }
      } catch (error: any) {
        console.log(error);
        RemoteDataController.logError(error);
        reject(error);
      }
    });
  }
}

export default new IAPService();
