import { format } from "date-fns";
import "../../App.css";
import "./EvidenceComponents.css";
import { IEvidenceDateHeader } from "../../types/Components";

const EvidenceDateHeader: React.FC<IEvidenceDateHeader> = (props) => {
  return <div className="evidenceDateHeader">{format(props.date, "MMMM yyyy").toUpperCase()}</div>;
};

export default EvidenceDateHeader;
