import React, { useEffect, useState } from "react";
import "../../theme/tailwind.css";
import { IProgramCertificatesDisplay } from "../../types/Components";
import { format, isAfter } from "date-fns";
import { IonSpinner } from "@ionic/react";
import { StringUtils } from "../../utils/stringUtils";

const ProgramCertificatesDisplay: React.FC<IProgramCertificatesDisplay> = (props) => {
  const [disabled, setDisabled] = useState<boolean>(false);
  const [viewing, setViewing] = useState<boolean>(false);
  const [expired, setExpired] = useState<boolean>(false);

  useEffect(() => {
    setDisabled(props.viewingCertificate && props.viewingFilename !== props.certificate.certificate?.name);
    setViewing(props.viewingCertificate && props.viewingFilename === props.certificate.certificate?.name);
  }, [props.certificate, props.viewingCertificate, props.viewingFilename]);

  useEffect(() => {
    if (props.certificate.expiryDate) {
      setExpired(isAfter(new Date(), new Date(props.certificate.expiryDate)));
    }
  }, [props.certificate.expiryDate]);

  return (
    <div>
      <div className="mt-[8px] mb-[12px]">
        <div className="text-[13px] leading-[1.54] tracking-[-0.1px] text-grey-70">
          {`${props.certificate.programData.certificateType} certificate`}
        </div>
        <div className="flex flex-row justify-between items-center">
          <button
            disabled={disabled}
            key={props.certificate.certificate?.name}
            onClick={() => !(disabled || viewing) && props.buttonPressed()}
            className={`flex min-w-0 flex-[-1] flex-row items-center !min-h-[26px] bg-transparent self-start mr-[8px] pointer-events-auto program-certificate-button
            ${viewing && "program-certificate-button-wait"} ${disabled && "program-certificate-button-disabled"}`}
          >
            <div className="text-[16px] leading-default tracking-default text-cta-blue whitespace-nowrap overflow-hidden text-ellipsis">
              {props.certificate.certificate?.name}
            </div>
            {viewing && <IonSpinner className="w-[15px] h-[15px] ml-[8px] text-cta-blue" />}
          </button>
          {props.certificate.expiryDate && (
            <div
              className={StringUtils.getCertificatePillContainerClassFromExpiryDate(
                new Date(props.certificate.expiryDate)
              )}
            >
              {`${expired ? "Expired" : "Expires"} ${format(new Date(props.certificate.expiryDate), "d MMM yy")}`}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ProgramCertificatesDisplay;
