import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonModal,
  IonPage,
  IonToolbar,
  useIonAlert,
  useIonLoading,
} from "@ionic/react";
import { useCallback, useEffect, useState } from "react";
import { EventRegister } from "react-native-event-listeners";
import { useHistory, withRouter } from "react-router";
import {
  useRecoilStateLoadable,
  useRecoilValue,
  useRecoilValueLoadable,
  useResetRecoilState,
  useSetRecoilState,
} from "recoil";
import { FirebaseService } from "../../controllers/FirebaseService";
import DataController from "../../controllers/DataController";
import {
  accessibilityAtom,
  appInfoAtom,
  debugUserAtom,
  deviceInfoAtom,
  draftsHintDismissedAtom,
  evidenceAtom,
  evidenceDraftsAtom,
  guidelinesCardDismissedAtom,
  parapassDataAtom,
  plusDataAtom,
  programDataAtom,
  programDataVersionInfoAtom,
  programInfoDismissedAtom,
  progressCheckDataAtom,
  userAtom,
  userProgramsAtom,
  welcomeDismissedAtom,
} from "../../state/State";
import {
  AllProgressCheckStatuses,
  IAccessibilitySettings,
  IClassSubscription,
  IEvidence,
  IEvidenceDraft,
  IParapassUsageData,
  IPlusUsageData,
  ISubscriptionModalType,
  ISubscriptionPurchase,
  IUser,
} from "../../Interfaces";
import { DeviceInfo } from "@capacitor/device";
import { AppInfo } from "@capacitor/app";
import AccountCard from "../../components/profile/AccountCard";
import EvidenceContainer from "../../components/evidence/EvidenceContainer";
import AuthenticationController from "../../controllers/AuthenticationController";
import { Subscriptions } from "capacitor-subscriptions";
import { format } from "date-fns";
import { Capacitor } from "@capacitor/core";
import IAPService from "../../controllers/IAPService";
import _ from "lodash";
import HeaderTitle from "../../components/common/HeaderTitle";
import { CLASS_TERMS_URL, GOOGLE_PLAY_MANAGE_SUBSCRIPTIONS_URL } from "../../Constants";
import { loadableHasValue } from "../../utils/recoilUtils";

const Account: React.FC = (props) => {
  const history = useHistory();

  const [presentOverlay, dismissOverlay] = useIonLoading();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [presentAlert, dismissAlert] = useIonAlert();

  const [user, setUser] = useRecoilStateLoadable<IUser | null>(userAtom);
  const setEvidence = useSetRecoilState<IEvidence[] | null>(evidenceAtom);
  const setGuidelinesCardDismissed = useSetRecoilState<boolean>(guidelinesCardDismissedAtom);
  const setWelcomeDismissed = useSetRecoilState<boolean>(welcomeDismissedAtom);
  const setPlusData = useSetRecoilState<IPlusUsageData[] | null>(plusDataAtom);
  const setParapassData = useSetRecoilState<IParapassUsageData[] | null>(parapassDataAtom);
  const setAccessibilitySettings = useSetRecoilState<IAccessibilitySettings | null>(accessibilityAtom);
  const setInfoDismissed = useSetRecoilState<any[]>(programInfoDismissedAtom);
  const setDraftsHintDismissed = useSetRecoilState<boolean>(draftsHintDismissedAtom);
  const setDrafts = useSetRecoilState<IEvidenceDraft[] | null>(evidenceDraftsAtom);
  const setAllProgressCheckData = useSetRecoilState<AllProgressCheckStatuses[]>(progressCheckDataAtom);

  const resetUser = useResetRecoilState(userAtom);
  const resetAllProgressCheckData = useResetRecoilState(progressCheckDataAtom);
  const resetUserPrograms = useResetRecoilState(userProgramsAtom);

  const debugUserLoadable = useRecoilValueLoadable(debugUserAtom);
  const setDebugUser = useSetRecoilState(debugUserAtom);
  const programDataLoadable = useRecoilValueLoadable(programDataAtom);
  const setProgramData = useSetRecoilState(programDataAtom);
  const programVersionInfoLoadable = useRecoilValueLoadable(programDataVersionInfoAtom);
  const setProgramVersionInfo = useSetRecoilState(programDataVersionInfoAtom);

  const deviceInfo = useRecoilValue<DeviceInfo | null>(deviceInfoAtom);
  const appInfo = useRecoilValue<AppInfo | null>(appInfoAtom);

  const [currentSubscription, setCurrentSubscription] = useState<IClassSubscription | null>(null);
  const [shouldShowSubscriptionButtons, setShowShowSubscriptionButtons] = useState<boolean>(false);
  const [isFreeTrial, setIsFreeTrial] = useState<boolean>(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [showRestore, setShowRestore] = useState<boolean>(Capacitor.getPlatform() !== "web");

  const [accountDeletionModalVisible, setAccountDeletionModalVisible] = useState<boolean>(false);

  useEffect(() => {
    const getClassSubscription = (): void => {
      const allSubscriptions = user.contents.userData.subscriptions;

      if (allSubscriptions && allSubscriptions.length > 0) {
        const latestSubscription = DataController.getLatestPortfolioSubscription(allSubscriptions);

        if (latestSubscription) {
          // console.log('Account.tsx', latestSubscription.SubscriptionID, latestSubscription.FreeTrial);
          setCurrentSubscription(latestSubscription);
          setIsFreeTrial(latestSubscription.FreeTrial === true);
          if (Capacitor.getPlatform() === "web") {
            setShowShowSubscriptionButtons(
              latestSubscription.SubscriptionID === 1517 && latestSubscription.FreeTrial === true
            );
          } else {
            setShowShowSubscriptionButtons(latestSubscription.SubscriptionID === 1517);
          }
        }
      }
    };

    if (user.state === "hasValue" && user.contents) {
      getClassSubscription();
    }
  }, [user]);

  const sendSupportEmail = useCallback((): void => {
    if (user.state === "hasValue" && user.contents) {
      const url = "mailto:apps@class.co.uk";
      const subject = "ParaFolio - Subscription support";

      const body = DataController.supportEmailBody(user.contents.userData, deviceInfo, appInfo);

      window.open(`${url}?subject=${subject}&body=${body}`, "_blank");
    }
  }, [appInfo, deviceInfo, user]);

  useEffect(() => {
    EventRegister.emit("tab-bar/visibility-changed", false);

    return () => EventRegister.emit("tab-bar/visibility-changed", true);
  });

  useEffect(() => {
    const handleClick = async (event: MouseEvent): Promise<void> => {
      const element = event.target as HTMLAnchorElement;

      if (element.href && element.href?.includes("accountContactSupport")) {
        event.preventDefault();
        await FirebaseService.logEvent("support_link_pressed", {
          link: "contact_support",
          type: "app_support",
        });

        sendSupportEmail();
      } else if (element.href && element.href?.includes(CLASS_TERMS_URL)) {
        await FirebaseService.logEvent("support_link_pressed", {
          link: "terms_and_conditions",
        });
      }
    };

    const handleAuxClick = async (event: MouseEvent): Promise<void> => {
      const element = event.target as HTMLAnchorElement;

      if (event.button === 1) {
        if (element.href && element.href?.includes("accountContactSupport")) {
          event.preventDefault();
          await FirebaseService.logEvent("support_link_pressed", {
            link: "contact_support",
            type: "app_support",
          });

          sendSupportEmail();
        } else if (element.href && element.href?.includes(CLASS_TERMS_URL)) {
          await FirebaseService.logEvent("support_link_pressed", {
            link: "terms_and_conditions",
          });
        }
      }
    };

    document.addEventListener("click", handleClick);
    document.addEventListener("auxclick", handleAuxClick);

    return () => {
      document.removeEventListener("click", handleClick);
      document.removeEventListener("auxclick", handleAuxClick);
    };
  }, [sendSupportEmail]);

  const logOut = async (): Promise<void> => {
    await AuthenticationController.logout();
    setEvidence(null);
    setPlusData(null);
    setParapassData(null);
    setAccessibilitySettings(null);
    setWelcomeDismissed(false);
    setDraftsHintDismissed(false);
    setGuidelinesCardDismissed(false);
    setInfoDismissed([]);
    setDrafts(null);
    resetUser();
    resetAllProgressCheckData();
    resetUserPrograms();
    loadableHasValue(debugUserLoadable) && setDebugUser(false);
    loadableHasValue(programDataLoadable) && setProgramData([]);
    loadableHasValue(programVersionInfoLoadable) && setProgramVersionInfo([]);

    history.replace("/onboarding/login");
  };

  const openSubscriptionOptions = (): void => {
    EventRegister.emit("subscriptions/show-modal", ISubscriptionModalType.PROFILE);
  };

  const manageSubscription = async (): Promise<void> => {
    let url: string = "";

    if (Capacitor.getPlatform() === "android") {
      window.open(GOOGLE_PLAY_MANAGE_SUBSCRIPTIONS_URL, "_blank");
    } else if (Capacitor.getPlatform() === "ios") {
      Subscriptions.manageSubscriptions();
    }
  };

  const updatePurchase = async (
    productID: string,
    subscription: ISubscriptionPurchase
  ): Promise<boolean | undefined> => {
    try {
      if (user.state === "hasValue" && user.contents && subscription) {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const receipt = await Subscriptions.getCurrentEntitlements();

        let response;

        if (!_.isEmpty(user.contents)) {
          response = await IAPService.sendPurchaseInfo(
            `${user.contents?.userData.contactID}`,
            format(new Date(subscription.startDate!), "yyyy-MM-dd"),
            subscription.transactionId!,
            productID,
            Capacitor.getPlatform() === "ios" ? "iOS" : "Android",
            user.contents?.token!,
            format(new Date(subscription.endDate!), "yyyy-MM-dd")
          );
        }

        if (response) {
          let userToSave = await AuthenticationController.getUserData(user.contents);

          if (userToSave) {
            setUser(userToSave);

            return true;
          }
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const restoreSubscription = async (): Promise<void> => {
    await presentOverlay({ message: "Restoring purchases..." });

    const entitlements = await IAPService.restoreSubscription();

    if (entitlements.success) {
      // console.log(entitlements.subs);
      if (entitlements.subs.length > 0) {
        const latest = entitlements.subs[0];

        if (latest) {
          // console.log('latest', latest);

          const success = await updatePurchase(latest.productId!, latest);

          if (success) {
            await dismissOverlay();

            window.alert("Successfully restored purchases");
          } else {
            await dismissOverlay();

            window.alert("An error occurred when restored purchases");
          }
        } else {
          await dismissOverlay();

          window.alert("No purchases to restore");
        }
      } else {
        await dismissOverlay();

        window.alert("No purchases to restore");
      }
    } else {
      await dismissOverlay();
      window.alert("An error occurred when restored purchases");
    }
  };

  const requestDeletion = async (): Promise<void> => {
    try {
      const success = await AuthenticationController.requestAccountDeletion(
        user.contents.userData?.contactID,
        user.contents?.token
      );

      setAccountDeletionModalVisible(false);

      if (success) {
        logOut();
        presentAlert({
          cssClass: "accountDeletionAlert",
          header: "Your account has been requested to be deleted",
          message: "You'll be notified when your account has successfully been deleted",
          buttons: ["Dismiss"],
          mode: "ios",
        });
      } else {
        presentAlert({
          cssClass: "accountDeletionAlert",
          header: "Request failed to send",
          message: "Please try again shortly",
          buttons: ["Dismiss"],
          mode: "ios",
        });
      }
    } catch (error) {
      presentAlert({
        cssClass: "accountDeletionAlert",
        header: "Request failed to send",
        message: "Please try again shortly",
        buttons: ["Dismiss"],
        mode: "ios",
      });
    }
    // const action = window.confirm('Request account deletion?\n\');

    // if (action) {
    //   window.alert('success');
    // }
  };

  // console.log(shouldShowSubscriptionButtons);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar
          mode="ios"
          className="navBar"
          style={{
            maxWidth: DataController.isWeb() ? 980 : undefined,
            height: Capacitor.getPlatform() === "android" ? "54px" : "unset",
          }}
        >
          <IonButtons slot="start">
            <IonBackButton
              className="header-back-buttons"
              defaultHref="/profile"
              text={DataController.getBackIconText()}
              icon={DataController.getBackIconType()}
              style={{
                marginLeft: Capacitor.getPlatform() === "android" ? 8 : 0,
                "--icon-font-size": Capacitor.getPlatform() === "android" ? "24px" : "30px",
              }}
            />
          </IonButtons>
          <HeaderTitle>{"My account"}</HeaderTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className="page-background">
        <EvidenceContainer style={{ paddingTop: 0 }}>
          <AccountCard title="Email">
            <div className="homeCardUserInfoVertical">
              <div className="homeCardUserDetailsHeader" style={{ marginTop: 0 }}>
                {"Email"}
              </div>
              <div className="homeCardUserDetailsText">{user.contents?.email || "Unknown"}</div>
              <IonButton mode="ios" className="accountButton" onClick={() => logOut()}>
                <div className="accountButtonTextLogout">{"Log out"}</div>
              </IonButton>
            </div>
          </AccountCard>
          <AccountCard title="Subscription">
            {currentSubscription ? (
              <div className="homeCardUserInfoVertical">
                <div className="homeCardUserDetailsHeader" style={{ marginTop: 0 }}>
                  {"Description"}
                </div>
                <div className="homeCardUserDetailsText">
                  {currentSubscription.Description}
                  {isFreeTrial && " (Trial)"}
                </div>
                <div className="homeCardUserDetailsHeader" style={{ marginTop: 8 }}>
                  {"Expiry Date"}
                </div>
                <div className="homeCardUserDetailsText">
                  {currentSubscription.ExpiryDateStr
                    ? format(new Date(currentSubscription.ExpiryDateStr), "d MMMM yyyy")
                    : "N/A"}
                </div>
                {!shouldShowSubscriptionButtons ? null : isFreeTrial || Capacitor.getPlatform() === "web" ? (
                  <IonButton mode="ios" className="accountButton" onClick={() => openSubscriptionOptions()}>
                    <div className="accountButtonText">{"See subscription options"}</div>
                  </IonButton>
                ) : (
                  <IonButton mode="ios" className="accountButton" onClick={() => manageSubscription()}>
                    <div className="accountButtonText">{"Manage subscription"}</div>
                  </IonButton>
                )}
              </div>
            ) : (
              <div className="homeCardUserInfoVertical">
                <div className="homeCardUserDetailsHeader" style={{ marginTop: 0 }}>
                  {"Description"}
                </div>
                <div className="homeCardUserDetailsText">{user.contents?.email || "Unknown"}</div>
                <IonButton mode="ios" className="accountButton" onClick={() => manageSubscription()}>
                  <div className="accountButtonText">{"Manage subscription"}</div>
                </IonButton>
                {showRestore && (
                  <>
                    <div className="homeCardUserText" style={{ marginTop: 16 }}>
                      {"If you already have a subscription and cannot see it, try restoring your subscription through " +
                        "the button below."}
                    </div>
                    <IonButton mode="ios" className="accountButton" onClick={() => restoreSubscription()}>
                      <div className="accountButtonText">{"Restore subscription"}</div>
                    </IonButton>
                  </>
                )}
              </div>
            )}
          </AccountCard>
          {showRestore && (
            <AccountCard title="Subscription information">
              <div className="homeCardUserInfoVertical">
                <div className="homeCardUserText">
                  {"For app subscription related help request please use this "}
                  <a href="accountContactSupport" className="accountFAQLink">
                    {"link"}
                  </a>
                  {"."}
                  <br />
                  <br />
                  {
                    "ParaFolio is free to download and includes a free 30 day trial. After the trial has finished there are 2 types of subscription available; a recurring monthly subscription or a recurring annual subscription."
                  }
                  <br />
                  <br />
                  {
                    "Both subscriptions are auto-renewing but can be cancelled at any time using the manage subscription button found higher up this page. Auto-renew must be turned off at least 24- hours before the end of the current renewal period in order to not be charged."
                  }
                  <br />
                  <br />
                  {`Subscription payments will be charged to your ${Capacitor.getPlatform() === "ios" ? "Apple ID" : "Google Play account"} at confirmation of purchase.`}
                  <br />
                  <br />
                  {
                    "Any unused portion of a free trial period, if offered, will be forfeited when the user purchases a subscription to that publication, where applicable."
                  }
                  <br />
                  <br />
                  {"Please read the "}
                  <a href={CLASS_TERMS_URL} target="_blank" rel="noreferrer" className="accountFAQLink">
                    {"Privacy Policy"}
                  </a>
                  {" and "}
                  <a href={CLASS_TERMS_URL} target="_blank" rel="noreferrer" className="accountFAQLink">
                    {"Terms of Use"}
                  </a>
                  {"."}
                </div>
              </div>
            </AccountCard>
          )}
          <AccountCard title="Request account deletion">
            <div className="homeCardUserInfoVertical">
              <div className="homeCardUserText">
                {"If you are looking to delete your account, please use the button below to send a request to our support " +
                  "team who will handle your request."}
              </div>
              <IonButton mode="ios" className="accountButton" onClick={() => setAccountDeletionModalVisible(true)}>
                <div className="accountButtonTextRequest">{"Request account deletion"}</div>
              </IonButton>
            </div>
          </AccountCard>
          <IonModal
            id="accountDeletionModal"
            isOpen={accountDeletionModalVisible}
            backdropDismiss={true}
            canDismiss={true}
            className="fullscreen"
            onDidDismiss={() => setAccountDeletionModalVisible(false)}
          >
            <IonContent className="accountDeletionModal">
              <div className="tipModalContainer">
                <div>
                  <div className="tipModalTitle">{"Request account deletion?"}</div>
                  <div className="tipModalText">
                    {
                      "Confirming will alert our support team to delete your account. Our support team will deal with your request within a few days and you will be notified once your account has successfully been deleted."
                    }
                  </div>
                  <div className="tipModalText" style={{ marginTop: 16 }}>
                    {"Please note that deleting your account will not cancel your subscription automatically."}
                  </div>
                </div>
                <div className="tipBottomButtonContainer" style={{ marginTop: 20 }}>
                  <button className="tipBottomButton tipBottomButtonDestructive" onClick={() => requestDeletion()}>
                    {"Request deletion"}
                  </button>
                </div>
                <div className="tipBottomButtonContainer">
                  <button className="tipBottomButton" onClick={() => setAccountDeletionModalVisible(false)}>
                    {"Cancel deletion"}
                  </button>
                </div>
              </div>
            </IonContent>
          </IonModal>
        </EvidenceContainer>
      </IonContent>
    </IonPage>
  );
};

export default withRouter(Account);
