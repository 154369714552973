import { IonButton, IonButtons, IonContent, IonHeader, IonPage, IonToolbar } from "@ionic/react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Swiper as SwiperInterface } from "swiper";

import "swiper/css";
import "@ionic/react/css/ionic-swiper.css";

import "./Onboarding.css";
import "../../components/onboarding/LoginModal.css";

import { useCallback, useEffect, useState } from "react";
import { Capacitor } from "@capacitor/core";

import { useHistory, withRouter } from "react-router";
import { FirebaseService } from "../../controllers/FirebaseService";
import DataController from "../../controllers/DataController";
import {
  Button_Onboarding_Tick,
  Image_OB_Audit,
  Image_OB_Organise,
  Image_OB_Reflect,
  Image_OB_Welcome,
} from "../../assets/images";
import { NavigationBar } from "@hugotomazi/capacitor-navigation-bar";
import HeaderTitle from "../../components/common/HeaderTitle";

const Welcome: React.FC = () => {
  const history = useHistory();

  const [swiperInstance, setSwiperInstance] = useState<SwiperInterface>();
  const [slidePosition, setSlidePosition] = useState<number>(0);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [compactSlider, setCompactSlider] = useState<boolean>(false);

  const setNavbarColors = async (): Promise<void> => {
    if (Capacitor.getPlatform() === "android") {
      try {
        NavigationBar.setColor({ color: "#026F8A" });
      } catch (error) {}
    }
  };

  useEffect(() => {
    setNavbarColors();
  }, []);

  useEffect(() => {
    const setPageName = async (): Promise<void> => {
      await FirebaseService.setScreenName("onboarding");
    };

    setPageName();
  }, []);

  const onSlideChange = (): void => {
    setSlidePosition(swiperInstance?.realIndex || 0);
  };

  const progressDotClicked = (value: number): void => {
    if (value !== slidePosition) {
      changeSlideIndex(value);
    }
  };

  const changeSlideIndex = useCallback(
    (value: number, skip?: boolean): void => {
      swiperInstance?.slideTo(value, skip ? 0 : undefined);
    },
    [swiperInstance]
  );

  const skipClicked = () => {
    changeSlideIndex(4, true);
  };

  const loginClicked = (event: React.MouseEvent<HTMLIonButtonElement, MouseEvent>) => {
    setSlidePosition(0);
    changeSlideIndex(0, true);
    history.push("/onboarding/login");
  };

  const registerClicked = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setSlidePosition(0);
    changeSlideIndex(0, true);
    history.push("/onboarding/register");
  };

  const buttonClicked = () => {
    if (slidePosition !== 4) {
      changeSlideIndex(slidePosition + 1);
    }
  };

  useEffect(() => {
    setNavbarColors();
    setSlidePosition(0);
    changeSlideIndex(0, true);

    return () => {
      setSlidePosition(0);
      changeSlideIndex(0, true);
    };
  }, [changeSlideIndex]);

  return (
    <IonPage>
      <IonHeader mode="ios">
        <IonToolbar
          mode="ios"
          className="navBar"
          style={{
            maxWidth: DataController.isWeb() ? 980 : undefined,
            height: Capacitor.getPlatform() === "android" ? "54px" : "unset",
          }}
        >
          <HeaderTitle>{slidePosition === 0 ? "" : "ParaFolio"}</HeaderTitle>
          {slidePosition !== 4 && (
            <IonButtons slot="end">
              <IonButton mode="ios" className="cancelButton" onClick={() => skipClicked()}>
                {"Skip"}
              </IonButton>
            </IonButtons>
          )}
        </IonToolbar>
      </IonHeader>
      <IonContent className="onboardingPage">
        <div className="onboardingContainer">
          <div className="sliderContainer">
            <Swiper
              threshold={5}
              mousewheel={false}
              onSwiper={(swiper) => setSwiperInstance(swiper)}
              onSlideChange={onSlideChange}
              className="onboarding-swiper"
            >
              <SwiperSlide>
                <div className="onboardingSlide items-center">
                  <img className="onboardingSlideImage mx-auto" src={Image_OB_Welcome} alt="" />
                  <div className="onboardingSlideHeader">{"Welcome to ParaFolio"}</div>
                  <div className="onboardingSlideText">
                    {"The new CPD tool for organising your portfolio for audit. Get your portfolio started today."}
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="onboardingSlide">
                  <img className="onboardingSlideImage mx-auto" src={Image_OB_Reflect} alt="" />
                  <div className="onboardingSlideHeaderSmall">{"Reflect on your activity"}</div>
                  <div className="onboardingSlideText">
                    {
                      "Sign in using your account from one of these apps and get your activity imported into your Timeline. Reflect on your results from ParaPass or your acknowledged Clinical Notices from JRCALC Plus."
                    }
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="onboardingSlide">
                  <img className="onboardingSlideImage mx-auto" src={Image_OB_Organise} alt="" />
                  <div className="onboardingSlideHeaderSmall">{"Organise your evidence"}</div>
                  <div className="onboardingSlideText">
                    {
                      "Add evidence quickly and use custom tags to keep your portfolio organised the way you want it. Tag it as HCPC evidence to add it to your HCPC profile."
                    }
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="onboardingSlide">
                  <img className="onboardingSlideImage mx-auto" src={Image_OB_Audit} alt="" />
                  <div className="onboardingSlideHeaderSmall">{"Get ready for audit"}</div>
                  <div className="onboardingSlideText">
                    {
                      "Organise everything you need for audit or appraisal in one place. Add your summary of work, personal statement and tag your evidence ready for export as a single PDF document."
                    }
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="onboardingSlide">
                  <div className="onboardingSlideHeaderSmall">{"Got an account with us?"}</div>
                  <div className="onboardingSlideTextLeft">
                    {"If you have an existing ParaFolio account, or use one of these apps then log in below:"}
                  </div>
                  <div className="onboardingSlideTickContainer">
                    <div className="onboardingSlideTick">
                      <img className="onboardingSlideTickImage" src={Button_Onboarding_Tick} alt="" />
                      <div className="onboardingSlideTickText">{"JRCALC Plus"}</div>
                    </div>
                    <div className="onboardingSlideTick">
                      <img className="onboardingSlideTickImage" src={Button_Onboarding_Tick} alt="" />
                      <div className="onboardingSlideTickText">{"iCPG"}</div>
                    </div>
                    <div className="onboardingSlideTick">
                      <img className="onboardingSlideTickImage" src={Button_Onboarding_Tick} alt="" />
                      <div className="onboardingSlideTickText">{"ParaPass"}</div>
                    </div>
                    <div className="onboardingSlideTick">
                      <img className="onboardingSlideTickImage" src={Button_Onboarding_Tick} alt="" />
                      <div className="onboardingSlideTickText">{"Responder Plus"}</div>
                    </div>
                  </div>
                  <IonButton mode="ios" className="onboardingWideButton" onClick={(event) => loginClicked(event)}>
                    {"Login"}
                  </IonButton>
                  <div className="onboardingFooter onboardingFooterLogin">
                    {"Don't have an account? "}
                    <button onClick={(event) => registerClicked(event)}>{"Register here"}</button>
                  </div>
                </div>
              </SwiperSlide>
            </Swiper>
          </div>
          <div className="onboardingDots">
            {[0, 1, 2, 3, 4].map((position) => {
              return (
                <div
                  key={`onboardingProgressDot${position}`}
                  onClick={() => progressDotClicked(position)}
                  className={`onboardingProgressDot ${slidePosition === position ? "onboardingProgressDotFilled" : ""}`}
                />
              );
            })}
          </div>
          {slidePosition !== 4 && (
            <IonButton mode="ios" className="onboardingWideButton" onClick={() => buttonClicked()}>
              {slidePosition === 0 ? "Learn More" : "Continue"}
            </IonButton>
          )}
        </div>
      </IonContent>
    </IonPage>
  );
};

export default withRouter(Welcome);
