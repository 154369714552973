import React from "react";
import { ICommentWrapper } from "../../types/Components";
import "../../theme/tailwind.css";

const CommentWrapper: React.FC<ICommentWrapper> = (props) => {
  return (
    <div className={"pt-[16px] ml-safe-area-l mr-safe-area-r shadow-comment shadow-grey-30"}>{props.children}</div>
  );
};

export default CommentWrapper;
