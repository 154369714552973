import { useEffect, useState } from "react";
import { CompetenceActivityClass, ProgramData, ProgramSkill, ProgressCheck } from "../../Interfaces";
import * as ProgramUtils from "../../utils/programUtils";
import { useProgramData } from "../data/useProgramData";

export const useProgramInfo = (programID: string = "") => {
  const { programData } = useProgramData();

  const [program, setProgram] = useState<ProgramData | any | null>(null);
  const [infoBody, setInfoBody] = useState<string | null>(null);

  const [competences, setCompetences] = useState<CompetenceActivityClass[] | any[]>([]);
  const [progressChecks, setProgressChecks] = useState<ProgressCheck[] | any[]>([]);

  useEffect(() => {
    if (programID.length > 0 && programID !== program?.ID) {
      const currentProgram = programData.find((item: any): item is ProgramData => item.ID === programID);

      currentProgram ? setProgram(currentProgram) : setProgram(null);
      currentProgram?.PopupDisclaimer ? setInfoBody(currentProgram.PopupDisclaimer) : setInfoBody(null);
      currentProgram?.CompetenceActivity ? setCompetences(currentProgram.CompetenceActivity) : setCompetences([]);
      currentProgram?.ProgressChecks ? setProgressChecks(currentProgram.ProgressChecks) : setProgressChecks([]);
    }
  }, [programID, program?.ID]);

  return {
    program,
    infoBody,
    progressChecks,
    competences,
  };
};
