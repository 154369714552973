import { useEffect, useState } from "react";
import { EvidencePart } from "../../../Interfaces";
import { format } from "date-fns";
import { Button_Dropdown } from "../../../assets/images";
import { IonDatetime, IonPopover } from "@ionic/react";
import { Text, View } from "@react-pdf/renderer";

type Props = {
  children?: React.ReactNode;
  style?: React.CSSProperties;
  definition: EvidencePart;
  onValueChanged?: (value: any) => void;
  data?: any;
  isDisplay?: boolean;
  isPDF?: boolean;
  pdfStyles: any;
  initialValue?: any;
};

const DateInput: React.FC<Props> = ({
  children,
  style,
  definition,
  onValueChanged,
  data,
  isDisplay,
  isPDF,
  pdfStyles,
  initialValue,
}) => {
  const [date, setDate] = useState<Date>(initialValue ? new Date(initialValue) : new Date());
  const [datePickerVisible, setDatePickerVisible] = useState<boolean>(false);

  useEffect(() => {
    if (!initialValue) {
      setDate(new Date());
      onValueChanged && onValueChanged(new Date());
    }
  }, [initialValue, onValueChanged]);

  const onDateChanged = (event: any): void => {
    const _value = new Date(event.detail.value);

    setDate(_value);
    onValueChanged && onValueChanged(_value);
  };

  const openDatePicker = (): void => {
    setDatePickerVisible(true);
  };

  if (isPDF) {
    return (
      <View>
        <Text style={pdfStyles.reflectionHeader}>{(definition["Display Title"] || definition.Name) + ":"}</Text>
        <Text style={pdfStyles.evidenceDate}>{format(new Date(data), "d MMMM yyyy")}</Text>
      </View>
    );
  }

  if (isDisplay) {
    return (
      <div className="readEvidenceBlock">
        <div className="readEvidenceHeader">{definition["Display Title"] || definition.Name}</div>
        <div className="readEvidenceText">{format(new Date(data), "d MMMM yyyy")}</div>
        <div className="h-[1px] w-full bg-grey-30 mt-4" />
      </div>
    );
  }

  return (
    <div id={definition.id} className="reflectionDateContainer" onClick={() => openDatePicker()}>
      <div className="reflectionDateText">{format(date, "dd / MM / yyyy")}</div>
      <img src={Button_Dropdown} className="reflectionsHeaderDropdown" alt="" />
      <IonPopover trigger={definition.id} isOpen={datePickerVisible} onDidDismiss={() => setDatePickerVisible(false)}>
        <IonDatetime
          mode="md"
          size="cover"
          color="primary"
          value={date.toISOString()}
          onChange={(event) => console.log(event)}
          onIonChange={onDateChanged}
          max={new Date().toISOString()}
          presentation="date"
          firstDayOfWeek={1}
        />
      </IonPopover>
    </div>
  );
};

export default DateInput;
