import { IonImg, IonItem, IonLabel } from "@ionic/react";
import { Button_Disclosure_Right } from "../../assets/images";
import { IInformationCardButton } from "../../Interfaces";

const InformationCardButton = ({ icon, title, subtitle, disabled, buttonClicked }: IInformationCardButton) => {
  return (
    <IonItem
      button
      disabled={disabled}
      onClick={() => buttonClicked?.()}
      className="informationCardButton"
      detail={false}
    >
      <div className="informationCardButtonContainer">
        <div className="informationCardButtonInnerContainer">
          <IonImg src={icon} className="informationCardButtonIcon" />
          <div className="informationCardButtonTitleContainer">
            <IonLabel className="informationCardButtonTitle">{title}</IonLabel>
            <IonLabel className="informationCardButtonSubtitle">{subtitle}</IonLabel>
          </div>
        </div>
        <IonImg src={Button_Disclosure_Right} className="profileSettingDisclosure" />
      </div>
    </IonItem>
  );
};

export default InformationCardButton;
