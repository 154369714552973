import { useEffect, useRef, useState } from "react";
import _ from "lodash";
import { AWSAccessParams, EvidenceAttachment, EvidenceCustomTag, EvidencePart, IUser } from "../../../Interfaces";
import { Link, Text, View } from "@react-pdf/renderer";
import { Button_Attachment, Icon_Attachment, Icon_Attachment_Delete } from "../../../assets/images";
import { useFilePicker } from "use-file-picker";
import { IonSpinner } from "@ionic/react";
import { useRecoilStateLoadable, useRecoilValueLoadable } from "recoil";
import { userAtom } from "../../../state/State";
import AWSService from "../../../controllers/AWSService";
import DataController from "../../../controllers/DataController";
import WriteBlob from "capacitor-blob-writer";
import { Directory } from "@capacitor/filesystem";
import { FileOpener } from "@capacitor-community/file-opener";
import mime from "mime";
import { EVIDENCE_ACCEPTED_FILE_TYPES, EVIDENCE_MAX_FILE_SIZE } from "../../../Constants";

type Props = {
  children?: React.ReactNode;
  style?: React.CSSProperties;
  definition: EvidencePart;
  onValueChanged?: (value: any) => void;
  data?: any;
  isDisplay?: boolean;
  isPDF?: boolean;
  pdfStyles: any;
  initialValue?: any;
  evidenceID?: string;
  user: IUser;
  accessParams: AWSAccessParams;
  id?: string;
};

const AttachmentsInput: React.FC<Props> = ({
  children,
  style,
  onValueChanged,
  data,
  definition,
  isDisplay,
  isPDF,
  pdfStyles,
  initialValue,
  evidenceID,
  user,
  accessParams,
  id,
}) => {
  const [openFileSelector, { filesContent, clear, plainFiles, errors }] = useFilePicker({
    accept: EVIDENCE_ACCEPTED_FILE_TYPES,
    maxFileSize: EVIDENCE_MAX_FILE_SIZE,
    readAs: "DataURL",
    multiple: true,
  });

  const [attachments, setAttachments] = useState<EvidenceAttachment[]>(initialValue?.attachments || []);

  const [attachmentsToAdd, setAttachmentsToAdd] = useState<EvidenceAttachment[]>([]);
  const [attachmentsToDelete, setAttachmentsToDelete] = useState<EvidenceAttachment[]>([]);

  const [viewingAttachment, setViewingAttachment] = useState<boolean>(false);
  const [viewingAttachmentFilename, setViewingAttachmentFilename] = useState<string>("");

  useEffect(() => {
    if (!initialValue?.attachments.length) {
      setAttachments([]);
    }
    setAttachmentsToAdd([]);
    setAttachmentsToDelete([]);
  }, [id]);

  useEffect(() => {
    if (plainFiles.length > 0) {
      const _attachments = _.cloneDeep(attachments);
      const toAdd = _.cloneDeep(attachmentsToAdd);

      for (let i = 0; i < plainFiles.length; i++) {
        const file = {
          name: plainFiles[i].name,
          type: plainFiles[i].type,
          size: plainFiles[i].size,
          content: filesContent[i].content,
          lastModified: plainFiles[i].lastModified,
        };

        const fileExists = _.findIndex(_attachments, (item) => item.name === file.name) > -1;
        const adding = _.findIndex(toAdd, (item) => item.name === file.name) > -1;

        if (!fileExists && !adding) {
          _attachments.push(file);
          toAdd.push(file);
        }
      }

      setAttachments(_attachments);
      setAttachmentsToAdd(_.uniqBy(toAdd, "name"));
      onValueChanged &&
        onValueChanged({
          attachmentsToAdd: _.uniqBy(toAdd, "name"),
          attachmentsToDelete,
        });
      clear();
    }
  }, [attachments, attachmentsToAdd, attachmentsToDelete, clear, filesContent, onValueChanged, plainFiles]);

  const viewAttachment = async (attachment: EvidenceAttachment): Promise<void> => {
    try {
      if (attachment.url && evidenceID) {
        setViewingAttachment(true);
        setViewingAttachmentFilename(attachment.name);

        const _accessParams: AWSAccessParams = await AWSService.generateSTSToken(user);
        const file = await AWSService.openAttachmentFromServer(user, _accessParams, evidenceID, attachment);

        if (await DataController.isWebAsync()) {
          const url = window.URL.createObjectURL(
            new Blob([new Uint8Array(file.Body.data)], {
              type: file.ContentType,
            })
          );
          window.open(url, "_blank");
        } else {
          const blob = new Blob([new Uint8Array(file.Body.data)], {
            type: file.ContentType,
          });

          const filePath = await WriteBlob({
            path: `ParaFolio/Attachments/${attachment.name}.${mime.getExtension(file.ContentType)}`,
            blob,
            directory: Directory.Library,
            recursive: true,
          });

          FileOpener.open({
            filePath,
          });
        }

        setViewingAttachment(false);
        setViewingAttachmentFilename("");
      }
    } catch (error) {
      console.log(error);
      setViewingAttachment(false);
      setViewingAttachmentFilename("");
      window.alert("An error occurred when opening attachment");
    }
  };

  const deleteAttachment = (name: string): void => {
    const index = attachments.findIndex((attachment: EvidenceAttachment) => attachment?.name === name);

    if (index > -1) {
      const attachment = attachments[index];

      if (attachment.url) {
        const toDelete = _.cloneDeep(attachmentsToDelete);
        toDelete.push(attachment);

        setAttachmentsToDelete(toDelete);
        onValueChanged && onValueChanged({ attachmentsToAdd, attachmentsToDelete: toDelete });
      } else if (attachment.content) {
        const toAdd = _.cloneDeep(attachmentsToAdd);
        const addedIndex = _.findIndex(toAdd, (item) => item.name === name);
        addedIndex > -1 && toAdd.splice(addedIndex);

        setAttachmentsToAdd(toAdd);
        onValueChanged && onValueChanged({ attachmentsToAdd: toAdd, attachmentsToDelete });
      }
    }

    index !== -1 && setAttachments(attachments.filter((_, i) => i !== index));
  };

  if (isPDF) {
    return (
      <View>
        <Text style={pdfStyles.reflectionHeader}>{(definition.Name || definition["Display Title"]) + ":"}</Text>
        {data?.attachments?.map((attachment: EvidenceAttachment) => {
          const signedURL = AWSService.getAttachmentURL(user, accessParams, evidenceID!, attachment);

          // console.log(signedURL);

          return (
            <Link style={pdfStyles.hyperlink} src={signedURL || ""} key={attachment.name}>
              {attachment.name}
            </Link>
          );
        })}
      </View>
    );
  }

  if (isDisplay) {
    if (!_.isEmpty(data.attachments)) {
      return (
        <div className="readEvidenceBlock">
          <div className="readEvidenceHeader">{definition.Name || definition["Display Title"]}</div>
          <div className="readEvidenceText">
            {data.attachments.map((attachment: EvidenceAttachment) => {
              const disabled = viewingAttachment && viewingAttachmentFilename !== attachment.name;
              const viewing = viewingAttachment && viewingAttachmentFilename === attachment.name;

              return (
                <div
                  key={attachment.name}
                  id={attachment.name}
                  className={`readEvidenceAttachmentContainer ${viewing ? "readEvidenceAttachmentContainerWait" : ""} ${disabled ? "readEvidenceAttachmentContainerDisabled" : ""}`}
                  onClick={() => !(disabled || viewing) && viewAttachment(attachment)}
                >
                  <div className="readEvidenceAttachment">{attachment.name}</div>
                  {viewing && (
                    <div className="readEvidenceSpinnerContainer">
                      <IonSpinner className="readEvidenceAttachmentIconSpinner" />
                    </div>
                  )}
                </div>
              );
            })}
          </div>
          <div className="h-[1px] w-full bg-grey-30 mt-4" />
        </div>
      );
    } else {
      return null;
    }
  }

  return (
    <div className="reflectionsCustomTagContainer">
      {attachments.map((attachment, index) => {
        const disabled = viewingAttachment && viewingAttachmentFilename !== attachment.name;
        const viewing = viewingAttachment && viewingAttachmentFilename === attachment.name;

        let split = attachment.name.split(".");
        let filename = split[0];
        let extension = split[1];

        if (filename.length > 14) {
          filename = `${filename.substring(0, 7)}...${filename.substring(7, 14)}`;
        }

        return (
          <div className="attachmentContainer" key={attachment.name}>
            <div
              className={`${attachment.url ? "attachmentInnerContainerLink" : "attachmentInnerContainer"}`}
              onClick={() => !(disabled || viewing) && viewAttachment(attachment)}
            >
              {viewing ? (
                <div className="attachmentIconSpinnerContainer">
                  <IonSpinner className="attachmentIconSpinner" />
                </div>
              ) : (
                <img src={Icon_Attachment} className="attachmentIcon" alt="" />
              )}
              <div title={attachment.name} className="attachmentFilename">
                {`${filename}.${extension}`}
              </div>
            </div>
            <div className="attachmentIconCloseContainer" onClick={() => deleteAttachment(attachment.name || "")}>
              <img src={Icon_Attachment_Delete} className="attachmentIconClose" alt="" />
            </div>
          </div>
        );
      })}
      <div className="reflectionDocumentsAddButton" onClick={openFileSelector}>
        <div className="reflectionDocumentsAddText">{"Add attachments"}</div>
        <img src={Button_Attachment} className="reflectionDocumentsAddIcon" alt="" />
      </div>
    </div>
  );
};

export default AttachmentsInput;
