import { Preferences } from "@capacitor/preferences";
import { IUser } from "../Interfaces";
import RemoteDataController from "./RemoteDataController";
import { ConnectionStatus, Network } from "@capacitor/network";
import { AUTH_SERVER_URL } from "../Constants";
import localforage from "localforage";

export class AuthenticationController {
  async logout(): Promise<void> {
    await Preferences.remove({ key: "user" });
    await Preferences.remove({ key: "evidence" });
    await Preferences.remove({ key: "plusData" });
    await Preferences.remove({ key: "parapassData" });
    await Preferences.remove({ key: "welcomeDismissed" });
    await Preferences.remove({ key: "guidelinesCardDismissed" });
    await Preferences.remove({ key: "appVersion" });
    await Preferences.remove({ key: "appFeatures" });
    await Preferences.remove({ key: "accessibilitySettings" });
    await Preferences.remove({ key: "programInfoDismissed" });
    await Preferences.remove({ key: "userPrograms" });
    await Preferences.remove({ key: "draftsHintDismissed" });
    await Preferences.remove({ key: "evidenceDrafts" });
    await Preferences.remove({ key: "progressCheckData" });
    await localforage.removeItem("debugUser");
    await localforage.removeItem("programData");
    await localforage.removeItem("programDataVersionInfo");
  }

  async register(firstName: string, lastName: string, email: string, password: string): Promise<any> {
    return new Promise(async (resolve, reject) => {
      try {
        const status: ConnectionStatus = await Network.getStatus();

        if (!status.connected) {
          reject("You are not connected to the internet. Please reconnect to the internet to register");
        }

        const url: string = AUTH_SERVER_URL + "/register";

        const response: Response = await fetch(url, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            email: email,
            password: password,
            firstName: firstName.trim(),
            lastName: lastName.trim(),
            CreatedSubscriberProduct: "ParaFolio",
          }),
        });

        if (response) {
          const json = await response.json();

          // console.log(json);

          if (json.success) {
            resolve(true);
          } else if (json.body.ErrorMessage && json.body.ErrorMessage.toLowerCase().includes("already exists")) {
            reject("This email is already in use");
          } else {
            reject(json.body.ErrorMessage);
          }
        } else {
          reject("The server didn't respond");
        }
      } catch (error: any) {
        console.log(error);
        RemoteDataController.logError(error);
        reject(error);
      }
    });
  }

  async login(email: string, password: string): Promise<any> {
    return new Promise(async (resolve, reject) => {
      try {
        const status: ConnectionStatus = await Network.getStatus();

        if (!status.connected) {
          reject("You are not connected to the internet. Please reconnect to the internet to log in");
        }

        const url: string = AUTH_SERVER_URL + "/login";

        const response: Response = await fetch(url, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            email: email,
            password: password,
            product: "ParaFolio",
          }),
        });

        if (response) {
          const json = await response.json();

          // console.log(json);

          if (json.auth && json.token) {
            const user = {
              token: json.token,
              subscriptions: json.subscriptions && json.subscriptions,
              email: email,
              name: json.userName,
              userData: json,
            };

            resolve(user);
          } else {
            reject("Incorrect account details entered. Please check and resubmit");
          }
        } else {
          reject("The server didn't respond");
        }
      } catch (error: any) {
        console.log(error);
        RemoteDataController.logError(error);
        reject(error);
      }
    });
  }

  async loginSSO(accessToken: string): Promise<any> {
    return new Promise(async (resolve, reject) => {
      try {
        const status: ConnectionStatus = await Network.getStatus();

        if (!status.connected) {
          reject("You are not connected to the internet. Please reconnect to the internet to log in");
        }

        const url: string = AUTH_SERVER_URL + "/nwasLogin";

        const response: Response = await fetch(url, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            accessToken: accessToken,
          }),
        });

        if (response) {
          const json = await response.json();

          if (json.success && json.token) {
            let user = {
              token: json.token,
              email: json.email,
              userData: {
                contactID: json.contactID,
              },
            };

            user = await this.addUserData(user);

            resolve(user);
          } else {
            reject("User not found. Please contact support");
          }
        } else {
          reject("The server didn't respond");
        }
      } catch (error: any) {
        console.log(error);
        RemoteDataController.logError(error);
        reject(error);
      }
    });
  }

  async requestAccountDeletion(contactID: number, token: string): Promise<boolean> {
    return new Promise(async (resolve, reject) => {
      try {
        const url = AUTH_SERVER_URL + "/requestAccountDel";

        const response: Response = await fetch(url, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            ContactID: `${contactID}`,
            token,
            product: "ParaFolio",
          }),
        });

        if (response) {
          const json = await response.json();

          if (response.status === 200 && json.success === true) {
            resolve(true);
          }
        }
      } catch (error: any) {
        console.log(error);
        RemoteDataController.logError(error);
        reject(error);
      }
    });
  }

  async updateSubAppData(contactID: number, token: string, subscriberID: string): Promise<boolean> {
    return new Promise(async (resolve, reject) => {
      try {
        const url = AUTH_SERVER_URL + "/updateSubAppData";

        const response: Response = await fetch(url, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            ContactID: `${contactID}`,
            token,
            SubscriberID: subscriberID,
            AppData: JSON.stringify({ FirstScreenShown: true }),
          }),
        });

        if (response) {
          const json = await response.json();

          // console.log(json);

          if (json.success) {
            resolve(true);
          } else {
            reject(false);
          }
        }
      } catch (error: any) {
        console.log(error);
        RemoteDataController.logError(error);
        reject(error);
      }
    });
  }

  async appendSubAppData(contactID: number, token: string, subscriberID: string, data: any): Promise<boolean> {
    return new Promise(async (resolve, reject) => {
      try {
        const url = AUTH_SERVER_URL + "/updateSubAppData";

        const response: Response = await fetch(url, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            ContactID: `${contactID}`,
            token,
            SubscriberID: subscriberID,
            AppData: JSON.stringify(data),
          }),
        });

        if (response) {
          const json = await response.json();

          // console.log(json);

          if (json.success) {
            resolve(true);
          } else {
            reject(false);
          }
        }
      } catch (error: any) {
        console.log(error);
        RemoteDataController.logError(error);
        reject(error);
      }
    });
  }

  async addUserData(user: IUser): Promise<any> {
    try {
      if (user.userData) {
        const url = AUTH_SERVER_URL + "/userInfo";

        const response: Response = await fetch(url, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            token: user.token,
            ContactID: `${user.userData.contactID}`,
            product: "ParaFolio",
          }),
        });

        if (response) {
          const json = await response.json();

          if (json.success) {
            const userData = json;

            return this.updateUserSSO(user, userData);
          }
        }

        return null;
      }
    } catch (error: any) {
      console.log(error);
      RemoteDataController.logError(error);
    }
  }

  async getUserData(user: IUser): Promise<any> {
    try {
      if (user.userData) {
        const url = AUTH_SERVER_URL + "/userInfo";

        const response: Response = await fetch(url, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            token: user.token,
            ContactID: `${user.userData.contactID}`,
            product: "ParaFolio",
          }),
        });

        if (response) {
          const json = await response.json();

          if (json.success) {
            const userData = json;

            return this.updateUser(user, userData);
          }
        }

        return null;
      }
    } catch (error: any) {
      console.log(error);
      RemoteDataController.logError(error);
    }
  }

  async updateUser(user: IUser, userData: any): Promise<IUser | null> {
    try {
      const userToSave = {
        ...user,
        userData: {
          ...user.userData,
          subscriptions: userData.subscriptions,
        },
      };

      // console.log('updateUser', userData);
      // console.log('updateUser', userToSave);

      this.saveUser(userToSave);

      return userToSave;
    } catch (error: any) {
      console.log(error);
      RemoteDataController.logError(error);
      return null;
    }
  }

  async updateUserSSO(user: IUser, userData: any): Promise<IUser | null> {
    try {
      const userToSave = {
        ...user,
        subscriptions: userData.subscriptions,
        userData: {
          ...user.userData,
          ...userData,
        },
      };

      console.log(userToSave);

      return userToSave;
    } catch (error: any) {
      console.log(error);
      RemoteDataController.logError(error);
      return null;
    }
  }

  async saveUser(user: any): Promise<boolean> {
    try {
      await Preferences.set({ key: "user", value: JSON.stringify(user) });
      return true;
    } catch (error: any) {
      console.log(error);
      RemoteDataController.logError(error);
      return false;
    }
  }

  async getUser(): Promise<IUser | null> {
    try {
      const user = await Preferences.get({ key: "user" });

      return user?.value ? JSON.parse(user.value) : null;
    } catch (error: any) {
      RemoteDataController.logError(error);
      return null;
    }
  }

  async saveAppVersion(appVersion: string): Promise<boolean> {
    try {
      await Preferences.set({ key: "appVersion", value: appVersion });
      return true;
    } catch (error: any) {
      console.log(error);
      RemoteDataController.logError(error);
      return false;
    }
  }

  async saveAppFeatures(appFeatures: string[]): Promise<boolean> {
    try {
      await Preferences.set({
        key: "appFeatures",
        value: JSON.stringify(appFeatures),
      });
      return true;
    } catch (error: any) {
      console.log(error);
      RemoteDataController.logError(error);
      return false;
    }
  }
}

export default new AuthenticationController();
