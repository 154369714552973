import { useEffect } from "react";
import { EventRegister } from "react-native-event-listeners";

export const useHidingTabBar = () => {
  useEffect(() => {
    EventRegister.emit("tab-bar/visibility-changed", false);

    return () => EventRegister.emit("tab-bar/visibility-changed", true);
  });
};
