import { IonToast } from "@ionic/react";
import React, { useEffect, useState } from "react";
import { EventRegister } from "react-native-event-listeners";

const DraftSavedToast: React.FC = () => {
  const [visible, setVisible] = useState<boolean>(false);

  useEffect(() => {
    EventRegister.addEventListener("evidence/draft-saved", () => setVisible(true));

    return () => {
      EventRegister.removeEventListener("evidence/draft-saved");
    };
  }, []);

  return (
    <IonToast
      buttons={[{ text: "", role: "cancel" }]}
      cssClass="draft-toast"
      duration={2000}
      icon=""
      isOpen={visible}
      message="Draft saved"
      mode="ios"
      onDidDismiss={() => setVisible(false)}
      position="bottom"
    />
  );
};

export default DraftSavedToast;
