import { IonButton } from "@ionic/react";
import { IDashboardCard } from "../../Interfaces";
import "./HomeComponents.css";

const DashboardCard = ({
  title,
  subtitle,
  isProfile,
  inProgress,
  comingSoon,
  newFeature,
  sideButton,
  buttonClicked,
  sideButtonText,
  children,
}: IDashboardCard) => {
  const shouldOverflow = (value: string): boolean => {
    const array = ["Summary of Work & Personal Statement"];

    return array.includes(value) || typeof isProfile !== "undefined";
  };

  return (
    <div className="dashboardCard">
      <div className="dashboardHeader">
        <div className="dashboardCardTitleContainer">
          <div className="dashboardCardTitle">
            {title}
            {subtitle && <div className="dashboardCardSubtitle">{subtitle}</div>}
          </div>
          {newFeature && (
            <div
              className={`developmentProgressPill newFeaturePill ${shouldOverflow(title) ? "developmentProgressPillOverflow" : ""}`}
            >
              {"NEW"}
            </div>
          )}
          {inProgress && (
            <div
              className={`developmentProgressPill ${shouldOverflow(title) ? "developmentProgressPillOverflow" : ""}`}
            >
              {"IN PROGRESS"}
            </div>
          )}
          {comingSoon && (
            <div
              className={`developmentProgressPill ${shouldOverflow(title) ? "developmentProgressPillOverflow" : ""}`}
            >
              {"COMING SOON"}
            </div>
          )}
        </div>
        {sideButton && (
          <IonButton mode="ios" className="dashboardSideButton" onClick={() => buttonClicked?.()}>
            {sideButtonText || "Edit"}
          </IonButton>
        )}
      </div>
      <div className="dashboardCardText">{children}</div>
    </div>
  );
};

export default DashboardCard;
