import { useState } from "react";
import { EvidencePart } from "../../../Interfaces";
import "./EvidenceParts.css";
import { IonItem, IonLabel, IonRadio, IonRadioGroup } from "@ionic/react";
import { Text, View } from "@react-pdf/renderer";

type Props = {
  children?: React.ReactNode;
  style?: React.CSSProperties;
  definition: EvidencePart;
  onValueChanged?: (value: any) => void;
  data?: any;
  isDisplay?: boolean;
  isPDF?: boolean;
  pdfStyles: any;
  initialValue?: any;
};

const ConfidenceScale: React.FC<Props> = ({
  children,
  style,
  definition,
  onValueChanged,
  data,
  isDisplay,
  isPDF,
  pdfStyles,
  initialValue,
}) => {
  const [value, setValue] = useState<string>(initialValue || "");
  const listValues: string[] = [
    ...Array(5)
      .fill(1)
      .map((_, i) => `${i + 1}`),
  ];

  const valueChanged = (event: any): void => {
    const _value = event.detail.value;

    setValue(_value);
    onValueChanged && onValueChanged(_value);
  };

  if (isPDF) {
    return (
      <View>
        <Text style={pdfStyles.reflectionHeader}>{definition["Display Title"] || definition.Name}</Text>
        <Text style={pdfStyles.evidenceDate}>{data}</Text>
      </View>
    );
  }

  if (isDisplay) {
    return (
      <div className="readEvidenceBlock">
        <div className="readEvidenceHeader">{definition["Display Title"] || definition.Name}</div>
        <div className="readEvidenceText">{data}</div>
        <div className="h-[1px] w-full bg-grey-30 mt-4" />
      </div>
    );
  }

  return (
    <div>
      <IonRadioGroup color="light" id="editRoleRadioGroup" value={value} onIonChange={valueChanged}>
        {listValues.map((item, index) => {
          return (
            <IonItem className="evidencePartsRadioContainer" key={item}>
              <IonLabel className="evidencePartsRadioLabel">{item}</IonLabel>
              <IonRadio aria-label={item} mode="md" value={item} slot="end" />
            </IonItem>
          );
        })}
      </IonRadioGroup>
    </div>
  );
};

export default ConfidenceScale;
