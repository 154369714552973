import { useEffect, useState } from "react";
import { useHistory, withRouter } from "react-router";
import { useRecoilStateLoadable } from "recoil";
import { IUser, IAccessibilitySettings, IFontFamily } from "../../Interfaces";
import { accessibilityAtom, userAtom } from "../../state/State";
import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonImg,
  IonItem,
  IonLabel,
  IonPage,
  IonTitle,
  IonToggle,
  IonToolbar,
} from "@ionic/react";
import EvidenceContainer from "../../components/evidence/EvidenceContainer";
import DataController from "../../controllers/DataController";
import { EventRegister } from "react-native-event-listeners";
import AccessibilitySection from "../../components/profile/AccessibilitySection";
import { Preferences } from "@capacitor/preferences";
import { FirebaseService } from "../../controllers/FirebaseService";
import {
  Button_Line_Decrease,
  Button_Line_Increase,
  Button_Text_decrease,
  Button_Text_increase,
  Button_Tick,
} from "../../assets/images";
import {
  DEFAULT_FONT_SIZE,
  DEFAULT_LINE_HEIGHT,
  MAX_FONT_SIZE,
  MAX_LINE_HEIGHT,
  MIN_FONT_SIZE,
  MIN_LINE_HEIGHT,
} from "../../Constants";
import HeaderTitle from "../../components/common/HeaderTitle";
import { Capacitor } from "@capacitor/core";

const Accessibility: React.FC = (props) => {
  const history = useHistory();

  const [user, setUser] = useRecoilStateLoadable<IUser | null>(userAtom);
  const [accessibilitySettings, setAccessibilitySettings] = useRecoilStateLoadable<IAccessibilitySettings | null>(
    accessibilityAtom
  );

  useEffect(() => {
    EventRegister.emit("tab-bar/visibility-changed", false);

    return () => EventRegister.emit("tab-bar/visibility-changed", true);
  });

  const saveAccessibilitySetting = async (settings: IAccessibilitySettings, setting: string): Promise<void> => {
    try {
      await FirebaseService.logEvent("accessibility_setting_changed", {
        setting,
      });

      // console.log(settings);

      await Preferences.set({
        key: "accessibilitySettings",
        value: JSON.stringify(settings),
      });
      setAccessibilitySettings(settings);
    } catch (error) {
      console.log(error);
    }
  };

  const decreaseFontSize = async (): Promise<void> => {
    try {
      let fontSizeChange = accessibilitySettings.contents?.fontSizeChange;

      if (fontSizeChange && fontSizeChange > MIN_FONT_SIZE) {
        fontSizeChange = fontSizeChange - 1;
      } else if (!fontSizeChange) {
        fontSizeChange = -1;
      } else {
        return;
      }

      const settings: IAccessibilitySettings = {
        ...accessibilitySettings.contents,
        fontSizeChange,
      };

      await saveAccessibilitySetting(settings, "font_size");
    } catch (error) {
      console.log(error);
    }
  };

  const resetFontSize = async (): Promise<void> => {
    try {
      const settings: IAccessibilitySettings = {
        ...accessibilitySettings.contents,
        fontSizeChange: DEFAULT_FONT_SIZE,
      };

      await saveAccessibilitySetting(settings, "font_size");
    } catch (error) {
      console.log(error);
    }
  };

  const increaseFontSize = async (): Promise<void> => {
    try {
      let fontSizeChange = accessibilitySettings.contents?.fontSizeChange;

      if (fontSizeChange && fontSizeChange < MAX_FONT_SIZE) {
        fontSizeChange = fontSizeChange + 1;
      } else if (!fontSizeChange) {
        fontSizeChange = 1;
      } else {
        return;
      }

      const settings: IAccessibilitySettings = {
        ...accessibilitySettings.contents,
        fontSizeChange,
      };

      await saveAccessibilitySetting(settings, "font_size");
    } catch (error) {
      console.log(error);
    }
  };

  const decreaseLineHeight = async (): Promise<void> => {
    try {
      let lineHeightChange = accessibilitySettings.contents?.lineHeightChange;

      if (lineHeightChange && lineHeightChange > MIN_LINE_HEIGHT) {
        lineHeightChange = lineHeightChange - 0.5;
      } else if (!lineHeightChange) {
        lineHeightChange = 1;
      } else {
        return;
      }

      const settings: IAccessibilitySettings = {
        ...accessibilitySettings.contents,
        lineHeightChange,
      };

      await saveAccessibilitySetting(settings, "line_height");
    } catch (error) {
      console.log(error);
    }
  };

  const resetLineHeight = async (): Promise<void> => {
    try {
      const settings: IAccessibilitySettings = {
        ...accessibilitySettings.contents,
        lineHeightChange: DEFAULT_LINE_HEIGHT,
      };

      await saveAccessibilitySetting(settings, "line_height");
    } catch (error) {
      console.log(error);
    }
  };

  const increaseLineHeight = async (): Promise<void> => {
    try {
      let lineHeightChange = accessibilitySettings.contents?.lineHeightChange;

      if (lineHeightChange && lineHeightChange < MAX_LINE_HEIGHT) {
        lineHeightChange = lineHeightChange + 0.5;
      } else if (!lineHeightChange) {
        lineHeightChange = 2;
      } else {
        return;
      }

      const settings: IAccessibilitySettings = {
        ...accessibilitySettings.contents,
        lineHeightChange,
      };

      await saveAccessibilitySetting(settings, "line_height");
    } catch (error) {
      console.log(error);
    }
  };

  const changeFontFamily = async (fontFamily: string): Promise<void> => {
    try {
      const settings: IAccessibilitySettings = {
        ...accessibilitySettings.contents,
        fontFamily,
      };

      await saveAccessibilitySetting(settings, "font_family");
    } catch (error) {
      console.log(error);
    }
  };

  const toggleTextToSpeech = async (): Promise<void> => {
    try {
      const settings: IAccessibilitySettings = {
        ...accessibilitySettings.contents,
        textToSpeech: accessibilitySettings.contents?.textToSpeech ? false : true,
      };

      await saveAccessibilitySetting(settings, "text_to_speech");
    } catch (error) {
      console.log(error);
    }
  };

  const toggleUnderlineButtons = async (): Promise<void> => {
    try {
      const settings: IAccessibilitySettings = {
        ...accessibilitySettings.contents,
        underlineButtons: accessibilitySettings.contents?.underlineButtons ? false : true,
      };

      await saveAccessibilitySetting(settings, "underline_buttons");
    } catch (error) {
      console.log(error);
    }
  };

  const toggleBoldText = async (): Promise<void> => {
    try {
      const settings: IAccessibilitySettings = {
        ...accessibilitySettings.contents,
        boldText: accessibilitySettings.contents?.boldText ? false : true,
      };

      await saveAccessibilitySetting(settings, "bold_text");
    } catch (error) {
      console.log(error);
    }
  };

  const fontSizeUnset = (): boolean => {
    return typeof accessibilitySettings.contents?.fontSizeChange === "undefined";
  };

  const lineHeightUnset = (): boolean => {
    return typeof accessibilitySettings.contents?.lineHeightChange === "undefined";
  };

  const fontFamilyUnset = (): boolean => {
    return typeof accessibilitySettings.contents?.fontFamily === "undefined";
  };

  const getFontSizePercentage = (): number => {
    let fontSizeChange = accessibilitySettings.contents?.fontSizeChange || 0;

    return 100 + fontSizeChange * 10;
  };

  const getLineHeightPercentage = (): number => {
    let lineHeightChange = accessibilitySettings.contents?.lineHeightChange || 1.5;

    switch (lineHeightChange) {
      case 1:
        return 66;
      case 1.5:
        return 100;
      case 2:
        return 133;
      case 2.5:
        return 166;
      case 3:
        return 200;
      default:
        return 100;
    }
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar
          mode="ios"
          className="navBar"
          style={{
            maxWidth: DataController.isWeb() ? 980 : undefined,
            height: Capacitor.getPlatform() === "android" ? "54px" : "unset",
          }}
        >
          <IonButtons slot="start">
            <IonBackButton
              className="header-back-buttons"
              defaultHref="/profile"
              text={DataController.getBackIconText()}
              icon={DataController.getBackIconType()}
              style={{
                marginLeft: Capacitor.getPlatform() === "android" ? 8 : 0,
                "--icon-font-size": Capacitor.getPlatform() === "android" ? "24px" : "30px",
              }}
            />
          </IonButtons>
          <HeaderTitle>{"Accessibility"}</HeaderTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className="page-background">
        <EvidenceContainer style={{ paddingTop: 0 }}>
          <div className="accessibilityTextPreviewContainer">
            <div className="accessibilityTextPreviewContainerInner">
              <div className="accessibilityTextPreviewText">
                {
                  "This text is to show the current text size you have set. Use the options above to increase or decrease the text size, as well as the line height."
                }
              </div>
            </div>
          </div>
          <AccessibilitySection title="Text options">
            <div className="accessibilitySectionButtonsRow">
              <IonItem
                button
                detail={false}
                className="accessibilitySectionPercentageButton"
                onClick={() => decreaseFontSize()}
              >
                <div className="accessibilitySectionPercentageButtonInner">
                  <IonImg src={Button_Text_decrease} className="accessibilitySectionPercentageIcon" />
                </div>
              </IonItem>
              <IonItem
                button
                detail={false}
                disabled={accessibilitySettings.contents?.fontSizeChange === DEFAULT_FONT_SIZE || fontSizeUnset()}
                className="accessibilitySectionPercentageButtonReset"
                onClick={() => resetFontSize()}
              >
                <div className="accessibilitySectionPercentageButtonInner">
                  {accessibilitySettings.contents?.fontSizeChange === DEFAULT_FONT_SIZE || fontSizeUnset() ? (
                    <div className="accessibilitySectionPercentageTextPlaceholder">{`${getFontSizePercentage()}%`}</div>
                  ) : (
                    <>
                      <div className="accessibilitySectionPercentageText">{`${getFontSizePercentage()}%`}</div>
                      <div className="accessibilitySectionPercentageTextReset">{"Reset"}</div>
                    </>
                  )}
                </div>
              </IonItem>
              <IonItem
                button
                detail={false}
                className="accessibilitySectionPercentageButton"
                onClick={() => increaseFontSize()}
              >
                <div className="accessibilitySectionPercentageButtonInner">
                  <IonImg src={Button_Text_increase} className="accessibilitySectionPercentageIcon" />
                </div>
              </IonItem>
            </div>
            <div className="accessibilitySectionButtonsRow">
              <IonItem
                button
                detail={false}
                className="accessibilitySectionPercentageButton"
                onClick={() => decreaseLineHeight()}
              >
                <div className="accessibilitySectionPercentageButtonInner">
                  <IonImg src={Button_Line_Decrease} className="accessibilitySectionPercentageIcon" />
                </div>
              </IonItem>
              <IonItem
                button
                detail={false}
                disabled={accessibilitySettings.contents?.lineHeightChange === DEFAULT_LINE_HEIGHT || lineHeightUnset()}
                className="accessibilitySectionPercentageButtonReset"
                onClick={() => resetLineHeight()}
              >
                <div className="accessibilitySectionPercentageButtonInner">
                  {accessibilitySettings.contents?.lineHeightChange === DEFAULT_LINE_HEIGHT || lineHeightUnset() ? (
                    <div className="accessibilitySectionPercentageTextPlaceholder">{`${getLineHeightPercentage()}%`}</div>
                  ) : (
                    <>
                      <div className="accessibilitySectionPercentageText">{`${getLineHeightPercentage()}%`}</div>
                      <div className="accessibilitySectionPercentageTextReset">{"Reset"}</div>
                    </>
                  )}
                </div>
              </IonItem>
              <IonItem
                button
                detail={false}
                className="accessibilitySectionPercentageButton"
                onClick={() => increaseLineHeight()}
              >
                <div className="accessibilitySectionPercentageButtonInner">
                  <IonImg src={Button_Line_Increase} className="accessibilitySectionPercentageIcon" />
                </div>
              </IonItem>
            </div>
            <IonItem button onClick={() => toggleBoldText()} className="accessibilitySectionButton" detail={false}>
              <div className="accessibilitySectionButtonContainer">
                <div className="accessibilitySectionButtonInnerContainer">
                  <IonLabel className="accessibilitySectionButtonTitle">{"Bold text"}</IonLabel>
                  <IonToggle
                    color="success"
                    checked={accessibilitySettings.contents?.boldText}
                    mode="ios"
                    aria-label="Bold text"
                    onClick={() => toggleBoldText()}
                  />
                </div>
              </div>
            </IonItem>
          </AccessibilitySection>
          <AccessibilitySection title="Fonts">
            <IonItem
              button
              onClick={() => changeFontFamily("Inter")}
              disabled={accessibilitySettings.contents?.fontFamily === IFontFamily.INTER || fontFamilyUnset()}
              className="accessibilitySectionButton"
              detail={false}
            >
              <div className="accessibilitySectionButtonContainer">
                <div className="accessibilitySectionButtonInnerContainer">
                  <IonLabel className="accessibilitySectionButtonTitleInter">{"Inter"}</IonLabel>
                  {(accessibilitySettings.contents?.fontFamily === IFontFamily.INTER || fontFamilyUnset()) && (
                    <IonImg src={Button_Tick} className="accessibilitySettingsTick" />
                  )}
                </div>
              </div>
            </IonItem>
            <IonItem
              button
              disabled={accessibilitySettings.contents?.fontFamily === IFontFamily.OPEN_DYSLEXIC}
              onClick={() => changeFontFamily("Open Dyslexic")}
              className="accessibilitySectionButton"
              detail={false}
            >
              <div className="accessibilitySectionButtonContainer">
                <div className="accessibilitySectionButtonInnerContainer">
                  <IonLabel className="accessibilityFontFamilyDyslexic">{"OpenDyslexic"}</IonLabel>
                  {accessibilitySettings.contents?.fontFamily === IFontFamily.OPEN_DYSLEXIC && (
                    <IonImg src={Button_Tick} className="accessibilitySettingsTick" />
                  )}
                </div>
              </div>
            </IonItem>
          </AccessibilitySection>
          <AccessibilitySection title="Text-to-speech">
            <IonItem button onClick={() => toggleTextToSpeech()} className="accessibilitySectionButton" detail={false}>
              <div className="accessibilitySectionButtonContainer">
                <div className="accessibilitySectionButtonInnerContainer">
                  <IonLabel className="accessibilitySectionButtonTitle">{"Show text-to-speech"}</IonLabel>
                  <IonToggle
                    color="success"
                    checked={accessibilitySettings.contents?.textToSpeech}
                    mode="ios"
                    aria-label="Show text-to-speech"
                    onClick={() => toggleTextToSpeech()}
                  />
                </div>
              </div>
            </IonItem>
          </AccessibilitySection>
          <AccessibilitySection title="Appearance">
            <IonItem
              button
              onClick={() => toggleUnderlineButtons()}
              className="accessibilitySectionButton"
              detail={false}
            >
              <div className="accessibilitySectionButtonContainer">
                <div className="accessibilitySectionButtonInnerContainer">
                  <IonLabel className="accessibilitySectionButtonTitle">{"Underline Buttons"}</IonLabel>
                  <IonToggle
                    color="success"
                    checked={accessibilitySettings.contents?.underlineButtons}
                    mode="ios"
                    aria-label="Underline Buttons"
                    onClick={() => toggleUnderlineButtons()}
                  />
                </div>
              </div>
            </IonItem>
          </AccessibilitySection>
        </EvidenceContainer>
      </IonContent>
    </IonPage>
  );
};

export default withRouter(Accessibility);
