import {
  IClassSubscription,
  IEvidence,
  INOSSelectCompetence,
  IUserProgramCertificate,
  ProgramCertificate,
  ProgramData,
  SubmissionState,
  UsageData,
} from "../Interfaces";

export function displayUserRegions(programID: string): boolean {
  return ["LNA4FTCKB012M"].includes(programID);
}

export function getUserRegions(regions: string[] | undefined): string {
  if (regions) {
    return regions.join(", ");
  }

  return "None";
}

export function displayUserESRNumber(programID: string): boolean {
  return ["LNA4FTCKB012M", "LNA4FTCKB003M"].includes(programID);
}

export function getCertificateHeader(
  certificate: IUserProgramCertificate,
  programCertificate?: ProgramCertificate,
  program?: ProgramData
): string {
  if (program?.ID === "LNLT7JSYDT5PJ")
    if (programCertificate?.Description) {
      return programCertificate.Description + " certificate";
    }

  if (program?.ID === "LR0HYZYWY6JQK") {
    return "Supporting Evidence";
  }

  if (certificate.programData.competenceName) {
    return certificate.programData.competenceName + " certificate";
  }

  if (certificate.programData.certificateType) {
    return certificate.programData.certificateType;
  }

  return "Certificate";
}

export function getCompliancePercentage(id: string, usageData: UsageData[] | undefined): string {
  const usage = usageData?.find((item) => item.compId === id);
  const result = usage ? Number(usage.percentage) : 0;

  return `${Math.floor(result)}%`;
}

export function getEvidenceCountForStandard(id: string, allEvidence: IEvidence[]): number {
  let result = 0;

  const length = allEvidence.length;

  for (let i = 0; i < length; i++) {
    try {
      let evidence = allEvidence[i];
      const evidenceJSON = evidence.evidenceJSON && JSON.parse(evidence.evidenceJSON);

      if (Array.from(evidenceJSON.Comps).some((item: any): item is INOSSelectCompetence => item.id === id)) {
        result += 1;
      }
    } catch (error) {}
  }

  return result;
}

export function getUserESRNumber(
  subscriptions: IClassSubscription[] | undefined,
  programSubscriptionIDs: string[] | undefined
): string {
  if (programSubscriptionIDs && subscriptions) {
    const programSubscription = subscriptions.find((item: IClassSubscription) => {
      const subscriptionPrograms: string[] = item.ParaFolioPrograms ?? [];

      return (
        subscriptionPrograms?.some((_sub) => programSubscriptionIDs.includes(_sub)) ||
        programSubscriptionIDs.includes(`${item.SubscriptionID}`)
      );
    });

    if (programSubscription?.ESR) {
      return programSubscription.ESR;
    }
  }

  return "N/A";
}

export function displayMentors(): boolean {
  return false;
}

export function getMentors(): string {
  return "None";
}

export function showApprovedByText(status: string | undefined): boolean {
  return (
    status === SubmissionState.Approved || status === SubmissionState.OnHold || status === SubmissionState.OnHoldReset
  );
}
