export namespace SubscriptionUtils {
  export function getSubscriptionPurchaseText(productID: string): string {
    if (productID.includes("1month")) {
      return "a Monthly Subscription";
    } else if (productID.includes("12month")) {
      return "an Annual Subscription";
    } else {
      return "a Monthly Subscription";
    }
  }
}
