import { useEffect, useState } from "react";
import { IEvidence, StandardTag } from "../../Interfaces";
import { isAfter, subDays, subYears } from "date-fns";
import _ from "lodash";

import "./HomeComponents.css";

type StandardsBlock = {
  standards: StandardTag[];
  evidence: IEvidence[];
  title: string;
};

const NOSStandardsGraph = ({ standards, evidence, title }: StandardsBlock) => {
  const [numberOfStandards, setNumberOfStandards] = useState<number>(standards.length);
  const [completedStandards, setCompletedStandards] = useState<number>(0);

  const minDate = subDays(subYears(new Date(), 2), 1);

  useEffect(() => {
    const evidenceWithinLimit = evidence.filter((item) => {
      return isAfter(new Date(item.date), minDate);
    });

    const standardTagCodes = standards.map((item) => item.Code);
    const evidenceWithStandardsForRole = evidenceWithinLimit.filter((item) => {
      let tags = item.standardTags?.map((item) => item.tag) || [];
      tags = tags.filter((item) => standardTagCodes.includes(item));

      return tags.length > 0;
    });

    const tagsCompleted = evidenceWithStandardsForRole.flatMap((item) => item.standardTags?.map((tag) => tag.tag));
    const uniqueTags = _.uniq(tagsCompleted);

    let totalCompleted = uniqueTags.filter((tag) => tagsCompleted.filter((item) => item === tag).length >= 3);

    totalCompleted = totalCompleted.filter((tag) => standardTagCodes.includes(tag));

    setCompletedStandards(totalCompleted.length);
    setNumberOfStandards(standards.length);
  }, [evidence, minDate, standards]);

  const array = Array.from(Array(numberOfStandards).keys());

  return (
    <div>
      <div className="completedStandardsContainer" style={{ marginTop: 12 }}>
        <div className="overviewStandardsHeader">
          {title}
          <div className="completedStandardsCount">
            <div className="completedStandards">{`${completedStandards}`}</div>
            <div>&nbsp;</div>
            <div className="totalStandards">{` / ${numberOfStandards}`}</div>
          </div>
        </div>
        <div className="completedStandardsGraphContainer">
          {array.map((value, index) => {
            const isEnd = index === array.length - 1;
            const completed = index < completedStandards;

            return (
              <div className="graphSegmentContainer" key={index}>
                <div className={`${completed ? "graphFilledSegment" : "graphEmptySegment"}`} />
                {!isEnd && <div className="graphSeparator" />}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default NOSStandardsGraph;
