import { IonImg, IonItem, IonLabel } from "@ionic/react";
import { IMenuButton } from "../../Interfaces";
import "./MenuComponents.css";
import { Button_Disclosure_Right } from "../../assets/images";

const MenuButton = ({ icon, title, buttonClicked, syncingData, showDisclosure }: IMenuButton) => {
  return (
    <IonItem button={!syncingData} onClick={() => buttonClicked?.()} className="menuItemButton" detail={false}>
      <div className="menuItemButtonContainer">
        <div className="menuItemButtonInnerContainer">
          <IonImg src={icon} className={`menuItemButtonIcon ${syncingData ? "menuItemIconRotate" : ""}`} />
          <IonLabel className="menuItemButtonTitle">{title}</IonLabel>
          {showDisclosure && <IonImg src={Button_Disclosure_Right} className="menuItemButtonDisclosure" />}
        </div>
      </div>
    </IonItem>
  );
};

export default MenuButton;
