import "../../App.css";
import { IEvidenceCard } from "../../Interfaces";
import "./CommonComponents.css";

const EvidenceContentCard: React.FC<IEvidenceCard> = (props) => {
  return (
    <div className="reflectionContentCard" id={props.id}>
      <div className="reflectionContentCardTitle">
        {props.title}
        {props.mandatory && " (Mandatory)"}
      </div>
      {props.children}
    </div>
  );
};

export default EvidenceContentCard;
