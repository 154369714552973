import { StyleSheet } from "@react-pdf/renderer";

export const htmlStyles = {
  p: {
    fontFamily: "Inter",
    marginTop: 8,
    marginBottom: 8,
  },
  b: {
    fontFamily: "Inter",
    fontWeight: 700,
  },
  i: {
    fontFamily: "Inter",
    fontStyle: "italic",
  },
  em: {
    fontFamily: "Inter",
    fontStyle: "italic",
  },
  strong: {
    fontFamily: "Inter",
    fontWeight: 700,
  },
};

export const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    backgroundColor: "#FFFFFF",
    paddingTop: 87,
    paddingHorizontal: 35,
    paddingBottom: 75,
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
  heading: {
    backgroundColor: "#026F8A",
    paddingVertical: 8,
    paddingHorizontal: 35,
    position: "absolute",
    top: 35,
  },
  headingText: {
    fontFamily: "Inter",
    fontSize: 16,
    fontWeight: 800,
    letterSpacing: -0.02,
    color: "#FFFFFF",
  },
  pageHeader: {
    position: "absolute",
    flexDirection: "row",
    paddingHorizontal: 35,
    top: 47,
    right: 0,
  },
  pageHeaderText: {
    fontFamily: "Inter",
    fontSize: 12,
    fontWeight: 400,
    letterSpacing: -0.1,
    color: "#212529",
  },
  pageNumber: {
    fontFamily: "Inter",
    fontSize: 12,
    fontWeight: 400,
    letterSpacing: -0.1,
    color: "#212529",
    marginLeft: 18,
  },
  titlePageContainer: {
    flexGrow: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  titlePageName: {
    fontFamily: "Inter",
    fontSize: 28,
    fontWeight: 600,
    letterSpacing: -0.4,
    color: "#000000",
    textAlign: "center",
    marginBottom: 6,
  },
  titlePageRole: {
    fontFamily: "Inter",
    fontSize: 20,
    fontWeight: 400,
    letterSpacing: -0.3,
    color: "#000000",
    textAlign: "center",
    marginBottom: 40,
  },
  titlePageTitle: {
    fontFamily: "Inter",
    fontSize: 34,
    fontWeight: 600,
    letterSpacing: -0.5,
    lineHeight: 1.294,
    color: "#000000",
    textAlign: "center",
  },
  pageInnerContainer: {
    flexGrow: 1,
    overflow: "hidden",
  },
  standardsHeaderOverview: {
    fontFamily: "Inter",
    fontSize: 22,
    fontWeight: 600,
    letterSpacing: -0.3,
    color: "#000000",
    marginBottom: 4,
  },
  standardsHeaderDescription: {
    fontFamily: "Inter",
    fontSize: 12,
    fontWeight: 400,
    letterSpacing: -0.1,
    color: "#000000",
  },
  standardsTableTitle: {
    fontFamily: "Inter",
    fontSize: 15,
    fontWeight: 600,
    letterSpacing: -0.2,
    color: "#000000",
    marginTop: 14,
    marginBottom: 8,
  },
  standardsTableHeader: {
    minHeight: 28,
    backgroundColor: "#F1F3F5",
    borderColor: "#CED4DA",
    borderWidth: 1,
    flexDirection: "row",
  },
  standardsTableRow: {
    minHeight: 28,
    borderColor: "#CED4DA",
    borderLeftWidth: 1,
    borderRightWidth: 1,
    borderBottomWidth: 1,
    flexDirection: "row",
  },
  standardsTableRowCode: {
    width: 90,
    borderColor: "#CED4DA",
    borderRightWidth: 1,
    justifyContent: "center",
    padding: 6,
  },
  standardsTableRowDescription: {
    width: 294,
    borderColor: "#CED4DA",
    borderRightWidth: 1,
    justifyContent: "center",
    padding: 6,
  },
  standardsTableRowComplianceDescription: {
    width: 264,
    borderColor: "#CED4DA",
    borderRightWidth: 1,
    justifyContent: "center",
    padding: 6,
  },
  standardsTableRowEvidence: {
    width: 70,
    borderColor: "#CED4DA",
    borderRightWidth: 1,
    justifyContent: "center",
    padding: 6,
  },
  standardsTableRowCompliance: {
    width: 100,
    borderColor: "#CED4DA",
    borderRightWidth: 1,
    justifyContent: "center",
    padding: 6,
  },
  standardsTableRowAchieved: {
    width: 70,
    borderColor: "#CED4DA",
    borderRightWidth: 1,
    justifyContent: "center",
    padding: 6,
  },
  standardsTableHeaderBold: {
    fontFamily: "Inter",
    fontSize: 12,
    fontWeight: 600,
    letterSpacing: -0.2,
    color: "#212529",
  },
  standardsTableText: {
    fontFamily: "Inter",
    fontSize: 12,
    fontWeight: 400,
    letterSpacing: -0.1,
    color: "#212529",
  },
  separator: {
    width: "100%",
    height: 1,
    backgroundColor: "#CED4DA",
    marginVertical: 14,
  },
  evidenceTitle: {
    fontFamily: "Inter",
    fontSize: 17,
    fontWeight: 600,
    letterSpacing: -0.2,
    color: "#212529",
    marginBottom: 6,
  },
  evidenceDateRow: {
    flexDirection: "row",
    marginBottom: 4,
  },
  evidenceDateBold: {
    fontFamily: "Inter",
    width: 120,
    fontSize: 12,
    fontWeight: 600,
    color: "#212529",
    marginRight: 16,
  },
  boldText: {
    fontFamily: "Inter",
    fontSize: 12,
    fontWeight: 600,
    color: "#212529",
  },
  normalText: {
    fontFamily: "Inter",
    fontSize: 12,
    fontWeight: 400,
    letterSpacing: -0.1,
    color: "#212529",
  },
  evidenceDate: {
    maxWidth: 400,
    fontFamily: "Inter",
    fontSize: 12,
    fontWeight: 400,
    letterSpacing: -0.1,
    color: "#212529",
  },
  detailsInfo: {
    fontFamily: "Inter",
    fontSize: 12,
    fontWeight: 400,
    letterSpacing: -0.1,
    color: "#212529",
    marginBottom: 4,
  },
  reflectionHeader: {
    fontFamily: "Inter",
    fontSize: 12,
    fontWeight: 600,
    color: "#212529",
    marginTop: 12,
    marginBottom: 3,
  },
  boldHeader: {
    fontFamily: "Inter",
    fontSize: 12,
    fontWeight: 600,
    color: "#212529",
  },
  certificateDate: {
    flexDirection: "row",
    marginTop: 8,
  },
  hyperlink: {
    fontFamily: "Inter",
    fontSize: 12,
    fontWeight: 600,
    color: "#007AFF",
    marginTop: 4,
  },
  imageContainer: {
    borderWidth: 1,
    borderColor: "#ADB5BD",
    borderStyle: "solid",
    flexShrink: -1,
    display: "flex",
    flexDirection: "column",
    margin: 0,
    padding: 0,
    maxWidth: "100%",
    alignSelf: "center",
  },
  image: {
    objectFit: "contain",
  },
});
