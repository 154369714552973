import { useEffect, useState } from "react";
import { useRecoilValueLoadable } from "recoil";
import { IEvidence, IUser } from "../../Interfaces";
import { evidenceAtom, userAtom } from "../../state/State";
import { IonBackButton, IonButtons, IonContent, IonHeader, IonPage, IonToolbar } from "@ionic/react";
import DataController from "../../controllers/DataController";
import EvidenceContainer from "../../components/evidence/EvidenceContainer";
import ProgramInfoCard from "../../components/home/ProgramInfoCard";
import NOSStandardsGraph from "../../components/home/NOSStandardsGraph";
import GuidelineCard from "../../components/guidelines/GuidelineCard";
import NOSStandardsBreakdown from "../../components/home/NOSStandardsBreakdown";
import HeaderTitle from "../../components/common/HeaderTitle";
import { Capacitor } from "@capacitor/core";
import * as ProgramUtils from "../../utils/programUtils";

const NOSOverview: React.FC = (props) => {
  const user = useRecoilValueLoadable<IUser | null>(userAtom);
  const evidence = useRecoilValueLoadable<IEvidence[] | null>(evidenceAtom);

  const [optionalStandards, setOptionalStandards] = useState<any[] | undefined>([]);
  const [mandatoryStandards, setMandatoryStandards] = useState<any[] | undefined>([]);

  useEffect(() => {
    const getStandardTagsForRole = (_user: IUser): void => {
      if (_user && _user.role) {
        const standards = ProgramUtils.getNOSStandardTagsForRole(_user.role);

        setMandatoryStandards(standards.mandatory);
        setOptionalStandards(standards.optional);
      }
    };

    if (user.state === "hasValue" && user.contents) {
      getStandardTagsForRole(user.contents);
    }
  }, [user]);

  let nonDraftEvidence: IEvidence[] = evidence.contents ?? [];
  nonDraftEvidence = nonDraftEvidence.filter((item) => !item.draft);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar
          mode="ios"
          className="navBar"
          style={{
            maxWidth: DataController.isWeb() ? 980 : undefined,
            height: Capacitor.getPlatform() === "android" ? "54px" : "unset",
          }}
        >
          <IonButtons slot="start">
            <IonBackButton
              className="header-back-buttons"
              defaultHref="/dashboard"
              text={DataController.getBackIconText()}
              icon={DataController.getBackIconType()}
              style={{
                marginLeft: Capacitor.getPlatform() === "android" ? 8 : 0,
                "--icon-font-size": Capacitor.getPlatform() === "android" ? "24px" : "30px",
              }}
            />
          </IonButtons>
          <HeaderTitle>{"NOS Overview"}</HeaderTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className="page-background">
        <EvidenceContainer style={{ paddingTop: 0 }}>
          <ProgramInfoCard title="NOS Overview">
            <div className="nosCardDetails">
              {`As ${DataController.getIndefiniteArticle(user.contents?.role || "")} ${user.contents?.role}, `}
              {mandatoryStandards &&
                mandatoryStandards.length > 0 &&
                ` you are required to achieve ${mandatoryStandards.length} of National Occupational Standards set out by NHS England and the National Ambulance Resilience Unit (NARU) in line with the Skills for Justice (SFJ).`}
              {optionalStandards && optionalStandards.length > 0
                ? mandatoryStandards && mandatoryStandards.length > 0
                  ? ` There ${optionalStandards.length === 1 ? "is" : "are"} also ${optionalStandards.length} optional standard${optionalStandards.length === 1 ? "" : "s"} that you can meet.`
                  : ` there ${optionalStandards?.length === 1 ? "is" : "are"} ${optionalStandards?.length} optional standard${optionalStandards?.length === 1 ? "" : "s"} that you can meet. `
                : ""}
            </div>
            {mandatoryStandards && mandatoryStandards.length > 0 && (
              <NOSStandardsGraph
                title="Mandatory Standards"
                standards={mandatoryStandards}
                evidence={nonDraftEvidence}
              />
            )}
            {optionalStandards && optionalStandards?.length > 0 && (
              <NOSStandardsGraph title="Optional Standards" standards={optionalStandards} evidence={nonDraftEvidence} />
            )}
          </ProgramInfoCard>
          {mandatoryStandards && mandatoryStandards.length > 0 && (
            <ProgramInfoCard title="Mandatory Standards">
              <NOSStandardsBreakdown standards={mandatoryStandards} evidence={nonDraftEvidence} />
            </ProgramInfoCard>
          )}
          {optionalStandards && optionalStandards.length > 0 && (
            <ProgramInfoCard title="Optional Standards">
              <NOSStandardsBreakdown standards={optionalStandards} evidence={nonDraftEvidence} />
            </ProgramInfoCard>
          )}
          <ProgramInfoCard title="Special Situation Guidelines" style={{ paddingBottom: 0 }}>
            <div className="nosCardDetails">
              {"Need a starting point for your evidence? The below JRCALC Special Situation guidelines can act as a starting " +
                "point. Read through the guidelines in JRCALC Plus or take a quiz in ParaPass and reflect on what you've learnt."}
            </div>
            <GuidelineCard title="Atropine for CBRNE" GLID="S0030" />
            <GuidelineCard
              title="Chemical Biological Radiological Nuclear (CBRN) Incidents Including Hazardous Materials"
              GLID="G0810"
            />
            <GuidelineCard title="Major, Complex and High-Risk Incidents" GLID="S0020" />
            <GuidelineCard title="Police Incapacitants" GLID="S0100" />
          </ProgramInfoCard>
          <ProgramInfoCard title="SWAST Update">
            <p>
              This is where you will see latest news from your trust on upcoming training events and other useful
              updates. There are no updates at the moment.
            </p>
          </ProgramInfoCard>
        </EvidenceContainer>
      </IonContent>
    </IonPage>
  );
};

export default NOSOverview;
