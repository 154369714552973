import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonImg,
  IonItem,
  IonModal,
  IonSpinner,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { EventRegister } from "react-native-event-listeners";
import { useHistory } from "react-router";
import { useRecoilState, useRecoilStateLoadable, useRecoilValue } from "recoil";
import { IAppFeatures, IEvidence, IExportType, IUser, ProgramData, UsageData } from "../../Interfaces";
import { appFeaturesAtom, evidenceAtom, userAtom, userProgramsAtom } from "../../state/State";
import _ from "lodash";
import { Icon_Export_Blue } from "../../assets/images";
import { format } from "date-fns";
import DataController from "../../controllers/DataController";
import { useCallback, useRef, useState } from "react";
import { Document, pdf } from "@react-pdf/renderer";
import ExportedPDF from "../pdfExport/ExportedPDF";
import { FirebaseService } from "../../controllers/FirebaseService";
import { saveAs } from "file-saver";
import { Directory } from "@capacitor/filesystem";
import WriteBlob from "capacitor-blob-writer";
import { FileOpener } from "@capacitor-community/file-opener";
import * as ProgramUtils from "../../utils/programUtils";
import { PMUsageData, ProgramModule } from "pf-support-lib";

type Props = {
  personalStatementPressed: () => void;
  summaryOfWorkPressed: () => void;
};

const HCPCProfileCard: React.FC<Props> = ({ personalStatementPressed, summaryOfWorkPressed }) => {
  const [user, setUser] = useRecoilStateLoadable<IUser | null>(userAtom);
  const [evidence, setEvidence] = useRecoilStateLoadable<IEvidence[] | null>(evidenceAtom);
  const [appFeatures, setAppFeatures] = useRecoilState<IAppFeatures[] | string[]>(appFeaturesAtom);

  const filenameRef = useRef<HTMLInputElement>(null);

  const [exporting, setExporting] = useState<boolean>(false);
  const [defaultFilename, setDefaultFilename] = useState<string>(format(new Date(), "dd-MM-yyyy"));
  const [currentExportType, setCurrentExportType] = useState<string>("");
  const [currentExportProgram, setCurrentExportTypeProgram] = useState<string>("");
  const [filename, setFilename] = useState<string>(defaultFilename);
  const [exportVisible, setExportVisible] = useState<boolean>(false);

  const userPrograms = useRecoilValue<ProgramData[] | any[]>(userProgramsAtom);

  const summaryOfWorkClicked = (): void => {
    summaryOfWorkPressed();
  };

  const personalStatementClicked = (): void => {
    personalStatementPressed();
  };

  const addEvidence = async (): Promise<void> => {
    EventRegister.emit("evidence/empty-add-clicked");
  };

  const evidenceClicked = (): void => {
    EventRegister.emit("evidence/filter-hcpc");
  };

  const hasSummaryOfWork = (): boolean => {
    let value = false;

    if (user.state === "hasValue" && user.contents) {
      value = typeof user.contents.summaryOfWork !== "undefined";
    }

    return value;
  };

  const hasPersonalStatement = (): boolean => {
    let value = false;

    if (user.state === "hasValue" && user.contents) {
      value = typeof user.contents.personalStatement !== "undefined";
    }

    return value;
  };

  const hasEvidenceWithHCPC = (): boolean => {
    const _evidence: IEvidence[] = _.cloneDeep(evidence.contents) || [];
    const HCPC = _evidence.filter((item: IEvidence) => {
      const evidenceJSON = item.evidenceJSON && JSON.parse(item.evidenceJSON);
      const onHoldReset = evidenceJSON?.OnHoldReset ?? 0;

      return item.addToHCPCAudit && onHoldReset !== 1 && item.draft !== true;
    });

    return HCPC.length > 0;
  };

  const getHCPCCount = (): number => {
    const _evidence: IEvidence[] = _.cloneDeep(evidence.contents) || [];
    const HCPC = _evidence.filter((item: IEvidence) => {
      const evidenceJSON = item.evidenceJSON && JSON.parse(item.evidenceJSON);
      const onHoldReset = evidenceJSON?.OnHoldReset ?? 0;

      return item.addToHCPCAudit && onHoldReset !== 1 && item.draft !== true;
    });

    return HCPC.length;
  };

  const getLastEditedSummaryOfWork = (): string => {
    let value = "";

    if (user.state === "hasValue" && user.contents && user.contents.summaryOfWorkTimestamp) {
      let date = new Date(user.contents.summaryOfWorkTimestamp) || new Date();
      // date = new Date(date.getTime() + (date.getTimezoneOffset() * 60000));
      const dateText = format(date, "d MMM yyyy");
      const timeText = format(date, "h:mmaaa");

      value = `Last edited ${dateText} at ${timeText} · `;
    }

    return value;
  };

  const getLastEditedPersonalStatement = (): string => {
    let value = "";

    if (user.state === "hasValue" && user.contents && user.contents.personalStatementTimestamp) {
      let date = new Date(user.contents.personalStatementTimestamp) || new Date();

      const dateText = format(date, "d MMM yyyy");
      const timeText = format(date, "h:mmaaa");

      value = `Last edited ${dateText} at ${timeText} · `;
    }

    return value;
  };

  const logDocumentExport = async (pages: number): Promise<void> => {
    await FirebaseService.logEvent("hcpc_profile_exported", { pages });
  };

  const generatePdfDocument = useCallback(
    async (exportType: string, program?: ProgramData): Promise<void> => {
      try {
        const doc = pdf();

        let evidenceToExport: IEvidence[] = _.cloneDeep(evidence.contents) ?? [];
        let usageData: UsageData[] | undefined;

        if (exportType === IExportType.MAJOR_INCIDENT) {
          // Everything tagged with NOS standards - include table
          let copy = _.cloneDeep(evidenceToExport);
          copy = copy.filter((item) => item.standardTags && item.standardTags?.length > 0);

          evidenceToExport = _.sortBy(copy, (item) => new Date(item.date).getTime()).reverse();
        } else if (exportType === IExportType.PROGRAM) {
          // All evidence for the specific program selected - e.g. HART, CHUB, etc.
          let copy = _.cloneDeep(evidenceToExport);
          copy = copy.filter((item) => {
            const evidenceJSON = item.evidenceJSON && JSON.parse(item.evidenceJSON);
            const programName = evidenceJSON?.programInfo?.program || "";

            return programName === program?.Name;
          });

          evidenceToExport = _.sortBy(copy, (item) => new Date(item.date).getTime()).reverse();
        } else if (exportType === IExportType.HCPC) {
          // Everything tagged as HCPC - include personal statement and summary of work if exists
          let copy = _.cloneDeep(evidenceToExport);
          copy = copy.filter((item) => item.addToHCPCAudit);

          evidenceToExport = _.sortBy(copy, (item) => new Date(item.date).getTime()).reverse();
        } else if (exportType === IExportType.GENERAL) {
          // Everything currently visible on Evidence tab - don't include table or statements
          evidenceToExport = _.sortBy(_.cloneDeep(evidenceToExport), (item) => new Date(item.date).getTime()).reverse();
        }

        evidenceToExport = evidenceToExport.filter((item: IEvidence) => {
          const evidenceJSON = item.evidenceJSON && JSON.parse(item.evidenceJSON);
          const onHoldReset = evidenceJSON?.OnHoldReset ?? 0;

          return onHoldReset !== 1 && item.draft !== true;
        });

        if (program && ProgramUtils.shouldCalculateUsageData(program.ID)) {
          const pm = new ProgramModule(program);

          const contactID = Number(user.contents?.userData.contactID);
          const roleID = ProgramUtils.getSelectedRoleIdForNWASICProgram(user.contents);

          const pMUsageData = new PMUsageData(pm, []);

          usageData = pMUsageData.getUsageDataByUser(pm, contactID, roleID, evidenceToExport);
        }

        doc.updateContainer(
          <ExportedPDF
            evidence={evidenceToExport}
            user={user.contents}
            isHCPC={exportType === IExportType.HCPC}
            summaryOfWork={
              exportType === IExportType.HCPC && user.state === "hasValue" ? user.contents?.summaryOfWork : undefined
            }
            personalStatement={
              exportType === IExportType.HCPC && user.state === "hasValue"
                ? user.contents?.personalStatement
                : undefined
            }
            isMajorIncident={exportType === IExportType.MAJOR_INCIDENT}
            program={program}
            userPrograms={userPrograms}
            usageData={usageData}
          />
        );

        doc.on("change", async () => {
          if (doc.container.document && doc.container.document.children.length > 0) {
            const blob = await doc.toBlob();

            try {
              await logDocumentExport(doc.container.document.children.length);
            } catch (error) {
              console.log(error);
            }

            if (await DataController.isWebAsync()) {
              saveAs(blob, filename.trim().length > 0 ? filename.trim() : "Untitled");
            } else {
              const filePath = await WriteBlob({
                path: `ParaFolio/Exports/${DataController.getFolderFromExportType(exportType)}/${filename.trim().length > 0 ? filename.trim() : "Untitled"}.pdf`,
                blob,
                directory: Directory.Library,
                recursive: true,
              });

              FileOpener.open({
                filePath,
              });
            }

            doc.updateContainer(<Document />);
            setExporting(false);
            setCurrentExportType("");
            setCurrentExportTypeProgram("");
          }
        });
      } catch (error) {
        console.log(error);
        setExporting(false);
        setCurrentExportType("");
        setCurrentExportTypeProgram("");
      }
    },
    [evidence.contents, filename, user.contents, user.state]
  );

  const exportEvidence = useCallback(
    async (exportType: string, program?: ProgramData): Promise<void> => {
      try {
        if (user.state === "hasValue" && user.contents) {
          setExporting(true);
          setCurrentExportType(exportType);
          program && setCurrentExportTypeProgram(program.Name);
          await generatePdfDocument(exportType, program);
        }
      } catch (error) {
        console.log(error);
        setExporting(false);
        setCurrentExportType("");
        setCurrentExportTypeProgram("");
      }
    },
    [generatePdfDocument, user.contents, user.state]
  );

  const filenameChanged = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setFilename(event.target.value);
  };

  const openExportModal = (): void => {
    setDefaultFilename(
      `${user.contents.userData.userName?.replace(/\s/gi, "-") || "Untitled"}-Portfolio-${format(new Date(), "dd-MM-yyyy")}`
    );
    setFilename(
      `${user.contents.userData.userName?.replace(/\s/gi, "-") || "Untitled"}-Portfolio-${format(new Date(), "dd-MM-yyyy")}`
    );
    setExportVisible(true);
  };

  const closeExportModal = (): void => {
    setExportVisible(false);
  };

  const summaryWordCount = hasSummaryOfWork()
    ? DataController.getWordCount(user.state === "hasValue" ? user.contents?.summaryOfWork || "" : "")
    : 0;
  const personalStatementWordCount = hasPersonalStatement()
    ? DataController.getWordCount(user.state === "hasValue" ? user.contents?.personalStatement || "" : "")
    : 0;
  const HCPCCount = getHCPCCount();

  const exportButtonVisible = summaryWordCount > 0 || personalStatementWordCount > 0 || HCPCCount > 0;

  return (
    <div className="HCPCProfileCard">
      <IonModal
        id={!appFeatures.includes(IAppFeatures.SWAST_MIC) ? "exportModalSmall" : "exportModal"}
        isOpen={exportVisible}
        onDidDismiss={() => closeExportModal()}
        canDismiss={true}
        className="fullscreen"
      >
        <IonHeader>
          <IonToolbar mode="ios" className="navBar" style={{ maxWidth: DataController.isWeb() ? 980 : undefined }}>
            <IonTitle>{"Export"}</IonTitle>
            <IonButtons slot="end">
              <IonButton mode="ios" className="cancelButton" onClick={() => closeExportModal()}>
                {"Done"}
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <div className="inner-content">
          <div className="exportModalContainer">
            {/* <div className="evidenceEmptyText" style={{ fontSize: 18, marginBottom: 24 }}>
                {'Please note that the attachment URLs generated inside a PDF expire after 7 days ater exporting'}
              </div> */}
            <div className="exportModalTitle">{"Set a file name"}</div>
            <input
              ref={filenameRef}
              className="exportFilenameInput"
              placeholder="Enter a filename..."
              autoComplete="off"
              autoCapitalize="words"
              id="reflectionTitleInput"
              value={filename}
              onChange={filenameChanged}
            />
            <IonButton
              disabled={exporting}
              onClick={() => exportEvidence(IExportType.GENERAL)}
              className="generateButton"
            >
              {exporting && currentExportType === IExportType.GENERAL ? (
                <IonSpinner className="exportingEvidenceSpinner" />
              ) : (
                <div>{"Export Evidence"}</div>
              )}
            </IonButton>
            <IonButton disabled={exporting} onClick={() => exportEvidence(IExportType.HCPC)} className="generateButton">
              {exporting && currentExportType === IExportType.HCPC ? (
                <IonSpinner className="exportingEvidenceSpinner" />
              ) : (
                <div>{"Export Audit Profile"}</div>
              )}
            </IonButton>{" "}
            {appFeatures.includes(IAppFeatures.SWAST_MIC) && (
              <IonButton
                disabled={exporting}
                onClick={() => exportEvidence(IExportType.MAJOR_INCIDENT)}
                className="generateButton"
              >
                {exporting && currentExportType === IExportType.MAJOR_INCIDENT ? (
                  <IonSpinner className="exportingEvidenceSpinner" />
                ) : (
                  <div>{"Export Incident Portfolio"}</div>
                )}
              </IonButton>
            )}
            {userPrograms.map((program: ProgramData, index) => {
              let programName = program.Name;

              return (
                <IonButton
                  key={program.Title}
                  disabled={exporting}
                  onClick={() => exportEvidence(IExportType.PROGRAM, program)}
                  className="generateButton"
                >
                  {exporting && currentExportType === IExportType.PROGRAM && currentExportProgram === programName ? (
                    <IonSpinner className="exportingEvidenceSpinner" />
                  ) : (
                    <div>{`Export ${program.Title}`}</div>
                  )}
                </IonButton>
              );
            })}
          </div>
        </div>
      </IonModal>
      <div className="HCPCProfileCardTitleContainer">
        <div className="HCPCProfileCardTitle">{"Audit Profile"}</div>
        <div className="HCPCProfileCardSubtitle">{"Keep your CPD profile on track and ready to submit"}</div>
      </div>
      <div className={`HCPCSectionContainer ${hasSummaryOfWork() ? "HCPCSectionContainerFilled" : ""}`}>
        <div className="HCPCSectionTitle">{"Summary of work"}</div>
        {hasSummaryOfWork() ? (
          <div className="HCPCWordCount">
            {`${getLastEditedSummaryOfWork()} ${summaryWordCount} word${summaryWordCount === 1 ? "" : "s"}`}
          </div>
        ) : (
          <div className="HCPCSectionDescription">
            {
              "A description of your role and the work you do. It's recommended that you write up to 500 words on your main responsibilities, the specialist areas you work in and the people you communicate and work with most."
            }
          </div>
        )}
        {hasSummaryOfWork() ? (
          <IonButton mode="ios" onClick={() => summaryOfWorkClicked()} className="HCPCSectionEditButton">
            {"Edit"}
          </IonButton>
        ) : (
          <IonButton mode="ios" onClick={() => summaryOfWorkClicked()} className="HCPCSectionGetStartedButton">
            {"Get started"}
          </IonButton>
        )}
      </div>
      <div className={`HCPCSectionContainer ${hasPersonalStatement() ? "HCPCSectionContainerFilled" : ""}`}>
        <div className="HCPCSectionTitle">{"Personal statement"}</div>
        {hasPersonalStatement() ? (
          <div className="HCPCWordCount">
            {`${getLastEditedPersonalStatement()} ${personalStatementWordCount} word${personalStatementWordCount === 1 ? "" : "s"}`}
          </div>
        ) : (
          <div className="HCPCSectionDescription">
            {"Use up to 1500 words to focus on your professional development."}
          </div>
        )}
        {hasPersonalStatement() ? (
          <IonButton mode="ios" onClick={() => personalStatementClicked()} className="HCPCSectionEditButton">
            {"Edit"}
          </IonButton>
        ) : (
          <IonButton mode="ios" onClick={() => personalStatementClicked()} className="HCPCSectionGetStartedButton">
            {"Get started"}
          </IonButton>
        )}
      </div>
      <div className={`HCPCSectionContainer ${hasEvidenceWithHCPC() ? "HCPCSectionContainerFilledEvidence" : ""}`}>
        <div className="HCPCSectionTitle">{"Evidence"}</div>
        {hasEvidenceWithHCPC() ? (
          <div className="HCPCWordCount">{`${HCPCCount} piece${HCPCCount === 1 ? "" : "s"} of evidence`}</div>
        ) : (
          <div className="HCPCSectionDescription">
            {"Tick the "}
            <b>{"audit"}</b>
            {" box at the bottom of your added evidence to make sure it gets added towards your Audit Profile. "}
          </div>
        )}
        {hasEvidenceWithHCPC() ? (
          <IonButton
            mode="ios"
            onClick={() => evidenceClicked()}
            className="HCPCSectionEditButton HCPCSectionViewButton"
          >
            {"View"}
          </IonButton>
        ) : (
          <IonButton
            mode="ios"
            onClick={() => addEvidence()}
            routerLink={"/evidence"}
            className="HCPCSectionGetStartedButton"
          >
            {"Get started"}
          </IonButton>
        )}
      </div>
      {exportButtonVisible ? (
        <IonItem
          button
          className="HCPCExportContainer"
          onClick={() => openExportModal()}
          disabled={exporting}
          detail={false}
        >
          <div className="HCPCExportContainerInner">
            <div className="HCPCExportTitle">{"Export Audit Profile"}</div>
            {exporting ? (
              <div className="hcpcExportSpinnerContainer">
                <IonSpinner className="hcpcExportSpinner" />
              </div>
            ) : (
              <IonImg src={Icon_Export_Blue} className="profileSettingDisclosure" style={{ marginRight: -7 }} />
            )}
          </div>
        </IonItem>
      ) : (
        <div className="HCPCSectionContainer">
          <div className="HCPCSectionTitle">{"Summary of evidence"}</div>
          <div className="HCPCSectionDescription">
            {
              "When adding your evidence as part of your Audit Profile, add a brief description so you can provide a summary list of your CPD evidence"
            }
          </div>
        </div>
      )}
    </div>
  );
};

export default HCPCProfileCard;
