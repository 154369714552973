import React, { useEffect, useMemo, useState } from "react";
import { IonImg, IonItem } from "@ionic/react";
import { Button_Disclosure_Right_Grey } from "../../assets/images";
import "../../theme/tailwind.css";

const NOSOverviewItem: React.FC<{
  title: string;
  subtitle: string;
  description: string;
  percentage: number;
  buttonPressed: () => void;
}> = (props) => {
  function getStatus() {
    // see spec in basecamp project
    // if NOS has >=90% it's Full compliance
    // <90% and >=75% Partial Compliance
    // < 75% Non Compliance
    let bg = "";
    let text = "";
    if (props.percentage < 75) {
      bg = "bg-standard-not-compliant";
      text = "text-standard-not-compliant-text";
    } else if (props.percentage < 90) {
      bg = "bg-standard-partially-compliant";
      text = "text-standard-partially-compliant-text";
    } else {
      bg = "bg-standard-compliant";
      text = "text-standard-compliant-text";
    }
    return {
      value: `${props.percentage}%`,
      backgroundColor: bg,
      textColor: text,
    };
  }

  const nosItemStatus = getStatus();

  return (
    <IonItem
      button
      className="!flex flex-col !w-full !shadow-unset !pl-0 part program-skill-button"
      detail={false}
      onClick={props.buttonPressed}
    >
      <div className="w-full pl-0">
        <div className="flex flex-row justify-between items-center shadow-program-skill shadow-grey-30 py-[8px]">
          <div className="flex flex-col max-w-half">
            <div className="text-acc-15px font-semibold leading-[1.33] tracking-[-0.3px] text-grey-90 acc-underline">
              {props.title}
            </div>
            <div className="text-acc-11px font-normal leading-[1.27] text-grey-90 acc-underline my-1 line-clamp-2">
              {props.description}
            </div>
            <div className="text-acc-11px font-medium leading-[1.27] text-grey-60 acc-underline">{props.subtitle}</div>
          </div>
          <div className="flex flex-row items-center">
            <div
              className={`rounded-2xl py-1 px-2 text-acc-13px font-acc-600 ${nosItemStatus.textColor} ${nosItemStatus.backgroundColor}`}
            >
              {nosItemStatus.value}
            </div>
            <IonImg className="h-[24px] w-[24px]" src={Button_Disclosure_Right_Grey} />
          </div>
        </div>
      </div>
    </IonItem>
  );
};

export default NOSOverviewItem;
