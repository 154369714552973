import { Page, Text, View } from "@react-pdf/renderer";
import { IPDFTitlePage } from "./PDFTypes";
import * as PDFStyles from "./PDFStyles";

const PDFTitlePage: React.FC<IPDFTitlePage> = (props) => {
  return (
    <Page size="A4" style={[PDFStyles.styles.page, { padding: 0 }]}>
      <View style={PDFStyles.styles.heading} fixed>
        <Text style={PDFStyles.styles.headingText}>{"ParaFolio"}</Text>
      </View>
      <View style={PDFStyles.styles.titlePageContainer}>
        <Text
          style={[PDFStyles.styles.titlePageName, { marginBottom: props.isMajorIncident && props.user.role ? 6 : 24 }]}
        >
          {props.user.name}
        </Text>
        {props.isMajorIncident && props.user.role && (
          <Text style={PDFStyles.styles.titlePageRole}>{props.user.role}</Text>
        )}
        {props.program && <Text style={PDFStyles.styles.titlePageRole}>{props.program.Title}</Text>}
        <Text style={PDFStyles.styles.titlePageTitle}>{"Continuing Professional\nDevelopment Portfolio"}</Text>
      </View>
    </Page>
  );
};

export default PDFTitlePage;
