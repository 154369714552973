import { PF_SERVER_URL } from "../Constants";
import { AllProgressCheckStatuses, IUser, ProgressCheckStatus } from "../Interfaces";
import RemoteDataController from "./RemoteDataController";

export namespace ProgressCheckService {
  /**
   * Gets the status of all of the user's submitted progress checks from database
   * @param {IUser} user - The current user
   * @param {string} programID - The current Program Module ID
   * @returns All the progress check statuses and list of mentors
   */
  export async function getAllProgressCheckStatuses(
    user: IUser,
    programID: string
  ): Promise<AllProgressCheckStatuses | any> {
    return new Promise(async (resolve, reject) => {
      try {
        const url = PF_SERVER_URL + "/portfolio/Mentor/getPCs";

        const response: Response = await fetch(url, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/x-www-form-urlencoded",
          },
          body: new URLSearchParams({
            token: `${user.token}`,
            contactId: user.userData.contactID,
            menteeContactId: user.userData.contactID,
            pMId: programID,
            pCId: "ALL",
          }),
        });

        if (response) {
          const json = await response.json();

          if (json.success) {
            let progressCheckStatus = json.body;

            resolve(progressCheckStatus);
          } else {
            reject(json.message || json.error);
          }
        } else {
          reject("The server didn't respond");
        }
      } catch (error: any) {
        console.log(error);
        RemoteDataController.logError(error);
        reject("An error has occurred");
      }
    });
  }

  /**
   * Gets the status of a single progress check for a user
   * @param user - The current user
   * @param programID - The current Program Module ID
   * @param progressCheckID - The ID for the requested progress check
   * @returns The current progress check status
   */
  export async function getProgressCheckStatus(
    user: IUser,
    programID: string,
    progressCheckID: string
  ): Promise<ProgressCheckStatus | any> {
    return new Promise(async (resolve, reject) => {
      try {
        const url = PF_SERVER_URL + "/portfolio/Mentor/getPCs";

        const response: Response = await fetch(url, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            token: user.token,
            contactId: user.userData.contactID,
            menteeContactId: user.userData.contactID,
            pMId: programID,
            pCId: progressCheckID,
          }),
        });

        if (response) {
          const json = await response.json();

          if (json.success) {
            let progressCheckStatus = json.body;

            resolve(progressCheckStatus);
          } else {
            reject(json.message || json.error);
          }
        } else {
          reject("The server didn't respond");
        }
      } catch (error: any) {
        console.log(error);
        RemoteDataController.logError(error);
        reject("An error has occurred");
      }
    });
  }

  /**
   * Creates an In-Progress progress check submission
   * @param user - The current user
   * @param programID - The current Program Module ID
   * @param progressCheckID - The ID for the requested progress check
   * @param operation - optional param, default value is "In-Progress"
   * @returns true if the request completed successfully
   */
  export async function createProgressCheck(
    user: IUser,
    programID: string,
    progressCheckID: string,
    operation: string // e.g. "In-Progress"
  ): Promise<boolean | any> {
    return new Promise(async (resolve, reject) => {
      try {
        const url = PF_SERVER_URL + "/portfolio/Mentor/updateSubmissionStatus";

        const response: Response = await fetch(url, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/x-www-form-urlencoded",
          },
          body: new URLSearchParams({
            token: `${user.token}`,
            contactId: user.userData.contactID,
            menteeContactId: user.userData.contactID,
            pMId: programID,
            pCId: progressCheckID,
            operation: operation,
            role: "Mentee",
          }),
        });

        if (response) {
          const json = await response.json();

          if (json.success) {
            resolve(true);
          } else {
            reject(json.message || json.error);
          }
        } else {
          reject("The server didn't respond");
        }
      } catch (error: any) {
        console.log(error);
        RemoteDataController.logError(error);
        reject("An error has occurred");
      }
    });
  }

  /**
   * Marks a progress check submission as completed
   * @param user - The current user
   * @param programID - The current Program Module ID
   * @param progressCheckID - The ID for the requested progress check
   * @returns true if the request completed successfully
   */
  export async function completeProgressCheck(
    user: IUser,
    programID: string,
    progressCheckID: string
  ): Promise<boolean | any> {
    return new Promise(async (resolve, reject) => {
      try {
        const url = PF_SERVER_URL + "/portfolio/Mentor/updateSubmissionStatus";

        const response: Response = await fetch(url, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/x-www-form-urlencoded",
          },
          body: new URLSearchParams({
            token: `${user.token}`,
            contactId: user.userData.contactID,
            menteeContactId: user.userData.contactID,
            pMId: programID,
            pCId: progressCheckID,
            operation: "Completed",
            role: "Mentee",
          }),
        });

        if (response) {
          const json = await response.json();

          if (json.success) {
            resolve(true);
          } else {
            reject(json.message || json.error);
          }
        } else {
          reject("The server didn't respond");
        }
      } catch (error: any) {
        console.log(error);
        RemoteDataController.logError(error);
        reject("An error has occurred");
      }
    });
  }

  /**
   * Updates the current progress check submission for approval by the Mentors/ OO
   * @param user - The current user
   * @param programID - The current Program Module ID
   * @param progressCheckID - The ID for the requested progress check
   * @returns true if the request completed successfully
   */
  export async function updateProgressCheck(
    user: IUser,
    programID: string,
    progressCheckID: string
  ): Promise<boolean | any> {
    return new Promise(async (resolve, reject) => {
      try {
        const url = PF_SERVER_URL + "/portfolio/Mentor/updateSubmissionStatus";

        const response: Response = await fetch(url, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/x-www-form-urlencoded",
          },
          body: new URLSearchParams({
            token: `${user.token}`,
            contactId: user.userData.contactID,
            menteeContactId: user.userData.contactID,
            pMId: programID,
            pCId: progressCheckID,
            operation: "Submitted",
            role: "Mentee",
          }),
        });

        if (response) {
          const json = await response.json();

          if (json.success) {
            resolve(true);
          } else {
            reject(json.message || json.error);
          }
        } else {
          reject("The server didn't respond");
        }
      } catch (error: any) {
        console.log(error);
        RemoteDataController.logError(error);
        reject("An error has occurred");
      }
    });
  }

  /**
   * Adds a comment to the current progress check
   * @param user - The current user
   * @param programID - The current Program Module ID
   * @param progressCheckID - The ID for the requested progress check
   * @param comment - The comment to add
   * @returns true if the request completed successfully
   */
  export async function addComment(
    user: IUser,
    programID: string,
    progressCheckID: string,
    comment: string
  ): Promise<boolean | any> {
    return new Promise(async (resolve, reject) => {
      try {
        const url = PF_SERVER_URL + "/portfolio/Mentor/addComment";

        const response: Response = await fetch(url, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/x-www-form-urlencoded",
          },
          body: new URLSearchParams({
            token: `${user.token}`,
            contactId: user.userData.contactID,
            menteeContactId: user.userData.contactID,
            pMId: programID,
            pCId: progressCheckID,
            comment: comment,
            role: "Mentee",
          }),
        });

        if (response) {
          const json = await response.json();

          if (json.success) {
            resolve(true);
          } else {
            reject(json.message || json.error);
          }
        } else {
          reject("The server didn't respond");
        }
      } catch (error: any) {
        console.log(error);
        RemoteDataController.logError(error);
        reject("An error has occurred");
      }
    });
  }

  /**
   * Updates the progress check object in the database with the timestamp the user last read the comments
   * @param user - The current user
   * @param programID - The current Program Module ID
   * @param progressCheckID - The ID for the requested progress check
   * @returns true if the request completed successfully
   */
  export async function markCommentsAsRead(
    user: IUser,
    programID: string,
    progressCheckID: string
  ): Promise<boolean | any> {
    return new Promise(async (resolve, reject) => {
      try {
        const url = PF_SERVER_URL + "/portfolio/Mentor/markCommentsAsRead";

        const response: Response = await fetch(url, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/x-www-form-urlencoded",
          },
          body: new URLSearchParams({
            token: `${user.token}`,
            contactId: user.userData.contactID,
            menteeContactId: user.userData.contactID,
            pMId: programID,
            pCId: progressCheckID,
            timestamp: new Date().toISOString(),
          }),
        });

        if (response) {
          const json = await response.json();

          if (json.success) {
            resolve(true);
          } else {
            reject(json.message || json.error);
          }
        } else {
          reject("The server didn't respond");
        }
      } catch (error: any) {
        console.log(error);
        RemoteDataController.logError(error);
        reject("An error has occurred");
      }
    });
  }
}
