import { Link, Page, Text, View } from "@react-pdf/renderer";
import { IPDFProgramDetailsPage } from "./PDFTypes";
import * as PDFStyles from "./PDFStyles";
import * as PDFUtils from "../../utils/pdfUtils";
import { IUserProgramCertificate, ProgramCertificate } from "../../Interfaces";
import AWSService from "../../controllers/AWSService";
import { format } from "date-fns";

const PDFProgramDetailsPage: React.FC<IPDFProgramDetailsPage> = (props) => {
  return (
    <>
      {props.program ? (
        <Page size="A4" style={PDFStyles.styles.page}>
          <View style={PDFStyles.styles.heading} fixed>
            <Text style={PDFStyles.styles.headingText}>{"ParaFolio"}</Text>
          </View>
          <View
            fixed
            style={PDFStyles.styles.pageHeader}
            render={({ pageNumber }) => (
              <>
                <Text style={PDFStyles.styles.pageHeaderText}>{"Details"}</Text>
                <Text style={PDFStyles.styles.pageNumber}>{pageNumber - 1}</Text>
              </>
            )}
          />
          <View style={PDFStyles.styles.pageInnerContainer}>
            <Text
              style={[PDFStyles.styles.standardsHeaderOverview, { marginBottom: 0 }]}
            >{`${props.program.Title} Details`}</Text>
            <View style={PDFStyles.styles.separator} />
            {props.user.name && (
              <View>
                <Text style={PDFStyles.styles.reflectionHeader}>{"Name:"}</Text>
                <Text style={PDFStyles.styles.evidenceDate}>{props.user.name}</Text>
              </View>
            )}
            {props.user.email && (
              <View>
                <Text style={PDFStyles.styles.reflectionHeader}>{"Email:"}</Text>
                <Text style={PDFStyles.styles.evidenceDate}>{props.user.email}</Text>
              </View>
            )}
            {props.userRoles.length > 0 && (
              <View>
                <Text style={PDFStyles.styles.reflectionHeader}>{"Roles:"}</Text>
                {props.userRoles.sort().map((role: string) => (
                  <Text key={role} style={[PDFStyles.styles.detailsInfo, { marginBottom: 4 }]}>
                    {role}
                  </Text>
                ))}
              </View>
            )}
            {props.userTeam && (
              <View>
                <Text style={PDFStyles.styles.reflectionHeader}>{"Team:"}</Text>
                <Text key={props.userTeam} style={[PDFStyles.styles.detailsInfo, { marginBottom: 4 }]}>
                  {props.userTeam}
                </Text>
              </View>
            )}
            {PDFUtils.displayUserESRNumber(props.program.ID) && (
              <View>
                <Text style={PDFStyles.styles.reflectionHeader}>{"ESR No:"}</Text>
                <Text style={PDFStyles.styles.evidenceDate}>
                  {PDFUtils.getUserESRNumber(props.user.userData?.subscriptions, props.program.Subscription)}
                </Text>
              </View>
            )}
            {PDFUtils.displayUserRegions(props.program.ID) && (
              <View>
                <Text style={PDFStyles.styles.reflectionHeader}>{"Regions:"}</Text>
                <Text style={PDFStyles.styles.evidenceDate}>
                  {PDFUtils.getUserRegions(props.user.userData?.Region)}
                </Text>
              </View>
            )}
            {props.programCertificates.length > 0 && (
              <View>
                <Text style={PDFStyles.styles.reflectionHeader}>{"Certificates:"}</Text>
                {props.programCertificates.sort().map((_certificate: IUserProgramCertificate) => {
                  const programCertificate: ProgramCertificate = props.allProgramCertificates.find(
                    (item: ProgramCertificate) =>
                      item.Name.toUpperCase() === _certificate?.certificate?.certificateType.toUpperCase()
                  );

                  const signedURL = AWSService.getCertificateURL(props.user, props.accessParams, _certificate);

                  return (
                    <View key={_certificate?.certificate?.certificateType}>
                      <Text style={PDFStyles.styles.reflectionHeader}>
                        {PDFUtils.getCertificateHeader(_certificate, programCertificate, props.program)}
                      </Text>
                      <Link style={PDFStyles.styles.hyperlink} src={signedURL} key={_certificate.certificate?.name}>
                        {_certificate.certificate?.name}
                      </Link>
                      {_certificate.issueDate && (
                        <View style={PDFStyles.styles.certificateDate}>
                          <Text style={PDFStyles.styles.boldHeader}>{"Issue Date: "}</Text>
                          <Text style={PDFStyles.styles.evidenceDate}>
                            {format(new Date(_certificate.issueDate), "d MMMM yyyy")}
                          </Text>
                        </View>
                      )}
                      {_certificate.expiryDate && (
                        <View style={PDFStyles.styles.certificateDate}>
                          <Text style={PDFStyles.styles.boldHeader}>{"Expiry Date: "}</Text>
                          <Text style={PDFStyles.styles.evidenceDate}>
                            {format(new Date(_certificate.expiryDate), "d MMMM yyyy")}
                          </Text>
                        </View>
                      )}
                    </View>
                  );
                })}
              </View>
            )}
          </View>
        </Page>
      ) : null}
    </>
  );
};

export default PDFProgramDetailsPage;
