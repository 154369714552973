import { SetterOrUpdater, useRecoilState, useRecoilStateLoadable } from "recoil";
import { debugUserAtom, userAtom } from "../../state/State";
import { useState } from "react";

export const useDebugUser = (): {
  isDebugUser: boolean;
  setIsDebugUser: SetterOrUpdater<boolean>;
  debugHiddenButtonPressed: () => void;
} => {
  const [isDebugUser, setIsDebugUser] = useRecoilStateLoadable<boolean>(debugUserAtom);
  const [debugCounter, setDebugCounter] = useState<number>(0);

  const debugHiddenButtonPressed = (): void => {
    if (debugCounter < 4) {
      setDebugCounter((prev) => prev + 1);
    } else {
      setIsDebugUser(!isDebugUser.contents);
      setDebugCounter(0);
    }
  };

  return { isDebugUser: isDebugUser.contents ?? false, setIsDebugUser, debugHiddenButtonPressed };
};
