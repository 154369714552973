import { ISegmentedProgressBar } from "../../types/Components";

const SegmentedProgressBar: React.FC<ISegmentedProgressBar> = (props) => {
  return (
    <div>
      {props.title && <div className={`text-grey-90 text-acc-13px ${props.customTitleClasses}`}>{props.title}</div>}
      <div className={props.title && "mt-2"}>
        <span
          className={`font-bold text-acc-34px text-grey-60 ${props.numerator >= props.denominator && "text-progress-compliant-text"}`}
        >
          {props.numerator}
        </span>
        <span className="text-grey-60 font-bold text-acc-15px">{` / ${props.denominator}`}</span>
      </div>
      <div className="flex gap-1 mt-2">
        {Array.from({ length: props.denominator }).map((item, index) => (
          <div
            key={index}
            className={`flex-1 h-[4px] bg-program-card-progress-marker rounded ${index < props.numerator && "bg-progress-compliant-text"}`}
          />
        ))}
      </div>
    </div>
  );
};

export default SegmentedProgressBar;
