import { IonItem } from "@ionic/react";
import { Button_Checkbox_Active, Button_Checkbox_Inactive } from "../../assets/images";
import { IOptionalStandard } from "../../Interfaces";

const OptionalStandardCheckbox: React.FC<{
  standard: IOptionalStandard;
  checked: boolean;
  handleClick: () => void;
}> = (props) => {
  return (
    <IonItem button detail={false} className="checkboxItem" onClick={props.handleClick}>
      <div className="flex items-start justify-between w-full">
        <div className="flex-1">
          <div className={`text-grey-90 text-acc-17px mt-3 ${props.checked ? "font-acc-600" : "font-acc-400"}`}>
            {props.standard.name}
          </div>
          <div className="text-grey-90 text-acc-11px mb-3 mt-1 font-acc-500">{props.standard.description}</div>
        </div>
        <img
          className="select-none pointer-events-none"
          src={props.checked ? Button_Checkbox_Active : Button_Checkbox_Inactive}
          width={44}
          height={44}
        />
      </div>
    </IonItem>
  );
};

export default OptionalStandardCheckbox;
