import { useEffect, useState } from "react";
import _ from "lodash";
import { EvidencePart, IUser, IUserProgramRoles, ProgramData } from "../../../Interfaces";
import { IonItem } from "@ionic/react";
import { Button_Checkbox_Active, Button_Checkbox_Inactive } from "../../../assets/images";
import "./EvidenceParts.css";
import { Text, View } from "@react-pdf/renderer";

type Props = {
  children?: React.ReactNode;
  style?: React.CSSProperties;
  definition: EvidencePart;
  onValueChanged?: (value: any) => void;
  data?: any;
  isDisplay?: boolean;
  isPDF?: boolean;
  pdfStyles: any;
  initialValue?: any;
  program?: ProgramData;
  user: IUser;
};

const RolesSelect: React.FC<Props> = ({
  children,
  style,
  definition,
  onValueChanged,
  data,
  isDisplay,
  isPDF,
  pdfStyles,
  initialValue,
  program,
  user,
}) => {
  const [selectedValues, setSelectedValues] = useState<string[]>(initialValue || []);
  const [listValues, setListValues] = useState<string[]>([]);

  useEffect(() => {
    const getListValues = (): void => {
      if (user) {
        const roles = _.cloneDeep(user?.programRoles);

        if (roles) {
          const programRoles = roles.find((item: IUserProgramRoles) => item.programName === program?.Name);

          programRoles && setListValues(programRoles.programRoles.sort());
        }
      }
    };

    definition && getListValues();
  }, [definition, program, user]);

  const toggleValue = (value: string): void => {
    let array = _.cloneDeep(selectedValues);

    if (!Array.isArray(array)) {
      array = [array];
    }

    if (array.includes(value)) {
      array = array.filter((item) => item !== value);
    } else {
      array.push(value);
    }

    setSelectedValues(array);
    onValueChanged && onValueChanged(array);
  };

  if (isPDF) {
    return (
      <View>
        <Text style={pdfStyles.reflectionHeader}>{(definition["Display Title"] || definition.Name) + ":"}</Text>
        <Text style={pdfStyles.evidenceDate}>{Array.isArray(data) ? data?.sort().join(", ") : data}</Text>
      </View>
    );
  }

  if (isDisplay) {
    return (
      <div className="readEvidenceBlock">
        <div className="readEvidenceHeader">{definition["Display Title"] || definition.Name}</div>
        <div className="readEvidenceText">{Array.isArray(data) ? data?.sort().join(", ") : data}</div>
        <div className="h-[1px] w-full bg-grey-30 mt-4" />
      </div>
    );
  }

  return listValues.length === 0 ? (
    <div className="evidenceRolesSelectPlaceholder">{definition["Placeholder Text"]}</div>
  ) : (
    <div>
      {listValues.map((item, index) => {
        const selected = selectedValues.includes(item);

        return (
          <IonItem
            button
            detail={false}
            key={item}
            className="evidencePartsCheckboxContainer"
            onClick={() => toggleValue(item)}
          >
            <div className="evidencePartsCheckboxContainerInner">
              <div className="evidencePartsCheckboxLabel">{item}</div>
              <img
                alt=""
                className="evidencePartsRadioCheckbox"
                src={selected ? Button_Checkbox_Active : Button_Checkbox_Inactive}
              />
            </div>
          </IonItem>
        );
      })}
    </div>
  );
};

export default RolesSelect;
