import { useRecoilValueLoadable } from "recoil";
import { evidenceAtom } from "../../state/State";
import { IEvidence, ProgramData } from "../../Interfaces";
import * as ProgramUtils from "../../utils/programUtils";

export const useProgramEvidence = (program: ProgramData | null) => {
  const allEvidence = useRecoilValueLoadable<IEvidence[] | null>(evidenceAtom);

  let evidenceForProgram: IEvidence[] = [];

  if (program) {
    evidenceForProgram = ProgramUtils.getEvidenceForProgramName(allEvidence.contents, program?.Name);
  }

  return evidenceForProgram;
};
