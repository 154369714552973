import { Redirect } from "react-router";

type ProtectedRouteProps = {
  isAuthenticated: boolean;
  authenticationPath: string;
  outlet: JSX.Element;
};

const ProtectedRoute = ({ isAuthenticated, authenticationPath, outlet }: ProtectedRouteProps) => {
  if (isAuthenticated) {
    return outlet;
  } else {
    return <Redirect to={authenticationPath} />;
  }
};

export default ProtectedRoute;
