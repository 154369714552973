import { IonTitle } from "@ionic/react";
import { Capacitor } from "@capacitor/core";
import { IHeaderTitle } from "../../types/Components";

const HeaderTitle: React.FC<IHeaderTitle> = (props) => {
  return (
    <IonTitle
      style={{
        height: Capacitor.getPlatform() === "android" ? "54px" : "44px",
      }}
    >
      {props.children}
    </IonTitle>
  );
};

export default HeaderTitle;
