import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonImg,
  IonPage,
  IonSpinner,
  IonToolbar,
} from "@ionic/react";
import { EvidenceContainer, HeaderTitle } from "parafolio-components";
import DataController from "../../controllers/DataController";
import { Capacitor } from "@capacitor/core";
import { useParams } from "react-router";
import { useHidingTabBar } from "../../hooks/tabs/useHidingTabBar";
import { useRefreshProgramData } from "../../hooks/programs/useRefreshProgramData";
import { useProgramData } from "../../hooks/data/useProgramData";
import { Icon_Refresh } from "../../assets/images";
import WellbeingResultsContainer from "../../components/wellbeing/WellbeingResultsContainer";

const WellbeingResults: React.FC = () => {
  const params = useParams<{ programId: string; progressCheckId: string; competenceId: string; evidenceId: string }>();

  useHidingTabBar();
  const { isRefreshingProgramData, refreshUserProgramData } = useRefreshProgramData();
  const { userPrograms } = useProgramData();

  const program = userPrograms?.find((program) => program.ID === params.programId)!;

  const wellbeingActivity = program?.WellbeingModules?.find(
    (wellbeingModule) => wellbeingModule.CompetenceID === params.competenceId
  );

  // console.log(params, wellbeingActivity);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar
          mode="ios"
          className="navBar"
          style={{
            maxWidth: DataController.isWeb() ? 980 : undefined,
            height: Capacitor.getPlatform() === "android" ? "54px" : "unset",
          }}
        >
          <IonButtons>
            <IonBackButton
              className="header-back-buttons"
              defaultHref={`/dashboard/program/${params.programId}/progress-check/${params.progressCheckId}`}
              text={DataController.getBackIconText()}
              icon={DataController.getBackIconType()}
              style={{
                marginLeft: Capacitor.getPlatform() === "android" ? 8 : 0,
                "--icon-font-size": Capacitor.getPlatform() === "android" ? "24px" : "30px",
              }}
            />
          </IonButtons>
          <HeaderTitle>{"Wellbeing Activity"}</HeaderTitle>
          <IonButtons slot="end">
            <IonButton className="header-button" onClick={refreshUserProgramData}>
              {isRefreshingProgramData ? (
                <IonSpinner className="w-11 h-11 text-white" />
              ) : (
                <IonImg src={Icon_Refresh} className="!w-11 !h-11" />
              )}
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent id="wellbeing-results-container" className="page-background !py-0 force-scroll-auto">
        <EvidenceContainer style={{ paddingTop: 0, alignSelf: "center", paddingBottom: 0 }}>
          <div className="!min-h-full">
            {wellbeingActivity && <WellbeingResultsContainer program={program} wellbeingActivity={wellbeingActivity} />}
          </div>
        </EvidenceContainer>
      </IonContent>
    </IonPage>
  );
};

export default WellbeingResults;
