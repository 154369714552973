import { ConnectionStatus, Network } from "@capacitor/network";
import { useEffect, useState } from "react";

export const useConnectionStatus = (): ConnectionStatus | null => {
  const [connectionStatus, setConnectionStatus] = useState<ConnectionStatus | null>(null);

  useEffect(() => {
    Network.addListener("networkStatusChange", (status: ConnectionStatus) => {
      setConnectionStatus(status);
    });

    const getStatus = async () => {
      const initialStatus = await Network.getStatus();

      setConnectionStatus(initialStatus);
    };

    getStatus();

    return () => {
      Network.removeAllListeners();
    };
  }, []);

  return connectionStatus;
};
