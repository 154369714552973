import * as PDFStyles from "./PDFStyles";
import * as PDFUtils from "../../utils/pdfUtils";
import * as ProgramUtils from "../../utils/programUtils";
import { IPDFStandardsPage } from "./PDFTypes";
import { Page, Text, View } from "@react-pdf/renderer";
import { CompetenceActivityClass } from "../../Interfaces";

const PDFStandardsPage: React.FC<IPDFStandardsPage> = (props) => {
  let mandatoryStandards: CompetenceActivityClass[] = [];
  let optionalStandards: CompetenceActivityClass[] = [];

  if (!ProgramUtils.displayStandardsInPDF(props.program?.ID)) {
    return null;
  }

  if (props.program) {
    mandatoryStandards = ProgramUtils.getMandatoryStandards(props.user, props.program);
    optionalStandards = ProgramUtils.getOptionalStandards(props.user, props.program);
  }

  return (
    <>
      <Page size="A4" style={PDFStyles.styles.page}>
        <View style={PDFStyles.styles.heading} fixed>
          <Text style={PDFStyles.styles.headingText}>{"ParaFolio"}</Text>
        </View>
        <View
          fixed
          style={PDFStyles.styles.pageHeader}
          render={({ pageNumber }) => (
            <>
              <Text style={PDFStyles.styles.pageHeaderText}>{"National Occupational Standards"}</Text>
              <Text style={PDFStyles.styles.pageNumber}>{pageNumber - 1}</Text>
            </>
          )}
        />
        <View style={PDFStyles.styles.pageInnerContainer}>
          <Text style={PDFStyles.styles.standardsHeaderOverview}>{"National Occupational Standards Overview"}</Text>
          {mandatoryStandards.length > 0 && (
            <View>
              <Text style={PDFStyles.styles.standardsTableTitle}>{"Mandatory Standards"}</Text>
              <View>
                <View style={PDFStyles.styles.standardsTableHeader}>
                  <View style={PDFStyles.styles.standardsTableRowCode}>
                    <Text style={PDFStyles.styles.standardsTableHeaderBold}>{"Name"}</Text>
                  </View>
                  <View style={PDFStyles.styles.standardsTableRowComplianceDescription}>
                    <Text style={PDFStyles.styles.standardsTableHeaderBold}>{"Description"}</Text>
                  </View>
                  <View style={PDFStyles.styles.standardsTableRowEvidence}>
                    <Text style={PDFStyles.styles.standardsTableHeaderBold}>{"Evidence"}</Text>
                  </View>
                  <View style={PDFStyles.styles.standardsTableRowCompliance}>
                    <Text style={PDFStyles.styles.standardsTableHeaderBold}>{"Compliance"}</Text>
                  </View>
                </View>
                {mandatoryStandards.map((standard: CompetenceActivityClass, index) => {
                  const evidenceCount = PDFUtils.getEvidenceCountForStandard(standard.ID, props.evidence);
                  const percentage = PDFUtils.getCompliancePercentage(standard.ID, props.usageData);

                  return (
                    <View style={PDFStyles.styles.standardsTableRow} key={standard.Name}>
                      <View style={PDFStyles.styles.standardsTableRowCode}>
                        <Text style={PDFStyles.styles.standardsTableText}>{standard.Name}</Text>
                      </View>
                      <View style={PDFStyles.styles.standardsTableRowComplianceDescription}>
                        <Text style={PDFStyles.styles.standardsTableText}>{standard.Description || ""}</Text>
                      </View>
                      <View style={PDFStyles.styles.standardsTableRowEvidence}>
                        <Text style={PDFStyles.styles.standardsTableText}>{evidenceCount}</Text>
                      </View>
                      <View style={PDFStyles.styles.standardsTableRowCompliance}>
                        <Text style={PDFStyles.styles.standardsTableText}>{percentage}</Text>
                      </View>
                    </View>
                  );
                })}
              </View>
            </View>
          )}
          {optionalStandards.length > 0 && (
            <View>
              <Text style={PDFStyles.styles.standardsTableTitle}>{"Optional Standards"}</Text>
              <View>
                <View style={PDFStyles.styles.standardsTableHeader}>
                  <View style={PDFStyles.styles.standardsTableRowCode}>
                    <Text style={PDFStyles.styles.standardsTableHeaderBold}>{"Name"}</Text>
                  </View>
                  <View style={PDFStyles.styles.standardsTableRowComplianceDescription}>
                    <Text style={PDFStyles.styles.standardsTableHeaderBold}>{"Description"}</Text>
                  </View>
                  <View style={PDFStyles.styles.standardsTableRowEvidence}>
                    <Text style={PDFStyles.styles.standardsTableHeaderBold}>{"Evidence"}</Text>
                  </View>
                  <View style={PDFStyles.styles.standardsTableRowCompliance}>
                    <Text style={PDFStyles.styles.standardsTableHeaderBold}>{"Compliance"}</Text>
                  </View>
                </View>
                {optionalStandards.map((standard: CompetenceActivityClass, index) => {
                  const evidenceCount = PDFUtils.getEvidenceCountForStandard(standard.ID, props.evidence);
                  const percentage = PDFUtils.getCompliancePercentage(standard.ID, props.usageData);

                  return (
                    <View style={PDFStyles.styles.standardsTableRow} key={standard.Name}>
                      <View style={PDFStyles.styles.standardsTableRowCode}>
                        <Text style={PDFStyles.styles.standardsTableText}>{standard.Name}</Text>
                      </View>
                      <View style={PDFStyles.styles.standardsTableRowComplianceDescription}>
                        <Text style={PDFStyles.styles.standardsTableText}>{standard.Description ?? ""}</Text>
                      </View>
                      <View style={PDFStyles.styles.standardsTableRowEvidence}>
                        <Text style={PDFStyles.styles.standardsTableText}>{evidenceCount}</Text>
                      </View>
                      <View style={PDFStyles.styles.standardsTableRowCompliance}>
                        <Text style={PDFStyles.styles.standardsTableText}>{percentage}</Text>
                      </View>
                    </View>
                  );
                })}
              </View>
            </View>
          )}
        </View>
      </Page>
    </>
  );
};

export default PDFStandardsPage;
