import { IUser, ProgramData } from "./../Interfaces";
import { FirebaseService } from "./FirebaseService";
export namespace NotificationService {
  /**
   * Subscribes the user to the notification topics for their ContactID and subscribed program IDs
   * @param user
   * @param programs
   * @returns
   */
  export async function subscribeToTopics(user: IUser, programs: ProgramData[] | any[]): Promise<boolean> {
    try {
      const topics: string[] = [];

      if (user.userData.contactID) {
        topics.push(`${user.userData.contactID}`);
      }

      if (programs.length > 0) {
        const programIDs = programs.map((item) => item.ID);

        topics.push(...programIDs);
      }

      for (let i = 0; i < topics.length; i++) {
        await FirebaseService.addTopic(topics[i]);
      }

      return true;
    } catch (error) {
      return false;
    }
  }
}
