import "../../App.css";
import { IEvidenceHeader } from "../../Interfaces";
import "./CommonComponents.css";

const EvidenceHeader: React.FC<IEvidenceHeader> = (props) => {
  if (!props.programName) {
    return null;
  }

  return (
    <div className="evidenceHeaderContainer" id={props.id}>
      <div className="evidenceHeaderText">{`${props.programName} ${props.competenceName ? `- ${props.competenceName}` : ""}`}</div>
    </div>
  );
};

export default EvidenceHeader;
