import {
  Button_Accessibility,
  Button_Link,
  HCPC_Logo,
  Icon_book_pencil,
  Icon_info,
  Icon_magnifying_glass,
  Icon_profile_arrow,
} from "../../assets/images";
import "./HomeComponents.css";
import InformationCardButton from "./InformationCardButton";
import info from "../../assets/json/info.json";
import { useHistory } from "react-router";
import { HCPC_URL } from "../../Constants";

type InfoCard = {
  id: string;
  HTML?: string | undefined;
  Title: string;
  Subtitle: string;
};

const InformationCard: React.FC = () => {
  const history = useHistory();

  const getIconFromTitle = (title: string): any => {
    switch (title) {
      case "Being selected for audit":
        return Icon_magnifying_glass;
      case "Preparing your portfolio":
        return Icon_book_pencil;
      case "Submitting your portfolio":
        return Icon_profile_arrow;
      case "Using the ParaFolio app":
        return Icon_info;
      case "HCPC website":
        return Button_Link;
      default:
        return Icon_book_pencil;
    }
  };

  const buttonClicked = (item: InfoCard): void => {
    if (item.Title === "HCPC website") {
      window.open(HCPC_URL, "_blank");
    } else {
      const slug = item.Title.replace(/\s/gim, "-").toLowerCase();

      history.push(`/dashboard/info/${slug}`);
    }
  };

  return (
    <div className="dashboardInfoCard">
      <div className="dashboardInfoCardHeader">
        <div className="dashboardCardTitleContainer dashboardInfoCardTitle">
          <div className="dashboardCardTitle">{"Information"}</div>
        </div>
      </div>
      {info.map((item, index) => {
        return (
          <InformationCardButton
            key={item.Title}
            title={item.Title}
            subtitle={item.Subtitle}
            icon={getIconFromTitle(item.Title)}
            buttonClicked={() => buttonClicked(item)}
          />
        );
      })}
    </div>
  );
};

export default InformationCard;
