import { useRef, useState } from "react";
import { IWellbeingTextBox } from "../../../types/Components";
import { Editor } from "@tinymce/tinymce-react";
import { Editor as TinyMCEEditor } from "tinymce";
import * as EditorUtils from "../../../utils/editorUtils";

const WellbeingTextBox: React.FC<IWellbeingTextBox> = (props) => {
  const ref = useRef<TinyMCEEditor | null>(null);

  const [value, setValue] = useState<string>("");

  const valueChanged = (change: string): void => {
    setValue(change);

    props.onValueChanged(change, true);
  };

  return (
    <div className="rounded-[5px] p-4 bg-white mx-4 my-1.5">
      <div className="text-acc-14px font-acc-600 text-grey-80 text-left">{props.wellbeingPart["Display Title"]}</div>
      <Editor
        tinymceScriptSrc={process.env.PUBLIC_URL + "/tinymce/tinymce.min.js"}
        onInit={(event, editor) => (ref.current = editor)}
        value={value}
        onEditorChange={(a, editor) => {
          a.length > 0 && editor.getBody().setAttribute("data-mce-placeholdar", "");
          valueChanged(a);
        }}
        init={{
          height: EditorUtils.getInitialHeight(),
          placeholder: props.wellbeingPart["Placeholder Text"] ?? "Add your response here...",
          browser_spellcheck: true,
          branding: false,
          menubar: false,
          paste_data_images: false,
          content_style: "body {font-size:14pt;}",
          plugins: ["autolink", "wordcount", "lists"],
          invalid_styles: {
            "*": "color line-height mso-outline-level background font-family",
          },
          style_formats: [
            {
              title: "Headings",
              items: [
                { title: "Heading 1", block: "h1" },
                { title: "Heading 2", block: "h2" },
                { title: "Heading 3", block: "h3" },
              ],
            },
            {
              title: "Inline",
              items: [
                { title: "Bold", block: "strong" },
                { title: "Italic", block: "em" },
                { title: "Underline", block: "u" },
                { title: "Strikethrough", block: "s" },
              ],
            },
            {
              title: "Blocks",
              items: [
                { title: "Paragraph", block: "p" },
                { title: "Blockquote", block: "blockquote" },
              ],
            },
          ],
          font_size_formats: "8pt 10pt 12pt 14pt 18pt 24pt 36pt",
          toolbar: [
            "undo redo | styles fontsize | bold italic underline | bullist numlist | alignleft aligncenter alignright",
          ],
          mobile: {
            toolbar_mode: "sliding",
          },
        }}
      />
    </div>
  );
};

export default WellbeingTextBox;
