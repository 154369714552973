import { useEffect, useState } from "react";

export const useNWASSSO = () => {
  const [available, setAvailable] = useState<boolean>(false);

  useEffect(() => {
    const checkIfSSOAvailable = async (): Promise<void> => {
      try {
        const response: Response = await fetch("https://parafolio-config.s3.eu-west-2.amazonaws.com/nwasSSO.json", {
          headers: {
            "Cache-Control": "no-cache",
          },
        });

        const result = await response.json();

        if (result != null) {
          setAvailable(true);
        }
      } catch (error) {
        setAvailable(false);
      }
    };

    checkIfSSOAvailable();
  }, []);

  return available;
};
