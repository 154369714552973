import { INOSSelectContainer } from "../../types/Components";

const NOSSelectContainer: React.FC<INOSSelectContainer> = (props) => {
  return (
    <div className="px-[14px] py-1 rounded-md border-[1px] border-solid border-grey-40 bg-white mb-4">
      {props.children}
    </div>
  );
};

export default NOSSelectContainer;
