import { Document } from "@react-pdf/renderer";
import { isAfter, subDays, subYears } from "date-fns";
import _ from "lodash";
import { useEffect, useState } from "react";
import roles from "../../assets/json/roles.json";
import standards from "../../assets/json/standards.json";
import AWSService from "../../controllers/AWSService";
import { AWSAccessParams, IUser, IUserProgramCertificate, ProgramCertificate, Required } from "../../Interfaces";
import { IExportedPDF } from "../../types/Components";
import PDFEvidence from "./PDFEvidence";
import PDFIncidentCommanderTable from "./PDFIncidentCommanderTable";
import PDFPersonalStatement from "./PDFPersonalStatement";
import PDFProgramDetailsPage from "./PDFProgramDetailsPage";
import PDFProgressChecksPage from "./PDFProgressChecksPage";
import PDFStandardsPage from "./PDFStandardsPage";
import PDFSummaryOfWork from "./PDFSummaryOfWork";
import PDFTitlePage from "./PDFTitlePage";

const ExportedPDF: React.FC<IExportedPDF> = (props) => {
  const [optionalStandards, setOptionalStandards] = useState<any[] | undefined>([]);
  const [mandatoryStandards, setMandatoryStandards] = useState<any[] | undefined>([]);
  const [accessParams, setAccessParams] = useState<AWSAccessParams | null>(null);
  const [imageMetadata, setImageMetadata] = useState<any[]>([]);
  const [userRoles, setUserRoles] = useState<string[]>([]);
  const [userTeam, setUserTeam] = useState<string>("");
  const [programCertificates, setProgramCertificates] = useState<IUserProgramCertificate[] | any[]>([]);
  const [allProgramCertificates, setAllProgramCertificates] = useState<any[]>([]);

  const minDate = subDays(subYears(new Date(), 2), 1);

  const evidenceWithinLimit = props.evidence.filter((item) => {
    return isAfter(new Date(item.date), minDate);
  });

  useEffect(() => {
    const getRolesForProgram = (): void => {
      const roles = props.user && _.cloneDeep(props.user.programRoles);

      if (roles) {
        const programRoles = roles.find((item) => item.programName === props.program?.Name);

        if (programRoles?.programName === "SWAST HART and EPRR Instructors" || programRoles?.programName === "MTS") {
          programRoles && setUserRoles(programRoles.programRoles);
          programRoles && setUserTeam(programRoles.team ?? "");
        }
      }
    };

    const getProgramCertificates = (): void => {
      if (props.program?.Certificates) {
        setAllProgramCertificates(_.orderBy(props.program.Certificates, ["Required", "Name"], ["asc", "asc"]));
      }
    };

    if (props.user && props.program) {
      getRolesForProgram();
      getProgramCertificates();
    }
  }, [props.user, props.program]);

  useEffect(() => {
    const programOrder = (item: IUserProgramCertificate): number => {
      const certificateName = item?.certificate?.certificateType;
      const mappedCertificate = props.program?.Certificates?.find(
        (_cert: ProgramCertificate) => _cert.Name.toUpperCase() === certificateName?.toUpperCase()
      );

      return mappedCertificate?.Required === Required.Mandatory ? 2 : 1;
    };

    const getProgramCertificateInfo = (): void => {
      let _programCertificates: IUserProgramCertificate[] | any[] = [];

      _programCertificates = props.user.programCertificates || [];
      _programCertificates = _programCertificates.filter(
        (item) => item.programData.programName === props.program?.Name
      );

      setProgramCertificates(
        _.orderBy(_programCertificates, [programOrder, (item) => item.certificate.certificateType], ["desc", "asc"])
      );
    };

    if (props.user && props.program) {
      getProgramCertificateInfo();
    }
  }, [props.program, props.user]);

  useEffect(() => {
    const getAccessParams = async () => {
      try {
        const response: AWSAccessParams = await AWSService.generateSTSToken(props.user);

        setAccessParams(response);
      } catch (error) {
        console.log(error);
      }
    };

    if (props.user && !accessParams) {
      getAccessParams();
    }
  }, [accessParams, props.user]);

  useEffect(() => {
    const getStandardTagsForRole = (_user: IUser): void => {
      if (_user && _user.role) {
        const role: string = _user.role;
        const roleObject = roles.find((item) => item.Name === role);

        const optional = roleObject?.OptionalRoles;
        const mandatory = roleObject?.MandatoryRoles;

        const mandatoryRoles = mandatory?.map((id) => standards.find((item) => item.id === id));
        const optionalRoles = optional?.map((id) => standards.find((item) => item.id === id));

        setOptionalStandards(_.orderBy(optionalRoles, ["Code"], ["asc"]) || []);

        setMandatoryStandards(_.orderBy(mandatoryRoles, ["Code"], ["asc"]) || []);
      }
    };

    props.user && getStandardTagsForRole(props.user);
  }, [props.user]);

  if (!props.user) {
    return null;
  }

  if (accessParams === null) {
    return null;
  }

  return (
    <Document style={{ fontFamily: "Inter" }}>
      <PDFTitlePage user={props.user} isMajorIncident={props.isMajorIncident} program={props.program} />
      <PDFProgramDetailsPage
        accessParams={accessParams}
        allProgramCertificates={allProgramCertificates}
        programCertificates={programCertificates}
        program={props.program}
        user={props.user}
        userRoles={userRoles}
        userTeam={userTeam}
      />
      <PDFSummaryOfWork isHCPC={props.isHCPC} summaryOfWork={props.summaryOfWork} />
      <PDFPersonalStatement isHCPC={props.isHCPC} personalStatement={props.personalStatement} />
      <PDFProgressChecksPage
        evidence={props.evidence}
        user={props.user}
        program={props.program}
        progressCheckData={props.programProgressCheckData}
      />
      <PDFStandardsPage
        evidence={props.evidence}
        user={props.user}
        program={props.program}
        usageData={props.usageData}
      />
      <PDFIncidentCommanderTable
        user={props.user}
        evidenceWithinLimit={evidenceWithinLimit}
        isMajorIncident={props.isMajorIncident}
        mandatoryStandards={mandatoryStandards}
        optionalStandards={optionalStandards}
      />
      <PDFEvidence
        accessParams={accessParams}
        user={props.user}
        evidence={props.evidence}
        imageMetadata={imageMetadata}
        isHCPC={props.isHCPC}
        program={props.program}
        userPrograms={props.userPrograms}
      />
    </Document>
  );
};

export default ExportedPDF;
