import { useState } from "react";
import { EvidencePart } from "../../../Interfaces";
import { Text, View } from "@react-pdf/renderer";
import options from "./helpers/TimeMask";
import { useMaskito } from "@maskito/react";
import { IonInput } from "@ionic/react";

type Props = {
  children?: React.ReactNode;
  style?: React.CSSProperties;
  definition: EvidencePart;
  onValueChanged?: (value: any, isInput?: boolean) => void;
  onInputBlurred: () => void;
  data?: any;
  isDisplay?: boolean;
  isPDF?: boolean;
  pdfStyles: any;
  initialValue?: any;
};

const TimeInput: React.FC<Props> = ({
  children,
  style,
  onValueChanged,
  onInputBlurred,
  data,
  definition,
  isDisplay,
  isPDF,
  pdfStyles,
  initialValue,
}) => {
  const [value, setValue] = useState<string>(initialValue || "");

  const inputChanged = (event: any): void => {
    const _value = event?.detail?.value || "";

    setValue(_value);
    onValueChanged && onValueChanged(_value, true);
  };

  const inputBlurred = (): void => {
    onInputBlurred && onInputBlurred();
  };

  const timeMask = useMaskito({ options });

  if (isPDF) {
    if (definition["Display Title"] === "Title") {
      return null;
    }

    return (
      <View>
        <Text style={pdfStyles.reflectionHeader}>{(definition["Display Title"] || definition.Name) + ":"}</Text>
        <Text style={pdfStyles.evidenceDate}>{data}</Text>
      </View>
    );
  }

  if (isDisplay) {
    if (definition["Display Title"] === "Title") {
      return <div className="readEvidenceTitle">{data}</div>;
    }

    return (
      <div className="readEvidenceBlock">
        <div className="readEvidenceHeader">{definition["Display Title"] || definition.Name}</div>
        <div className="readEvidenceText">{data}</div>
        <div className="h-[1px] w-full bg-grey-30 mt-4" />
      </div>
    );
  }

  return (
    <div>
      <IonInput
        ref={async (timeRef) => {
          if (timeRef) {
            const input = await timeRef.getInputElement();
            timeMask(input);
          }
        }}
        className="reflectionTagsInput"
        placeholder={definition?.["Placeholder Text"] || "Enter a value..."}
        autocomplete="off"
        value={value}
        onIonChange={inputChanged}
        onIonBlur={inputBlurred}
        inputmode="numeric"
      />
    </div>
  );
};

export default TimeInput;
