import { useRecoilState, useRecoilStateLoadable, useRecoilValueLoadable } from "recoil";
import { IClassSubscription, IUser, ProgramData, ProgramDataVersionInfo, ProgramDataVersions } from "../../Interfaces";
import { programDataAtom, programDataVersionInfoAtom, userAtom, userProgramsAtom } from "../../state/State";
import RemoteDataController from "../../controllers/RemoteDataController";
import { PF_SERVER_URL, PROGRAM_DATA_REFRESH_INTERVAL } from "../../Constants";
import { useCallback, useEffect, useRef, useState } from "react";
import { useDebugUser } from "../auth/useDebugUser";
import * as ProgramUtils from "../../utils/programUtils";
import _ from "lodash";
import { EventRegister } from "react-native-event-listeners";
import DataController from "../../controllers/DataController";
import { NotificationService } from "../../controllers/notificationService";
import { useAppFocus } from "../app/useAppFocus";
import { useConnectionStatus } from "../app/useConnectionStatus";

export interface UseProgramData {
  programData: ProgramData[];
  userPrograms: ProgramData[];
  versionInfo: ProgramDataVersions[];
}

export const useProgramData = (loadData?: boolean): UseProgramData => {
  let interval: any;

  const { isDebugUser } = useDebugUser();
  const connectionStatus = useConnectionStatus();
  const appInForeground = useAppFocus();

  const userObject = useRecoilValueLoadable<IUser | null>(userAtom);
  const [userPrograms, setUserPrograms] = useRecoilState<ProgramData[]>(userProgramsAtom);
  const [programData, setProgramData] = useRecoilStateLoadable<ProgramData[]>(programDataAtom);
  const [versionInfo, setVersionInfo] = useRecoilState<ProgramDataVersions[]>(programDataVersionInfoAtom);

  const [shouldLoad, setShouldLoad] = useState<boolean>(true);
  const [count, setCount] = useState<number>(0);

  const getProgramData = async (user: IUser, debug: boolean, programIDs?: string[]): Promise<ProgramData[] | null> => {
    try {
      const url: string = PF_SERVER_URL + "/portfolio/git/getProgramsGit";

      // console.log("getProgramData", user);
      const contactID = user.userData?.contactID;
      const token = user.token;

      const response: Response = await fetch(url, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ContactID: `${contactID}`,
          Token: token,
          debug: debug ?? false,
          ...(programIDs && { programs: programIDs }),
        }),
      });

      if (response) {
        const json = await response.json();

        if (json.success) {
          return json.body ?? [];
        } else {
          return null;
        }
      } else {
        return null;
      }
    } catch (error: any) {
      console.log(error);
      RemoteDataController.logError(error);
      return null;
    }
  };

  const getProgramDataVersions = async (user: IUser, debug: boolean): Promise<ProgramDataVersionInfo[] | null> => {
    try {
      const url: string = PF_SERVER_URL + "/portfolio/git/getVersions";

      const contactID = user.userData?.contactID;
      const token = user.token;

      const response: Response = await fetch(url, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ContactID: `${contactID}`,
          Token: token,
          debug: debug ?? false,
        }),
      });

      if (response) {
        const json = await response.json();

        if (json.success) {
          const versionInfo: ProgramDataVersionInfo[] = json.body;

          return versionInfo;
        } else {
          return null;
        }
      } else {
        return null;
      }
    } catch (error: any) {
      console.log(error);
      RemoteDataController.logError(error);
      return null;
    }
  };

  const hasProgramUpdates = (
    currentVersions: ProgramDataVersionInfo[],
    otaVersions: ProgramDataVersionInfo[]
  ): boolean => {
    let result = false;

    const length = otaVersions.length;

    for (let i = 0; i < length; i++) {
      const otaVersion = otaVersions[i];
      const currentVersion = currentVersions.find((item) => item.ID === otaVersion.ID);

      if (currentVersion && otaVersion.version !== currentVersion.version) {
        result = true;
        break;
      }
    }

    // console.log("hasProgramUpdates", result);

    return result;
  };

  const checkProgramVersions = useCallback(
    async (debug: boolean): Promise<boolean> => {
      const currentVersions = ProgramUtils.mapProgramDataVersionInfo(programData.contents);
      const otaVersions = await getProgramDataVersions(userObject.contents, debug);

      // console.log(debug, currentVersions, otaVersions);

      let shouldUpdate = false;

      if (otaVersions) {
        shouldUpdate = hasProgramUpdates(currentVersions, otaVersions);
      }

      return shouldUpdate;
    },
    [userObject, programData]
  );

  const checkUserHasProgramAccess = useCallback((): boolean => {
    let result = false;

    // console.log(user.contents);

    if (userObject.contents) {
      const allSubscriptions: IClassSubscription[] = userObject.contents.userData?.subscriptions ?? [];
      const length = allSubscriptions.length;

      for (let i = 0; i < length; i++) {
        const subscription = allSubscriptions[i];
        const subscriptionID = subscription.SubscriptionID;
        const subscriptionPrograms = subscription.ParaFolioPrograms;

        if (subscriptionID === 1531) {
          result = true;
          break;
        }

        if (subscriptionPrograms && subscriptionPrograms.length > 0) {
          result = true;
          break;
        }
      }
    }

    return result;
  }, [userObject.contents]);

  const getProgramsForUser = async (user: IUser, programs: ProgramData[]): Promise<void> => {
    try {
      let array = [];
      const allSubscriptions: IClassSubscription[] = user.userData?.subscriptions ?? [];

      const length = allSubscriptions.length;

      for (let i = 0; i < length; i++) {
        const subscription = allSubscriptions[i];
        const subscriptionID = subscription.SubscriptionID;
        const subscriptionPrograms = subscription.ParaFolioPrograms;

        const programList = programs.filter((item) => {
          // Subscription field in Program table - refers to subscription ID or ParaFolioPrograms name
          if (item.Subscription) {
            if (subscriptionPrograms && subscriptionPrograms?.length > 0) {
              return subscriptionPrograms.some((_sub) => item.Subscription?.includes(_sub));
            } else if (item.Subscription.includes(`${subscriptionID}`)) {
              return true;
            }
          }

          return false;
        });

        if (programList.length > 0) {
          array.push(...programList);
        }
      }

      array = _.uniqBy(array, "ID");

      if (!DataController.isWeb()) {
        await NotificationService.subscribeToTopics(user, array);
      }

      // console.log(array);

      if (!_.isEqual(userPrograms, array)) {
        setUserPrograms(array);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const loadProgramData = useCallback(
    async (debug: boolean): Promise<void> => {
      try {
        setShouldLoad(false);

        if (programData.state === "hasValue" && (!programData.contents || programData.contents?.length === 0)) {
          // console.log("loadProgramData", debug);
          const data = await getProgramData(userObject.contents, debug);
          appInForeground && EventRegister.emitEvent("ota/update-started", true);

          if (data) {
            setProgramData(data);
            await getProgramsForUser(userObject.contents, data);
            await getVersionInfo(userObject.contents, data);
            appInForeground && EventRegister.emitEvent("ota/update-completed", true);
          }
        } else {
          const shouldUpdate = await checkProgramVersions(debug);

          if (shouldUpdate) {
            appInForeground && EventRegister.emitEvent("ota/update-started", false);
            // console.log("loadProgramData, shouldUpdate", shouldUpdate, debug);
            const data = await getProgramData(userObject.contents, debug);

            if (data) {
              setProgramData(data);
              await getProgramsForUser(userObject.contents, data);
              await getVersionInfo(userObject.contents, data);
              appInForeground && EventRegister.emitEvent("ota/update-completed", true);
            }
          } else {
            appInForeground && EventRegister.emitEvent("ota/update-completed");
            await getVersionInfo(userObject.contents, programData.contents);
          }
        }

        setShouldLoad(false);
      } catch (error: any) {
        console.log(error);
        RemoteDataController.logError(error);
        setShouldLoad(false);
        appInForeground && EventRegister.emitEvent("ota/update-errored");
      }
    },
    [userObject, programData.state, appInForeground]
  );

  const getVersionInfo = useCallback(
    async (user: IUser, data: ProgramData[]): Promise<void> => {
      const currentVersions = ProgramUtils.mapProgramDataVersionInfo(data);
      const otaVersions = await getProgramDataVersions(user, true);
      const releaseVersions = await getProgramDataVersions(user, false);

      setVersionInfo([
        { type: "User", versionInfo: currentVersions },
        { type: "Release", versionInfo: releaseVersions ?? [] },
        { type: "Debug", versionInfo: otaVersions ?? [] },
      ]);
    },
    [programData]
  );

  const load = useCallback(async () => {
    if (loadData && checkUserHasProgramAccess()) {
      // console.log("load", isDebugUser, shouldLoad.current, versionInfo, userObject, programData);

      if (userObject.state === "hasValue" && userObject.contents && programData.state === "hasValue") {
        await loadProgramData(isDebugUser);
      }
    }
  }, [userObject, programData, loadData, isDebugUser]);

  const restartLoad = () => {
    setCount((prev) => (prev < 100 ? prev + 1 : 0));
    setShouldLoad(true);
  };

  const startInterval = () => {
    interval = setInterval(() => {
      restartLoad();
    }, PROGRAM_DATA_REFRESH_INTERVAL); // 10 Minutes
  };

  useEffect(() => {
    if (loadData) {
      startInterval();
    }

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    EventRegister.addEventListener("ota/load-data", () => restartLoad());

    return () => {
      EventRegister.removeEventListener("ota/load-data");
    };
  });

  useEffect(() => {
    setShouldLoad(userObject.contents ? true : false);
  }, [userObject.contents?.email, isDebugUser]);

  useEffect(() => {
    if (connectionStatus?.connected && loadData && shouldLoad) {
      // console.log("loadData useEffect", loadData, shouldLoad, isDebugUser, count);
      load();
    }
  }, [loadData, shouldLoad, isDebugUser, count, connectionStatus?.connected]);

  return {
    programData: programData.contents as ProgramData[],
    userPrograms,
    versionInfo,
  };
};
