import { useEffect, useState } from "react";
import _ from "lodash";
import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonImg,
  IonPage,
  IonSpinner,
  IonToolbar,
} from "@ionic/react";
import DataController from "../../controllers/DataController";
import { Capacitor } from "@capacitor/core";
import HeaderTitle from "../../components/common/HeaderTitle";
import { useHistory, useLocation, useParams } from "react-router";
import { useRecoilValueLoadable } from "recoil";
import {
  AWSAccessParams,
  CompetenceActivityClass,
  EvidenceDefinition,
  EvidencePart,
  IClassSubscription,
  IEvidence,
  IUser,
  IUserProgramCertificate,
  ProgramCertificate,
  ProgramData,
  ProgramSkill,
} from "../../Interfaces";
import { evidenceAtom, userAtom } from "../../state/State";
import EvidenceContainer from "../../components/evidence/EvidenceContainer";
import ProgramInfoCard from "../../components/home/ProgramInfoCard";
import { Icon_Add_Evidence } from "../../assets/images";
import CertificateModal from "../../components/programs/CertificateModal";
import "../../components/programs/ProgramComponents.css";
import { EventRegister } from "react-native-event-listeners";
import { addYears, format, isAfter, isSameMonth, isSameYear, subMonths } from "date-fns";
import AWSService from "../../controllers/AWSService";
import WriteBlob from "capacitor-blob-writer";
import { FileOpener } from "@capacitor-community/file-opener";
import { Directory } from "@capacitor/filesystem";
import mime from "mime";
import { FirebaseService } from "../../controllers/FirebaseService";
import ProgramEvidenceCard from "../../components/programs/ProgramEvidenceCard";
import { useProgramData } from "../../hooks/data/useProgramData";
import { useProgramSkills } from "../../hooks/programs/useProgramSkills";

const ProgramSkillPage: React.FC = (props) => {
  const history = useHistory();
  const params = useParams<{ programId: string; skillId: string }>();
  const location = useLocation();
  const { userPrograms } = useProgramData();

  console.log(params);
  const _skillData = useProgramSkills(params.programId, params.skillId);

  const user = useRecoilValueLoadable<IUser | null>(userAtom);
  const evidence = useRecoilValueLoadable<IEvidence[] | null>(evidenceAtom);

  const program = userPrograms.find((item) => item.ID === params.programId)!;

  const [skills, setSkills] = useState<ProgramSkill[] | any[]>([]);
  const [requiredSkills, setRequiredSkills] = useState<ProgramSkill[] | any[]>([]);
  const [certificates, setCertificates] = useState<ProgramCertificate[] | any[]>([]);
  const [competence, setCompetence] = useState<CompetenceActivityClass | any>(null);
  const [evidenceDefinitions, setEvidenceDefinitions] = useState<EvidenceDefinition | any>(null);
  const [competences, setCompetences] = useState<CompetenceActivityClass[] | any[]>([]);
  const [programCertificates, setProgramCertificates] = useState<IUserProgramCertificate[] | any[]>([]);

  const [mappedSkills, setMappedSkills] = useState<any[]>([]);
  const [mappedDisplaySkills, setMappedDisplaySkills] = useState<any[]>([]);
  const [techniquesShown, setTechniquesShown] = useState<boolean>(false);
  const [programEvidence, setProgramEvidence] = useState<IEvidence[]>([]);

  const [certificateModalVisible, setCertificateModalVisible] = useState<boolean>(false);
  const [selectedCertificate, setSelectedCertificate] = useState<ProgramCertificate | any>(null);

  const [viewingCertificate, setViewingCertificate] = useState<boolean>(false);
  const [viewingCertificateFilename, setViewingCertificateFilename] = useState<string>("");
  const [hasCertificate, setHasCertificate] = useState<boolean>(false);

  const [startDate, setStartDate] = useState<Date | null>(null);
  const [datesToShow, setDatesToShow] = useState<Date[]>([]);

  useEffect(() => {
    const getSkillDisplayDates = (start: Date): Date[] => {
      let dates: Date[] = [];

      const currentDate = new Date();

      if (isSameYear(start, currentDate)) {
        if (isSameMonth(start, currentDate)) {
          dates = [currentDate];
        } else {
          let date = new Date();

          while (isSameYear(date, currentDate) && isAfter(date, start)) {
            dates.push(date);

            date = subMonths(date, 1);
          }
        }
      } else {
        let date = new Date();

        while (isSameYear(date, currentDate) && isAfter(date, start)) {
          dates.push(date);

          date = subMonths(date, 1);
        }
      }

      // console.log(dates);

      return dates.reverse();
    };

    const getDatesToShow = (): void => {
      let dates = getSkillDisplayDates(startDate!);

      setDatesToShow(dates);
    };

    if (startDate) {
      getDatesToShow();
    }
  }, [startDate]);

  useEffect(() => {
    const getProgramStartDate = async (): Promise<void> => {
      const subscriptions = user.contents?.userData.subscriptions || [];
      const programSubscriptionIDs = program?.Subscription || [];

      const programSubscription: IClassSubscription = subscriptions.find((item: IClassSubscription | any) =>
        programSubscriptionIDs.includes(`${item.SubscriptionID}`)
      );

      if (programSubscription && programSubscription.App_Data) {
        let json: any = {};

        try {
          json = JSON.parse(programSubscription.App_Data);
        } catch (error) {
          console.log(error);
        }

        if (json[`${program.ID}`]?.startDate) {
          setStartDate(new Date(json[`${program.ID}`]?.startDate));
        }
      }
    };

    if (user.state === "hasValue" && program) {
      getProgramStartDate();
    }
  }, [program, user]);

  useEffect(() => {
    const getProgramData = (): void => {
      if (program) {
        const allCompetences: CompetenceActivityClass[] | any[] = program?.CompetenceActivity || [];
        const _competence: any = allCompetences.find(
          (item: any): item is CompetenceActivityClass => item.ID === params.skillId
        );

        if (_competence) {
          const _skills = _competence?.Skills || [];

          const required = _skills.filter(
            (item: any): item is ProgramSkill =>
              item && item.numberRequiredInDuration && parseInt(item.numberRequiredInDuration, 10) > 0
          );
          const evidenceDefinition = _competence["Evidence Definitions"][0] || [];
          const _evidenceDefinitions = evidenceDefinition["Evidence parts"];

          const _certificates = _competence.Certificates || [];

          setCompetence(_competence);

          const _competences = program.CompetenceActivity || [];

          // console.log('Competences: ', _competences);

          if (_competences) {
            setCompetences(_competences);
          }

          if (_skills) {
            setSkills(_skills);

            if (required) {
              setRequiredSkills(required);
            }
          }

          if (_certificates) {
            setCertificates(_certificates);
          }

          if (_evidenceDefinitions.length > 0) {
            setEvidenceDefinitions(_evidenceDefinitions[0]);
          }
        }
      }
    };

    getProgramData();
  }, [program?.version]);

  useEffect(() => {
    // Get all evidence for the selected competence for display in list
    const getEvidenceForCompetence = (): void => {
      let evidenceForCompetence: IEvidence[] = [];

      evidenceForCompetence = evidence.contents?.filter((evidence: IEvidence) => {
        const evidenceJSON = evidence.evidenceJSON && JSON.parse(evidence.evidenceJSON);
        const competenceName = evidenceJSON?.programInfo?.competence || "";

        return competenceName === competence?.Name;
      });

      evidenceForCompetence = _.orderBy(evidenceForCompetence, "date", "desc");

      if (skills.length > 0) {
        const evidenceDefinitions = competence["Evidence Definitions"][0];
        const _evidenceParts = evidenceDefinitions?.["Evidence parts"] || [];

        const evidencePartWithSkills = _evidenceParts.filter(
          (item: EvidencePart) => item.Skills && item.Skills?.length > 0
        );

        let skillsWithinEvidence = evidenceForCompetence
          .filter((evidence: IEvidence) => evidence.draft !== true)
          .map((item: IEvidence) => {
            const _evidence = JSON.parse(item.evidenceJSON);
            return _evidence[evidencePartWithSkills[0]["Name"]];
          });

        skillsWithinEvidence = _.flatten(skillsWithinEvidence);

        let _skills = [];

        for (let i = 0; i < skills.length; i++) {
          const skill = skills[i];
          const count = skillsWithinEvidence.filter((item: string) => {
            // console.log(item, skill.Name);

            if (item?.toLowerCase()?.startsWith("other") && skill.Name === "Other") {
              return true;
            } else {
              return item?.toLowerCase() === skill?.Name?.toLowerCase();
            }
          }).length;

          _skills.push({
            name: skill.Name,
            count: count,
          });
        }

        _skills = _.orderBy(_skills, "count", "desc");

        setMappedSkills(_skills);
        setMappedDisplaySkills(_skills);
      }

      setProgramEvidence(evidenceForCompetence);
    };

    getEvidenceForCompetence();
  }, [competence, evidence, evidenceDefinitions, skills]);

  useEffect(() => {
    const getUserProgramCertificates = (): void => {
      let _programCertificates = user.contents.programCertificates || [];
      _programCertificates = _programCertificates.filter(
        (item: IUserProgramCertificate) => item.programData.programName === program?.Name
      );

      setProgramCertificates(_programCertificates);
    };

    if (user.state === "hasValue" && user.contents) {
      getUserProgramCertificates();
    }
  }, [program, user]);

  useEffect(() => {
    const getDisplaySkills = (): void => {
      if (!techniquesShown) {
        let array = mappedSkills.slice(0, 3);

        setMappedDisplaySkills(array);
      } else {
        setMappedDisplaySkills(mappedSkills);
      }
    };

    if (mappedSkills.length > 0) {
      getDisplaySkills();
    }
  }, [mappedSkills, techniquesShown]);

  useEffect(() => {
    const checkHasCertificate = (): void => {
      const userCertificate: IUserProgramCertificate = programCertificates.find(
        (cert: IUserProgramCertificate) =>
          cert.programData.programName === program?.Name && cert.programData.competenceName === competence?.Name
      );

      setHasCertificate(userCertificate !== undefined);
    };

    checkHasCertificate();
  }, [certificates, competence, program, programCertificates]);

  const addEnhancedEvidence = (): void => {
    const data = {
      program: program,
      competences: competences,
      competence: competence,
      skill: null,
    };

    FirebaseService.logEvent("competence_add_enhanced_evidence", {
      program: program?.Name,
      competence: competence?.Name,
    });

    EventRegister.emit("evidence/add-program-evidence", data);
  };

  const addRequiredSkillEvidence = (skill: ProgramSkill): void => {
    const data = {
      program: program,
      competences: competences,
      competence: competence,
      skill: skill,
    };

    FirebaseService.logEvent("competence_required_skill_evidence", {
      program: program?.Name,
      competence: competence?.Name,
      skill: skill?.Name,
    });

    EventRegister.emit("evidence/add-skill-evidence", data);
  };

  const uploadCertificate = (item: ProgramCertificate): void => {
    FirebaseService.logEvent("competence_add_certificate_pressed", {
      program: program?.Name,
      certificate: item?.Name,
    });

    // console.log('uploadCertificate', item);
    setSelectedCertificate(item);
    setCertificateModalVisible(true);
  };

  const editCertificate = (): void => {
    const _certificate = certificates.find((item) => item?.Name === competence?.Name);

    // console.log(_certificate);
    setSelectedCertificate(_certificate);

    setCertificateModalVisible(true);
  };

  const closeCertificateModal = (): void => {
    setSelectedCertificate(null);
    setCertificateModalVisible(false);
  };

  const toggleTechniques = (): void => {
    if (!techniquesShown) {
      FirebaseService.logEvent("competence_show_all_techniques", {});
    }

    setTechniquesShown(!techniquesShown);
  };

  const openCertificate = async (item: IUserProgramCertificate): Promise<void> => {
    try {
      if (user.state === "hasValue" && user.contents) {
        setViewingCertificate(true);
        setViewingCertificateFilename(item.certificate?.name || "");

        const accessParams: AWSAccessParams = await AWSService.generateSTSToken(user.contents!);

        const file = await AWSService.openCertificateFromServer(user.contents!, accessParams, item.certificate);

        if (await DataController.isWebAsync()) {
          const url = window.URL.createObjectURL(
            new Blob([new Uint8Array(file.Body.data)], {
              type: file.ContentType,
            })
          );
          window.open(url, "_blank");
        } else {
          const blob = new Blob([new Uint8Array(file.Body.data)], {
            type: file.ContentType,
          });

          const filePath = await WriteBlob({
            path: `ParaFolio/Certificates/${item.certificate?.name}.${mime.getExtension(file.ContentType)}`,
            blob,
            directory: Directory.Cache,
            recursive: true,
          });

          FileOpener.open({
            filePath,
          });
        }

        setViewingCertificate(false);
        setViewingCertificateFilename("");
      }
    } catch (error: any) {
      console.log(error);
      window.alert("An error occurred when opening certificate");
      setViewingCertificate(false);
      setViewingCertificateFilename("");
    }
  };

  const evidenceCardClicked = (item: IEvidence): void => {
    let _evidence = _.pickBy(_.cloneDeep(item), _.identity);

    const programID = program?.ID;
    const skillID = competence.ID;

    history.push(`/dashboard/program/${programID}/${skillID}/read/${_evidence.id}`);
  };

  const evidenceCount = programEvidence.filter((item) => !item.draft).length;

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar
          mode="ios"
          className="navBar"
          style={{
            maxWidth: DataController.isWeb() ? 980 : undefined,
            height: Capacitor.getPlatform() === "android" ? "54px" : "unset",
          }}
        >
          <IonButtons slot="start">
            <IonBackButton
              className="header-back-buttons"
              defaultHref={`/dashboard/program/${program?.ID}`}
              text={DataController.getBackIconText()}
              icon={DataController.getBackIconType()}
              style={{
                marginLeft: Capacitor.getPlatform() === "android" ? 8 : 0,
                "--icon-font-size": Capacitor.getPlatform() === "android" ? "24px" : "30px",
              }}
            />
          </IonButtons>
          <HeaderTitle>{competence?.Name || ""}</HeaderTitle>
          {hasCertificate && (
            <IonButtons slot="end">
              <IonButton mode="ios" className="cancelButton" onClick={() => editCertificate()}>
                {"Edit"}
              </IonButton>
            </IonButtons>
          )}
        </IonToolbar>
      </IonHeader>
      <IonContent className="page-background">
        <EvidenceContainer style={{ paddingTop: 0 }}>
          <CertificateModal
            visible={certificateModalVisible}
            closeModal={() => closeCertificateModal()}
            certificate={selectedCertificate}
            program={program}
            competence={competence}
          />
          <ProgramInfoCard title={competence?.Name || ""}>
            {certificates.map((item: ProgramCertificate, index) => {
              const userCertificate: IUserProgramCertificate = programCertificates.find(
                (cert: IUserProgramCertificate) =>
                  cert.programData.programName === program?.Name && cert.programData.competenceName === item.Name
              );

              // console.log(item);
              let expired = false;
              const addExpires = userCertificate !== undefined;

              if (addExpires && userCertificate.issueDate) {
                const expiry = addYears(new Date(userCertificate.issueDate), 1);
                expired = isAfter(new Date(), new Date(expiry));
              }

              const disabled = viewingCertificate && viewingCertificateFilename !== userCertificate?.certificate?.name;
              const viewing = viewingCertificate && viewingCertificateFilename === userCertificate?.certificate?.name;

              return (
                <div key={item.Name} className="techniqueCertificateMain">
                  <div className="text-acc-13px font-semibold text-grey-70">{"Certificate"}</div>
                  <div className="techniqueCertificateContainer">
                    <button
                      disabled={disabled}
                      title={userCertificate ? userCertificate.certificate?.name : undefined}
                      key={item.Name || index}
                      className={`homeCardUserInfoButtonSlim ${viewing ? "homeCardUserInfoButtonSlimWait" : ""} ${disabled ? "homeCardUserInfoButtonSlimDisabled" : ""}`}
                      onClick={() =>
                        !(disabled || viewing) &&
                        (userCertificate ? openCertificate(userCertificate) : uploadCertificate(item))
                      }
                    >
                      <div className="text-acc-16px leading-[1.5] tracking-[-0.2px] text-cta-blue whitespace-nowrap overflow-hidden truncate">
                        {userCertificate ? userCertificate.certificate?.name : "Upload certificate"}
                      </div>
                      {viewing && <IonSpinner className="homeCardCertificateSpinner" />}
                    </button>
                    {item.Duration && (
                      <div className={DataController.getCertificatePillContainer(userCertificate, item.Duration)}>
                        {addExpires && (expired ? "Expired " : "Expires ")}
                        {DataController.getCertificatePillString(userCertificate, item.Duration)}
                      </div>
                    )}
                  </div>
                  {expired && <div className="programCertificateExpiredText">{competence["Expire Notice"]}</div>}
                  {!userCertificate && (
                    <IonButton
                      mode="ios"
                      className="programCardButtonBlue"
                      style={{ alignSelf: "center" }}
                      onClick={() => uploadCertificate(item)}
                    >
                      <div className="accountButtonInner">
                        <div className="programCardButtonBlueText">{"Upload certificate"}</div>
                      </div>
                    </IonButton>
                  )}
                </div>
              );
            })}
            {skills.length > 0 && (
              <div>
                <div className="techniquesHeaderContainer">
                  <div className="text-acc-15px font-bold text-grey-90">{`${competence?.SkillName} tagged` || ""}</div>
                  {skills.length > 3 && (
                    <button
                      className="text-acc-15px leading-[var(--accessibility-line-height)] text-right text-cta-blue text-decoration-[var(--accessibility-underline-links)] cursor-pointer bg-transparent bg-no-repeat overflow-hidden outline-none"
                      onClick={() => toggleTechniques()}
                    >
                      {techniquesShown ? "Showing all" : "Showing top 3"}
                    </button>
                  )}
                </div>
                {mappedDisplaySkills.map((item: any, index) => {
                  return (
                    <div key={item?.name} className="techniqueEvidenceCountContainer">
                      <div className="text-acc-15px leading-[var(--accessibility-line-height)] text-grey-90">
                        {item?.name}
                      </div>
                      <div className="text-acc-15px font-bold text-grey-90 leading-[var(--accessibility-line-height)]">
                        {item?.count}
                      </div>
                    </div>
                  );
                })}
                <div className="techniqueEvidenceCountContainer">
                  <div className="text-acc-15px font-bold text-grey-90 leading-[var(--accessibility-line-height)]">
                    {"Total evidence"}
                  </div>
                  <div className="text-acc-15px font-bold text-grey-90 leading-[var(--accessibility-line-height)]">
                    {evidenceCount}
                  </div>
                </div>
              </div>
            )}
            <IonButton
              mode="ios"
              className="programCardButton"
              style={{ alignSelf: "center" }}
              onClick={() => addEnhancedEvidence()}
            >
              <div className="accountButtonInner">
                <IonImg src={Icon_Add_Evidence} className="accountButtonIcon" />
                <div className="accountButtonText">{"Add new evidence"}</div>
              </div>
            </IonButton>
          </ProgramInfoCard>
          {requiredSkills.map((item: ProgramSkill, index) => {
            const evidenceDefinitions = competence["Evidence Definitions"][0];
            const _evidenceParts = evidenceDefinitions?.["Evidence parts"] || [];
            const evidencePartWithSkills = _evidenceParts.filter(
              (item: EvidencePart) => item.Skills && item.Skills.length > 0
            )[0];

            const evidenceWithSkills = programEvidence?.filter((_evidence) => {
              const evidenceJSON = JSON.parse(_evidence.evidenceJSON);
              const part = evidenceJSON[evidencePartWithSkills.Name];

              const onHoldReset = evidenceJSON?.OnHoldReset ?? 0;

              return part !== undefined && onHoldReset !== 1 && _evidence.draft !== true;
            });

            const total = item.numberRequiredInDuration || "0";
            const count =
              evidenceWithSkills?.filter((_evidence) => {
                const evidenceJSON = JSON.parse(_evidence.evidenceJSON);
                const part = evidenceJSON[evidencePartWithSkills.Name];
                const achieved = part?.includes(item.Name);

                return achieved && isSameMonth(new Date(), new Date(_evidence.date));
              }).length || 0;

            const array = Array.from(Array(parseInt(total, 10)).keys());

            return (
              <ProgramInfoCard title={item.Name} key={item.Name}>
                <>
                  <div className="programCardRequiredSkillContainer" key={item.id}>
                    <div className="text-acc-13px text-grey-70">
                      {`To ensure recertification in ${competence?.Name}, you must complete ${total} piece${parseInt(total, 10) !== 1 ? "s" : ""} of CPD on ${item.Name} per ${item.durationDescription}`}
                    </div>
                    <div className="programCardRequiredSkillResult">
                      <div
                        className={`programCardRequiredSkillAchieved ${count > 0 && "programCardRequiredSkillAchievedGreen"}`}
                      >
                        {`${count}`}
                      </div>
                      <div className="programCardRequiredSkillNeeded">
                        &nbsp;
                        {"/ "}
                        {parseInt(total, 10)}
                      </div>
                    </div>
                    <div className="programCardRequiredGraph">
                      {array.map((value, index) => {
                        const isEnd = index === array.length - 1;
                        const completed = index < count;

                        return (
                          <div className="programSegmentContainer" key={index}>
                            <div className={`${completed ? "programSegmentFilled" : "programSegmentEmpty"}`} />
                            {!isEnd && <div className="programGraphSeparator" />}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                  <div className="techniqueRequiredSkillContainer">
                    <IonButton
                      mode="ios"
                      className="programCardButton"
                      style={{ alignSelf: "center" }}
                      onClick={() => addRequiredSkillEvidence(item)}
                    >
                      <div className="accountButtonInner">
                        <IonImg src={Icon_Add_Evidence} className="accountButtonIcon" />
                        <div className="accountButtonText">{`Add new evidence`}</div>
                      </div>
                    </IonButton>
                  </div>
                  {datesToShow.map((date, index) => {
                    const totalNumber = parseInt(total, 10);

                    const count =
                      evidenceWithSkills?.filter((_evidence) => {
                        const evidenceJSON = JSON.parse(_evidence.evidenceJSON);
                        const part = evidenceJSON[evidencePartWithSkills.Name];
                        const achieved = part?.includes(item.Name);

                        return achieved && isSameMonth(date, new Date(_evidence.date));
                      }).length || 0;

                    const getCountClass = (): string => {
                      if (count >= totalNumber) {
                        return "techniqueRequiredSkillMonthCountAchievedGreen";
                      }

                      if (isSameMonth(date, new Date())) {
                        return "";
                      }

                      return "techniqueRequiredSkillMonthCountAchievedRed";
                    };

                    const showError = (): boolean => {
                      if (isSameMonth(date, new Date())) {
                        return false;
                      }

                      return count < totalNumber;
                    };

                    return (
                      <div key={date.toISOString()} className="techniqueRequiredSkillMonthContainer">
                        <div className="techniqueRequiredSkillMonthRow">
                          <div className="techniqueRequiredSkillMonthText">{format(date, "MMMM")}</div>
                          <div className="techniqueRequiredSkillMonthCountContainer">
                            <div
                              className={`techniqueRequiredSkillMonthCountAchieved ${getCountClass()}`}
                            >{`${count}`}</div>
                            <div className="techniqueRequiredSkillMonthCountRequired">
                              &nbsp;
                              {"/ "}
                              {parseInt(total, 10)}
                            </div>
                          </div>
                        </div>
                        {showError() && (
                          <div className="techniqueRequiredSkillMonthErrorText">
                            {`You haven’t added ${count === 0 ? "" : "enough"} evidence for this month`}
                          </div>
                        )}
                      </div>
                    );
                  })}
                </>
              </ProgramInfoCard>
            );
          })}
          <ProgramInfoCard
            title="Evidence"
            style={{ paddingTop: 14, paddingBottom: 8 }}
            subTitle={
              programEvidence.length === 0
                ? "Any evidence you record for this enhanced skill will appear here."
                : undefined
            }
          >
            {programEvidence.length > 0 && (
              <div>
                {programEvidence.map((item) => (
                  <div key={item.id}>
                    <ProgramEvidenceCard
                      key={item.id}
                      buttonPressed={() => evidenceCardClicked(item)}
                      programEvidence={item}
                    />
                  </div>
                ))}
              </div>
            )}
          </ProgramInfoCard>
        </EvidenceContainer>
      </IonContent>
    </IonPage>
  );
};

export default ProgramSkillPage;
