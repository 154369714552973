import { IonImg, IonItem, IonLabel } from "@ionic/react";
import { Button_Disclosure_Right } from "../../assets/images";
import { IProfileSettingsButton } from "../../Interfaces";
import "./ProfileComponents.css";

const ProfileSettingsButton = ({
  icon,
  title,
  inProgress,
  syncingData,
  comingSoon,
  buttonClicked,
  showDisclosure,
  logout,
}: IProfileSettingsButton) => {
  return (
    <IonItem
      button={!comingSoon && !inProgress && !syncingData}
      onClick={() => buttonClicked?.()}
      className="profileSettingsButton"
      detail={false}
    >
      <div className="profileSettingsButtonContainer">
        <div className="profileSettingsButtonInnerContainer">
          <IonImg src={icon} className={`profileSettingIcon ${syncingData ? "profileSettingIconRotate" : ""}`} />
          <IonLabel className={`profileSettingButtonTitle ${logout && "profileSettingLogout"}`}>{title}</IonLabel>
          {inProgress && <div className="developmentProgressPill">{"IN PROGRESS"}</div>}
          {comingSoon && <div className="developmentProgressPill">{"COMING SOON"}</div>}
        </div>
        {showDisclosure && <IonImg src={Button_Disclosure_Right} className="profileSettingDisclosure" />}
      </div>
    </IonItem>
  );
};

export default ProfileSettingsButton;
