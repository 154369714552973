import React from "react";
import "../../theme/tailwind.css";
import { IMandatoryCertificateDisplay } from "../../types/Components";
import { IUserProgramCertificate, Required } from "../../Interfaces";

const MandatoryCertificateDisplay: React.FC<IMandatoryCertificateDisplay> = (props) => {
  const mandatory = props.certificate.Required === Required.Mandatory;

  if (mandatory) {
    const hasCertificate = props.programCertificates.find(
      (certificate: IUserProgramCertificate) =>
        certificate.certificate?.certificateType.toUpperCase() === props.certificate.Name.toUpperCase()
    );

    if (hasCertificate) {
      return null;
    }

    return (
      <div>
        <div className="mt-[8px] mb-[12px]">
          <div className="font-inter text-[13px] leading-[1.54] tracking-[-0.1px] text-grey-70">
            {`${props.certificate.Name} certificate`}
          </div>
          <div className="flex flex-row justify-between items-center">
            <button
              className="flex min-w-0 flex-[-1] flex-row items-center !min-h-[26px] bg-transparent self-start mr-[8px] pointer-events-auto program-certificate-button"
              key={props.certificate.Name}
              onClick={props.buttonPressed}
            >
              <div className="font-inter text-[16px] leading-default tracking-default text-cta-blue whitespace-nowrap overflow-hidden text-ellipsis">
                {"Add certificate"}
              </div>
            </button>
            <div className="shrink-0 h-[20px] py-0 px-[8px] bg-[#FCBAAC] rounded-[10px] font-inter text-[13px] font-semibold leading-[1.54] tracking-[-0.05px] text-right text-[#890012]">
              {"No certificate"}
            </div>
          </div>
        </div>
      </div>
    );
  }

  return null;
};

export default MandatoryCertificateDisplay;
