import { Font } from "@react-pdf/renderer";
import InterBlack from '../assets/fonts/Inter-Black.ttf'
import InterBlackItalic from '../assets/fonts/Inter-BlackItalic.ttf';
import InterBold from '../assets/fonts/Inter-Bold.ttf';
import InterBoldItalic from '../assets/fonts/Inter-BoldItalic.ttf';
import InterExtraBold from '../assets/fonts/Inter-ExtraBold.ttf';
import InterExtraBoldItalic from '../assets/fonts/Inter-ExtraBoldItalic.ttf';
import InterExtraLight from '../assets/fonts/Inter-ExtraLight.ttf';
import InterExtraLightItalic from '../assets/fonts/Inter-ExtraLightItalic.ttf';
import InterLight from '../assets/fonts/Inter-Light.ttf';
import InterLightItalic from '../assets/fonts/Inter-LightItalic.ttf';
import InterMedium from '../assets/fonts/Inter-Medium.ttf';
import InterMediumItalic from '../assets/fonts/Inter-MediumItalic.ttf';
import InterRegular from '../assets/fonts/Inter-Regular.ttf';
import InterItalic from '../assets/fonts/Inter-Italic.ttf';
import InterSemiBold from '../assets/fonts/Inter-SemiBold.ttf';
import InterSemiBoldItalic from '../assets/fonts/Inter-SemiBoldItalic.ttf';
import InterThin from '../assets/fonts/Inter-Thin.ttf';
import InterThinItalic from '../assets/fonts/Inter-ThinItalic.ttf';

/**
 * Registers a list of fonts and fallbacks for use in the @react/pdf library
 */
export function registerFonts(): void {
  const fonts: string[] = [
    'Inter',
     'Calibri',
     'RobotoCondensed-Regular',
    'Arial',
    'RobotoCondensed-Bold',
    'Tahoma',
    'Times New Roman',
    'Symbol',
    'DengXian'
  ];

  const length = fonts.length;

  for (let i = 0; i < length; i++) {
    Font.register({
      family: fonts[i],
      fonts: [
        { src: InterRegular, fontWeight: 400 },
        { src: InterItalic, fontWeight: 400, fontStyle: 'italic' },
        { src: InterThin, fontWeight: 100 },
        { src: InterThinItalic, fontWeight: 100, fontStyle: 'italic' },
        { src: InterExtraLight, fontWeight: 200 },
        { src: InterExtraLightItalic, fontWeight: 200, fontStyle: 'italic' },
        { src: InterLight, fontWeight: 300 },
        { src: InterLightItalic, fontWeight: 300, fontStyle: 'italic' },
        { src: InterMedium, fontWeight: 500 },
        { src: InterMediumItalic, fontWeight: 500, fontStyle: 'italic' },
        { src: InterSemiBold, fontWeight: 600 },
        { src: InterSemiBoldItalic, fontWeight: 600, fontStyle: 'italic' },
        { src: InterBold, fontWeight: 700 },
        { src: InterBoldItalic, fontWeight: 700, fontStyle: 'italic' },
        { src: InterExtraBold, fontWeight: 800 },
        { src: InterExtraBoldItalic, fontWeight: 800, fontStyle: 'italic' },
        { src: InterBlack, fontWeight: 900 },
        { src: InterBlackItalic, fontWeight: 900, fontStyle: 'italic' },
      ]
    });
  }

  console.log('PDF fonts loaded');
}