import { useEffect, useState } from "react";
import { EventRegister } from "react-native-event-listeners";
import { useRecoilValueLoadable } from "recoil";
import { userAtom } from "../../state/State";

export const useRefreshProgramData = () => {
  const user = useRecoilValueLoadable(userAtom);
  const [refreshingData, setRefreshingData] = useState<boolean>(false);

  useEffect(() => {
    EventRegister.addEventListener("program/data-refreshed", () => setRefreshingData(false));

    return () => {
      EventRegister.removeEventListener("program/data-refreshed");
    };
  });

  const refreshUserProgramData = (): void => {
    setRefreshingData(true);

    EventRegister.emit("program/refresh-button", user.contents);
  };

  return { isRefreshingProgramData: refreshingData, refreshUserProgramData };
};
