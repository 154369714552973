import React from "react";
import { IProgramRolesDisplay } from "../../types/Components";
import "../../theme/tailwind.css";

const ProgramRolesDisplay: React.FC<IProgramRolesDisplay> = (props) => {
  return (
    <div className="mt-[8px] mb-[4px]">
      <div className="text-acc-13px leading-[1.54] tracking-[-0.1px] text-grey-70">
        {props.userRoles.length === 1 ? "Your role" : "Your roles"}
      </div>
      <div className="text-acc-17px leading-[1.53] tracking-[-0.3px] text-grey-90">
        {props.userRoles.length > 0 ? props.userRoles.sort().join(", ") : "None"}
      </div>
    </div>
  );
};

export default ProgramRolesDisplay;
