import { useRef, useState } from "react";
import _ from "lodash";
import { EvidenceCustomTag, EvidencePart } from "../../../Interfaces";
import { Text, View } from "@react-pdf/renderer";
import { Icon_Attachment_Delete } from "../../../assets/images";

type Props = {
  children?: React.ReactNode;
  style?: React.CSSProperties;
  definition: EvidencePart;
  onValueChanged?: (value: any) => void;
  setEmptyTagModalVisible: (...args: any) => any;
  setExistingTagModalVisible: (...args: any) => any;
  data?: any;
  isDisplay?: boolean;
  isPDF?: boolean;
  pdfStyles: any;
  initialValue?: any;
};

const CustomTags: React.FC<Props> = ({
  children,
  style,
  onValueChanged,
  setExistingTagModalVisible,
  setEmptyTagModalVisible,
  data,
  definition,
  isDisplay,
  isPDF,
  pdfStyles,
  initialValue,
}) => {
  const ref = useRef<HTMLInputElement>(null);

  const [customTags, setCustomTags] = useState<EvidenceCustomTag[]>(initialValue || []);
  const [value, setValue] = useState<string>("");

  const inputChanged = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setValue(event.target.value);
  };

  const customTagKeyPressed = (event: React.KeyboardEvent): void => {
    if (event.key === "Enter") {
      if (value.trim() === "") {
        setEmptyTagModalVisible(true);
      } else if (!customTags.find((tag) => tag.tag.toUpperCase() === value.toUpperCase().trim())) {
        setCustomTags((tags) => [...tags, { tag: value.trim() }]);
        onValueChanged && onValueChanged([...customTags, { tag: value.trim() }]);
        setValue("");
      } else {
        setExistingTagModalVisible(true);
      }
    }
  };

  const deleteCustomTag = (_value: string): void => {
    const index = customTags.findIndex((tag: EvidenceCustomTag) => tag.tag === _value);

    if (index !== -1) {
      let array = _.cloneDeep(customTags);
      array = array.filter((_, i) => i !== index);

      setCustomTags(array);
      onValueChanged && onValueChanged(array);
    }
  };

  if (isPDF) {
    return (
      <View>
        <Text style={pdfStyles.reflectionHeader}>{(definition["Display Title"] || definition.Name) + ":"}</Text>
        <Text style={pdfStyles.evidenceDate}>
          {Array.isArray(data)
            ? data
                ?.map((tag: EvidenceCustomTag) => tag.tag)
                .sort()
                .join(", ")
            : data}
        </Text>
      </View>
    );
  }

  if (isDisplay) {
    return (
      <div className="readEvidenceBlock">
        <div className="readEvidenceHeader">{definition["Display Title"] || definition.Name}</div>
        <div className="readEvidenceText">
          {Array.isArray(data)
            ? data
                ?.map((tag: EvidenceCustomTag) => tag.tag)
                .sort()
                .join(", ")
            : data}
        </div>
        <div className="h-[1px] w-full bg-grey-30 mt-4" />
      </div>
    );
  }

  return (
    <div className="reflectionsCustomTagContainer">
      {customTags.map((tag, index) => {
        return (
          <div className="reflectionTagContainer" key={tag.tag} onClick={() => deleteCustomTag(tag.tag)}>
            <div className="reflectionTagText">{tag.tag}</div>
            <img src={Icon_Attachment_Delete} className="attachmentIconClose" alt="" />
          </div>
        );
      })}
      <div>
        <input
          ref={ref}
          className="reflectionTagsInput"
          placeholder={definition?.["Placeholder Text"] || "Enter a value..."}
          id="reflectionCustomTagsInput"
          autoComplete="off"
          spellCheck
          value={value}
          onChange={inputChanged}
          onKeyUp={customTagKeyPressed}
        />
      </div>
    </div>
  );
};

export default CustomTags;
