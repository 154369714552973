import { TTSOptions, TextToSpeech } from "@capacitor-community/text-to-speech";
import { useCallback, useState } from "react";

export const useTextToSpeech = (textContainerId: string) => {
  const [playing, setPlaying] = useState<boolean>(false);

  const handleAudioPlayback = useCallback(async (): Promise<void> => {
    try {
      if (playing) {
        setPlaying(false);
        TextToSpeech.stop();
      } else {
        let content = document.getElementById(textContainerId);
        let text: string = content?.innerText ?? "";
        let supportedLanguages = await TextToSpeech.getSupportedLanguages();

        if (supportedLanguages.languages.length > 0) {
          text = text.replace(/\n/g, ". ");
          text = text.replace(/\.\./g, ".");
          text = text.replace(/\.\s\./g, ".");

          if (text.startsWith(". ")) {
            text = text.slice(2);
          }

          let textToSpeechProperties: TTSOptions = {
            text: text ?? "",
            rate: 1.0,
            pitch: 1.0,
            volume: 1.0,
            category: "playback",
          };

          if (supportedLanguages.languages.includes("en-GB")) {
            textToSpeechProperties.lang = "en-GB";
          }

          setPlaying(true);

          await TextToSpeech.speak(textToSpeechProperties);

          setPlaying(false);
        }
      }
    } catch (error: any) {
      if (error?.error !== "interrupted") {
        console.log(error);
      }

      setPlaying(false);
    }
  }, [playing]);

  const stopAudioPlayback = (): void => {
    TextToSpeech.stop();

    setPlaying(false);
  };

  return { handleTTS: handleAudioPlayback, playing, stopTTS: stopAudioPlayback };
};
