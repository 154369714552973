import { PF_SERVER_URL } from "../Constants";

export class RemoteDataController {
  /**
   * Logs an error remotely to the server to display in nginx error logs
   * @param error
   * @returns
   */
  async logError(error: string | Error): Promise<any> {
    return new Promise(async (resolve, reject) => {
      try {
        const url = PF_SERVER_URL + "/portfolio/logError";

        console.log(error);

        const response: Response = await fetch(url, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            Error: error instanceof Error ? error.stack : error,
          }),
        });

        if (response) {
          const json = await response.json();

          if (json.success) {
            resolve(true);
          } else {
            reject(false);
          }
        }
      } catch (error) {
        console.log(error);
        reject("An error has occurred");
      }
    });
  }
}

export default new RemoteDataController();
