import { Page, Text, View } from "@react-pdf/renderer";
import * as PDFStyles from "./PDFStyles";
import { Html } from "react-pdf-html";
import { IPDFPersonalStatement } from "./PDFTypes";
import DataController from "../../controllers/DataController";

const PDFPersonalStatement: React.FC<IPDFPersonalStatement> = (props) => {
  return (
    <>
      {props.isHCPC && props.personalStatement ? (
        <Page size="A4" style={PDFStyles.styles.page}>
          <View style={PDFStyles.styles.heading} fixed>
            <Text style={PDFStyles.styles.headingText}>{"ParaFolio"}</Text>
          </View>
          <View
            fixed
            style={PDFStyles.styles.pageHeader}
            render={({ pageNumber }) => (
              <>
                <Text style={PDFStyles.styles.pageHeaderText}>{"Personal Statement"}</Text>
                <Text style={PDFStyles.styles.pageNumber}>{pageNumber - 1}</Text>
              </>
            )}
          />
          <View style={PDFStyles.styles.pageInnerContainer}>
            <View>
              <Text style={PDFStyles.styles.standardsHeaderOverview}>{"Personal Statement"}</Text>
              <View style={PDFStyles.styles.separator} />
            </View>
            <Html style={{ fontSize: 12 }} stylesheet={PDFStyles.htmlStyles}>
              {DataController.decodeHTML(props.personalStatement)}
            </Html>
          </View>
        </Page>
      ) : null}
    </>
  );
};

export default PDFPersonalStatement;
