import { useState } from "react";
import { EvidencePart } from "../../../Interfaces";
import { Text, View } from "@react-pdf/renderer";
import { useMaskito } from "@maskito/react";
import { IonInput } from "@ionic/react";
import DataController from "../../../controllers/DataController";

type Props = {
  children?: React.ReactNode;
  style?: React.CSSProperties;
  definition: EvidencePart;
  onValueChanged?: (value: any, isInput?: boolean) => void;
  onInputBlurred: () => void;
  data?: any;
  isDisplay?: boolean;
  isPDF?: boolean;
  pdfStyles: any;
  initialValue?: any;
};

const ActivityDuration: React.FC<Props> = ({
  children,
  style,
  onValueChanged,
  onInputBlurred,
  data,
  definition,
  isDisplay,
  isPDF,
  pdfStyles,
  initialValue,
}) => {
  const [value, setValue] = useState<string>(initialValue || "");

  const inputChanged = (event: any): void => {
    const _value = event.target.value;

    setValue(_value);
    onValueChanged && onValueChanged(_value, true);
  };

  const activityDurationMask = useMaskito({
    options: {
      mask: [/\d/, /\d/, ":", /\d/, /\d/],
    },
  });

  const numberOfHoursBlurred = (event: any): void => {
    const input = event.target.value;

    if (input.length > 0) {
      if (input.includes(":")) {
        let split = input.split(":");

        // console.log(split);

        if (split[1].length === 0) {
          setValue(`${split[0]}:00`);
          onValueChanged && onValueChanged(`${split[0]}:00`, true);
        } else if (split[1].length === 1) {
          setValue(`${split[0]}:0${split[1]}`);
          onValueChanged && onValueChanged(`${split[0]}:0${split[1]}`, true);
        } else if (split[1].length === 2) {
          let hours = parseInt(split[0], 10);
          let minutes = parseInt(split[1], 10);

          if (minutes > 59) {
            hours += 1;
            minutes = minutes - 60;
          }

          if (hours === 100) {
            hours = 99;
            minutes = 59;
          }

          if (hours < 10) {
            if (minutes < 10) {
              setValue(`0${hours}:0${minutes}`);
              onValueChanged && onValueChanged(`0${hours}:0${minutes}`, true);
            } else {
              setValue(`0${hours}:${minutes}`);
              onValueChanged && onValueChanged(`0${hours}:${minutes}`, true);
            }
          } else {
            if (minutes < 10) {
              setValue(`${hours}:0${minutes}`);
              onValueChanged && onValueChanged(`${hours}:0${minutes}`, true);
            } else {
              setValue(`${hours}:${minutes}`);
              onValueChanged && onValueChanged(`${hours}:${minutes}`, true);
            }
          }
        }
      } else {
        if (input.length === 1) {
          setValue(`0${input}:00`);
          onValueChanged && onValueChanged(`0${input}:00`, true);
        } else if (input.length === 2) {
          setValue(`${input}:00`);
          onValueChanged && onValueChanged(`${input}:00`, true);
        }
      }

      onInputBlurred && onInputBlurred();
    }
  };

  if (isPDF) {
    return (
      <View>
        <Text style={pdfStyles.reflectionHeader}>{(definition["Display Title"] || definition.Name) + ":"}</Text>
        <Text style={pdfStyles.evidenceDate}>{DataController.convertToHoursMinutes(data)}</Text>
      </View>
    );
  }

  if (isDisplay) {
    return (
      <div className="readEvidenceBlock">
        <div className="readEvidenceHeader">{definition["Display Title"] || definition.Name}</div>
        <div className="readEvidenceText">{DataController.convertToHoursMinutes(data)}</div>
        <div className="h-[1px] w-full bg-grey-30 mt-4" />
      </div>
    );
  }

  return (
    <div>
      <IonInput
        ref={async (activityDurationRef) => {
          if (activityDurationRef) {
            const input = await activityDurationRef.getInputElement();
            activityDurationMask(input);
          }
        }}
        className="reflectionTagsInput"
        placeholder={definition?.["Placeholder Text"] || "00:00 (HH:MM)"}
        autocomplete="off"
        value={value}
        defaultValue={"00:00"}
        onIonChange={inputChanged}
        onIonBlur={numberOfHoursBlurred}
        inputmode="numeric"
      />
    </div>
  );
};

export default ActivityDuration;
