import "../../App.css";
import "./TimelineComponents.css";

type Props = {
  children?: React.ReactNode;
  id?: string;
  style?: React.CSSProperties;
};

const TimelineContainer: React.FC<Props> = ({ children, style, id }) => {
  return (
    <div id={id} className="timelineContainer" style={style}>
      {children}
    </div>
  );
};

export default TimelineContainer;
