import { useState } from "react";
import Collapsible from "react-collapsible";
import { INOSSelectLearningObjectives, LearningObjective } from "../../../Interfaces";
import { IonItem } from "@ionic/react";
import { Button_Checkbox_Active, Button_Checkbox_Inactive } from "../../../assets/images";
import * as ProgramUtils from "../../../utils/programUtils";

type Props = {
  style?: React.CSSProperties;
  learningObjectives: LearningObjective[];
  selectedLearningObjectives: INOSSelectLearningObjectives[];
  onValueChanged?: (value: any) => void;
  toggleDescriptions: () => void;
  selectAllLearningObjectives?: () => void;
  deselectAllLearningObjectives?: () => void;
  showDescription: boolean;
  collapsibleOpen: boolean;
  isDisplay?: boolean;
  isPDF?: boolean;
  pdfStyles?: any;
  initialValue?: any;
};

const LearningObjectiveSelector: React.FC<Props> = (props) => {
  const allSelected = ProgramUtils.checkAllLearningObjectivesSelected(
    props.selectedLearningObjectives,
    props.learningObjectives
  );

  return (
    <Collapsible
      trigger={<div />}
      triggerDisabled
      transitionTime={250}
      transitionCloseTime={50}
      open={props.collapsibleOpen}
    >
      <div className="h-[2px] bg-grey-20" />
      <div className="mt-5 text-acc-12px font-acc-600 leading-[1.333] text-grey-90">
        {"Select the relevant PCKU (Performance Criteria / Knowledge & Understanding) for this NOS"}
      </div>
      <div className="flex flex-row justify-between mt-2 mb-1">
        <button onClick={() => props.toggleDescriptions()}>
          <div className="text-[17px] leading-default tracking-[-0.3] text-cta-blue ">
            {props.showDescription ? "Hide descriptions" : "Show descriptions"}
          </div>
        </button>
        <button>
          <div
            className="text-[17px] leading-default tracking-[-0.3] text-cta-blue "
            onClick={() =>
              allSelected ? props.deselectAllLearningObjectives?.() : props.selectAllLearningObjectives?.()
            }
          >
            {allSelected ? "Deselect all" : "Select all"}
          </div>
        </button>
      </div>
      {props.learningObjectives.map((learningObjective) => {
        const selected = props.selectedLearningObjectives.findIndex((item) => item.id === learningObjective.ID) > -1;

        return (
          <IonItem
            button
            detail={false}
            className="evidencePartsCheckboxContainer"
            key={learningObjective.ID}
            onClick={() => props.onValueChanged?.(learningObjective)}
          >
            <div className="w-full py-2">
              <div className="w-full flex flex-row items-center !justify-between">
                <div className="text-[17px] font-acc-600 leading-default text-grey-90 flex-1">
                  {learningObjective["Display Name"]}
                </div>
                <img
                  alt=""
                  className="h-icon w-icon img-no-select"
                  src={selected ? Button_Checkbox_Active : Button_Checkbox_Inactive}
                />
              </div>
              {props.showDescription && (
                <div className="text-[11px] leading-[1.273] tracking-[-0.3] text-grey-80">
                  {learningObjective.Description}
                </div>
              )}
            </div>
          </IonItem>
        );
      })}
      <div className="mb-3" />
    </Collapsible>
  );
};

export default LearningObjectiveSelector;
