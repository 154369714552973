import React from "react";
import { IEvidenceSearchCard } from "../../types/Components";
import * as ProgramUtils from "../../utils/programUtils";
import { EvidenceCustomTag, EvidenceStandardTag } from "../../Interfaces";
import { format } from "date-fns";
import { StringUtils } from "../../utils/stringUtils";
import { EvidenceCard } from "parafolio-components";
import EvidenceDateHeader from "./EvidenceDateHeader";

const EvidenceSearchCard: React.FC<IEvidenceSearchCard> = (props) => {
  // Use new evidence type or old evidence type
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-explicit-any
  const evidenceJSON: any =
    props.evidence.evidenceJSON &&
    typeof props.evidence.evidenceJSON === "string" &&
    JSON.parse(props.evidence.evidenceJSON);

  const taskingType: string | undefined = evidenceJSON?.["Tasking type or training"];

  const programName = evidenceJSON?.programInfo?.program || "";
  const progressCheckName = evidenceJSON?.programInfo.progressCheck || "";
  const competenceName = evidenceJSON?.programInfo?.competence || "";
  const title = props.evidence?.title || evidenceJSON?.Title || "";

  const hasSearchText = props.searchText.trim().length > 0;

  const customTagsKey = ProgramUtils.getEvidenceCustomTagsKey(props.evidence);
  const customTags: EvidenceCustomTag[] = customTagsKey ? evidenceJSON?.[customTagsKey] : props.evidence.customTags;

  const searchInTitle = hasSearchText && title?.toLowerCase().includes(props.searchText.toLowerCase());
  const searchInActivityType =
    hasSearchText &&
    props.evidence.activity !== null &&
    props.evidence.activity?.type.toLowerCase().includes(props.searchText.toLowerCase());
  const searchInDate =
    hasSearchText &&
    format(new Date(props.evidence.date), "d MMM yyyy").toLowerCase().includes(props.searchText.toLowerCase());
  const searchInProgram = hasSearchText && programName?.toLowerCase().includes(props.searchText.toLowerCase());
  const searchInProgressCheck =
    hasSearchText && progressCheckName?.toLowerCase().includes(props.searchText.toLowerCase());
  const searchInCompetence = hasSearchText && competenceName?.toLowerCase().includes(props.searchText.toLowerCase());
  const searchInTaskingType = hasSearchText && taskingType?.toLowerCase().includes(props.searchText.toLowerCase());

  let splitTitle: string[] = [];
  let splitActivityType: string[] = [];
  let splitDate: string[] = [];
  let splitProgram: string[] = [];
  let splitProgressCheck: string[] = [];
  let splitCompetence: string[] = [];
  let splitTaskingType: string[] = [];

  const regex = new RegExp(`(${StringUtils.escapeRegExp(props.searchText)})`, "gi");

  if (searchInTitle) {
    splitTitle = title?.split(regex) || [];
    splitTitle = splitTitle.filter((item) => item.length > 0);
  }

  if (searchInActivityType) {
    splitActivityType = props.evidence.activity?.type.split(regex)!;
    splitActivityType = splitActivityType.filter((item) => item.length > 0);
  }

  if (searchInDate) {
    splitDate = format(new Date(props.evidence.date), "d MMM yyyy").split(regex);
    splitDate = splitDate.filter((item) => item.length > 0);
  }

  if (searchInProgram) {
    splitProgram = programName?.split(regex) || [];
    splitProgram = splitProgram.filter((item) => item.length > 0);
  }

  if (searchInProgressCheck) {
    splitProgressCheck = progressCheckName?.split(regex) || [];
    splitProgressCheck = splitProgressCheck.filter((item) => item.length > 0);
  }

  if (searchInCompetence) {
    splitCompetence = competenceName?.split(regex) || [];
    splitCompetence = splitCompetence.filter((item) => item.length > 0);
  }

  if (searchInTaskingType) {
    splitTaskingType = taskingType?.split(regex) || [];
    splitTaskingType = splitTaskingType.filter((item) => item.length > 0);
  }

  const hasSeparator = programName.length > 0 || competenceName.length > 0 || props.evidence.activity?.type;
  const hasSecondarySeparator = programName.length > 0 && (competenceName.length > 0 || progressCheckName.length > 0);

  const onHoldReset = evidenceJSON?.OnHoldReset === 1;

  return (
    <div>
      {props.showHeader && (
        <div className="flex justify-center">
          <EvidenceDateHeader date={new Date(props.evidence.date)} />
        </div>
      )}
      <div className="timelineBlock">
        <EvidenceCard onClick={props.buttonPressed}>
          <div className="evidenceCardDateContainer">
            {props.evidence.draft && (
              <div className="flex items-center justify-center self-start h-[16px] w-[42px] px-[6px] rounded-[13px] mr-[8px] bg-orange-30 ">
                <div className="text-[12px] font-semibold text-[#9C4221]">{"Draft"}</div>
              </div>
            )}
            <div className="evidenceCardDate">
              {splitDate.length > 0 ? (
                <>
                  {splitDate.map((split: string, _index: number) => {
                    const searchTerm = split.toLowerCase() === props.searchText.toLowerCase();

                    if (searchTerm) {
                      return (
                        <span key={`evidence-date-${split}-${_index}`} className="timelineBlockSearchHighlight">
                          {split}
                        </span>
                      );
                    }

                    return <span key={`evidence-date-${split}-${_index}`}>{split}</span>;
                  })}
                </>
              ) : (
                <>{format(new Date(props.evidence.date), "d MMM yyyy") || ""}</>
              )}
            </div>
            {hasSeparator && <div className="evidenceCardDotSeparator" />}
            {props.evidence.activity?.type && (
              <div className="evidenceCardActivity">
                {splitActivityType.length > 0 ? (
                  <>
                    {splitActivityType.map((split: string, _index: number) => {
                      const searchTerm = split.toLowerCase() === props.searchText.toLowerCase();

                      if (searchTerm) {
                        return (
                          <span key={`evidence-activity-${split}-${_index}`} className="timelineBlockSearchHighlight">
                            {split}
                          </span>
                        );
                      }

                      return <span key={`evidence-activity-${split}-${_index}`}>{split}</span>;
                    })}
                  </>
                ) : (
                  <>{props.evidence.activity?.type}</>
                )}
              </div>
            )}
            {programName.length > 0 && (
              <div className="evidenceCardActivity">
                {splitProgram.length > 0 ? (
                  <>
                    {splitProgram.map((split: string, _index: number) => {
                      const searchTerm = split.toLowerCase() === props.searchText.toLowerCase();

                      if (searchTerm) {
                        return (
                          <span key={`evidence-activity-${split}-${_index}`} className="timelineBlockSearchHighlight">
                            {split}
                          </span>
                        );
                      }

                      return <span key={`evidence-activity-${split}-${_index}`}>{split}</span>;
                    })}
                  </>
                ) : (
                  <>{programName}</>
                )}
              </div>
            )}
            {hasSecondarySeparator && <div className="evidenceCardDotSeparator" />}
            {competenceName.length > 0 && (
              <div className="evidenceCardActivity">
                {splitCompetence.length > 0 ? (
                  <>
                    {splitCompetence.map((split: string, _index: number) => {
                      const searchTerm = split.toLowerCase() === props.searchText.toLowerCase();

                      if (searchTerm) {
                        return (
                          <span key={`evidence-activity-${split}-${_index}`} className="timelineBlockSearchHighlight">
                            {split}
                          </span>
                        );
                      }

                      return <span key={`evidence-activity-${split}-${_index}`}>{split}</span>;
                    })}
                  </>
                ) : (
                  <>{competenceName}</>
                )}
              </div>
            )}
            {progressCheckName.length > 0 && (
              <div className="evidenceCardActivity">
                {splitProgressCheck.length > 0 ? (
                  <>
                    {splitProgressCheck.map((split: string, _index: number) => {
                      const searchTerm = split.toLowerCase() === props.searchText.toLowerCase();

                      if (searchTerm) {
                        return (
                          <span key={`evidence-activity-${split}-${_index}`} className="timelineBlockSearchHighlight">
                            {split}
                          </span>
                        );
                      }

                      return <span key={`evidence-activity-${split}-${_index}`}>{split}</span>;
                    })}
                  </>
                ) : (
                  <>{progressCheckName}</>
                )}
              </div>
            )}
            {onHoldReset && (
              <div className="flex flex-row">
                <div className="evidenceCardDotSeparator" />
                <div className="text-acc-12px font-acc-500 leading-[1.33] tracking-[-0.1px] text-cta-red whitespace-break-spaces">
                  {"Submission Reset"}
                </div>
              </div>
            )}
          </div>
          <div className="evidenceCardTitle">
            {splitTitle.length > 0 ? (
              <>
                {splitTitle.map((split: string, _index: number) => {
                  const searchTerm = split.toLowerCase() === props.searchText.toLowerCase();

                  if (searchTerm) {
                    return (
                      <span key={`evidence-title-${split}-${_index}`} className="timelineBlockSearchHighlight">
                        {split}
                      </span>
                    );
                  }

                  return <span key={`evidence-title-${split}-${_index}`}>{split}</span>;
                })}
              </>
            ) : (
              <>{title}</>
            )}
          </div>
          <span className="evidenceTagsContainer">
            {taskingType && (
              <>
                {splitTaskingType.length > 0 ? (
                  <span className="evidenceCustomTags">
                    {splitTaskingType.map((split: string, _index: number) => {
                      const searchTerm = split.toLowerCase() === props.searchText.toLowerCase();

                      if (searchTerm) {
                        return (
                          <span
                            key={`evidence-tasking-type-${split}-${_index}`}
                            className="timelineBlockSearchHighlight"
                          >
                            {split}
                          </span>
                        );
                      }

                      return <span key={`evidence-tasking-type-${split}-${_index}`}>{split}</span>;
                    })}
                  </span>
                ) : (
                  <span className="evidenceCustomTags">{taskingType}</span>
                )}
              </>
            )}
            {props.evidence.standardTags && props.evidence.standardTags?.length > 0 && (
              <span className="evidenceStandardTags">
                {props.evidence.standardTags.map((tag: EvidenceStandardTag) => tag.tag).join(", ")}
              </span>
            )}
            {props.evidence.standardTags &&
              props.evidence.standardTags.length > 0 &&
              props.evidence.customTags &&
              props.evidence.customTags.length > 0 && <span className="evidenceCardDotSeparator" />}
            {customTags && customTags.length > 0 && (
              <span className="evidenceCustomTags">
                {customTags.map((tag: EvidenceCustomTag) => tag.tag).join(", ")}
              </span>
            )}
          </span>
          {props.evidence.addToHCPCAudit && <div className="evidenceHCPCPill">{"Audit"}</div>}
        </EvidenceCard>
      </div>
    </div>
  );
};

export default EvidenceSearchCard;
