import { ActivityPage, CompetenceActivityClass, ProgramData, ProgressCheck, WellbeingPartType } from "../Interfaces";
import _ from "lodash";

export function getDefaultEvidenceJSONForWellbeingActivity(
  pages: ActivityPage[],
  program: ProgramData,
  progressCheck: ProgressCheck,
  progressCheckCompetence: CompetenceActivityClass
): any {
  const result = {
    Title: "Wellbeing Activity",
    programInfo: {
      program: program.Name,
      programID: program.ID,
      progressCheck: progressCheck.Name,
      progressCheckID: progressCheck.ID,
      progressCheckCompetence: progressCheckCompetence.Name,
      progressCheckCompetenceID: progressCheckCompetence.ID,
    },
  };

  const rangeParts = pages
    .flatMap((page) => page["Wellbeing Parts"].map((part) => ({ ...part, page: page.ID })))
    .filter((part) => part["Part type"] === WellbeingPartType.WellbeingRange)
    .map((part) => ({ Name: part.Name, value: 0, mavValue: part["Max value"], page: part.page }));

  const length = rangeParts.length;

  for (let i = 0; i < length; i++) {
    const part = rangeParts[i];

    _.extend(result, {
      [part.Name]: { value: 0, mavValue: part.mavValue, page: part.page },
    });
  }

  return result;
}
