import { IonButton, IonSpinner } from "@ionic/react";
import { ISaveEvidenceButton } from "../../types/Components";

const SaveEvidenceButton: React.FC<ISaveEvidenceButton> = (props) => {
  return (
    <IonButton
      mode="ios"
      disabled={props.disabled}
      className={`flex w-full max-w-button mb-12px self-center`}
      onClick={() => props.saveEvidence()}
    >
      {props.saving ? (
        <IonSpinner className="h-save-spinner w-save-spinner text-white mt-save-spinner" />
      ) : (
        <div>{props.isNewEvidence || props.isDraft ? "Add evidence" : "Save evidence"}</div>
      )}
    </IonButton>
  );
};

export default SaveEvidenceButton;
