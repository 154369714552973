import { differenceInCalendarMonths } from "date-fns";
import { IEvidence } from "../../Interfaces";
import { Icon_Tick_Green } from "../../assets/images";
import SegmentedProgressBar from "../common/SegmentedProgressBar";

const EVIDENCE_REQURED_PER_MONTH = 2;

const MonthlyEvidenceBreakdown = (props: { evidence: IEvidence[] }) => {
  const filteredEvidence = getFilteredEvidence();
  const months = getMonths();

  const groupedEvidence = filteredEvidence.reduce((acc: { [key: string]: IEvidence[] }, current) => {
    const month = new Date(current.date).toLocaleString("default", { month: "long" });
    if (!acc[month]) {
      acc[month] = [];
    }
    acc[month].push(current);
    return acc;
  }, {});

  // TODO this is duplicated in ChubOverview.tsx
  // Only get relevant evidence that should be counted for ongoing development
  // ('Peer Review' comp with Peer Review LO, and 'General' comp)
  function getFilteredEvidence() {
    return [...props.evidence].filter((e) => {
      const parsedEvidence = JSON.parse(e.evidenceJSON);
      const peerReviewLoFound = parsedEvidence?.LearningOutcomes?.find((lo: any) => lo.ID === "LX4VATNYDQGT7"); // TODO Peer Review LO id (set in airtable id instead??)
      return (
        !parsedEvidence.OnHoldReset &&
        !e.draft &&
        (parsedEvidence.programInfo?.progressCheckCompetenceID == "LWAGQ8UP7YU3L" || peerReviewLoFound)
      );
    });
  }

  function getMonths() {
    const months = [];
    const today = new Date();
    const oldestDateForEvidence = new Date(Math.min(...filteredEvidence.map((e) => new Date(e.date).getTime())));
    const monthsDiff = differenceInCalendarMonths(today, oldestDateForEvidence);
    const maxMonthBackdate = monthsDiff > 6 ? 6 : monthsDiff;

    for (let i = 0; i < maxMonthBackdate + 1; i++) {
      const date = new Date(today.getFullYear(), today.getMonth() - i, 1);
      months.push(date.toLocaleString("default", { month: "long" }));
    }

    return months;
  }

  function getEvidenceCountColour(count: number) {
    let colour = "text-grey-60";
    if (count > 0 && count < EVIDENCE_REQURED_PER_MONTH) {
      colour = "text-cta-red";
    } else if (count === EVIDENCE_REQURED_PER_MONTH) {
      colour = "text-green-highlight";
    }
    return colour;
  }

  return (
    <>
      {months.length ? (
        <>
          {months.map((month, index) => {
            const evidenceCount = groupedEvidence[month] ? groupedEvidence[month].length : 0;
            if (index === 0) {
              // show data for current month
              return (
                <SegmentedProgressBar
                  title="Evidence added this month"
                  key={index}
                  numerator={evidenceCount}
                  denominator={EVIDENCE_REQURED_PER_MONTH}
                  customTitleClasses="font-bold mb-[-8px]"
                />
              );
            } else {
              // show data for previous months
              return (
                <div key={index} className={index === 1 ? "mt-1" : ""}>
                  <div className="flex justify-between items-center py-3">
                    <div className="text-acc-15px text-grey-90">
                      <>{month}</>
                      {evidenceCount < EVIDENCE_REQURED_PER_MONTH && (
                        <div className="text-cta-red text-acc-12px font-medium">
                          You haven’t added enough evidence this month
                        </div>
                      )}
                    </div>
                    <div className="flex items-center">
                      {evidenceCount === EVIDENCE_REQURED_PER_MONTH && (
                        <img src={Icon_Tick_Green} className="mr-2 h-[18px] w-[18px]" />
                      )}
                      <div className={`text-acc-15px font-bold ${getEvidenceCountColour(evidenceCount)}`}>
                        {evidenceCount} / {EVIDENCE_REQURED_PER_MONTH}
                      </div>
                    </div>
                  </div>
                  <div className="h-[1px] bg-grey-20" />
                </div>
              );
            }
          })}
        </>
      ) : (
        <SegmentedProgressBar
          title="Evidence added this month"
          numerator={0}
          denominator={EVIDENCE_REQURED_PER_MONTH}
          customTitleClasses="font-bold mb-[-8px]"
        />
      )}
    </>
  );
};

export default MonthlyEvidenceBreakdown;
