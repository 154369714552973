import { useEffect, useState } from "react";
import * as ProgramUtils from "../../utils/programUtils";
import { CompetenceActivityClass, ProgramData, ProgramSkill } from "../../Interfaces";
import { useProgramData } from "../data/useProgramData";

export const useProgramSkills = (programID: string, skillID: string = "") => {
  const { programData } = useProgramData();

  const [competence, setCompetence] = useState<CompetenceActivityClass | any>(null);
  const [skills, setSkills] = useState<ProgramSkill[] | any[]>([]);
  const [requiredSkills, setRequiredSkills] = useState<ProgramSkill[] | any[]>([]);

  useEffect(() => {
    if (programID.length > 0) {
      const currentProgram = programData.find((item: any): item is ProgramData => item.ID === programID);

      const allCompetences: CompetenceActivityClass[] | any[] = currentProgram?.CompetenceActivity ?? [];
      const competence: any = allCompetences.find((item: any): item is CompetenceActivityClass => item.ID === skillID);

      if (allCompetences.length > 0) {
        const required = ProgramUtils.getAllRequiredSkillsForProgram(allCompetences);

        required && setRequiredSkills(required);
      }

      if (competence) {
        setCompetence(competence);
      }
    }
  }, [programID]);

  return {
    skills,
    requiredSkills,
    competence,
  };
};
