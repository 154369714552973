import { IonSpinner } from "@ionic/react";
import { IAttachmentInput } from "../../types/Components";
import { Button_Attachment, Icon_Attachment, Icon_Attachment_Delete } from "../../assets/images";

const AttachmentInput: React.FC<IAttachmentInput> = (props) => {
  return (
    <div className="flex flex-row flex-wrap items-center gap-[8px]">
      {props.attachments.map((attachment) => {
        const disabled = props.viewingAttachment && props.viewingAttachmentFilename !== attachment.name;
        const viewing = props.viewingAttachment && props.viewingAttachmentFilename === attachment.name;

        let split = attachment.name.split(".");
        let filename = split[0];
        let extension = split[1];

        if (filename.length > 14) {
          filename = `${filename.substring(0, 7)}...${filename.substring(7, 14)}`;
        }

        return (
          <div className="attachmentContainer" key={attachment.name}>
            <div
              className={`${attachment.url ? "attachmentInnerContainerLink" : "attachmentInnerContainer"}`}
              onClick={() => !(disabled || viewing) && props.viewAttachment(attachment)}
            >
              {viewing ? (
                <div className="attachmentIconSpinnerContainer">
                  <IonSpinner className="attachmentIconSpinner" />
                </div>
              ) : (
                <img src={Icon_Attachment} className="attachmentIcon" alt="" />
              )}
              <div title={attachment.name} className="attachmentFilename">
                {`${filename}.${extension}`}
              </div>
            </div>
            <div className="attachmentIconCloseContainer" onClick={() => props.deleteAttachment(attachment.name ?? "")}>
              <img src={Icon_Attachment_Delete} className="attachmentIconClose" alt="" />
            </div>
          </div>
        );
      })}
      <button className="reflectionDocumentsAddButton" onClick={() => props.openFileSelector()}>
        <div className="reflectionDocumentsAddText">{"Add attachments"}</div>
        <img src={Button_Attachment} className="reflectionDocumentsAddIcon" alt="" />
      </button>
    </div>
  );
};

export default AttachmentInput;
