import { isAfter } from "date-fns";
import { AllProgressCheckStatuses, CommentReadTime, IComment, IUser } from "../Interfaces";

export namespace CommentHelpers {
  export function getFormattedUnreadCommentCount(
    user: IUser,
    comments: IComment[] | undefined,
    readTimes: CommentReadTime[] | undefined
  ): string {
    const contactID = user?.userData?.contactID;

    let number = 0;

    if (comments && comments.length > 0) {
      const readTime = readTimes?.find((item) => `${item.ContactID}` === `${contactID}`);

      if (readTime) {
        const commentsAfterReadTime = comments.filter(
          (item) => `${item.contactId}` !== `${contactID}` && isAfter(new Date(item.date), new Date(readTime.Date))
        );

        number = commentsAfterReadTime.length;
      } else {
        const unreadComments = comments.filter((item) => `${item.contactId}` !== `${contactID}`);

        number = unreadComments.length;
      }
    }

    const string = `${number} new comment${number !== 1 ? "s" : ""}`;

    return string;
  }

  export function getLatestComment(comments: IComment[] | undefined): any {
    if (comments) {
      return comments[0] && mapServerCommentToComponent(comments[0]);
    }

    return undefined;
  }

  export function getUnreadCommentCount(
    user: IUser,
    comments: IComment[] | undefined,
    readTimes: CommentReadTime[] | undefined
  ): number {
    const contactID = user?.userData?.contactID;

    if (comments && comments.length > 0) {
      const readTime = readTimes?.find((item) => `${item.ContactID}` === `${contactID}`);

      if (readTime) {
        const commentsAfterReadTime = comments.filter(
          (item) => `${item.contactId}` !== `${contactID}` && isAfter(new Date(item.date), new Date(readTime.Date))
        );

        return commentsAfterReadTime.length;
      } else {
        const unreadComments = comments.filter((item) => `${item.contactId}` !== `${contactID}`);

        return unreadComments.length;
      }
    }

    return 0;
  }

  export function getCommentCountForProgressCheckButton(
    user: IUser,
    progressCheckData: AllProgressCheckStatuses | null,
    progressCheckID: string
  ): number {
    if (progressCheckData) {
      const progressCheck = progressCheckData.pCs.find((item) => item.pCId === progressCheckID);

      if (progressCheck) {
        return getUnreadCommentCount(user, progressCheck.comments, progressCheck.commentReadTimes);
      }
    }

    return 0;
  }

  export function mapServerCommentToComponent(comment: IComment) {
    return {
      ...comment,
      contactID: comment.contactId,
    };
  }

  export function showNewCommentLabel(
    user: IUser,
    comment: IComment,
    readTimes: CommentReadTime[] | undefined
  ): boolean {
    const contactID = user?.userData?.contactID;

    if (contactID && comment) {
      if (readTimes && readTimes?.length > 0) {
        const readTime = readTimes.find((item) => `${item.ContactID}` === `${contactID}`);

        if (readTime) {
          return (
            `${readTime.ContactID}` !== `${comment.contactId}` &&
            isAfter(new Date(comment.date), new Date(readTime.Date))
          );
        } else {
          return `${contactID}` !== `${comment.contactId}`;
        }
      } else {
        return false;
      }
    }

    return false;
  }

  export function showNewCommentsCardLabel(
    user: IUser,
    comments: IComment[] | undefined,
    readTimes: CommentReadTime[] | undefined
  ): boolean {
    const contactID = user?.userData?.contactID;

    if (contactID && comments && comments?.length > 0) {
      if (readTimes && readTimes?.length > 0) {
        const readTime = readTimes.find((item) => `${item.ContactID}` === `${contactID}`);

        if (readTime) {
          const commentsAfterReadTime = comments.filter(
            (item) => `${item.contactId}` !== `${contactID}` && isAfter(new Date(item.date), new Date(readTime.Date))
          );

          return commentsAfterReadTime.length > 0;
        } else {
          const commentsNotByUser = comments.filter((item) => `${item.contactId}` !== `${contactID}`);

          return commentsNotByUser.length > 0;
        }
      } else {
        const unreadComments = comments.filter((item) => `${item.contactId}` !== `${contactID}`);

        return unreadComments.length > 0;
      }
    }

    return false;
  }
}
