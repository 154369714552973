import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToggle,
  IonToolbar,
} from "@ionic/react";
import { withRouter } from "react-router";
import DataController from "../../controllers/DataController";
import { Capacitor } from "@capacitor/core";
import { useEffect } from "react";
import { EventRegister } from "react-native-event-listeners";
import { useProgramData } from "../../hooks/data/useProgramData";
import { ContentContainer } from "parafolio-components";
import { useDebugUser } from "../../hooks/auth/useDebugUser";

const ProgramDataInfo: React.FC = () => {
  const { versionInfo } = useProgramData();
  const { isDebugUser, setIsDebugUser } = useDebugUser();

  useEffect(() => {
    EventRegister.emit("tab-bar/visibility-changed", false);

    return () => EventRegister.emit("tab-bar/visibility-changed", true);
  });

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar
          mode="ios"
          className="navBar"
          style={{
            maxWidth: DataController.isWeb() ? 980 : undefined,
            height: Capacitor.getPlatform() === "android" ? "54px" : "unset",
          }}
        >
          <IonButtons slot="start">
            <IonBackButton
              className="header-back-buttons"
              defaultHref="/dashboard"
              text={DataController.getBackIconText()}
              icon={DataController.getBackIconType()}
              style={{
                marginLeft: Capacitor.getPlatform() === "android" ? 8 : 0,
                "--icon-font-size": Capacitor.getPlatform() === "android" ? "24px" : "30px",
              }}
            />
          </IonButtons>
          <IonTitle>{"Program Module Data"}</IonTitle>
          <IonButtons slot="end">
            <IonToggle checked={isDebugUser} onIonChange={() => setIsDebugUser(!isDebugUser)} class="debug-toggle" />
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent className="page-background whiteBackground infoPage">
        <ContentContainer tabBarDisabled>
          <div className="pl-safe-area-l pr-safe-area-r">
            <div className={`text-xl font-bold mb-2 ml-4 ${isDebugUser ? "text-[#147C3B]" : "text-[#9C1919]"}`}>
              {isDebugUser ? "Debug Mode on" : "Debug Mode off"}
            </div>
            {versionInfo.length > 0 ? (
              versionInfo.map((item) => (
                <div key={item.type} className="mb-12">
                  <div className="text-xl font-bold underline mb-1 ml-4">{item.type}</div>
                  <div className="relative overflow-x-auto">
                    <table className="w-full text-left">
                      <thead className="text-base uppercase bg-gray-50">
                        <tr>
                          <th scope="col" className="px-4 py-2 whitespace-nowrap">
                            {"Program Name"}
                          </th>
                          <th scope="col" className="px-4 py-2 whitespace-nowrap">
                            {"Program ID"}
                          </th>
                          <th scope="col" className="px-4 py-2 whitespace-nowrap">
                            {"Version"}
                          </th>
                        </tr>
                      </thead>
                      {item.versionInfo.map((versionInfo, index) => (
                        <tbody key={versionInfo.ID} className="even:bg-white odd:bg-gray-50 hover:bg-gray-200">
                          <tr className="border-b">
                            <td scope="row" className="px-4 py-3 whitespace-nowrap">
                              {versionInfo.Name}
                            </td>
                            <td scope="row" className="px-4 py-3 whitespace-nowrap">
                              {versionInfo.version ?? "N/A"}
                            </td>
                            <td scope="row" className="px-4 py-3 whitespace-nowrap">
                              {versionInfo.ID}
                            </td>
                          </tr>
                        </tbody>
                      ))}
                    </table>
                  </div>
                </div>
              ))
            ) : (
              <div className="text-xl font-bold mb-1 ml-4 text-[#9C1919]">{"User has no Program Modules"}</div>
            )}
          </div>
        </ContentContainer>
      </IonContent>
    </IonPage>
  );
};

export default withRouter(ProgramDataInfo);
