import * as PDFStyles from "./PDFStyles";
import * as PDFUtils from "../../utils/pdfUtils";
import * as ProgramUtils from "../../utils/programUtils";
import { IPDFProgressChecksPage } from "./PDFTypes";
import { Page, Text, View } from "@react-pdf/renderer";
import { useRecoilValueLoadable } from "recoil";
import { AllProgressCheckStatuses } from "../../Interfaces";
import { progressCheckDataAtom } from "../../state/State";
import { useProgressCheckData } from "../../hooks/programs/useProgressCheckData";
import { StringUtils } from "../../utils/stringUtils";
import { format } from "date-fns";
import { CommentHelpers } from "parafolio-components";

const PDFProgressChecksPage: React.FC<IPDFProgressChecksPage> = (props) => {
  if (!ProgramUtils.displayProgressChecksInPDF(props.program?.ID)) {
    return null;
  }

  return (
    <>
      <Page size="A4" style={PDFStyles.styles.page}>
        <View style={PDFStyles.styles.heading} fixed>
          <Text style={PDFStyles.styles.headingText}>{"ParaFolio"}</Text>
        </View>
        <View
          fixed
          style={PDFStyles.styles.pageHeader}
          render={({ pageNumber }) => (
            <>
              <Text style={PDFStyles.styles.pageHeaderText}>{"Progress Checks"}</Text>
              <Text style={PDFStyles.styles.pageNumber}>{pageNumber - 1}</Text>
            </>
          )}
        />
        <View style={PDFStyles.styles.pageInnerContainer}>
          <Text style={PDFStyles.styles.standardsHeaderOverview}>{"Progress Checks"}</Text>
          <View style={PDFStyles.styles.separator} />
          {props.program?.ProgressChecks?.map((progressCheck) => {
            const progressCheckData = props.progressCheckData?.pCs.find((item) => item.pCId === progressCheck.ID);
            const submissionStatus = progressCheckData?.submissions;
            const hasSubmissionHistory = (submissionStatus?.history && submissionStatus.history.length > 0) ?? false;

            return (
              <View key={progressCheck.ID} style={{ marginBottom: 32 }}>
                <View>
                  <Text style={PDFStyles.styles.evidenceTitle}>{progressCheck.Name}</Text>
                </View>
                <View>
                  <Text style={PDFStyles.styles.reflectionHeader}>{"Status:"}</Text>
                  <Text style={PDFStyles.styles.evidenceDate}>
                    {StringUtils.getProgressCheckStatusText(submissionStatus?.status)}
                  </Text>
                </View>
                {submissionStatus && ProgramUtils.isProgressCheckFinished(submissionStatus) && (
                  <View>
                    <Text style={PDFStyles.styles.reflectionHeader}>
                      {StringUtils.getProgressCheckPDFCompletedText(submissionStatus)}
                    </Text>
                    {hasSubmissionHistory && (
                      <Text style={PDFStyles.styles.evidenceDate}>
                        {format(new Date(submissionStatus?.history[0].date!), "d MMMM yyyy")}
                      </Text>
                    )}
                  </View>
                )}
                {submissionStatus && PDFUtils.showApprovedByText(submissionStatus.status) && (
                  <View>
                    <Text style={PDFStyles.styles.reflectionHeader}>
                      {StringUtils.getProgressCheckSubmissionChangedByHeader(submissionStatus.status)}
                    </Text>
                    <Text style={PDFStyles.styles.evidenceDate}>
                      {`${submissionStatus.history[0].displayName}${StringUtils.getRoleDisplayString(submissionStatus.history[0].role)}`}
                    </Text>
                  </View>
                )}
                {submissionStatus &&
                  ProgramUtils.isProgressCheckFinished(submissionStatus) &&
                  progressCheck["LOs Automatic"] &&
                  progressCheck["LOs Automatic"]?.length > 0 && (
                    <View>
                      <Text style={PDFStyles.styles.reflectionHeader}>{"Learning Objectives achieved:"}</Text>
                      {progressCheck["LOs Automatic"]?.map((learningObjective) => (
                        <View key={learningObjective.ID} style={{ marginBottom: 8 }}>
                          <Text style={PDFStyles.styles.boldText}>{learningObjective.Name}</Text>
                          <Text style={PDFStyles.styles.normalText}>{learningObjective.TopSection}</Text>
                          <Text style={PDFStyles.styles.normalText}>{learningObjective.Section}</Text>
                          <Text style={PDFStyles.styles.normalText}>{learningObjective.Description}</Text>
                        </View>
                      ))}
                    </View>
                  )}
              </View>
            );
          })}
        </View>
      </Page>
    </>
  );
};

export default PDFProgressChecksPage;
