export const APPLE_PRODUCT_IDS = [
  "uk.co.classprofessional.parafolio.1month",
  "uk.co.classprofessional.parafolio.12month",
];

export const GOOGLE_PRODUCT_IDS = ["uk.co.class.parafolio.android.1month", "uk.co.class.parafolio.android.12month"];

export const GOOGLE_VERIFY_ENDPOINT = "https://class.aimernginx.co.uk/eos/verifyWithGoogleAimer";

export const GOOGLE_PLAY_MANAGE_SUBSCRIPTIONS_URL =
  "https://play.google.com/store/account/subscriptions?package=uk.co.classprofessional.parafolio";

export const BUNDLE_ID = "uk.co.classprofessional.parafolio";

export const FEEDBACK_FORM_URL = "https://m3kh8cpcbse.typeform.com/to/HrOJjCk3/";

export const TERMS_AND_CONDITIONS_URL =
  "https://www.classprofessional.co.uk/terms-of-use/parafolio-privacy-policy-terms-and-conditions/";

export const MAX_FONT_SIZE = 10;
export const DEFAULT_FONT_SIZE = 0;
export const MIN_FONT_SIZE = -5;

export const MIN_LINE_HEIGHT = 1;
export const DEFAULT_LINE_HEIGHT = 1.5;
export const MAX_LINE_HEIGHT = 3;

export const DEV_TOKEN =
  "eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9eyJ1aWQiOiIyNDg1NDkiLCJjbGFpbXMiOnsiY29udGFjdElEIjoiMjQ4NTQ5In0sImlhdCI6MTU4Mjg4ODI2N30HC2iY4co2OvTc9yKZ7rNRkFORX";
export const DEV_CONTACT_ID = 48952;
export const DEV_SUPERUSER = 602678;

// export const AUTH_SERVER_URL = "http://localhost:3011"; // Local server - For development
export const AUTH_SERVER_URL = "https://class.aimernginx.co.uk/classapi/SF"; // Live server - For releases
export const PASSWORD_RESET_URL = "https://www.classprofessional.co.uk/app-reset";
// export const PF_SERVER_URL = "http://localhost:8034"; // Local server - For development
export const PF_SERVER_URL = "https://pf.aimernginx.co.uk"; // Live server - For releases
export const PLUS_SERVER_URL = "https://class.aimernginx.co.uk";
export const PARAPASS_SERVER_URL = "https://aimermedia-api.co.uk";

export const PLUS_DEEPLINK_URL = "https://jrcalcplusweb.co.uk/?GLID=";
export const PARAPASS_DEEPLINK_URL = "https://parapassweb.co.uk/?quiz=";

export const HCPC_URL = "https://www.hcpc-uk.org";

export const CLASS_APPS_URL = "https://www.classprofessional.co.uk/digital-products/apps";
export const CLASS_PARAFOLIO_URL = "https://www.classprofessional.co.uk/digital-products/apps";
export const CLASS_TERMS_URL =
  "https://www.classprofessional.co.uk/terms-of-use/parafolio-privacy-policy-terms-and-conditions";

export const PF_FAQ_URL = "https://www.classprofessional.co.uk/faqs/#parafolio-app";
export const PF_FEATURES_URL = "https://www.classprofessional.co.uk/2023/02/23/parafolio-features";

export const DATA_REFRESH_INTERVAL = 15000; // 15 seconds
export const USAGE_DATA_REFRESH_INTERVAL = 600000; // 10 minutes
// export const PROGRAM_DATA_REFRESH_INTERVAL = 5000; // 5 seconds
export const PROGRAM_DATA_REFRESH_INTERVAL = 600000; // 10 minutes

export const EVIDENCE_ACCEPTED_FILE_TYPES = [
  ".pdf",
  ".odt", // OpenDocument Text
  ".doc",
  ".docx",
  ".xls",
  ".xlsx",
  ".ppt",
  ".pptx",
  "image/*", // All image file types
  "audio/*", // All audio file types
  "video/*", // All video file types
  ".csv",
  ".rtf",
  ".rtx",
];
export const EVIDENCE_MAX_FILE_SIZE = 85;

export const CERTIFICATE_ACCEPTED_FILE_TYPES = [
  ".pdf",
  ".odt", // OpenDocument Text
  ".doc",
  ".docx",
  "image/*", // All image file types
  ".csv",
  ".rtf",
  ".rtx",
];
export const CERTIFICATE_MAX_FILE_SIZE = 85;
