import "../../App.css";

type Props = {
  children?: React.ReactNode;
  tabBarDisabled?: boolean;
  style?: React.CSSProperties;
  id?: string;
};

const ContentContainer: React.FC<Props> = ({ children, tabBarDisabled, style, id }) => {
  return (
    <div id={id} className={`contentContainer ${tabBarDisabled ? "contentContainerNoTabs" : ""}`} style={style}>
      {children}
    </div>
  );
};

export default ContentContainer;
