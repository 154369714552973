import { EvidencePart } from "../../../Interfaces";

type Props = {
  definition: EvidencePart;
  isPDF: boolean;
  isDisplay: boolean;
};

const Banner: React.FC<Props> = (props) => {
  const bannerColourVariants: any = {
    Red: "bg-red-banner",
    Yellow: "bg-yellow-banner",
    Grey: "bg-grey-20",
  };

  if (props.isDisplay || props.isPDF) {
    return null;
  }

  return (
    <div
      className={`reflectionContentCard ${props.definition["Banner color"] ? bannerColourVariants[props.definition["Banner color"]] : bannerColourVariants.Grey}`}
    >
      <div className="reflectionContentCardTitle !mb-0">{props.definition["Display Title"]}</div>
    </div>
  );
};

export default Banner;
