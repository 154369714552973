import { WellbeingPartType } from "../../../Interfaces";
import WellbeingBarGraph from "./WellbeingBarGraph";
import WellbeingInfoCard from "./WellbeingInfoCard";
import WellbeingRadarGraph from "./WellbeingRadarGraph";
import WellbeingRangeSelect from "./WellbeingRangeSelect";
import WellbeingSupportCard from "./WellbeingSupportCard";
import WellbeingTextBox from "./WellbeingTextBox";
import WellbeingTextDisplay from "./WellbeingTextDisplay";

export const CreateWellbeingActivityPart: any = (type: string, additionalProps: any) => {
  if (type === WellbeingPartType.InfoBlock) {
    return <WellbeingInfoCard {...additionalProps} />;
  } else if (type === WellbeingPartType.WellbeingRange) {
    return <WellbeingRangeSelect {...additionalProps} />;
  } else if (type === WellbeingPartType.TextBox) {
    return <WellbeingTextBox {...additionalProps} />;
  } else if (type === WellbeingPartType.SupportBlock) {
    return <WellbeingSupportCard {...additionalProps} />;
  } else if (type === WellbeingPartType.RadarGraph) {
    return <WellbeingRadarGraph {...additionalProps} />;
  } else if (type === WellbeingPartType.BarGraph) {
    return <WellbeingBarGraph {...additionalProps} />;
  } else if (type === WellbeingPartType.TextDisplay) {
    return <WellbeingTextDisplay {...additionalProps} />;
  }
};
