import { Capacitor } from "@capacitor/core";
import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonContent,
  IonFooter,
  IonHeader,
  IonItem,
  IonPage,
  IonSpinner,
  IonToolbar,
} from "@ionic/react";
import _ from "lodash";
import { HeaderTitle } from "parafolio-components";
import { useState } from "react";
import { useHistory, useLocation, useParams } from "react-router";
import { useRecoilStateLoadable } from "recoil";
import { Icon_Radio_Active, Icon_Radio_Grey } from "../../assets/images";
import { DatabaseService } from "../../controllers/DatabaseService";
import DataController from "../../controllers/DataController";
import { useProgramData } from "../../hooks/data/useProgramData";
import { IUser, IUserProgramRoles, ProgramRole } from "../../Interfaces";
import { userAtom } from "../../state/State";

function RoleAndTeamSetup() {
  const history = useHistory();
  const location = useLocation();

  const params = useParams<{ programId: string }>();

  const { userPrograms } = useProgramData();

  const program = userPrograms.find((p) => p.ID === params.programId)!;

  const [user, setUser] = useRecoilStateLoadable<IUser | null>(userAtom);

  const [selectedRole, setSelectedRole] = useState(getInitialRole());
  const [selectedTeam, setSelectedTeam] = useState(getInitialTeam());
  const [saving, setSaving] = useState(false);

  const disableSaveButton = saving || selectedRole === "" || selectedTeam === "";

  function getInitialRole() {
    if (user.state === "hasValue" && user.contents) {
      const role = user.contents.programRoles?.find((role) => role.programID === program.ID);
      if (role && role.programRoles.length) {
        return role.programRoles[0];
      }
    }

    return "";
  }

  function getInitialTeam() {
    if (user.state === "hasValue" && user.contents) {
      const role = user.contents.programRoles?.find((role) => role.programID === program.ID);
      if (role && role?.team) {
        return role.team;
      }
    }

    return "";
  }

  async function saveDetails() {
    setSaving(true);

    try {
      if (user.state === "hasValue" && user.contents) {
        const userToSave = _.cloneDeep(user.contents);

        let newData: IUserProgramRoles = {
          programName: program.Name,
          programID: program.ID,
          programRoles: [selectedRole],
          team: selectedTeam,
        };

        const userRoles = userToSave.programRoles || [];
        let index = userRoles.findIndex((item) => item.programID === program.ID);

        if (index === -1) {
          userRoles.push(newData);
        } else {
          userRoles.splice(index, 1, newData);
        }

        userToSave.programRoles = userRoles;

        await DatabaseService.updateUserDetails(userToSave, {
          programRoles: userToSave.programRoles,
        });

        setUser(userToSave);

        setTimeout(() => {
          history.goBack();
        }, 500);
      }
    } catch (error) {
      console.log(error);
      setSaving(false);
      window.alert("An error occurred when saving details");
    }
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar
          mode="ios"
          className="navBar"
          style={{
            maxWidth: DataController.isWeb() ? 980 : undefined,
            height: Capacitor.getPlatform() === "android" ? "54px" : "unset",
          }}
        >
          <IonButtons slot="start">
            <IonBackButton
              className="header-back-buttons"
              defaultHref="/dashboard"
              text={DataController.getBackIconText()}
              icon={DataController.getBackIconType()}
              style={{
                marginLeft: Capacitor.getPlatform() === "android" ? 8 : 0,
                "--icon-font-size": Capacitor.getPlatform() === "android" ? "24px" : "30px",
              }}
            />
          </IonButtons>
          <HeaderTitle>{"Your details"}</HeaderTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className="page-background whiteBackground">
        <div className="editRolesContent" style={{ paddingTop: 16 }}>
          <div className="programAddCertificateTitle">{"Set up your details"}</div>
          <div className="accountCardSubTitle">{program.DetailsDescription}</div>
          <div className="editRolesHeader" style={{ marginTop: 16 }}>
            Your role
          </div>
          {program.Roles?.map((role: ProgramRole) => {
            return (
              <IonItem
                button
                detail={false}
                key={role.ID}
                className="detailsRoleCheckboxContainer"
                onClick={() => setSelectedRole(role.Name)}
              >
                <div className="evidencePartsCheckboxContainerInner">
                  <div className="programRoleCheckboxContainerInner">
                    <div
                      className={`evidencePartsCheckboxLabel ${selectedRole === role.Name ? "evidencePartsCheckboxLabelSelected" : ""}`}
                    >
                      {role.Name}
                    </div>
                    {role.Description && <div className="programRoleDescription">{role.Description}</div>}
                  </div>
                  <img
                    className="evidencePartsRadioCheckbox"
                    src={selectedRole === role.Name ? Icon_Radio_Active : Icon_Radio_Grey}
                  />
                </div>
              </IonItem>
            );
          })}
          <div className="editRolesHeader" style={{ marginTop: 16 }}>
            Your team
          </div>
          {program.Teams?.map((team: string) => {
            return (
              <IonItem
                button
                detail={false}
                key={team}
                className="detailsRoleCheckboxContainer"
                onClick={() => setSelectedTeam(team)}
              >
                <div className="evidencePartsCheckboxContainerInner">
                  <div className="programRoleCheckboxContainerInner">
                    <div
                      className={`evidencePartsCheckboxLabel ${selectedTeam === team ? "evidencePartsCheckboxLabelSelected" : ""}`}
                    >
                      {team}
                    </div>
                  </div>
                  <img
                    className="evidencePartsRadioCheckbox"
                    src={selectedTeam === team ? Icon_Radio_Active : Icon_Radio_Grey}
                  />
                </div>
              </IonItem>
            );
          })}
        </div>
      </IonContent>
      <IonFooter className="programDetailsFooter">
        <div className="programDetailsSaveContainer">
          <IonButton
            mode="ios"
            disabled={disableSaveButton}
            className={`programDetailsSaveButton ${disableSaveButton && "reflectionsSaveButtonDisabled"}`}
            onClick={() => saveDetails()}
          >
            {saving ? <IonSpinner className="reflectionSaveSpinner" /> : <div>{"Confirm"}</div>}
          </IonButton>
        </div>
      </IonFooter>
    </IonPage>
  );
}

export default RoleAndTeamSetup;
