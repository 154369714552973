import { useHistory, useParams } from "react-router";
import { Swiper, SwiperSlide } from "swiper/react";
import { Swiper as SwiperInterface } from "swiper";
import { IEvidence, IUser, IWellbeingResultsContainer, WellbeingPart, WellbeingPartType } from "../../Interfaces";
import { useRecoilState, useRecoilValueLoadable } from "recoil";
import { evidenceAtom, userAtom } from "../../state/State";
import { useCallback, useState } from "react";
import { CreateWellbeingActivityPart } from "./wellbeingParts/WellbeingComponentWrapper";
import _ from "lodash";

const WellbeingResultsContainer: React.FC<IWellbeingResultsContainer> = (props) => {
  const params = useParams<{ programId: string; progressCheckId: string; competenceId: string; evidenceId: string }>();
  const history = useHistory();
  const user = useRecoilValueLoadable<IUser | null>(userAtom);
  const allEvidence = useRecoilValueLoadable<IEvidence[] | null>(evidenceAtom);

  const progressCheck = props.program.ProgressChecks?.find((item) => item.ID === params.progressCheckId);
  const progressCheckCompetence = progressCheck?.["Competence/Activity"].find(
    (item) => item.ID === params.competenceId
  );
  const evidence = allEvidence.contents?.find((item: any): item is IEvidence => item.id === params.evidenceId);
  const evidenceJSON = evidence?.evidenceJSON && JSON.parse(evidence.evidenceJSON);

  const resultsPages = props.wellbeingActivity["Activity Pages"].filter((item) => item["Show in Results View"]);

  const [swiperInstance, setSwiperInstance] = useState<SwiperInterface>();
  const [slidePosition, setSlidePosition] = useState<number>(resultsPages.length - 1);

  const onSlideChange = (): void => {
    setSlidePosition(swiperInstance?.realIndex ?? 0);
  };

  const getInitialValue = (part: WellbeingPart): any => {
    if (part["Part type"] === WellbeingPartType.RadarGraph || part["Part type"] === WellbeingPartType.BarGraph) {
      const keys = Object.keys(evidenceJSON).filter((key) => evidenceJSON[key].page !== undefined);

      let groups = keys.map((key) => evidenceJSON[key].page);
      groups = _.uniq(groups);

      const data = groups.map((group) => {
        const keys = Object.keys(evidenceJSON).filter((key) => evidenceJSON[key].page === group);
        const activities = props.wellbeingActivity["Activity Pages"];
        const activity = activities.find((page) => page.ID === evidenceJSON[keys[0]]?.page);

        return keys.map((key) => ({
          ...evidenceJSON[key],
          label: key,
          header: activity?.["Chart Header"],
          total: part["Max value"],
        }));
      });

      return {
        labels: keys,
        data,
      };
    }

    return evidenceJSON[part.Name];
  };

  return (
    <div className="w-full flex flex-col flex-1 justify-between min-h-dvh-nav pb-h-dvh-nav">
      <Swiper
        threshold={0}
        mousewheel={false}
        onSwiper={(swiper) => setSwiperInstance(swiper)}
        onSlideChange={onSlideChange}
        allowTouchMove={false}
        simulateTouch={false}
        className="!max-w-app-column !w-full h-auto relative"
        autoHeight={true}
        observer={true}
        observeParents={true}
        initialSlide={slidePosition}
        id="wellbeing-swiper"
        noSwiping={true}
        noSwipingClass="swiper-no-swiping"
      >
        <>
          {resultsPages.map((page) => (
            <SwiperSlide key={page.ID} draggable={false}>
              <div className="w-full flex flex-col h-auto swiper-no-swiping">
                {page["Wellbeing Parts"].map((part, index) => (
                  <div key={part.ID}>
                    {CreateWellbeingActivityPart(part["Part type"], {
                      wellbeingPart: part,
                      initialValue: getInitialValue(part),
                      page,
                      evidenceJSON,
                      isResultsPage: true,
                      index,
                    })}
                  </div>
                ))}
              </div>
            </SwiperSlide>
          ))}
        </>
      </Swiper>
    </div>
  );
};

export default WellbeingResultsContainer;
