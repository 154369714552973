import { IEvidence, StandardTag } from "../../Interfaces";
import { isAfter, subDays, subYears } from "date-fns";

import "./HomeComponents.css";

type StandardsBlock = {
  standards: StandardTag[];
  evidence: IEvidence[];
};

const NOSStandardsBreakdown = ({ standards, evidence }: StandardsBlock) => {
  const minDate = subDays(subYears(new Date(), 2), 1);
  const evidenceWithinLimit = evidence.filter((item) => {
    return isAfter(new Date(item.date), minDate);
  });

  return (
    <div>
      {standards.map((value, index) => {
        const evidenceWithStandard = evidenceWithinLimit.filter((item) => {
          const tags = item.standardTags?.map((item) => item.tag);

          return tags ? tags.includes(value.Code) : false;
        });

        const count = Math.min(evidenceWithStandard.length, 3);
        const required = 3;
        const percentage = (count / required) * 100;

        return (
          <div className="overviewCardStandardContainer" key={value.Code}>
            <div className="overviewCardStandardProgressContainer">
              <div className="overviewCardStandardCode">{value.Code}</div>
              <div className="overviewCardStandardGraphContainer">
                <div
                  style={{
                    height: 20,
                    width: `${percentage}%`,
                    backgroundColor: percentage === 100 ? "#09AB55" : "#ED8936",
                  }}
                />
              </div>
              <div className="overviewCardStandardCount">{`${count} of ${required}`}</div>
            </div>
            {value.Description && <div className="overviewCardStandardDescription">{value.Description}</div>}
          </div>
        );
      })}
    </div>
  );
};

export default NOSStandardsBreakdown;
