import { EventRegister } from "react-native-event-listeners";
import { Button_Edit, Icon_ParaPass, Icon_Plus } from "../../assets/images";
import { FirebaseService } from "../../controllers/FirebaseService";
import { IGuidelineCard, INewGuidelineEvidence } from "../../Interfaces";
import "./GuidelineComponents.css";
import { IonItem } from "@ionic/react";
import { PARAPASS_DEEPLINK_URL, PLUS_DEEPLINK_URL } from "../../Constants";

const GuidelineCard = ({ title, GLID }: IGuidelineCard) => {
  const jrcalcClicked = (): void => {
    try {
      // TODO: Add handling for mobile
      // jrcalcplus://open?GLID=${GLID}
      FirebaseService.logEvent("guideline_pressed", { GLID });

      window.open(`${PLUS_DEEPLINK_URL}${GLID}`, "_blank");
    } catch (error) {
      console.log(error);
    }
  };

  const parapassClicked = (): void => {
    try {
      // TODO: Add handling for mobile
      // format: cpgcpd://quiz/${GLID}
      FirebaseService.logEvent("parapass_quiz_pressed", { GLID });

      window.open(`${PARAPASS_DEEPLINK_URL}${GLID}`, "_blank");
    } catch (error) {
      console.log(error);
    }
  };

  const addEvidenceClicked = (): void => {
    const object: INewGuidelineEvidence = { title: `Evidence for '${title}'` };

    FirebaseService.logEvent("add_evidence_pressed", {
      tab: "guideline",
      GLID,
    });

    EventRegister.emit("evidence-modal/add-evidence-guideline", object);
  };

  return (
    <div className="guidelineCard">
      <div className="guidelineCardTitle">{title}</div>
      <IonItem button detail={false} className="guidelineCardButtonItem" onClick={jrcalcClicked}>
        <div className="guidelineCardButtonInner">
          <img src={Icon_Plus} alt="" className="guidelineAppIcon" />
          <div className="guidelineButtonText">{"Read the JRCALC Plus guideline"}</div>
        </div>
      </IonItem>
      <IonItem button detail={false} className="guidelineCardButtonItem" onClick={parapassClicked}>
        <div className="guidelineCardButtonInner">
          <img src={Icon_ParaPass} alt="" className="guidelineAppIcon" />
          <div className="guidelineButtonText">{"Take the ParaPass quiz"}</div>
        </div>
      </IonItem>
      <IonItem
        button
        detail={false}
        className="guidelineCardButtonItem guidelineCardButtonNoBorder"
        onClick={addEvidenceClicked}
      >
        <div className="guidelineCardButtonInner">
          <img src={Button_Edit} alt="" className="guidelineReflectionIcon" />
          <div className="guidelineButtonText">{"Add a piece of evidence"}</div>
        </div>
      </IonItem>
    </div>
  );
};

export default GuidelineCard;
