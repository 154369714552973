import { IonButton } from "@ionic/react";
import "../profile/ProfileComponents.css";

type Props = {
  title: string;
  subTitle?: string;
  children?: React.ReactNode;
  subTitleIsError?: boolean;
  style?: React.CSSProperties;
  sideButton?: boolean;
  sideButtonText?: string;
  sidePill?: boolean;
  sidePillText?: string;
  topPill?: boolean;
  topPillText?: string;
  buttonClicked?: () => void;
};

const ProgramInfoCard = ({
  title,
  subTitle,
  children,
  style,
  subTitleIsError,
  sideButton,
  buttonClicked,
  sideButtonText,
  sidePill,
  sidePillText,
}: Props) => {
  return (
    <div className="flex justify-center">
      <div className="accountCardInner" style={style}>
        <div className="dashboardHeader">
          <div className="accountCardTitle">{title}</div>
          {sideButton && (
            <IonButton mode="ios" className="dashboardSideButton" onClick={() => buttonClicked?.()}>
              {sideButtonText || "Edit"}
            </IonButton>
          )}
          {sidePill && (
            <div className="h-[20px] flex shrink-0 items-center px-[8px] py-[2.25] justify-center h-20px rounded-[10px] bg-cta-red">
              <div className="h-[20px] leading-[20px] text-[13px] my-[2.25] font-semibold text-white">
                {sidePillText}
              </div>
            </div>
          )}
        </div>
        {subTitle && (
          <>
            {subTitleIsError ? (
              <div className="p-[12px] rounded-[4px] bg-red-background mt-[5.5px] mb-[-12.5px]">
                <div className="font-text-acc-16px font-acc-normal leading-acc-lh tracking-default text-red-text">
                  {subTitle}
                </div>
              </div>
            ) : (
              <div className="accountCardSubTitle">{subTitle}</div>
            )}
          </>
        )}
        <div className="programCardContent">{children}</div>
      </div>
    </div>
  );
};

export default ProgramInfoCard;
