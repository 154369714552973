import { Page, Text, View } from "@react-pdf/renderer";
import DataController from "../../controllers/DataController";
import * as PDFStyles from "./PDFStyles";
import { IPDFIncidentCommanderTable } from "./PDFTypes";
import { StandardTag } from "../../Interfaces";

const PDFIncidentCommanderTable: React.FC<IPDFIncidentCommanderTable> = (props) => {
  return (
    <>
      {props.isMajorIncident && props.user.role && (
        <Page size="A4" style={PDFStyles.styles.page}>
          <View style={PDFStyles.styles.heading} fixed>
            <Text style={PDFStyles.styles.headingText}>{"ParaFolio"}</Text>
          </View>
          <View
            fixed
            style={PDFStyles.styles.pageHeader}
            render={({ pageNumber }) => (
              <>
                <Text style={PDFStyles.styles.pageHeaderText}>{"National Occupational Standards"}</Text>
                <Text style={PDFStyles.styles.pageNumber}>{pageNumber - 1}</Text>
              </>
            )}
          />
          <View style={PDFStyles.styles.pageInnerContainer}>
            <Text style={PDFStyles.styles.standardsHeaderOverview}>{"National Occupational Standards Overview"}</Text>
            <Text style={PDFStyles.styles.standardsHeaderDescription}>
              {`As ${DataController.getIndefiniteArticle(props.user?.role || "")} ${props.user?.role},`}
              {props.mandatoryStandards &&
                props.mandatoryStandards.length > 0 &&
                ` you are required to achieve ${props.mandatoryStandards.length} standard${props.mandatoryStandards.length === 1 ? "" : "s"} set out by NARU.`}
              {props.optionalStandards && props.optionalStandards.length > 0
                ? props.mandatoryStandards && props.mandatoryStandards.length > 0
                  ? ` There ${props.optionalStandards.length === 1 ? "is" : "are"} also ${props.optionalStandards.length} optional standard${props.optionalStandards.length === 1 ? "" : "s"} that you can meet.`
                  : ` there ${props.optionalStandards?.length === 1 ? "is" : "are"} ${props.optionalStandards?.length} optional standard${props.optionalStandards?.length === 1 ? "" : "s"} that you can meet. `
                : ""}
            </Text>
            {props.mandatoryStandards && props.mandatoryStandards?.length > 0 && (
              <View>
                <Text style={PDFStyles.styles.standardsTableTitle}>{"Mandatory Standards"}</Text>
                <View>
                  <View style={PDFStyles.styles.standardsTableHeader}>
                    <View style={PDFStyles.styles.standardsTableRowCode}>
                      <Text style={PDFStyles.styles.standardsTableHeaderBold}>{"Code"}</Text>
                    </View>
                    <View style={PDFStyles.styles.standardsTableRowDescription}>
                      <Text style={PDFStyles.styles.standardsTableHeaderBold}>{"Description"}</Text>
                    </View>
                    <View style={PDFStyles.styles.standardsTableRowEvidence}>
                      <Text style={PDFStyles.styles.standardsTableHeaderBold}>{"Evidence"}</Text>
                    </View>
                    <View style={PDFStyles.styles.standardsTableRowAchieved}>
                      <Text style={PDFStyles.styles.standardsTableHeaderBold}>{"Achieved"}</Text>
                    </View>
                  </View>
                  {props.mandatoryStandards.map((value: StandardTag, index) => {
                    const evidenceWithStandard = props.evidenceWithinLimit.filter((item) => {
                      const tags = item.standardTags?.map((item) => item.tag);

                      return tags ? tags.includes(value.Code) : false;
                    });

                    const achieved = evidenceWithStandard.length >= 3;

                    return (
                      <View style={PDFStyles.styles.standardsTableRow} key={value.Code}>
                        <View style={PDFStyles.styles.standardsTableRowCode}>
                          <Text style={PDFStyles.styles.standardsTableText}>{value.Code}</Text>
                        </View>
                        <View style={PDFStyles.styles.standardsTableRowDescription}>
                          <Text style={PDFStyles.styles.standardsTableText}>{value.Description || ""}</Text>
                        </View>
                        <View style={PDFStyles.styles.standardsTableRowEvidence}>
                          <Text style={PDFStyles.styles.standardsTableText}>{evidenceWithStandard.length}</Text>
                        </View>
                        <View style={PDFStyles.styles.standardsTableRowAchieved}>
                          <Text style={PDFStyles.styles.standardsTableText}>{achieved ? "✓" : "X"}</Text>
                        </View>
                      </View>
                    );
                  })}
                </View>
              </View>
            )}
            {props.optionalStandards && props.optionalStandards?.length > 0 && (
              <View>
                <Text style={PDFStyles.styles.standardsTableTitle}>{"Optional Standards"}</Text>
                <View>
                  <View style={PDFStyles.styles.standardsTableHeader}>
                    <View style={PDFStyles.styles.standardsTableRowCode}>
                      <Text style={PDFStyles.styles.standardsTableHeaderBold}>{"Code"}</Text>
                    </View>
                    <View style={PDFStyles.styles.standardsTableRowDescription}>
                      <Text style={PDFStyles.styles.standardsTableHeaderBold}>{"Description"}</Text>
                    </View>
                    <View style={PDFStyles.styles.standardsTableRowEvidence}>
                      <Text style={PDFStyles.styles.standardsTableHeaderBold}>{"Evidence"}</Text>
                    </View>
                    <View style={PDFStyles.styles.standardsTableRowAchieved}>
                      <Text style={PDFStyles.styles.standardsTableHeaderBold}>{"Achieved"}</Text>
                    </View>
                  </View>
                  {props.optionalStandards.map((value: StandardTag, index) => {
                    const evidenceWithStandard = props.evidenceWithinLimit.filter((item) => {
                      const tags = item.standardTags?.map((item) => item.tag);

                      return tags ? tags.includes(value.Code) : false;
                    });

                    const achieved = evidenceWithStandard.length >= 3;

                    return (
                      <View style={PDFStyles.styles.standardsTableRow} key={value.Code}>
                        <View style={PDFStyles.styles.standardsTableRowCode}>
                          <Text style={PDFStyles.styles.standardsTableText}>{value.Code}</Text>
                        </View>
                        <View style={PDFStyles.styles.standardsTableRowDescription}>
                          <Text style={PDFStyles.styles.standardsTableText}>{value.Description || ""}</Text>
                        </View>
                        <View style={PDFStyles.styles.standardsTableRowEvidence}>
                          <Text style={PDFStyles.styles.standardsTableText}>{evidenceWithStandard.length}</Text>
                        </View>
                        <View style={PDFStyles.styles.standardsTableRowAchieved}>
                          <Text style={PDFStyles.styles.standardsTableText}>{achieved ? "✓" : "X"}</Text>
                        </View>
                      </View>
                    );
                  })}
                </View>
              </View>
            )}
          </View>
        </Page>
      )}
    </>
  );
};

export default PDFIncidentCommanderTable;
