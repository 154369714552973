import { IonSpinner } from "@ionic/react";
import { ICertificateInfo, IDatePickerType, IUserProgramCertificate, ProgramCertificate } from "../../Interfaces";
import { ICertificatesList } from "../../types/Components";
import * as ProgramUtils from "../../utils/programUtils";
import { Icon_Attachment_Onboarding } from "../../assets/images";
import { format } from "date-fns";
import _ from "lodash";

const CertificatesList: React.FC<ICertificatesList> = (props) => {
  return (
    <>
      {props.certs.length > 0 &&
        props.certs.map((item: ProgramCertificate, index) => {
          const hasCertificate = _.find(
            props.uploads,
            (_item: ICertificateInfo) => _item.certificateType === item.Name
          );
          const existingCertificate = _.find(props.existingCertificates, (_item: IUserProgramCertificate) => {
            return (
              _item.programData.programName === props.program.Name && _item.certificate?.certificateType === item.Name
            );
          });

          let issueDate: any;

          // Find existing issue date
          if (existingCertificate && existingCertificate.issueDate) {
            issueDate = new Date(existingCertificate.issueDate).toISOString();
          }

          // Get inputted issue date if exists
          const _issueDate = props.issueDates.find((_item) => _item.type === item.Name);
          issueDate = _issueDate ? new Date(_issueDate?.date).toISOString() : issueDate;

          let expiryDate: any;

          // Find existing expiry date
          if (existingCertificate && existingCertificate.expiryDate) {
            expiryDate = new Date(existingCertificate.expiryDate).toISOString();
          }

          // Get inputted expiry date if exists
          const _expiryDate = props.expiryDates.find((_item) => _item.type === item.Name);
          expiryDate = _expiryDate ? new Date(_expiryDate?.date).toISOString() : expiryDate;

          let certLabel = `${item.Description || item.Name} certificate`;
          if (ProgramUtils.useNameAsCertLabel(props.program?.ID)) {
            certLabel = item.Name;
          }

          if (existingCertificate) {
            return (
              <div key={item.Name}>
                <div className="onboardingDateHeader" style={{ marginTop: index === 0 ? 24 : 32 }}>
                  {certLabel}
                </div>
                <div className="programCertificateAttachmentContainer">
                  <img src={Icon_Attachment_Onboarding} className="onboardingAttachmentIcon" alt="" />
                  <div className="onboardingAttachmentFilename">{existingCertificate.certificate?.name}</div>
                  {props.deletingCertificate &&
                  props.certificateType === existingCertificate.certificate?.certificateType ? (
                    <div className="onboardingAttachmentCancelSpinnerContainer">
                      <IonSpinner className="onboardingAttachmentCancelSpinner" />
                    </div>
                  ) : (
                    <div
                      className="onboardingAttachmentCancel"
                      onClick={() => props.removeExistingCertificate(existingCertificate)}
                    >
                      {"Remove"}
                    </div>
                  )}
                </div>
                <div>
                  <div className="onboardingDateHeader">{`Issue date${ProgramUtils.shouldInputDateOnSupportingEvidenceBeMandatory(props.program?.ID) ? " (Mandatory)" : ""}`}</div>
                  <div
                    id={`programRoleCertificateIssueDate-${item.Name}`}
                    className="onboardingDateContainer"
                    onClick={() =>
                      props.openDatePicker(
                        IDatePickerType.ISSUE,
                        item.Name,
                        new Date(issueDate) || new Date().toISOString()
                      )
                    }
                  >
                    <div className={`onboardingDateText ${!issueDate ? "onboardingDateTextSelected" : ""}`}>
                      {issueDate ? format(new Date(issueDate), "dd / MM / yyyy") : "Tap to add issue date"}
                    </div>
                  </div>
                </div>
                {!ProgramUtils.hideExpiryDateCertInput(props.program?.ID) && (
                  <div>
                    <div className="onboardingDateHeader">{"Expiry (if recertification required)"}</div>
                    <div
                      id={`programRoleCertificateExpiryDate-${item.Name}`}
                      className="onboardingDateContainer"
                      onClick={() =>
                        props.openDatePicker(
                          IDatePickerType.EXPIRY,
                          item.Name,
                          new Date(expiryDate) || new Date().toISOString()
                        )
                      }
                    >
                      <div className={`onboardingDateText ${!expiryDate ? "onboardingDateTextSelected" : ""}`}>
                        {expiryDate ? format(new Date(expiryDate), "dd / MM / yyyy") : "Tap to add expiry date"}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            );
          }

          if (hasCertificate) {
            return (
              <div key={item.Name}>
                <div className="onboardingDateHeader" style={{ marginTop: index === 0 ? 24 : 32 }}>
                  {certLabel}
                </div>
                <div className="programCertificateAttachmentContainer">
                  <img src={Icon_Attachment_Onboarding} className="onboardingAttachmentIcon" alt="" />
                  <div className="onboardingAttachmentFilename">{hasCertificate.name}</div>
                  {props.deletingCertificate && props.certificateType === hasCertificate.certificateType ? (
                    <div className="onboardingAttachmentCancelSpinnerContainer">
                      <IonSpinner className="onboardingAttachmentCancelSpinner" />
                    </div>
                  ) : (
                    <div
                      className="onboardingAttachmentCancel"
                      onClick={() => props.removeCertificateFromUpload(hasCertificate?.certificateType || "")}
                    >
                      {"Remove"}
                    </div>
                  )}
                </div>
                <div>
                  <div className="onboardingDateHeader">{`Issue date${ProgramUtils.shouldInputDateOnSupportingEvidenceBeMandatory(props.program?.ID) ? " (Mandatory)" : ""}`}</div>
                  <div
                    id={`programRoleCertificateIssueDate-${item.Name}`}
                    className="onboardingDateContainer"
                    onClick={() =>
                      props.openDatePicker(
                        IDatePickerType.ISSUE,
                        item.Name,
                        new Date(issueDate) || new Date().toISOString()
                      )
                    }
                  >
                    <div className={`onboardingDateText ${!issueDate ? "onboardingDateTextSelected" : ""}`}>
                      {issueDate ? format(new Date(issueDate), "dd / MM / yyyy") : "Tap to add issue date"}
                    </div>
                  </div>
                </div>
                {!ProgramUtils.hideExpiryDateCertInput(props.program?.ID) && (
                  <div>
                    <div className="onboardingDateHeader">{"Expiry (if recertification required)"}</div>
                    <div
                      id={`programRoleCertificateExpiryDate-${item.Name}`}
                      className="onboardingDateContainer"
                      onClick={() =>
                        props.openDatePicker(
                          IDatePickerType.EXPIRY,
                          item.Name,
                          new Date(expiryDate) || new Date().toISOString()
                        )
                      }
                    >
                      <div className={`onboardingDateText ${!expiryDate ? "onboardingDateTextSelected" : ""}`}>
                        {expiryDate ? format(new Date(expiryDate), "dd / MM / yyyy") : "Tap to add expiry date"}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            );
          }

          return (
            <div key={item.Name}>
              <div className="onboardingDateHeader" style={{ marginTop: index === 0 ? 24 : 32 }}>
                {certLabel}
              </div>
              {ProgramUtils.showDescriptionForCertificate(props.program?.ID) && item.Description && (
                <div className="mb-2 text-acc-13px font-inter font-acc-400">{item.Description}</div>
              )}
              <div className="onboardingDateContainer" onClick={() => props.addCertificate(item.Name)}>
                <div className="programCertificateAddText">
                  <>{"Tap to add certificate"}</>
                </div>
              </div>
            </div>
          );
        })}
    </>
  );
};

export default CertificatesList;
