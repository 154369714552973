import { useEffect, useRef, useState } from "react";
import { EvidencePart } from "../../../Interfaces";
import { Text, View } from "@react-pdf/renderer";
import { IonInput } from "@ionic/react";

type Props = {
  children?: React.ReactNode;
  style?: React.CSSProperties;
  definition: EvidencePart;
  onValueChanged?: (value: any, isInput?: boolean) => void;
  onInputBlurred: () => void;
  data?: any;
  isDisplay?: boolean;
  isPDF?: boolean;
  pdfStyles: any;
  initialValue?: any;
  id: string;
};

const NumberInput: React.FC<Props> = ({
  children,
  style,
  onValueChanged,
  onInputBlurred,
  data,
  definition,
  isDisplay,
  isPDF,
  pdfStyles,
  initialValue,
  id,
}) => {
  const ref = useRef<HTMLIonInputElement>(null);
  const regex = /^[0-9\b]+$/;

  const [value, setValue] = useState<string>(initialValue || "");

  const inputChanged = (event: any): void => {
    let _value = event?.target?.value || "";

    if (_value === "" || regex.test(_value)) {
      if (definition["Max value"] && Number(_value) > definition["Max value"]) {
        _value = `${definition["Max value"]}`;
      }

      setValue(_value);
      onValueChanged && onValueChanged(_value, true);
    }
  };

  const inputBlurred = (): void => {
    if (value !== initialValue) {
      onInputBlurred && onInputBlurred();
    }
  };

  useEffect(() => {
    if (!initialValue?.length) {
      setValue("");
    }
  }, [id]);

  if (isPDF) {
    if (definition["Display Title"] === "Title") {
      return null;
    }

    return (
      <View>
        <Text style={pdfStyles.reflectionHeader}>{(definition["Display Title"] || definition.Name) + ":"}</Text>
        <Text style={pdfStyles.evidenceDate}>{data}</Text>
      </View>
    );
  }

  if (isDisplay) {
    if (definition["Display Title"] === "Title") {
      return <div className="readEvidenceTitle">{data}</div>;
    }

    return (
      <div className="readEvidenceBlock">
        <div className="readEvidenceHeader">{definition["Display Title"] || definition.Name}</div>
        <div className="readEvidenceText">{data}</div>
        <div className="h-[1px] w-full bg-grey-30 mt-4" />
      </div>
    );
  }

  return (
    <div>
      <input
        className="reflectionTagsInput"
        placeholder={definition?.["Placeholder Text"] || "Enter a value..."}
        autoComplete="off"
        value={value}
        onChange={inputChanged}
        onBlur={inputBlurred}
        inputMode="numeric"
        min={0}
        max={definition["Max value"] || undefined}
      />
    </div>
  );
};

export default NumberInput;
