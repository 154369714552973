import React from "react";
import { Icon_Close_Callout, Icon_Info_Draft } from "../../assets/images";
import { IEvidenceDraftsHint } from "../../types/Components";

const EvidenceDraftsHint: React.FC<IEvidenceDraftsHint> = (props) => {
  return (
    <div className="flex justify-center mt-[16px]">
      <div className="relative pt-[2px] flex max-w-app-column w-full flex-col bg-[#FEEBC8] ml-safe-area-l mr-safe-area-r rounded-[4px] border-[1px] border-solid border-[#F6AD55] shadow-evidence-drafts-hint">
        <div className="flex flex-row items-center">
          <img alt="Info icon" className="w-icon h-icon img-no-select" src={Icon_Info_Draft} />
          <div className="w-full text-acc-16px font-bold leading-default tracking-default text-grey-90">
            {"Save evidence as draft"}
          </div>
          <button onClick={props.buttonPressed}>
            <img alt="" className="w-icon h-icon img-no-select" src={Icon_Close_Callout} />
          </button>
        </div>
        <div className="mx-[12px] mb-[12px] text-acc-13px font-medium leading-default tracking-[-0.1px] text-grey-90">
          {"Start a new evidence and finish it later using drafts."}
        </div>
      </div>
    </div>
  );
};

export default EvidenceDraftsHint;
