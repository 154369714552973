import { IonContent } from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Route } from "react-router";
import { useRecoilState, useRecoilStateLoadable, useRecoilValueLoadable, useSetRecoilState } from "recoil";
import {
  accessibilityAtom,
  appInfoAtom,
  deviceInfoAtom,
  evidenceAtom,
  parapassDataAtom,
  plusDataAtom,
  progressCheckDataAtom,
  userAtom,
  userProgramsAtom,
} from "./state/State";
import Tabs from "./Tabs";
import _ from "lodash";
import { DatabaseService } from "./controllers/DatabaseService";
import DataController from "./controllers/DataController";
import {
  AllProgressCheckStatuses,
  IAccessibilitySettings,
  IClassSubscription,
  IEvidence,
  IParapassUsageData,
  IPlusUsageData,
  ISubscriptionModalType,
  IUser,
} from "./Interfaces";
import AuthenticationController from "./controllers/AuthenticationController";
import { Device, DeviceInfo } from "@capacitor/device";
import { App, AppInfo } from "@capacitor/app";
import OnboardingRoutes from "./OnboardingRoutes";
import SubscriptionsModal from "./components/profile/SubscriptionsModal";
import { EventRegister } from "react-native-event-listeners";
import { addDays, format, isAfter, isSameDay, startOfDay } from "date-fns";
import { Capacitor } from "@capacitor/core";
import Menu from "./pages/menu/Menu";
import IAPService from "./controllers/IAPService";
import DraftSavedToast from "./components/evidence/DraftSavedToast";
import { ProgressCheckService } from "./controllers/ProgressCheckService";
import * as ProgramUtils from "./utils/programUtils";
import { DATA_REFRESH_INTERVAL, USAGE_DATA_REFRESH_INTERVAL } from "./Constants";
import { useProgramData } from "./hooks/data/useProgramData";
import ProgramDataUpdateToast from "./components/common/ProgramDataUpdateToast";
import DeepLinkingListener from "./components/app/DeepLinkingListener";

const PortfolioPlus: React.FC = () => {
  const routerRef = useRef(null);

  const [user, setUser] = useRecoilState(userAtom);
  const [evidence, setEvidence] = useRecoilStateLoadable<IEvidence[] | null>(evidenceAtom);
  const setPlusData = useSetRecoilState<IPlusUsageData[] | null>(plusDataAtom);
  const setParapassData = useSetRecoilState<IParapassUsageData | null>(parapassDataAtom);
  const setProgressCheckData = useSetRecoilState<AllProgressCheckStatuses[]>(progressCheckDataAtom);
  const accessibilitySettings = useRecoilValueLoadable<IAccessibilitySettings | null>(accessibilityAtom);

  const { userPrograms } = useProgramData(true);

  const setDeviceInfo = useSetRecoilState<DeviceInfo | null>(deviceInfoAtom);
  const setAppInfo = useSetRecoilState<AppInfo | null>(appInfoAtom);
  const [loggedIn, setLoggedIn] = useState<boolean>(!_.isEmpty(user));

  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [modalType, setModalType] = useState<ISubscriptionModalType>(ISubscriptionModalType.ONBOARDING);

  const refreshUserDetails = useCallback(
    async (_user: IUser): Promise<void> => {
      try {
        if (_user) {
          let userDetails = await DatabaseService.getUserDetails(_user);

          if (userDetails && userDetails.contactID === `${_user.userData.contactID}`) {
            let userToSave: IUser = {
              ..._user,
              certificates: userDetails.certificates ? JSON.parse(userDetails.certificates) : undefined,
              usageData: userDetails.dataSharing === 1,
              role: userDetails.role,
              ...(userDetails.personalStatement && {
                personalStatement: userDetails.personalStatement,
              }),
              ...(userDetails.summaryOfWork && {
                summaryOfWork: userDetails.summaryOfWork,
              }),
              ...(userDetails.personalStatementTimestamp && {
                personalStatementTimestamp: userDetails.personalStatementTimestamp,
              }),
              ...(userDetails.summaryOfWorkTimestamp && {
                summaryOfWorkTimestamp: userDetails.summaryOfWorkTimestamp,
              }),
              ...(userDetails.HCPCNumber && {
                HCPCNumber: userDetails.HCPCNumber,
              }),
            };

            userToSave = await AuthenticationController.getUserData(userToSave);

            setUser(userToSave);
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
    [setUser]
  );

  const refreshProgressCheckData = useCallback(
    async (_user: IUser): Promise<void> => {
      console.log("refreshProgressCheckData() called");
      try {
        const programIDs = ProgramUtils.getProgramIDsFromSubscriptions(_user?.userData.subscriptions, userPrograms);
        const progressCheckProgramIDs = ProgramUtils.filterProgressCheckProgramIDs(programIDs);

        if (progressCheckProgramIDs?.length > 0) {
          let data = [];

          for (let i = 0; i < progressCheckProgramIDs.length; i++) {
            let result = await ProgressCheckService.getAllProgressCheckStatuses(_user, progressCheckProgramIDs[i]);
            data.push({ ...result, programID: progressCheckProgramIDs[i] });
          }

          setProgressCheckData(data);
        }
      } catch (error) {
        console.log(error);
      }
    },
    [setProgressCheckData]
  );

  const refreshUserEvidence = useCallback(async (_user: IUser): Promise<void> => {
    try {
      let allEvidence = [];
      allEvidence = await DatabaseService.getEvidence(_user);

      // Check if evidence from server is equal to current evidence
      if (!_.isEqual(evidence.contents, allEvidence)) {
        await DataController.saveEvidence(allEvidence);
        setEvidence(allEvidence);
      }
    } catch (error) {
      console.log(error);
    }
  }, []);

  useEffect(() => {
    const checkSubscriptionRenewal = async () => {
      let entitlements = await IAPService.restoreSubscription();
      const allSubscriptions = user?.userData ? user.userData?.subscriptions : [];
      const latestSubscription = DataController.getLatestPortfolioSubscription(allSubscriptions);

      if (entitlements.success && entitlements.subs.length > 0) {
        const latest = entitlements.subs[0];

        if (latest && latestSubscription) {
          const dateSeconds = parseInt(latestSubscription.ExpiryDate.replace("/Date(", "").replace(")/", ""), 10);
          const dateCurrent = new Date(dateSeconds);
          const dateRenewal = new Date(latest.endDate!);

          if (!isSameDay(dateCurrent, dateRenewal) && isAfter(dateRenewal, dateCurrent)) {
            let response = await IAPService.sendPurchaseInfo(
              `${user.userData.contactID}`,
              format(new Date(latest.startDate!), "yyyy-MM-dd"),
              latest.transactionId!,
              latest.productId!,
              Capacitor.getPlatform() === "ios" ? "iOS" : "Android",
              user.token!,
              format(new Date(latest.endDate!), "yyyy-MM-dd")
            );

            if (response) {
              let userToSave = await AuthenticationController.getUserData(user);

              if (userToSave) {
                setUser(userToSave);
              }
            }
          }
        }
      }
    };

    if (loggedIn && user) {
      if (Capacitor.getPlatform() !== "web") {
        checkSubscriptionRenewal();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    EventRegister.addEventListener("subscriptions/show-modal", (modalType: ISubscriptionModalType) => {
      setModalVisible(true);
      setModalType(modalType);
    });

    return () => {
      EventRegister.removeEventListener("subscriptions/show-modal");
    };
  }, []);

  useEffect(() => {
    EventRegister.addEventListener("subscriptions/refresh-user", (_user) => refreshUserDetails(_user));

    return () => {
      EventRegister.removeEventListener("subscriptions/refresh-user");
    };
  }, [refreshUserDetails]);

  useEffect(() => {
    EventRegister.addEventListener("comments/comment-sent", (_user) => refreshProgressCheckData(_user));
    EventRegister.addEventListener("comments/read-time-updated", (_user) => refreshProgressCheckData(_user));
    EventRegister.addEventListener("progress-check/submitted", (_user) => refreshProgressCheckData(_user));
    EventRegister.addEventListener("progress-check/created", (_user) => refreshProgressCheckData(_user));
    EventRegister.addEventListener("program/refresh-button", async (_user) => {
      await refreshProgressCheckData(_user);
      await refreshUserEvidence(_user);
      EventRegister.emit("program/data-refreshed");
    });

    return () => {
      EventRegister.removeEventListener("comments/comment-sent");
      EventRegister.removeEventListener("comments/read-time-updated");
      EventRegister.removeEventListener("progress-check/submitted");
      EventRegister.removeEventListener("progress-check/created");
      EventRegister.removeEventListener("program/refresh-button");
    };
  }, [refreshProgressCheckData]);

  useEffect(() => {
    if (accessibilitySettings.contents?.fontFamily) {
      document.documentElement.style.setProperty("--ion-font-family", accessibilitySettings.contents.fontFamily);
    } else {
      document.documentElement.style.setProperty("--ion-font-family", "Inter");
    }

    if (accessibilitySettings.contents?.boldText) {
      document.documentElement.style.setProperty("--accessibility-bold-text", "bold");
      document.documentElement.style.setProperty("--accessibility-bold-text-400", "bold");
      document.documentElement.style.setProperty("--accessibility-bold-text-500", "bold");
      document.documentElement.style.setProperty("--accessibility-bold-text-600", "bold");
    } else {
      document.documentElement.style.setProperty("--accessibility-bold-text", "normal");
      document.documentElement.style.setProperty("--accessibility-bold-text-400", "400");
      document.documentElement.style.setProperty("--accessibility-bold-text-500", "500");
      document.documentElement.style.setProperty("--accessibility-bold-text-600", "600");
    }

    if (accessibilitySettings.contents?.underlineButtons) {
      document.documentElement.style.setProperty("--accessibility-underline-links", "underline");
    } else {
      document.documentElement.style.setProperty("--accessibility-underline-links", "initial");
    }

    if (accessibilitySettings.contents?.lineHeightChange) {
      document.documentElement.style.setProperty(
        "--accessibility-line-height",
        accessibilitySettings.contents.lineHeightChange
      );
    } else {
      document.documentElement.style.setProperty("--accessibility-line-height", "1.5");
    }

    if (accessibilitySettings.contents?.fontSizeChange || accessibilitySettings.contents?.fontSizeChange === 0) {
      document.documentElement.style.setProperty(
        "--accessibility-font-size",
        `${accessibilitySettings.contents.fontSizeChange}px`
      );
    } else {
      document.documentElement.style.setProperty("--accessibility-font-size", "0px");
    }
  }, [accessibilitySettings.contents]);

  useEffect(() => {
    setLoggedIn(!_.isEmpty(user));
  }, [user]);

  useEffect(() => {
    const checkHasAccess = async (): Promise<void> => {
      let latestSubscription: IClassSubscription | null = null;
      const subscriptions = user.userData.subscriptions;

      if (subscriptions && subscriptions.length > 0) {
        latestSubscription = DataController.getLatestPortfolioSubscription(subscriptions);
        // console.log(latestSubscription);

        if (!latestSubscription) {
          EventRegister.emit("subscriptions/show-modal", ISubscriptionModalType.PROFILE);
        } else {
          let valid = false;
          const dateSeconds = parseInt(latestSubscription.ExpiryDate.replace("/Date(", "").replace(")/", ""), 10);
          const withGrace = startOfDay(addDays(new Date(dateSeconds), 2));

          if (dateSeconds === 0) {
            valid = true;
          } else if (isAfter(withGrace, new Date())) {
            valid = true;
          } else if (isSameDay(new Date(), withGrace)) {
            valid = true;
          }

          if (!valid) {
            EventRegister.emit("subscriptions/show-modal", ISubscriptionModalType.PROFILE);
          }
        }
      } else {
        EventRegister.emit("subscriptions/show-modal", ISubscriptionModalType.PROFILE);
      }
    };

    if (loggedIn && user) {
      checkHasAccess();
    }
  }, [loggedIn, user]);

  useEffect(() => {
    const getDeviceInfo = async (): Promise<void> => {
      try {
        const info = await Device.getInfo();

        setDeviceInfo(info);
      } catch (error) {
        console.log(error);
      }
    };

    const getAppInfo = async (): Promise<void> => {
      try {
        if (!(await DataController.isWebAsync())) {
          const info = await App.getInfo();

          setAppInfo(info);
        }
      } catch (error) {
        console.log(error);
      }
    };

    getDeviceInfo();
    getAppInfo();
  }, [setAppInfo, setDeviceInfo]);

  // Get user details from server and update user object
  useEffect(() => {
    const getUserDetails = async (): Promise<void> => {
      try {
        let userDetails = await DatabaseService.getUserDetails(user);

        if (userDetails && userDetails.contactID === `${user.userData.contactID}`) {
          let userToSave: IUser = {
            ...user,
            certificates: userDetails.certificates ? JSON.parse(userDetails.certificates) : undefined,
            usageData: userDetails.dataSharing === 1,
            role: userDetails.role,
            ...(userDetails.personalStatement && {
              personalStatement: userDetails.personalStatement,
            }),
            ...(userDetails.summaryOfWork && {
              summaryOfWork: userDetails.summaryOfWork,
            }),
            ...(userDetails.personalStatementTimestamp && {
              personalStatementTimestamp: userDetails.personalStatementTimestamp,
            }),
            ...(userDetails.summaryOfWorkTimestamp && {
              summaryOfWorkTimestamp: userDetails.summaryOfWorkTimestamp,
            }),
            ...(userDetails.HCPCNumber && {
              HCPCNumber: userDetails.HCPCNumber,
            }),
            programCertificates: userDetails.programCertificates
              ? JSON.parse(userDetails.programCertificates)
              : undefined,
            programRoles: userDetails.programRoles ? JSON.parse(userDetails.programRoles) : undefined,
          };

          userToSave = await AuthenticationController.getUserData(userToSave);

          setUser(userToSave);
        }
      } catch (error) {
        console.log(error);
      }
    };

    if (loggedIn && user) {
      getUserDetails();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Get user evidence from database and update user
  useEffect(() => {
    const getUserEvidence = async (): Promise<void> => {
      try {
        if (loggedIn && user) {
          let allEvidence = [];
          allEvidence = await DatabaseService.getEvidence(user);

          // Check if evidence from server is equal to current evidence
          if (!_.isEqual(evidence.contents, allEvidence)) {
            await DataController.saveEvidence(allEvidence);
            setEvidence(allEvidence);
          }
        }
      } catch (error) {
        console.log(error);
      }
    };

    const interval = setInterval(() => {
      getUserEvidence();
    }, DATA_REFRESH_INTERVAL);

    return () => {
      try {
        clearInterval(interval);
      } catch (error) {
        console.log(error);
      }
    };
  }, [evidence, loggedIn, setEvidence, user]);

  useEffect(() => {
    const getUserEvidence = async (): Promise<void> => {
      try {
        if (user) {
          let allEvidence = [];
          allEvidence = await DatabaseService.getEvidence(user);
          await DataController.saveEvidence(allEvidence);
          setEvidence(allEvidence);
        }
      } catch (error) {
        console.log(error);
      }
    };

    if (loggedIn && user) {
      getUserEvidence();
    }
  }, [loggedIn, setEvidence, user]);

  useEffect(() => {
    const getJRCALCPlusData = async (): Promise<void> => {
      try {
        if (loggedIn && user) {
          let data = [];
          data = await DatabaseService.getJRCALCPLusTimelineData(user);
          await DataController.saveJRCALCPlusTimelineData(data);
          setPlusData(data);
        }
      } catch (error) {
        console.log(error);
      }
    };

    const getParapassData = async (): Promise<void> => {
      try {
        if (loggedIn && user) {
          let data = [];
          data = await DatabaseService.getParapassUsageData(user);
          await DataController.saveParapassUsageData(data);
          setParapassData(data);
        }
      } catch (error) {
        console.log(error);
      }
    };

    const getProgressCheckData = async (): Promise<void> => {
      try {
        const programIDs = ProgramUtils.getProgramIDsFromSubscriptions(user?.userData.subscriptions, userPrograms);
        const progressCheckProgramIDs = ProgramUtils.filterProgressCheckProgramIDs(programIDs);

        let data = [];

        for (let i = 0; i < progressCheckProgramIDs.length; i++) {
          let result = await ProgressCheckService.getAllProgressCheckStatuses(user, progressCheckProgramIDs[i]);
          data.push({ ...result, programID: progressCheckProgramIDs[i] });
        }

        if (loggedIn && user) {
          setProgressCheckData(data);
        }
      } catch (error) {
        console.log(error);
      }
    };

    const interval = setInterval(() => {
      getJRCALCPlusData();
      getParapassData();
    }, USAGE_DATA_REFRESH_INTERVAL);

    const progressCheckDataInterval = setInterval(() => {
      if (loggedIn && user) {
        getProgressCheckData();
      }
    }, DATA_REFRESH_INTERVAL);

    return () => {
      try {
        clearInterval(interval);
        clearInterval(progressCheckDataInterval);
      } catch (error) {
        console.log(error);
      }
    };
  }, [loggedIn, setParapassData, setPlusData, setProgressCheckData, user, userPrograms]);

  useEffect(() => {
    const getJRCALCPlusData = async (): Promise<void> => {
      try {
        let data = [];
        data = await DatabaseService.getJRCALCPLusTimelineData(user);
        await DataController.saveJRCALCPlusTimelineData(data);
        setPlusData(data);
      } catch (error) {
        console.log(error);
      }
    };

    const getParapassData = async (): Promise<void> => {
      try {
        let data = [];
        data = await DatabaseService.getParapassUsageData(user);
        await DataController.saveParapassUsageData(data);
        setParapassData(data);
      } catch (error) {
        console.log(error);
      }
    };

    const getProgressCheckData = async (): Promise<void> => {
      try {
        const programIDs = ProgramUtils.getProgramIDsFromSubscriptions(user?.userData.subscriptions, userPrograms);
        const progressCheckProgramIDs = ProgramUtils.filterProgressCheckProgramIDs(programIDs);

        let data = [];

        for (let i = 0; i < progressCheckProgramIDs.length; i++) {
          let result = await ProgressCheckService.getAllProgressCheckStatuses(user, progressCheckProgramIDs[i]);
          data.push({ ...result, programID: progressCheckProgramIDs[i] });
        }

        setProgressCheckData(data);
      } catch (error) {
        console.log(error);
      }
    };

    if (loggedIn && user) {
      getJRCALCPlusData();
      getParapassData();
      getProgressCheckData();
    }
  }, [loggedIn, setParapassData, setPlusData, setProgressCheckData, user, userPrograms]);

  const continueWithTrial = (): void => {
    EventRegister.emit("subscription/continue");
    setModalVisible(false);
  };

  if (Capacitor.getPlatform() === "ios") {
    return (
      <>
        <SubscriptionsModal
          visible={modalVisible}
          closeModal={() => setModalVisible(false)}
          type={modalType}
          continueWithTrial={() => continueWithTrial()}
        />
        <IonReactRouter ref={routerRef}>
          <DeepLinkingListener />
          {loggedIn && <Menu />}
          <Route path="/" render={(props) => (loggedIn ? <Tabs /> : <OnboardingRoutes />)} />
        </IonReactRouter>
      </>
    );
  }

  return (
    <IonContent>
      <SubscriptionsModal
        visible={modalVisible}
        closeModal={() => setModalVisible(false)}
        type={modalType}
        continueWithTrial={() => continueWithTrial()}
      />
      <ProgramDataUpdateToast />
      <DraftSavedToast />
      <IonReactRouter ref={routerRef}>
        <DeepLinkingListener />
        {loggedIn && <Menu />}
        <Route path="/" render={(props) => (loggedIn ? <Tabs /> : <OnboardingRoutes />)} />
      </IonReactRouter>
    </IonContent>
  );
};

export default PortfolioPlus;
