import { IonToast } from "@ionic/react";
import { useEffect, useState } from "react";
import { EventRegister } from "react-native-event-listeners";
import { useDebugUser } from "../../hooks/auth/useDebugUser";
import { useAppFocus } from "../../hooks/app/useAppFocus";

const ProgramDataUpdateToast: React.FC = () => {
  const { isDebugUser } = useDebugUser();
  const appInForeground = useAppFocus();

  const [visible, setVisible] = useState<boolean>(false);
  const [firstLoad, setIsFirstLoad] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [updateComplete, setUpdateComplete] = useState<boolean>(false);
  const [updateHasErrors, setUpdateHasErrors] = useState<boolean>(false);

  useEffect(() => {
    EventRegister.addEventListener("ota/update-started", (firstLoad: boolean) => start(firstLoad));
    EventRegister.addEventListener("ota/update-completed", (showUpdated?: boolean) => complete(showUpdated));
    EventRegister.addEventListener("ota/update-errored", () => errors());

    return () => {
      EventRegister.removeEventListener("ota/update-started");
      EventRegister.removeEventListener("ota/update-completed");
      EventRegister.removeEventListener("ota/update-errored");
    };
  }, []);

  const start = (firstLoad: boolean): void => {
    if (appInForeground) {
      setUpdateComplete(false);
      setIsFirstLoad(firstLoad);
      setUpdateHasErrors(false);
      setVisible(true);
      setLoading(true);
    }
  };

  const complete = (showUpdated?: boolean): void => {
    if (showUpdated) {
      setUpdateComplete(true);
      setLoading(false);
      setUpdateHasErrors(false);

      setTimeout(() => {
        setVisible(false);
      }, 1500);
    } else {
      setVisible(false);
    }
  };

  const errors = (): void => {
    setUpdateHasErrors(true);
    setUpdateComplete(false);
    setLoading(false);

    setTimeout(() => {
      setVisible(false);
    }, 1500);
  };

  const getToastMessage = (): string => {
    if (isDebugUser) {
      if (loading) {
        return firstLoad ? "Fetching debug data..." : "Updating debug data...";
      } else if (updateComplete) {
        return "Debug data updated!";
      } else if (updateHasErrors) {
        return "An error occurred!";
      }
    } else {
      if (loading) {
        return firstLoad ? "Fetching program data..." : "Updating program data...";
      } else if (updateComplete) {
        return "Program data updated!";
      } else if (updateHasErrors) {
        return "An error occurred!";
      }
    }

    return "";
  };

  if (!appInForeground) return null;

  return (
    <IonToast
      cssClass={`program-data-update-toast ${updateHasErrors ? "toast-200" : ""}`}
      icon=""
      isOpen={visible}
      message={getToastMessage()}
      mode="ios"
      onDidDismiss={() => setVisible(false)}
      position="bottom"
    />
  );
};

export default ProgramDataUpdateToast;
