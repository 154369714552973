import { IonButton } from "@ionic/react";
import React from "react";
import { Image_Evidence_Empty_State } from "../../assets/images";
import { IBlankEvidenceView } from "../../types/Components";

const BlankEvidenceView: React.FC<IBlankEvidenceView> = (props) => {
  return (
    <div className="min-h-evidence-empty-container mx-auto !max-w-app-column flex self-stretch flex-col justify-center items-center w-full mb-[-90px] pb-0 px-[16px]">
      <div className="evidenceEmptyHeader">{"Add some evidence"}</div>
      <img src={Image_Evidence_Empty_State} className="evidenceEmptyImage" alt="" />
      <div className="evidenceEmptyText">
        {"All your added evidence will appear here, where you can sort, filter and export your evidence into a " +
          "single file ready for audit. Try adding a piece of evidence now to get your portfolio started."}
      </div>
      <IonButton onClick={props.addEvidence} className="addEvidenceButton">
        <div>{"Add evidence"}</div>
      </IonButton>
    </div>
  );
};

export default BlankEvidenceView;
