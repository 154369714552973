import { Button_Checkbox_Active, Button_Checkbox_Inactive } from "../../assets/images";
import { IAuditTickbox } from "../../types/Components";
import { StringUtils } from "../../utils/stringUtils";

const AuditTickbox: React.FC<IAuditTickbox> = (props) => {
  return (
    <div className="flex flex-row items-center ml-[-10px] cursor-pointer mb-[24px]" onClick={() => props.toggleValue()}>
      <img
        alt=""
        className="h-icon w-icon img-no-select"
        src={props.active ? Button_Checkbox_Active : Button_Checkbox_Inactive}
      />
      <div className="text-17px font-acc-normal leading-[1.53] tracking-[-0.3px] text-grey-90 acc-underline decoration-current">
        {StringUtils.getAuditTickboxText()}
      </div>
    </div>
  );
};

export default AuditTickbox;
