import { IProfileSettingsCard } from "../../Interfaces";
import "./ProfileComponents.css";

const ProfileSettingsCard = ({ title, children }: IProfileSettingsCard) => {
  return (
    <div className="profileSettings">
      <div className="profileSettingsTitleContainer">
        <div className="profileSettingsTitle">{title}</div>
      </div>
      <div>{children}</div>
    </div>
  );
};

export default ProfileSettingsCard;
